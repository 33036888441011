
define("json!data/BuildingSyncSchema.json", function(){ return {
  "$schema": "http://json-schema.org/draft-04/schema#",
  "additionalProperties": false,
  "definitions": {
    ".auc:Address": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "auc:City": {
          "additionalProperties": false,
          "description": "The city for the Address Type.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:Country": {
          "additionalProperties": false,
          "description": "Country of the Address.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:County": {
          "additionalProperties": false,
          "description": "The county for the Address Type.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:PostalCode": {
          "additionalProperties": false,
          "description": "The 5 digit postal code for the Address Type. NNNNN",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:PostalCodePlus4": {
          "additionalProperties": false,
          "description": "The 4-digit add-on to the postal code in which the state is located. NNNN",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:State": {
          "additionalProperties": false,
          "description": "The state for the address type, following the ISO 3166-2 Region code for US states.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/auc:State"
            }
          },
          "type": "object"
        },
        "auc:StreetAddressDetail": {
          "additionalProperties": false,
          "description": "Choice of simplified or more complex address format.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:Complex": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:StreetAdditionalInfo": {
                  "additionalProperties": false,
                  "description": "Information other than a prefix or suffix for the street portion of a postal address.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:StreetDirPrefix": {
                  "additionalProperties": false,
                  "description": "The direction indicator that precedes the street name.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "North",
                        "Northeast",
                        "East",
                        "Southeast",
                        "South",
                        "Southwest",
                        "West",
                        "Northwest"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:StreetDirSuffix": {
                  "additionalProperties": false,
                  "description": "The direction indicator that follows a street address.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "North",
                        "Northeast",
                        "East",
                        "Southeast",
                        "South",
                        "Southwest",
                        "West",
                        "Northwest"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:StreetName": {
                  "additionalProperties": false,
                  "description": "The street name portion of a street address.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:StreetNumberNumeric": {
                  "additionalProperties": false,
                  "description": "The numeric identifier for a land parcel, house, building, or other location along a thoroughfare or within a community.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:integer"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:StreetNumberPrefix": {
                  "additionalProperties": false,
                  "description": "The portion of the complete address number which precedes the Address Number itself.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:StreetNumberSuffix": {
                  "additionalProperties": false,
                  "description": "The portion of the complete address number which follows the Address Number itself. In some areas the street number may contain non-numeric characters. This field can also contain extensions and modifiers to the street number, such as \"1/2\" or \"-B\". This street number field should not include Prefixes, Direction or Suffixes.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:StreetSuffix": {
                  "additionalProperties": false,
                  "description": "The suffix portion of a street address.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Alley",
                        "Annex",
                        "Arcade",
                        "Avenue",
                        "Bayou",
                        "Beach",
                        "Bend",
                        "Bluff",
                        "Bluffs",
                        "Bottom",
                        "Boulevard",
                        "Branch",
                        "Bridge",
                        "Brook",
                        "Brooks",
                        "Burg",
                        "Burgs",
                        "Bypass",
                        "Camp",
                        "Canyon",
                        "Cape",
                        "Causeway",
                        "Center",
                        "Centers",
                        "Circle",
                        "Circles",
                        "Cliff",
                        "Club",
                        "Common",
                        "Commons",
                        "Corner",
                        "Corners",
                        "Course",
                        "Court",
                        "Courts",
                        "Cove",
                        "Coves",
                        "Creek",
                        "Crescent",
                        "Crest",
                        "Crossing",
                        "Crossroad",
                        "Crossroads",
                        "Curve",
                        "Dale",
                        "Dam",
                        "Divide",
                        "Drive",
                        "Drives",
                        "Estate",
                        "Estates",
                        "Expressway",
                        "Extension",
                        "Extensions",
                        "Fall",
                        "Falls",
                        "Ferry",
                        "Field",
                        "Fields",
                        "Flat",
                        "Flats",
                        "Ford",
                        "Fords",
                        "Forest",
                        "Forge",
                        "Forges",
                        "Fork",
                        "Forks",
                        "Fort",
                        "Freeway",
                        "Garden",
                        "Gardens",
                        "Gateway",
                        "Glen",
                        "Glens",
                        "Green",
                        "Greens",
                        "Grove",
                        "Groves",
                        "Harbor",
                        "Harbors",
                        "Haven",
                        "Heights",
                        "Highway",
                        "Hill",
                        "Hills",
                        "Hollow",
                        "Inlet",
                        "Island",
                        "Islands",
                        "Isle",
                        "Junction",
                        "Junctions",
                        "Key",
                        "Keys",
                        "Knoll",
                        "Knolls",
                        "Lake",
                        "Lakes",
                        "Land",
                        "Landing",
                        "Lane",
                        "Light",
                        "Lights",
                        "Loaf",
                        "Lock",
                        "Locks",
                        "Lodge",
                        "Loop",
                        "Mall",
                        "Manor",
                        "Manors",
                        "Meadow",
                        "Meadows",
                        "Mews",
                        "Mill",
                        "Mills",
                        "Mission",
                        "Motorway",
                        "Mount",
                        "Mountain",
                        "Mountains",
                        "Neck",
                        "Orchard",
                        "Oval",
                        "Overpass",
                        "Park",
                        "Parks",
                        "Parkway",
                        "Parkways",
                        "Pass",
                        "Passage",
                        "Path",
                        "Pike",
                        "Pine",
                        "Pines",
                        "Place",
                        "Plain",
                        "Plains",
                        "Plaza",
                        "Point",
                        "Points",
                        "Port",
                        "Ports",
                        "Prairie",
                        "Radial",
                        "Ramp",
                        "Ranch",
                        "Rapid",
                        "Rapids",
                        "Rest",
                        "Ridge",
                        "Ridges",
                        "River",
                        "Road",
                        "Roads",
                        "Route",
                        "Row",
                        "Rue",
                        "Run",
                        "Shoal",
                        "Shoals",
                        "Shore",
                        "Shores",
                        "Skyway",
                        "Spring",
                        "Springs",
                        "Spur",
                        "Spurs",
                        "Square",
                        "Squares",
                        "Station",
                        "Stravenue",
                        "Stream",
                        "Street",
                        "Streets",
                        "Summit",
                        "Terrace",
                        "Throughway",
                        "Trace",
                        "Track",
                        "Trafficway",
                        "Trail",
                        "Trailer",
                        "Tunnel",
                        "Turnpike",
                        "Underpass",
                        "Union",
                        "Unions",
                        "Valley",
                        "Valleys",
                        "Viaduct",
                        "View",
                        "Views",
                        "Village",
                        "Villages",
                        "Ville",
                        "Vista",
                        "Walk",
                        "Walks",
                        "Wall",
                        "Way",
                        "Ways",
                        "Well",
                        "Wells"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:StreetSuffixModifier": {
                  "additionalProperties": false,
                  "description": "An extension or prefix for the street suffix.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:SubaddressIdentifier": {
                  "additionalProperties": false,
                  "description": "The letters, numbers, words, or combination thereof used to distinguish different subaddresses of the same type when several occur within the same feature. For example, in subaddress \"Building 4\", the Subaddress Identifier = \"4\". Subaddress Identifier can also be parts of a building, for example \"Penthouse\" or \"Mezzanine\".",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:SubaddressType": {
                  "additionalProperties": false,
                  "description": "The type of subaddress to which the associated Subaddress Identifier applies.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Apartment",
                        "Basement",
                        "Berth",
                        "Block",
                        "Building",
                        "Corridor",
                        "Cubicle",
                        "Department",
                        "Floor",
                        "Front",
                        "Hanger",
                        "Key",
                        "Lobby",
                        "Lot",
                        "Lower",
                        "Office",
                        "Penthouse",
                        "Pier",
                        "PO Box",
                        "Rear",
                        "Room",
                        "Seat",
                        "Side",
                        "Slip",
                        "Space",
                        "Stop",
                        "Suite",
                        "Terminal",
                        "Tower",
                        "Trailer",
                        "Unit",
                        "Upper",
                        "Wing"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Simplified": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:StreetAdditionalInfo": {
                  "additionalProperties": false,
                  "description": "Information other than a prefix or suffix for the street portion of a postal address.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:StreetAddress": {
                  "additionalProperties": false,
                  "description": "Street Address. This address can be defined multiple times for situations where that is needed for one premises, such as a complex of buildings. This address represents a complete street address, including street number, street name, prefixes, suffixes, modifiers, and unit number. It is assumed that a street address is either represented in this way, as a complete address, or is broken up into it's various components, using the terms\"Street Number\", \"Street Number Numeric\", \"Street Dir Prefix\", \"Street Name\", \"Street Additional Info\", \"Street Suffix\", \"Street Suffix Modifier\", \"Street Dir Suffix\", and \"Unit Number\".",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        }
      },
      "type": "object"
    },
    ".auc:AnnualCoolingEfficiencyUnits": {
      "additionalProperties": false,
      "description": "The measure used to quantify efficiency",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "COP",
            "EER",
            "SEER",
            "kW/ton",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:AnnualHeatingEfficiencyUnit": {
      "additionalProperties": false,
      "description": "The measure used to quantify efficiency",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "COP",
            "AFUE",
            "HSPF",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:AssetScore": {
      "additionalProperties": false,
      "description": "A facility's Commercial Building Energy Asset Score Data.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "auc:UseType": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AssetScoreData": {
                  "$ref": "#/definitions/.auc:AssetScoreData"
                },
                "auc:AssetScoreUseType": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Assisted Living Facility",
                        "City Hall",
                        "Community Center",
                        "Courthouse",
                        "Education",
                        "Library",
                        "Lodging",
                        "Medical Office",
                        "Multi-family (4 floors or greater)",
                        "Multi-family (fewer than 4 floors)",
                        "Office",
                        "Parking Garage (Ventilation Only)",
                        "Police Station",
                        "Post Office",
                        "Religious Building",
                        "Retail",
                        "Senior Center",
                        "Warehouse non-refrigerated"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "required": [
                "auc:AssetScoreData",
                "auc:AssetScoreUseType"
              ],
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:AssetScoreData": {
                    "$ref": "#/definitions/.auc:AssetScoreData"
                  },
                  "auc:AssetScoreUseType": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Assisted Living Facility",
                          "City Hall",
                          "Community Center",
                          "Courthouse",
                          "Education",
                          "Library",
                          "Lodging",
                          "Medical Office",
                          "Multi-family (4 floors or greater)",
                          "Multi-family (fewer than 4 floors)",
                          "Office",
                          "Parking Garage (Ventilation Only)",
                          "Police Station",
                          "Post Office",
                          "Religious Building",
                          "Retail",
                          "Senior Center",
                          "Warehouse non-refrigerated"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  }
                },
                "required": [
                  "auc:AssetScoreData",
                  "auc:AssetScoreUseType"
                ],
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:WholeBuilding": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:AssetScoreData": {
              "$ref": "#/definitions/.auc:AssetScoreData"
            },
            "auc:EnergyUseByEndUse": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:EndUse": {
                      "additionalProperties": false,
                      "description": "End use for which data is included.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/auc:EndUse"
                        }
                      },
                      "type": "object"
                    },
                    "auc:EnergyUse": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "required": [
                    "auc:EndUse",
                    "auc:EnergyUse"
                  ],
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:EndUse": {
                        "additionalProperties": false,
                        "description": "End use for which data is included.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/auc:EndUse"
                          }
                        },
                        "type": "object"
                      },
                      "auc:EnergyUse": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      }
                    },
                    "required": [
                      "auc:EndUse",
                      "auc:EnergyUse"
                    ],
                    "type": "object"
                  },
                  "type": "array"
                }
              ]
            },
            "auc:Rankings": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:Ranking": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:Rank": {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/auc:RankType"
                            }
                          },
                          "type": "object"
                        },
                        "auc:Type": {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:EnvelopeType": {
                              "additionalProperties": false,
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Floor U-Value, Mass",
                                    "Roof U-Value, Non-Attic",
                                    "Walls U-Value, Framed",
                                    "Walls + Windows U-Value",
                                    "Window Solar Heat Gain Coefficient",
                                    "Windows U-Value"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:SystemsType": {
                              "additionalProperties": false,
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Cooling",
                                    "Heating",
                                    "Hot Water",
                                    "Interior Lighting",
                                    "Overall HVAC Systems"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "required": [
                        "auc:Rank",
                        "auc:Type"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:Rank": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/auc:RankType"
                              }
                            },
                            "type": "object"
                          },
                          "auc:Type": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:EnvelopeType": {
                                "additionalProperties": false,
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Floor U-Value, Mass",
                                      "Roof U-Value, Non-Attic",
                                      "Walls U-Value, Framed",
                                      "Walls + Windows U-Value",
                                      "Window Solar Heat Gain Coefficient",
                                      "Windows U-Value"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:SystemsType": {
                                "additionalProperties": false,
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Cooling",
                                      "Heating",
                                      "Hot Water",
                                      "Interior Lighting",
                                      "Overall HVAC Systems"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          }
                        },
                        "required": [
                          "auc:Rank",
                          "auc:Type"
                        ],
                        "type": "object"
                      },
                      "minItems": 1,
                      "type": "array"
                    }
                  ]
                }
              },
              "required": [
                "auc:Ranking"
              ],
              "type": "object"
            }
          },
          "required": [
            "auc:AssetScoreData"
          ],
          "type": "object"
        }
      },
      "type": "object"
    },
    ".auc:AssetScoreData": {
      "additionalProperties": false,
      "description": "A facility's Commercial Building Energy Asset Score, and optional Site/Source energy use by fuel type.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "auc:Score": {
          "additionalProperties": false,
          "description": "An individual use type's Asset Score within a commercial building.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:SiteEnergyUse": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:EnergyUseByFuelType": {
              "anyOf": [
                {
                  "$ref": "#/definitions/.auc:EnergyUseByFuelType"
                },
                {
                  "items": {
                    "$ref": "#/definitions/.auc:EnergyUseByFuelType"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:EnergyUseByFuelType"
          ],
          "type": "object"
        },
        "auc:SourceEnergyUse": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:EnergyUseByFuelType": {
              "anyOf": [
                {
                  "$ref": "#/definitions/.auc:EnergyUseByFuelType"
                },
                {
                  "items": {
                    "$ref": "#/definitions/.auc:EnergyUseByFuelType"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            },
            "auc:SourceEnergyUseIntensity": {
              "additionalProperties": false,
              "description": "The Source Energy Use divided by the premises gross floor area. (kBtu/ft2)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            }
          },
          "required": [
            "auc:EnergyUseByFuelType"
          ],
          "type": "object"
        }
      },
      "required": [
        "auc:Score"
      ],
      "type": "object"
    },
    ".auc:Audits": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "auc:Audit": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "@ID": {
                  "$ref": "#/definitions/xs:ID"
                },
                "auc:Contacts": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:Contact": {
                      "anyOf": [
                        {
                          "$ref": "#/definitions/auc:ContactType"
                        },
                        {
                          "items": {
                            "$ref": "#/definitions/auc:ContactType"
                          },
                          "minItems": 1,
                          "type": "array"
                        }
                      ]
                    }
                  },
                  "required": [
                    "auc:Contact"
                  ],
                  "type": "object"
                },
                "auc:Measures": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:Measure": {
                      "anyOf": [
                        {
                          "$ref": "#/definitions/auc:MeasureType"
                        },
                        {
                          "items": {
                            "$ref": "#/definitions/auc:MeasureType"
                          },
                          "minItems": 1,
                          "type": "array"
                        }
                      ]
                    }
                  },
                  "required": [
                    "auc:Measure"
                  ],
                  "type": "object"
                },
                "auc:Report": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:ASHRAEAuditLevel": {
                      "additionalProperties": false,
                      "description": "Energy audit level as defined in ASHRAE Procedures for Commercial Building Energy Audits.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "Preliminary Energy-Use Analysis",
                            "Level 1: Walk-through",
                            "Level 2: Energy Survey and Analysis",
                            "Level 3: Detailed Survey and Analysis"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:AnalysisPeriod": {
                      "additionalProperties": false,
                      "description": "Period used for financial analysis. Can be combined with Interval Frequency to specify the units. (yrs)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:AuditCost": {
                      "additionalProperties": false,
                      "description": "Total cost associated with the audit ($)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:AuditDate": {
                      "additionalProperties": false,
                      "description": "Date audit was conducted. (CCYY-MM-DD)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:date"
                        }
                      },
                      "type": "object"
                    },
                    "auc:AuditExemption": {
                      "additionalProperties": false,
                      "description": "Conditions under which the building is exempt from a mandated audit.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "EPA ENERGY STAR certified",
                            "LEED certified",
                            "Simple building",
                            "Class 1 building",
                            "Other",
                            "None"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:AuditorContactID": {
                      "additionalProperties": false,
                      "description": "Contact ID of auditor responsible for the audit report.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    "auc:DiscountFactor": {
                      "additionalProperties": false,
                      "description": "Discount factor applied to calculate present values of future cash flows. (%)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:ElectricityPriceEscalationRate": {
                      "additionalProperties": false,
                      "description": "Assumed annual increase in electricity price (%)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:GasPriceEscalationRate": {
                      "additionalProperties": false,
                      "description": "Assumed annual increase in natural gas price (%)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:InflationRate": {
                      "additionalProperties": false,
                      "description": "Assumed annual inflation rate for non-energy costs (%)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:OtherEscalationRates": {
                      "anyOf": [
                        {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            },
                            "auc:EnergyResource": {
                              "$ref": "#/definitions/.auc:EnergyResource"
                            },
                            "auc:EscalationRate": {
                              "additionalProperties": false,
                              "description": "Assumed annual increase in price for the specified resource (%)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                }
                              },
                              "type": "object"
                            }
                          },
                          "type": "object"
                        },
                        {
                          "items": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              },
                              "auc:EnergyResource": {
                                "$ref": "#/definitions/.auc:EnergyResource"
                              },
                              "auc:EscalationRate": {
                                "additionalProperties": false,
                                "description": "Assumed annual increase in price for the specified resource (%)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          },
                          "type": "array"
                        }
                      ]
                    },
                    "auc:Qualifications": {
                      "anyOf": [
                        {
                          "additionalProperties": false,
                          "description": "Qualifications of audit team.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:AuditTeamMemberCertificationType": {
                              "additionalProperties": false,
                              "description": "Type of certification held by an auditor team member.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/auc:AuditorQualificationType"
                                }
                              },
                              "type": "object"
                            },
                            "auc:AuditorQualification": {
                              "additionalProperties": false,
                              "description": "Qualification of auditor responsible for the audit report.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/auc:AuditorQualificationType"
                                }
                              },
                              "type": "object"
                            },
                            "auc:AuditorQualificationNumber": {
                              "additionalProperties": false,
                              "description": "Certificate number, license number, etc., of Auditor Qualification.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:AuditorQualificationState": {
                              "additionalProperties": false,
                              "description": "If Auditor Qualification is state-issued, the state the qualification is from.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/auc:State"
                                }
                              },
                              "type": "object"
                            },
                            "auc:CertificationExpirationDate": {
                              "additionalProperties": false,
                              "description": "Date that the auditor qualification expires. (CCYY-MM-DD)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:date"
                                }
                              },
                              "type": "object"
                            },
                            "auc:CertifiedAuditTeamMemberContactID": {
                              "additionalProperties": false,
                              "description": "Contact ID of auditor team member with certification.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            }
                          },
                          "type": "object"
                        },
                        {
                          "items": {
                            "additionalProperties": false,
                            "description": "Qualifications of audit team.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:AuditTeamMemberCertificationType": {
                                "additionalProperties": false,
                                "description": "Type of certification held by an auditor team member.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/auc:AuditorQualificationType"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:AuditorQualification": {
                                "additionalProperties": false,
                                "description": "Qualification of auditor responsible for the audit report.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/auc:AuditorQualificationType"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:AuditorQualificationNumber": {
                                "additionalProperties": false,
                                "description": "Certificate number, license number, etc., of Auditor Qualification.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:AuditorQualificationState": {
                                "additionalProperties": false,
                                "description": "If Auditor Qualification is state-issued, the state the qualification is from.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/auc:State"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:CertificationExpirationDate": {
                                "additionalProperties": false,
                                "description": "Date that the auditor qualification expires. (CCYY-MM-DD)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:date"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:CertifiedAuditTeamMemberContactID": {
                                "additionalProperties": false,
                                "description": "Contact ID of auditor team member with certification.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              }
                            },
                            "type": "object"
                          },
                          "type": "array"
                        }
                      ]
                    },
                    "auc:RetrocommissioningAudit": {
                      "additionalProperties": false,
                      "description": "True if an assessment of retro- or re-commissioning measures was completed as part of the audit.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:boolean"
                        }
                      },
                      "type": "object"
                    },
                    "auc:Scenarios": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:Scenario": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:ScenarioType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:ScenarioType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:Scenario"
                      ],
                      "type": "object"
                    },
                    "auc:UserDefinedFields": {
                      "$ref": "#/definitions/.auc:UserDefinedFields"
                    },
                    "auc:WaterPriceEscalationRate": {
                      "additionalProperties": false,
                      "description": "Assumed annual increase in water price (%)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                },
                "auc:Schedules": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:Schedule": {
                      "anyOf": [
                        {
                          "$ref": "#/definitions/auc:ScheduleType"
                        },
                        {
                          "items": {
                            "$ref": "#/definitions/auc:ScheduleType"
                          },
                          "minItems": 1,
                          "type": "array"
                        }
                      ]
                    }
                  },
                  "required": [
                    "auc:Schedule"
                  ],
                  "type": "object"
                },
                "auc:Sites": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:Site": {
                      "anyOf": [
                        {
                          "$ref": "#/definitions/auc:SiteType"
                        },
                        {
                          "items": {
                            "$ref": "#/definitions/auc:SiteType"
                          },
                          "minItems": 1,
                          "type": "array"
                        }
                      ]
                    }
                  },
                  "required": [
                    "auc:Site"
                  ],
                  "type": "object"
                },
                "auc:Systems": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:AirInfiltrationSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:AirInfiltrationSystem": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:AirInfiltrationTest": {
                                  "additionalProperties": false,
                                  "description": "Type of air infiltration test performed on the building",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Blower door",
                                        "Tracer gas",
                                        "Checklist",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:AirInfiltrationValue": {
                                  "additionalProperties": false,
                                  "description": "The measured value from the Air Infiltration test.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:AirInfiltrationValueUnits": {
                                  "additionalProperties": false,
                                  "description": "Units associated with Air Infiltration Value",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "CFM25",
                                        "CFM50",
                                        "CFM75",
                                        "CFMnatural",
                                        "ACH50",
                                        "ACHnatural",
                                        "Effective Leakage Area (in2)",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:LinkedPremises": {
                                  "$ref": "#/definitions/.auc:LinkedPremises"
                                },
                                "auc:Tightness": {
                                  "additionalProperties": false,
                                  "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Very Tight",
                                        "Tight",
                                        "Average",
                                        "Leaky",
                                        "Very Leaky",
                                        "Unknown"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:UserDefinedFields": {
                                  "$ref": "#/definitions/.auc:UserDefinedFields"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "auc:AirInfiltrationTest": {
                                    "additionalProperties": false,
                                    "description": "Type of air infiltration test performed on the building",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "enum": [
                                          "Blower door",
                                          "Tracer gas",
                                          "Checklist",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:AirInfiltrationValue": {
                                    "additionalProperties": false,
                                    "description": "The measured value from the Air Infiltration test.",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "$ref": "#/definitions/xs:decimal"
                                      },
                                      "@auc:Source": {
                                        "enum": [
                                          "Default",
                                          "Estimate",
                                          "Agent",
                                          "Assessor",
                                          "Auditor",
                                          "Product specification",
                                          "Building Component Library",
                                          "Utility transfer",
                                          "Energy Management System",
                                          "Direct measurement",
                                          "Design files",
                                          "Simulation",
                                          "ENERGY STAR Portfolio Manager",
                                          "US EPA",
                                          "US EIA",
                                          "Target Finder",
                                          "Arch2030",
                                          "ASHRAE",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:AirInfiltrationValueUnits": {
                                    "additionalProperties": false,
                                    "description": "Units associated with Air Infiltration Value",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "enum": [
                                          "CFM25",
                                          "CFM50",
                                          "CFM75",
                                          "CFMnatural",
                                          "ACH50",
                                          "ACHnatural",
                                          "Effective Leakage Area (in2)",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:LinkedPremises": {
                                    "$ref": "#/definitions/.auc:LinkedPremises"
                                  },
                                  "auc:Tightness": {
                                    "additionalProperties": false,
                                    "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "enum": [
                                          "Very Tight",
                                          "Tight",
                                          "Average",
                                          "Leaky",
                                          "Very Leaky",
                                          "Unknown"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:UserDefinedFields": {
                                    "$ref": "#/definitions/.auc:UserDefinedFields"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:AirInfiltrationSystem"
                      ],
                      "type": "object"
                    },
                    "auc:CeilingSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:CeilingSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:CeilingSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:CeilingSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:CeilingSystem"
                      ],
                      "type": "object"
                    },
                    "auc:ConveyanceSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:ConveyanceSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:ConveyanceSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:ConveyanceSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:ConveyanceSystem"
                      ],
                      "type": "object"
                    },
                    "auc:CookingSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:CookingSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:CookingSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:CookingSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:CookingSystem"
                      ],
                      "type": "object"
                    },
                    "auc:CriticalITSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:CriticalITSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:CriticalITSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:CriticalITSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:CriticalITSystem"
                      ],
                      "type": "object"
                    },
                    "auc:DishwasherSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:DishwasherSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:DishwasherSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:DishwasherSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:DishwasherSystem"
                      ],
                      "type": "object"
                    },
                    "auc:DomesticHotWaterSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:DomesticHotWaterSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:DomesticHotWaterSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:DomesticHotWaterSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:DomesticHotWaterSystem"
                      ],
                      "type": "object"
                    },
                    "auc:FanSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:FanSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:FanSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:FanSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:FanSystem"
                      ],
                      "type": "object"
                    },
                    "auc:FenestrationSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:FenestrationSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:FenestrationSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:FenestrationSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:FenestrationSystem"
                      ],
                      "type": "object"
                    },
                    "auc:FoundationSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:FoundationSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:FoundationSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:FoundationSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:FoundationSystem"
                      ],
                      "type": "object"
                    },
                    "auc:HVACSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:HVACSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:HVACSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:HVACSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:HVACSystem"
                      ],
                      "type": "object"
                    },
                    "auc:HeatRecoverySystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:HeatRecoverySystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:HeatRecoverySystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:HeatRecoverySystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:HeatRecoverySystem"
                      ],
                      "type": "object"
                    },
                    "auc:LaundrySystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:LaundrySystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:LaundrySystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:LaundrySystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:LaundrySystem"
                      ],
                      "type": "object"
                    },
                    "auc:LightingSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:LightingSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:LightingSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:LightingSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:LightingSystem"
                      ],
                      "type": "object"
                    },
                    "auc:MotorSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MotorSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:MotorSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:MotorSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MotorSystem"
                      ],
                      "type": "object"
                    },
                    "auc:OnSiteStorageTransmissionGenerationSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:OnSiteStorageTransmissionGenerationSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:OnSiteStorageTransmissionGenerationSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:OnSiteStorageTransmissionGenerationSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:OnSiteStorageTransmissionGenerationSystem"
                      ],
                      "type": "object"
                    },
                    "auc:PlugLoads": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MiscellaneousElectricLoad": {
                          "additionalProperties": false,
                          "description": "Weighted average electric loads resulting from electric devices not responsible for space heating, cooling, regulated lighting, and water heating. Examples include computers, printers, copiers, desk lighting, vending machines, and refrigerators. (W/ft2)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:PlugLoad": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:PlugLoadType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:PlugLoadType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:PlugLoad"
                      ],
                      "type": "object"
                    },
                    "auc:Pools": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:Pool": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:PoolType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:PoolType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:Pool"
                      ],
                      "type": "object"
                    },
                    "auc:ProcessLoads": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MiscellaneousGasLoad": {
                          "additionalProperties": false,
                          "description": "Weighted average gas loads resulting from gas devices not responsible for space heating and water heating. (kBtu/ft2)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:ProcessLoad": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:ProcessLoadType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:ProcessLoadType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:ProcessLoad"
                      ],
                      "type": "object"
                    },
                    "auc:PumpSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:PumpSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:PumpSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:PumpSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:PumpSystem"
                      ],
                      "type": "object"
                    },
                    "auc:RefrigerationSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:RefrigerationSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:RefrigerationSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:RefrigerationSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:RefrigerationSystem"
                      ],
                      "type": "object"
                    },
                    "auc:RoofSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:RoofSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:RoofSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:RoofSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:RoofSystem"
                      ],
                      "type": "object"
                    },
                    "auc:WallSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:WallSystem": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:WallSystemType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:WallSystemType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:WallSystem"
                      ],
                      "type": "object"
                    },
                    "auc:WaterInfiltrationSystems": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:WaterInfiltrationSystem": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:LinkedPremises": {
                                  "$ref": "#/definitions/.auc:LinkedPremises"
                                },
                                "auc:LocationsOfExteriorWaterIntrusionDamage": {
                                  "anyOf": [
                                    {
                                      "additionalProperties": false,
                                      "description": "Location of observed moisture problems on the outside of the building",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "Roof",
                                            "Interior ceiling",
                                            "Foundation",
                                            "Basement",
                                            "Crawlspace",
                                            "Walls",
                                            "Around windows",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    {
                                      "items": {
                                        "additionalProperties": false,
                                        "description": "Location of observed moisture problems on the outside of the building",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Roof",
                                              "Interior ceiling",
                                              "Foundation",
                                              "Basement",
                                              "Crawlspace",
                                              "Walls",
                                              "Around windows",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "type": "array"
                                    }
                                  ]
                                },
                                "auc:LocationsOfInteriorWaterIntrusionDamage": {
                                  "anyOf": [
                                    {
                                      "additionalProperties": false,
                                      "description": "Location of observed moisture problems on the inside of the building",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "Kitchen",
                                            "Bathroom",
                                            "Basement",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    {
                                      "items": {
                                        "additionalProperties": false,
                                        "description": "Location of observed moisture problems on the inside of the building",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Kitchen",
                                              "Bathroom",
                                              "Basement",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "type": "array"
                                    }
                                  ]
                                },
                                "auc:UserDefinedFields": {
                                  "$ref": "#/definitions/.auc:UserDefinedFields"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "auc:LinkedPremises": {
                                    "$ref": "#/definitions/.auc:LinkedPremises"
                                  },
                                  "auc:LocationsOfExteriorWaterIntrusionDamage": {
                                    "anyOf": [
                                      {
                                        "additionalProperties": false,
                                        "description": "Location of observed moisture problems on the outside of the building",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Roof",
                                              "Interior ceiling",
                                              "Foundation",
                                              "Basement",
                                              "Crawlspace",
                                              "Walls",
                                              "Around windows",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      {
                                        "items": {
                                          "additionalProperties": false,
                                          "description": "Location of observed moisture problems on the outside of the building",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "enum": [
                                                "Roof",
                                                "Interior ceiling",
                                                "Foundation",
                                                "Basement",
                                                "Crawlspace",
                                                "Walls",
                                                "Around windows",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "type": "array"
                                      }
                                    ]
                                  },
                                  "auc:LocationsOfInteriorWaterIntrusionDamage": {
                                    "anyOf": [
                                      {
                                        "additionalProperties": false,
                                        "description": "Location of observed moisture problems on the inside of the building",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Kitchen",
                                              "Bathroom",
                                              "Basement",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      {
                                        "items": {
                                          "additionalProperties": false,
                                          "description": "Location of observed moisture problems on the inside of the building",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "enum": [
                                                "Kitchen",
                                                "Bathroom",
                                                "Basement",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "type": "array"
                                      }
                                    ]
                                  },
                                  "auc:UserDefinedFields": {
                                    "$ref": "#/definitions/.auc:UserDefinedFields"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:WaterInfiltrationSystem"
                      ],
                      "type": "object"
                    },
                    "auc:WaterUses": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:WaterUse": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:WaterUseType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:WaterUseType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:WaterUse"
                      ],
                      "type": "object"
                    }
                  },
                  "type": "object"
                },
                "auc:UserDefinedFields": {
                  "$ref": "#/definitions/.auc:UserDefinedFields"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "@ID": {
                    "$ref": "#/definitions/xs:ID"
                  },
                  "auc:Contacts": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:Contact": {
                        "anyOf": [
                          {
                            "$ref": "#/definitions/auc:ContactType"
                          },
                          {
                            "items": {
                              "$ref": "#/definitions/auc:ContactType"
                            },
                            "minItems": 1,
                            "type": "array"
                          }
                        ]
                      }
                    },
                    "required": [
                      "auc:Contact"
                    ],
                    "type": "object"
                  },
                  "auc:Measures": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:Measure": {
                        "anyOf": [
                          {
                            "$ref": "#/definitions/auc:MeasureType"
                          },
                          {
                            "items": {
                              "$ref": "#/definitions/auc:MeasureType"
                            },
                            "minItems": 1,
                            "type": "array"
                          }
                        ]
                      }
                    },
                    "required": [
                      "auc:Measure"
                    ],
                    "type": "object"
                  },
                  "auc:Report": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:ASHRAEAuditLevel": {
                        "additionalProperties": false,
                        "description": "Energy audit level as defined in ASHRAE Procedures for Commercial Building Energy Audits.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "enum": [
                              "Preliminary Energy-Use Analysis",
                              "Level 1: Walk-through",
                              "Level 2: Energy Survey and Analysis",
                              "Level 3: Detailed Survey and Analysis"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:AnalysisPeriod": {
                        "additionalProperties": false,
                        "description": "Period used for financial analysis. Can be combined with Interval Frequency to specify the units. (yrs)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:AuditCost": {
                        "additionalProperties": false,
                        "description": "Total cost associated with the audit ($)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:AuditDate": {
                        "additionalProperties": false,
                        "description": "Date audit was conducted. (CCYY-MM-DD)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:date"
                          }
                        },
                        "type": "object"
                      },
                      "auc:AuditExemption": {
                        "additionalProperties": false,
                        "description": "Conditions under which the building is exempt from a mandated audit.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "enum": [
                              "EPA ENERGY STAR certified",
                              "LEED certified",
                              "Simple building",
                              "Class 1 building",
                              "Other",
                              "None"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:AuditorContactID": {
                        "additionalProperties": false,
                        "description": "Contact ID of auditor responsible for the audit report.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "auc:DiscountFactor": {
                        "additionalProperties": false,
                        "description": "Discount factor applied to calculate present values of future cash flows. (%)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:ElectricityPriceEscalationRate": {
                        "additionalProperties": false,
                        "description": "Assumed annual increase in electricity price (%)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:GasPriceEscalationRate": {
                        "additionalProperties": false,
                        "description": "Assumed annual increase in natural gas price (%)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:InflationRate": {
                        "additionalProperties": false,
                        "description": "Assumed annual inflation rate for non-energy costs (%)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:OtherEscalationRates": {
                        "anyOf": [
                          {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              },
                              "auc:EnergyResource": {
                                "$ref": "#/definitions/.auc:EnergyResource"
                              },
                              "auc:EscalationRate": {
                                "additionalProperties": false,
                                "description": "Assumed annual increase in price for the specified resource (%)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          },
                          {
                            "items": {
                              "additionalProperties": false,
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                },
                                "auc:EnergyResource": {
                                  "$ref": "#/definitions/.auc:EnergyResource"
                                },
                                "auc:EscalationRate": {
                                  "additionalProperties": false,
                                  "description": "Assumed annual increase in price for the specified resource (%)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    }
                                  },
                                  "type": "object"
                                }
                              },
                              "type": "object"
                            },
                            "type": "array"
                          }
                        ]
                      },
                      "auc:Qualifications": {
                        "anyOf": [
                          {
                            "additionalProperties": false,
                            "description": "Qualifications of audit team.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:AuditTeamMemberCertificationType": {
                                "additionalProperties": false,
                                "description": "Type of certification held by an auditor team member.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/auc:AuditorQualificationType"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:AuditorQualification": {
                                "additionalProperties": false,
                                "description": "Qualification of auditor responsible for the audit report.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/auc:AuditorQualificationType"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:AuditorQualificationNumber": {
                                "additionalProperties": false,
                                "description": "Certificate number, license number, etc., of Auditor Qualification.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:AuditorQualificationState": {
                                "additionalProperties": false,
                                "description": "If Auditor Qualification is state-issued, the state the qualification is from.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/auc:State"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:CertificationExpirationDate": {
                                "additionalProperties": false,
                                "description": "Date that the auditor qualification expires. (CCYY-MM-DD)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:date"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:CertifiedAuditTeamMemberContactID": {
                                "additionalProperties": false,
                                "description": "Contact ID of auditor team member with certification.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              }
                            },
                            "type": "object"
                          },
                          {
                            "items": {
                              "additionalProperties": false,
                              "description": "Qualifications of audit team.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:AuditTeamMemberCertificationType": {
                                  "additionalProperties": false,
                                  "description": "Type of certification held by an auditor team member.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/auc:AuditorQualificationType"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:AuditorQualification": {
                                  "additionalProperties": false,
                                  "description": "Qualification of auditor responsible for the audit report.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/auc:AuditorQualificationType"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:AuditorQualificationNumber": {
                                  "additionalProperties": false,
                                  "description": "Certificate number, license number, etc., of Auditor Qualification.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:AuditorQualificationState": {
                                  "additionalProperties": false,
                                  "description": "If Auditor Qualification is state-issued, the state the qualification is from.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/auc:State"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:CertificationExpirationDate": {
                                  "additionalProperties": false,
                                  "description": "Date that the auditor qualification expires. (CCYY-MM-DD)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:date"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:CertifiedAuditTeamMemberContactID": {
                                  "additionalProperties": false,
                                  "description": "Contact ID of auditor team member with certification.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                }
                              },
                              "type": "object"
                            },
                            "type": "array"
                          }
                        ]
                      },
                      "auc:RetrocommissioningAudit": {
                        "additionalProperties": false,
                        "description": "True if an assessment of retro- or re-commissioning measures was completed as part of the audit.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:boolean"
                          }
                        },
                        "type": "object"
                      },
                      "auc:Scenarios": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:Scenario": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:ScenarioType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:ScenarioType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:Scenario"
                        ],
                        "type": "object"
                      },
                      "auc:UserDefinedFields": {
                        "$ref": "#/definitions/.auc:UserDefinedFields"
                      },
                      "auc:WaterPriceEscalationRate": {
                        "additionalProperties": false,
                        "description": "Assumed annual increase in water price (%)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      }
                    },
                    "type": "object"
                  },
                  "auc:Schedules": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:Schedule": {
                        "anyOf": [
                          {
                            "$ref": "#/definitions/auc:ScheduleType"
                          },
                          {
                            "items": {
                              "$ref": "#/definitions/auc:ScheduleType"
                            },
                            "minItems": 1,
                            "type": "array"
                          }
                        ]
                      }
                    },
                    "required": [
                      "auc:Schedule"
                    ],
                    "type": "object"
                  },
                  "auc:Sites": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:Site": {
                        "anyOf": [
                          {
                            "$ref": "#/definitions/auc:SiteType"
                          },
                          {
                            "items": {
                              "$ref": "#/definitions/auc:SiteType"
                            },
                            "minItems": 1,
                            "type": "array"
                          }
                        ]
                      }
                    },
                    "required": [
                      "auc:Site"
                    ],
                    "type": "object"
                  },
                  "auc:Systems": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:AirInfiltrationSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:AirInfiltrationSystem": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "auc:AirInfiltrationTest": {
                                    "additionalProperties": false,
                                    "description": "Type of air infiltration test performed on the building",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "enum": [
                                          "Blower door",
                                          "Tracer gas",
                                          "Checklist",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:AirInfiltrationValue": {
                                    "additionalProperties": false,
                                    "description": "The measured value from the Air Infiltration test.",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "$ref": "#/definitions/xs:decimal"
                                      },
                                      "@auc:Source": {
                                        "enum": [
                                          "Default",
                                          "Estimate",
                                          "Agent",
                                          "Assessor",
                                          "Auditor",
                                          "Product specification",
                                          "Building Component Library",
                                          "Utility transfer",
                                          "Energy Management System",
                                          "Direct measurement",
                                          "Design files",
                                          "Simulation",
                                          "ENERGY STAR Portfolio Manager",
                                          "US EPA",
                                          "US EIA",
                                          "Target Finder",
                                          "Arch2030",
                                          "ASHRAE",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:AirInfiltrationValueUnits": {
                                    "additionalProperties": false,
                                    "description": "Units associated with Air Infiltration Value",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "enum": [
                                          "CFM25",
                                          "CFM50",
                                          "CFM75",
                                          "CFMnatural",
                                          "ACH50",
                                          "ACHnatural",
                                          "Effective Leakage Area (in2)",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:LinkedPremises": {
                                    "$ref": "#/definitions/.auc:LinkedPremises"
                                  },
                                  "auc:Tightness": {
                                    "additionalProperties": false,
                                    "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "enum": [
                                          "Very Tight",
                                          "Tight",
                                          "Average",
                                          "Leaky",
                                          "Very Leaky",
                                          "Unknown"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:UserDefinedFields": {
                                    "$ref": "#/definitions/.auc:UserDefinedFields"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "auc:AirInfiltrationTest": {
                                      "additionalProperties": false,
                                      "description": "Type of air infiltration test performed on the building",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "Blower door",
                                            "Tracer gas",
                                            "Checklist",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:AirInfiltrationValue": {
                                      "additionalProperties": false,
                                      "description": "The measured value from the Air Infiltration test.",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "$ref": "#/definitions/xs:decimal"
                                        },
                                        "@auc:Source": {
                                          "enum": [
                                            "Default",
                                            "Estimate",
                                            "Agent",
                                            "Assessor",
                                            "Auditor",
                                            "Product specification",
                                            "Building Component Library",
                                            "Utility transfer",
                                            "Energy Management System",
                                            "Direct measurement",
                                            "Design files",
                                            "Simulation",
                                            "ENERGY STAR Portfolio Manager",
                                            "US EPA",
                                            "US EIA",
                                            "Target Finder",
                                            "Arch2030",
                                            "ASHRAE",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:AirInfiltrationValueUnits": {
                                      "additionalProperties": false,
                                      "description": "Units associated with Air Infiltration Value",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "CFM25",
                                            "CFM50",
                                            "CFM75",
                                            "CFMnatural",
                                            "ACH50",
                                            "ACHnatural",
                                            "Effective Leakage Area (in2)",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:LinkedPremises": {
                                      "$ref": "#/definitions/.auc:LinkedPremises"
                                    },
                                    "auc:Tightness": {
                                      "additionalProperties": false,
                                      "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "Very Tight",
                                            "Tight",
                                            "Average",
                                            "Leaky",
                                            "Very Leaky",
                                            "Unknown"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:UserDefinedFields": {
                                      "$ref": "#/definitions/.auc:UserDefinedFields"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:AirInfiltrationSystem"
                        ],
                        "type": "object"
                      },
                      "auc:CeilingSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:CeilingSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:CeilingSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:CeilingSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:CeilingSystem"
                        ],
                        "type": "object"
                      },
                      "auc:ConveyanceSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:ConveyanceSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:ConveyanceSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:ConveyanceSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:ConveyanceSystem"
                        ],
                        "type": "object"
                      },
                      "auc:CookingSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:CookingSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:CookingSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:CookingSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:CookingSystem"
                        ],
                        "type": "object"
                      },
                      "auc:CriticalITSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:CriticalITSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:CriticalITSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:CriticalITSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:CriticalITSystem"
                        ],
                        "type": "object"
                      },
                      "auc:DishwasherSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:DishwasherSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:DishwasherSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:DishwasherSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:DishwasherSystem"
                        ],
                        "type": "object"
                      },
                      "auc:DomesticHotWaterSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:DomesticHotWaterSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:DomesticHotWaterSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:DomesticHotWaterSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:DomesticHotWaterSystem"
                        ],
                        "type": "object"
                      },
                      "auc:FanSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:FanSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:FanSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:FanSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:FanSystem"
                        ],
                        "type": "object"
                      },
                      "auc:FenestrationSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:FenestrationSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:FenestrationSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:FenestrationSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:FenestrationSystem"
                        ],
                        "type": "object"
                      },
                      "auc:FoundationSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:FoundationSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:FoundationSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:FoundationSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:FoundationSystem"
                        ],
                        "type": "object"
                      },
                      "auc:HVACSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:HVACSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:HVACSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:HVACSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:HVACSystem"
                        ],
                        "type": "object"
                      },
                      "auc:HeatRecoverySystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:HeatRecoverySystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:HeatRecoverySystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:HeatRecoverySystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:HeatRecoverySystem"
                        ],
                        "type": "object"
                      },
                      "auc:LaundrySystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:LaundrySystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:LaundrySystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:LaundrySystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:LaundrySystem"
                        ],
                        "type": "object"
                      },
                      "auc:LightingSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:LightingSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:LightingSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:LightingSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:LightingSystem"
                        ],
                        "type": "object"
                      },
                      "auc:MotorSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MotorSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:MotorSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:MotorSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MotorSystem"
                        ],
                        "type": "object"
                      },
                      "auc:OnSiteStorageTransmissionGenerationSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:OnSiteStorageTransmissionGenerationSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:OnSiteStorageTransmissionGenerationSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:OnSiteStorageTransmissionGenerationSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:OnSiteStorageTransmissionGenerationSystem"
                        ],
                        "type": "object"
                      },
                      "auc:PlugLoads": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MiscellaneousElectricLoad": {
                            "additionalProperties": false,
                            "description": "Weighted average electric loads resulting from electric devices not responsible for space heating, cooling, regulated lighting, and water heating. Examples include computers, printers, copiers, desk lighting, vending machines, and refrigerators. (W/ft2)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:PlugLoad": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:PlugLoadType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:PlugLoadType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:PlugLoad"
                        ],
                        "type": "object"
                      },
                      "auc:Pools": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:Pool": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:PoolType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:PoolType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:Pool"
                        ],
                        "type": "object"
                      },
                      "auc:ProcessLoads": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MiscellaneousGasLoad": {
                            "additionalProperties": false,
                            "description": "Weighted average gas loads resulting from gas devices not responsible for space heating and water heating. (kBtu/ft2)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:ProcessLoad": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:ProcessLoadType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:ProcessLoadType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:ProcessLoad"
                        ],
                        "type": "object"
                      },
                      "auc:PumpSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:PumpSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:PumpSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:PumpSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:PumpSystem"
                        ],
                        "type": "object"
                      },
                      "auc:RefrigerationSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:RefrigerationSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:RefrigerationSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:RefrigerationSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:RefrigerationSystem"
                        ],
                        "type": "object"
                      },
                      "auc:RoofSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:RoofSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:RoofSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:RoofSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:RoofSystem"
                        ],
                        "type": "object"
                      },
                      "auc:WallSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:WallSystem": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:WallSystemType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:WallSystemType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:WallSystem"
                        ],
                        "type": "object"
                      },
                      "auc:WaterInfiltrationSystems": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:WaterInfiltrationSystem": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "auc:LinkedPremises": {
                                    "$ref": "#/definitions/.auc:LinkedPremises"
                                  },
                                  "auc:LocationsOfExteriorWaterIntrusionDamage": {
                                    "anyOf": [
                                      {
                                        "additionalProperties": false,
                                        "description": "Location of observed moisture problems on the outside of the building",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Roof",
                                              "Interior ceiling",
                                              "Foundation",
                                              "Basement",
                                              "Crawlspace",
                                              "Walls",
                                              "Around windows",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      {
                                        "items": {
                                          "additionalProperties": false,
                                          "description": "Location of observed moisture problems on the outside of the building",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "enum": [
                                                "Roof",
                                                "Interior ceiling",
                                                "Foundation",
                                                "Basement",
                                                "Crawlspace",
                                                "Walls",
                                                "Around windows",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "type": "array"
                                      }
                                    ]
                                  },
                                  "auc:LocationsOfInteriorWaterIntrusionDamage": {
                                    "anyOf": [
                                      {
                                        "additionalProperties": false,
                                        "description": "Location of observed moisture problems on the inside of the building",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Kitchen",
                                              "Bathroom",
                                              "Basement",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      {
                                        "items": {
                                          "additionalProperties": false,
                                          "description": "Location of observed moisture problems on the inside of the building",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "enum": [
                                                "Kitchen",
                                                "Bathroom",
                                                "Basement",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "type": "array"
                                      }
                                    ]
                                  },
                                  "auc:UserDefinedFields": {
                                    "$ref": "#/definitions/.auc:UserDefinedFields"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Description of the infiltration characteristics for an opaque surface, fenestration unit, a thermal zone.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "auc:LinkedPremises": {
                                      "$ref": "#/definitions/.auc:LinkedPremises"
                                    },
                                    "auc:LocationsOfExteriorWaterIntrusionDamage": {
                                      "anyOf": [
                                        {
                                          "additionalProperties": false,
                                          "description": "Location of observed moisture problems on the outside of the building",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "enum": [
                                                "Roof",
                                                "Interior ceiling",
                                                "Foundation",
                                                "Basement",
                                                "Crawlspace",
                                                "Walls",
                                                "Around windows",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        {
                                          "items": {
                                            "additionalProperties": false,
                                            "description": "Location of observed moisture problems on the outside of the building",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "enum": [
                                                  "Roof",
                                                  "Interior ceiling",
                                                  "Foundation",
                                                  "Basement",
                                                  "Crawlspace",
                                                  "Walls",
                                                  "Around windows",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "type": "array"
                                        }
                                      ]
                                    },
                                    "auc:LocationsOfInteriorWaterIntrusionDamage": {
                                      "anyOf": [
                                        {
                                          "additionalProperties": false,
                                          "description": "Location of observed moisture problems on the inside of the building",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "enum": [
                                                "Kitchen",
                                                "Bathroom",
                                                "Basement",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        {
                                          "items": {
                                            "additionalProperties": false,
                                            "description": "Location of observed moisture problems on the inside of the building",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "enum": [
                                                  "Kitchen",
                                                  "Bathroom",
                                                  "Basement",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "type": "array"
                                        }
                                      ]
                                    },
                                    "auc:UserDefinedFields": {
                                      "$ref": "#/definitions/.auc:UserDefinedFields"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:WaterInfiltrationSystem"
                        ],
                        "type": "object"
                      },
                      "auc:WaterUses": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:WaterUse": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:WaterUseType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:WaterUseType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:WaterUse"
                        ],
                        "type": "object"
                      }
                    },
                    "type": "object"
                  },
                  "auc:UserDefinedFields": {
                    "$ref": "#/definitions/.auc:UserDefinedFields"
                  }
                },
                "type": "object"
              },
              "minItems": 1,
              "type": "array"
            }
          ]
        }
      },
      "required": [
        "auc:Audit"
      ],
      "type": "object"
    },
    ".auc:BurnerType": {
      "additionalProperties": false,
      "description": "Type of burner on boiler or furnace, if applicable.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Atmospheric",
            "Power",
            "Sealed Combustion",
            "Rotary Cup",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:CalculationMethod": {
      "$ref": "#/definitions/auc:CalculationMethodType",
      "description": "Method used to determine energy use."
    },
    ".auc:Capacity": {
      "additionalProperties": false,
      "description": "Capacity of the system at rated conditions.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:decimal"
        },
        "@auc:Source": {
          "enum": [
            "Default",
            "Estimate",
            "Agent",
            "Assessor",
            "Auditor",
            "Product specification",
            "Building Component Library",
            "Utility transfer",
            "Energy Management System",
            "Direct measurement",
            "Design files",
            "Simulation",
            "ENERGY STAR Portfolio Manager",
            "US EPA",
            "US EIA",
            "Target Finder",
            "Arch2030",
            "ASHRAE",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:CapacityUnits": {
      "additionalProperties": false,
      "description": "Units used to measure capacity.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "cfh",
            "cfm",
            "kcf/h",
            "MCF/day",
            "gpm",
            "W",
            "kW",
            "hp",
            "MW",
            "Btu/h",
            "cal/h",
            "ft-lbf/h",
            "ft-lbf/min",
            "Btu/s",
            "kBtu/h",
            "MMBtu/h",
            "therms/h",
            "lbs/h",
            "Klbs/h",
            "Mlbs/h",
            "Tons",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:ClothesWasherClassification": {
      "additionalProperties": false,
      "description": "The sector where clothes washer is commonly used.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Residential",
            "Commercial",
            "Industrial",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:ClothesWasherLoaderType": {
      "additionalProperties": false,
      "description": "The type of configuration of a laundry appliance. Such as front and top loading clothes washers.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Front",
            "Top",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:CompressorStaging": {
      "additionalProperties": false,
      "description": "The compressor staging for the unit. Select \"Single Stage\" for units with single stage (on/off) control. Select \"Multiple, Discrete Stages\" for units with multiple compressors, discrete unloading stages, or compressors with stepped speed motors that are controlled to operate at discrete stages. Select \"Variable\" for compressors that operate at variable speeds or with modulating unloading.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Single stage",
            "Multiple discrete stages",
            "Variable",
            "Modulating",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:ContactEmailAddress": {
      "additionalProperties": false,
      "description": "Email address may be specified for customer, contractors, and other contacts or businesses.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:string"
        }
      },
      "type": "object"
    },
    ".auc:ControlTechnology": {
      "additionalProperties": false,
      "description": "Type of system operation control.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Programmable Thermostat",
            "Manual Analog Thermostat",
            "Manual Digital Thermostat",
            "Manual On/Off",
            "EMCS",
            "Always On",
            "Timer",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:ControlledByEMCS": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:boolean"
        }
      },
      "type": "object"
    },
    ".auc:CostEffectivenessScreeningMethod": {
      "additionalProperties": false,
      "description": "Method for calculating cost-effectiveness for measures or project.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Simple payback",
            "Return on investment",
            "Lifecycle Cost (LCC)",
            "Net Present Value",
            "Internal Rate of Return",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:DraftType": {
      "additionalProperties": false,
      "description": "Draft mechanism used for drawing air through the boiler or furnace.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Natural",
            "Mechanical forced",
            "Mechanical induced",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:DryerType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Residential",
            "Commercial",
            "Industrial",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:DutyCycle": {
      "additionalProperties": false,
      "description": "Percent of time the system operates. (0-1) (%)",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:decimal"
        },
        "@auc:Source": {
          "enum": [
            "Default",
            "Estimate",
            "Agent",
            "Assessor",
            "Auditor",
            "Product specification",
            "Building Component Library",
            "Utility transfer",
            "Energy Management System",
            "Direct measurement",
            "Design files",
            "Simulation",
            "ENERGY STAR Portfolio Manager",
            "US EPA",
            "US EIA",
            "Target Finder",
            "Arch2030",
            "ASHRAE",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:ENERGYSTARScore": {
      "additionalProperties": false,
      "description": "The ENERGY STAR Score is a measure of how well the property is performing relative to similar properties. The ENERGY STAR scores are based on data from national building energy consumption surveys, and allows Portfolio Manager to control for key variables affecting a building's energy performance, including climate, hours of operation, and building size.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:decimal"
        },
        "@auc:Source": {
          "enum": [
            "Default",
            "Estimate",
            "Agent",
            "Assessor",
            "Auditor",
            "Product specification",
            "Building Component Library",
            "Utility transfer",
            "Energy Management System",
            "Direct measurement",
            "Design files",
            "Simulation",
            "ENERGY STAR Portfolio Manager",
            "US EPA",
            "US EIA",
            "Target Finder",
            "Arch2030",
            "ASHRAE",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:EnergyResource": {
      "additionalProperties": false,
      "description": "Type of energy resource fuel. This can be applied at the premises or individual system or equipment level.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Electricity",
            "Natural gas",
            "Fuel oil",
            "Fuel oil No-1",
            "Fuel oil No-2",
            "Fuel oil No-4",
            "Fuel oil No-5 and No-6",
            "District steam",
            "District hot water",
            "District chilled water",
            "Propane",
            "Liquid propane",
            "Kerosene",
            "Diesel",
            "Coal",
            "Coal (anthracite)",
            "Coal (bituminous)",
            "Coke",
            "Wood",
            "Wood pellets",
            "Hydropower",
            "Biofuel",
            "Wind",
            "Geothermal",
            "Solar",
            "Biomass",
            "Hydrothermal",
            "Dry steam",
            "Flash steam",
            "Ethanol",
            "Biodiesel",
            "Waste heat",
            "Combination",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:EnergyUseByFuelType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "auc:EnergyUse": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        }
      },
      "required": [
        "auc:EnergyUse",
        "auc:PrimaryFuel"
      ],
      "type": "object"
    },
    ".auc:FloorAreas": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "auc:FloorArea": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:FloorAreaCustomName": {
                  "additionalProperties": false,
                  "description": "If \"Custom\" is used as the Floor Area Type, this term can be used to name and identify the custom floor area.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:FloorAreaType": {
                  "additionalProperties": false,
                  "description": "Floor area can be defined and described in many different ways for different purposes. This type field allows multiple types of floor area definitions to exist in the same dataset.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Gross",
                        "Net",
                        "Finished",
                        "Footprint",
                        "Rentable",
                        "Occupied",
                        "Lighted",
                        "Daylit",
                        "Heated",
                        "Cooled",
                        "Conditioned",
                        "Unconditioned",
                        "Semi-conditioned",
                        "Heated and Cooled",
                        "Heated only",
                        "Cooled only",
                        "Ventilated",
                        "Enclosed",
                        "Non-Enclosed",
                        "Open",
                        "Lot",
                        "Custom"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:FloorAreaValue": {
                  "additionalProperties": false,
                  "description": "The floor area numeric value. (ft2)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:Story": {
                  "additionalProperties": false,
                  "description": "The story of the given floor area type.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:int"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:FloorAreaCustomName": {
                    "additionalProperties": false,
                    "description": "If \"Custom\" is used as the Floor Area Type, this term can be used to name and identify the custom floor area.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:FloorAreaType": {
                    "additionalProperties": false,
                    "description": "Floor area can be defined and described in many different ways for different purposes. This type field allows multiple types of floor area definitions to exist in the same dataset.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Gross",
                          "Net",
                          "Finished",
                          "Footprint",
                          "Rentable",
                          "Occupied",
                          "Lighted",
                          "Daylit",
                          "Heated",
                          "Cooled",
                          "Conditioned",
                          "Unconditioned",
                          "Semi-conditioned",
                          "Heated and Cooled",
                          "Heated only",
                          "Cooled only",
                          "Ventilated",
                          "Enclosed",
                          "Non-Enclosed",
                          "Open",
                          "Lot",
                          "Custom"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:FloorAreaValue": {
                    "additionalProperties": false,
                    "description": "The floor area numeric value. (ft2)",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:decimal"
                      },
                      "@auc:Source": {
                        "enum": [
                          "Default",
                          "Estimate",
                          "Agent",
                          "Assessor",
                          "Auditor",
                          "Product specification",
                          "Building Component Library",
                          "Utility transfer",
                          "Energy Management System",
                          "Direct measurement",
                          "Design files",
                          "Simulation",
                          "ENERGY STAR Portfolio Manager",
                          "US EPA",
                          "US EIA",
                          "Target Finder",
                          "Arch2030",
                          "ASHRAE",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:Story": {
                    "additionalProperties": false,
                    "description": "The story of the given floor area type.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:int"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "minItems": 1,
              "type": "array"
            }
          ]
        }
      },
      "required": [
        "auc:FloorArea"
      ],
      "type": "object"
    },
    ".auc:FluorescentStartType": {
      "additionalProperties": false,
      "description": "Start technology used with fluorescent ballasts",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Instant start",
            "Rapid start",
            "Programmed start",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:FoundationWallInsulationCondition": {
      "additionalProperties": false,
      "description": "Assessed condition of installed insulation.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Excellent",
            "Good",
            "Average",
            "Poor",
            "Other/combination",
            "Unknown",
            "None"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:FoundationWallInsulationContinuity": {
      "additionalProperties": false,
      "description": "Insulation installation type.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Cavity",
            "Continuous",
            "Other",
            "Unknown",
            "None"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:HeatingStaging": {
      "additionalProperties": false,
      "description": "The method of heating staging used by the unit. Select \"Single Stage\" for units with single stage (on/off) control. Select \"Multiple, Discrete Stages\" for units with multiple discrete stages (low-fire / high-fire). Select \"Modulating\" for units which contain modulating burners.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Single stage",
            "Multiple discrete stages",
            "Variable",
            "Modulating",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:IdentifierCustomName": {
      "additionalProperties": false,
      "description": "If \"Custom\" is used as an Identifier Type, this term can be used to specify the name of the Custom ID. This would be used to specify the name of the specific program that this identifier applies to, for example \"Wisconsin Weatherization Program\". It can also be used for the Portfolio Manager Standard IDs that are assigned to different Portfolio Manager programs, such as \"NYC Building Identification Number (BIN)\"",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:string"
        }
      },
      "type": "object"
    },
    ".auc:IdentifierLabel": {
      "additionalProperties": false,
      "description": "Identifier used in a specific program or dataset. There can be multiple instances of Identifier Types within a dataset, such as a Listing ID, a Tax Map Number ID, and a Custom ID.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Premises",
            "Listing",
            "Name",
            "Portfolio Manager Property ID",
            "Portfolio Manager Standard",
            "Federal real property",
            "Tax book number",
            "Tax map number",
            "Assessor parcel number",
            "Tax parcel letter",
            "Custom",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:IdentifierValue": {
      "additionalProperties": false,
      "description": "The identifying value associated with the Identifier Type. There can be many Identifier Types and Values associated with an individual premises.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:string"
        }
      },
      "type": "object"
    },
    ".auc:IgnitionType": {
      "additionalProperties": false,
      "description": "Ignition mechanism in gas heating equipment. Either pilot light or an intermittent ignition device (IID)",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Intermittent ignition device",
            "Pilot light",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:LinkedPremises": {
      "additionalProperties": false,
      "description": "Establishes whether the system applies to one or more entire buildings, subsections, spaces, or zones within buildings. Power consuming system loads should be distributed in proportion to the floor areas of linked premises. Envelope systems should be distributed in proportion to the exterior surface areas of linked premises.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "auc:Facility": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:LinkedFacilityID": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "description": "ID numbers of the facilities associated with the system.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@IDref": {
                      "$ref": "#/definitions/xs:IDREF"
                    },
                    "auc:LinkedScheduleID": {
                      "anyOf": [
                        {
                          "additionalProperties": false,
                          "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "@IDref": {
                              "$ref": "#/definitions/xs:IDREF"
                            }
                          },
                          "required": [
                            "@IDref"
                          ],
                          "type": "object"
                        },
                        {
                          "items": {
                            "additionalProperties": false,
                            "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          "type": "array"
                        }
                      ]
                    }
                  },
                  "required": [
                    "@IDref"
                  ],
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "description": "ID numbers of the facilities associated with the system.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@IDref": {
                        "$ref": "#/definitions/xs:IDREF"
                      },
                      "auc:LinkedScheduleID": {
                        "anyOf": [
                          {
                            "additionalProperties": false,
                            "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          {
                            "items": {
                              "additionalProperties": false,
                              "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            "type": "array"
                          }
                        ]
                      }
                    },
                    "required": [
                      "@IDref"
                    ],
                    "type": "object"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:LinkedFacilityID"
          ],
          "type": "object"
        },
        "auc:Site": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:LinkedSiteID": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "description": "ID numbers of the sites associated with the system.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@IDref": {
                      "$ref": "#/definitions/xs:IDREF"
                    },
                    "auc:LinkedScheduleID": {
                      "anyOf": [
                        {
                          "additionalProperties": false,
                          "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "@IDref": {
                              "$ref": "#/definitions/xs:IDREF"
                            }
                          },
                          "required": [
                            "@IDref"
                          ],
                          "type": "object"
                        },
                        {
                          "items": {
                            "additionalProperties": false,
                            "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          "type": "array"
                        }
                      ]
                    }
                  },
                  "required": [
                    "@IDref"
                  ],
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "description": "ID numbers of the sites associated with the system.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@IDref": {
                        "$ref": "#/definitions/xs:IDREF"
                      },
                      "auc:LinkedScheduleID": {
                        "anyOf": [
                          {
                            "additionalProperties": false,
                            "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          {
                            "items": {
                              "additionalProperties": false,
                              "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            "type": "array"
                          }
                        ]
                      }
                    },
                    "required": [
                      "@IDref"
                    ],
                    "type": "object"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:LinkedSiteID"
          ],
          "type": "object"
        },
        "auc:Space": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:LinkedSpaceID": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "description": "ID numbers of the spaces associated with the system.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@IDref": {
                      "$ref": "#/definitions/xs:IDREF"
                    },
                    "auc:LinkedScheduleID": {
                      "anyOf": [
                        {
                          "additionalProperties": false,
                          "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "@IDref": {
                              "$ref": "#/definitions/xs:IDREF"
                            }
                          },
                          "required": [
                            "@IDref"
                          ],
                          "type": "object"
                        },
                        {
                          "items": {
                            "additionalProperties": false,
                            "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          "type": "array"
                        }
                      ]
                    }
                  },
                  "required": [
                    "@IDref"
                  ],
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "description": "ID numbers of the spaces associated with the system.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@IDref": {
                        "$ref": "#/definitions/xs:IDREF"
                      },
                      "auc:LinkedScheduleID": {
                        "anyOf": [
                          {
                            "additionalProperties": false,
                            "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          {
                            "items": {
                              "additionalProperties": false,
                              "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            "type": "array"
                          }
                        ]
                      }
                    },
                    "required": [
                      "@IDref"
                    ],
                    "type": "object"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:LinkedSpaceID"
          ],
          "type": "object"
        },
        "auc:Subsection": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:LinkedSubsectionID": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "description": "ID numbers of the subsections associated with the system.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@IDref": {
                      "$ref": "#/definitions/xs:IDREF"
                    },
                    "auc:LinkedScheduleID": {
                      "anyOf": [
                        {
                          "additionalProperties": false,
                          "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "@IDref": {
                              "$ref": "#/definitions/xs:IDREF"
                            }
                          },
                          "required": [
                            "@IDref"
                          ],
                          "type": "object"
                        },
                        {
                          "items": {
                            "additionalProperties": false,
                            "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          "type": "array"
                        }
                      ]
                    }
                  },
                  "required": [
                    "@IDref"
                  ],
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "description": "ID numbers of the subsections associated with the system.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@IDref": {
                        "$ref": "#/definitions/xs:IDREF"
                      },
                      "auc:LinkedScheduleID": {
                        "anyOf": [
                          {
                            "additionalProperties": false,
                            "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          {
                            "items": {
                              "additionalProperties": false,
                              "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            "type": "array"
                          }
                        ]
                      }
                    },
                    "required": [
                      "@IDref"
                    ],
                    "type": "object"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:LinkedSubsectionID"
          ],
          "type": "object"
        },
        "auc:ThermalZone": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:LinkedThermalZoneID": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "description": "ID numbers of the zones associated with the system.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@IDref": {
                      "$ref": "#/definitions/xs:IDREF"
                    },
                    "auc:LinkedScheduleID": {
                      "anyOf": [
                        {
                          "additionalProperties": false,
                          "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "@IDref": {
                              "$ref": "#/definitions/xs:IDREF"
                            }
                          },
                          "required": [
                            "@IDref"
                          ],
                          "type": "object"
                        },
                        {
                          "items": {
                            "additionalProperties": false,
                            "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          "type": "array"
                        }
                      ]
                    }
                  },
                  "required": [
                    "@IDref"
                  ],
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "description": "ID numbers of the zones associated with the system.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@IDref": {
                        "$ref": "#/definitions/xs:IDREF"
                      },
                      "auc:LinkedScheduleID": {
                        "anyOf": [
                          {
                            "additionalProperties": false,
                            "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          {
                            "items": {
                              "additionalProperties": false,
                              "description": "ID numbers of one or more schedules that apply to this system type in the context of the linked premise.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            "type": "array"
                          }
                        ]
                      }
                    },
                    "required": [
                      "@IDref"
                    ],
                    "type": "object"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:LinkedThermalZoneID"
          ],
          "type": "object"
        }
      },
      "type": "object"
    },
    ".auc:Location": {
      "additionalProperties": false,
      "description": "Location of system.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Indoors",
            "Outdoors",
            "Closet",
            "Garage",
            "Attic",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:Manufacturer": {
      "additionalProperties": false,
      "description": "Company that manufactured the equipment",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:string"
        }
      },
      "type": "object"
    },
    ".auc:ModelNumber": {
      "additionalProperties": false,
      "description": "Model or catalog number that can be used to identify more detailed system characteristics.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:string"
        }
      },
      "type": "object"
    },
    ".auc:OccupancyClassification": {
      "additionalProperties": false,
      "description": "Classification of the space (complex, whole building, or subsection) tasks by building occupants.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Manufactured home",
            "Single family",
            "Multifamily",
            "Multifamily with commercial",
            "Multifamily individual unit",
            "Residential",
            "Health care-Pharmacy",
            "Health care-Skilled nursing facility",
            "Health care-Residential treatment center",
            "Health care-Inpatient hospital",
            "Health care-Outpatient rehabilitation",
            "Health care-Diagnostic center",
            "Health care-Outpatient non-diagnostic",
            "Health care-Outpatient surgical",
            "Health care-Veterinary",
            "Health care-Morgue or mortuary",
            "Health care",
            "Gas station",
            "Convenience store",
            "Food sales-Grocery store",
            "Food sales",
            "Laboratory-Testing",
            "Laboratory-Medical",
            "Laboratory ",
            "Vivarium",
            "Office",
            "Bank",
            "Courthouse",
            "Public safety station",
            "Public safety-Detention center",
            "Public safety-Correctional facility",
            "Public safety",
            "Warehouse-Refrigerated",
            "Warehouse-Unrefrigerated",
            "Warehouse-Self-storage",
            "Warehouse",
            "Assembly-Religious",
            "Assembly-Cultural entertainment",
            "Assembly-Social entertainment",
            "Assembly-Arcade or casino without lodging",
            "Assembly-Convention center",
            "Assembly-Stadium",
            "Assembly-Public",
            "Recreation-Pool",
            "Recreation-Fitness center",
            "Recreation-Ice rink",
            "Recreation-Indoor sport",
            "Recreation",
            "Education-Higher",
            "Education-Secondary",
            "Education-Primary",
            "Education-Preschool or daycare",
            "Education",
            "Food service-Fast",
            "Food service-Full",
            "Food service-Limited",
            "Food service-Institutional",
            "Food service",
            "Lodging-Institutional",
            "Lodging with extended amenities",
            "Lodging with limited amenities",
            "Lodging",
            "Retail-Mall",
            "Retail-Strip mall",
            "Retail-Enclosed mall",
            "Retail-Dry goods retail",
            "Retail-Hypermarket",
            "Retail",
            "Service-Postal",
            "Service-Repair",
            "Service-Laundry or dry cleaning",
            "Service-Studio",
            "Service-Beauty and health",
            "Service-Production and assembly",
            "Service",
            "Transportation terminal",
            "Central Plant",
            "Water treatment-Wastewater",
            "Water treatment-Drinking water and distribution",
            "Water treatment",
            "Energy generation plant",
            "Industrial manufacturing plant",
            "Utility",
            "Industrial",
            "Agricultural estate",
            "Mixed-use commercial",
            "Parking",
            "Attic",
            "Basement",
            "Dining area",
            "Living area",
            "Sleeping area",
            "Laundry area",
            "Lodging area",
            "Dressing area",
            "Restroom",
            "Auditorium",
            "Classroom",
            "Day room",
            "Sport play area",
            "Stage",
            "Spectator area",
            "Office work area",
            "Non-office work area",
            "Common area",
            "Reception area",
            "Waiting area",
            "Transportation waiting area",
            "Lobby",
            "Conference room",
            "Computer lab",
            "Data center",
            "Printing room",
            "Media center",
            "Telephone data entry",
            "Darkroom",
            "Courtroom",
            "Kitchen",
            "Kitchenette",
            "Refrigerated storage",
            "Bar",
            "Dance floor",
            "Security room",
            "Shipping and receiving",
            "Mechanical room",
            "Chemical storage room",
            "Non-chemical storage room",
            "Janitorial closet",
            "Vault",
            "Corridor",
            "Deck",
            "Courtyard",
            "Atrium",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:OccupancyLevels": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "auc:OccupancyLevel": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:OccupantQuantity": {
                  "additionalProperties": false,
                  "description": "The value associated with the Occupant Quantity Type term.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:OccupantQuantityType": {
                  "additionalProperties": false,
                  "description": "Type of quantitative measure for capturing occupant information about the premises. The value is captured by the Occupant Quantity term.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Peak total occupants",
                        "Adults",
                        "Children",
                        "Average residents",
                        "Workers on main shift",
                        "Full-time equivalent workers",
                        "Average daily salaried labor hours",
                        "Registered students",
                        "Staffed beds",
                        "Licensed beds",
                        "Capacity",
                        "Capacity percentage"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:OccupantType": {
                  "additionalProperties": false,
                  "description": "Type of occupants who are permanently resident in a premises.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Family household",
                        "Married couple, no children",
                        "Male householder, no spouse",
                        "Female householder, no spouse",
                        "Cooperative household",
                        "Nonfamily household",
                        "Single male",
                        "Single female",
                        "Student community",
                        "Military community",
                        "Independent seniors community",
                        "Special accessibility needs community",
                        "Government subsidized community",
                        "Therapeutic community",
                        "No specific occupant type",
                        "For-profit organization",
                        "Religious organization",
                        "Non-profit organization",
                        "Government organization",
                        "Federal government",
                        "State government",
                        "Local government",
                        "Property",
                        "Animals",
                        "Other",
                        "Vacant",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:OccupantQuantity": {
                    "additionalProperties": false,
                    "description": "The value associated with the Occupant Quantity Type term.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:decimal"
                      },
                      "@auc:Source": {
                        "enum": [
                          "Default",
                          "Estimate",
                          "Agent",
                          "Assessor",
                          "Auditor",
                          "Product specification",
                          "Building Component Library",
                          "Utility transfer",
                          "Energy Management System",
                          "Direct measurement",
                          "Design files",
                          "Simulation",
                          "ENERGY STAR Portfolio Manager",
                          "US EPA",
                          "US EIA",
                          "Target Finder",
                          "Arch2030",
                          "ASHRAE",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:OccupantQuantityType": {
                    "additionalProperties": false,
                    "description": "Type of quantitative measure for capturing occupant information about the premises. The value is captured by the Occupant Quantity term.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Peak total occupants",
                          "Adults",
                          "Children",
                          "Average residents",
                          "Workers on main shift",
                          "Full-time equivalent workers",
                          "Average daily salaried labor hours",
                          "Registered students",
                          "Staffed beds",
                          "Licensed beds",
                          "Capacity",
                          "Capacity percentage"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:OccupantType": {
                    "additionalProperties": false,
                    "description": "Type of occupants who are permanently resident in a premises.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Family household",
                          "Married couple, no children",
                          "Male householder, no spouse",
                          "Female householder, no spouse",
                          "Cooperative household",
                          "Nonfamily household",
                          "Single male",
                          "Single female",
                          "Student community",
                          "Military community",
                          "Independent seniors community",
                          "Special accessibility needs community",
                          "Government subsidized community",
                          "Therapeutic community",
                          "No specific occupant type",
                          "For-profit organization",
                          "Religious organization",
                          "Non-profit organization",
                          "Government organization",
                          "Federal government",
                          "State government",
                          "Local government",
                          "Property",
                          "Animals",
                          "Other",
                          "Vacant",
                          "Unknown"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "minItems": 1,
              "type": "array"
            }
          ]
        }
      },
      "required": [
        "auc:OccupancyLevel"
      ],
      "type": "object"
    },
    ".auc:Ownership": {
      "additionalProperties": false,
      "description": "The type of organization, association, business, etc. that owns the premises",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Property management company",
            "Corporation/partnership/LLC",
            "Religious organization",
            "Individual",
            "Franchise",
            "Other non-government",
            "Government",
            "Federal government",
            "State government",
            "Local government",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:OwnershipStatus": {
      "additionalProperties": false,
      "description": "Ownership status of the premises with respect to the occupant",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Owned",
            "Mortgaged",
            "Leased",
            "Rented",
            "Occupied without payment of rent",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:PremisesIdentifiers": {
      "additionalProperties": false,
      "description": "Identifier used in a specific program or dataset. There can be multiple instances of Identifier Types within a dataset.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "auc:PremisesIdentifier": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:IdentifierCustomName": {
                  "$ref": "#/definitions/.auc:IdentifierCustomName"
                },
                "auc:IdentifierLabel": {
                  "$ref": "#/definitions/.auc:IdentifierLabel"
                },
                "auc:IdentifierValue": {
                  "$ref": "#/definitions/.auc:IdentifierValue"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:IdentifierCustomName": {
                    "$ref": "#/definitions/.auc:IdentifierCustomName"
                  },
                  "auc:IdentifierLabel": {
                    "$ref": "#/definitions/.auc:IdentifierLabel"
                  },
                  "auc:IdentifierValue": {
                    "$ref": "#/definitions/.auc:IdentifierValue"
                  }
                },
                "type": "object"
              },
              "minItems": 1,
              "type": "array"
            }
          ]
        }
      },
      "required": [
        "auc:PremisesIdentifier"
      ],
      "type": "object"
    },
    ".auc:PremisesName": {
      "additionalProperties": false,
      "description": "Name identifying the premises. This could be the name of the complex, the building, or the space within a building, such as a classroom number.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:string"
        }
      },
      "type": "object"
    },
    ".auc:PremisesNotes": {
      "additionalProperties": false,
      "description": "Details about the premises.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:string"
        }
      },
      "type": "object"
    },
    ".auc:PrimaryContactID": {
      "additionalProperties": false,
      "description": "Primary contact ID number for the premises.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@IDref": {
          "$ref": "#/definitions/xs:IDREF"
        }
      },
      "required": [
        "@IDref"
      ],
      "type": "object"
    },
    ".auc:PrimaryFuel": {
      "additionalProperties": false,
      "description": "Main fuel used by the system.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/auc:FuelTypes"
        }
      },
      "type": "object"
    },
    ".auc:Priority": {
      "additionalProperties": false,
      "description": "Order of precedence relative to other applicable systems. Enter Primary if this is the only system.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Primary",
            "Secondary",
            "Tertiary",
            "Back-up",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:Quantity": {
      "additionalProperties": false,
      "description": "Number of systems of this type.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:integer"
        },
        "@auc:Source": {
          "enum": [
            "Default",
            "Estimate",
            "Agent",
            "Assessor",
            "Auditor",
            "Product specification",
            "Building Component Library",
            "Utility transfer",
            "Energy Management System",
            "Direct measurement",
            "Design files",
            "Simulation",
            "ENERGY STAR Portfolio Manager",
            "US EPA",
            "US EIA",
            "Target Finder",
            "Arch2030",
            "ASHRAE",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:ReferenceCase": {
      "additionalProperties": false,
      "description": "ID number for scenario that serves as the reference case for calculating energy savings, simple payback, etc.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@IDref": {
          "$ref": "#/definitions/xs:IDREF"
        }
      },
      "required": [
        "@IDref"
      ],
      "type": "object"
    },
    ".auc:Refrigerant": {
      "additionalProperties": false,
      "description": "The type of refrigerant used in the system.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "R134a",
            "R123",
            "R22",
            "R290",
            "R401a",
            "R404a",
            "R407a",
            "R407c",
            "R408a",
            "R409a",
            "R410a",
            "R500",
            "R502",
            "R600a",
            "R744",
            "R717",
            "R718",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:ResourceBoundary": {
      "additionalProperties": false,
      "description": "The boundary that encompasses the measured resource.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Site",
            "Source",
            "Onsite",
            "Offsite",
            "Net",
            "Gross",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:ResourceUnits": {
      "additionalProperties": false,
      "description": "Units for resource consumption or generation",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Cubic Meters",
            "kcf",
            "MCF",
            "Gallons",
            "Wh",
            "kWh",
            "MWh",
            "Btu",
            "kBtu",
            "MMBtu",
            "therms",
            "lbs.",
            "Klbs",
            "Mlbs",
            "Tons",
            "Ton Hours",
            "Other / No Units",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:SlabHeating": {
      "additionalProperties": false,
      "description": "The classifications for floors in contact with the ground.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Heated",
            "Unheated",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:SlabInsulationCondition": {
      "additionalProperties": false,
      "description": "Assessed condition of installed insulation.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Excellent",
            "Good",
            "Average",
            "Poor",
            "Other",
            "Unknown",
            "None"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:SlabInsulationOrientation": {
      "additionalProperties": false,
      "description": "The location and extent of slab-on-grade floor insulation",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "12 in Horizontal",
            "12 in Vertical",
            "24 in Horizontal",
            "24 in Vertical",
            "36 in Horizontal",
            "36 in Vertical",
            "48 in Horizontal",
            "48 in Vertical",
            "Fully Insulated Slab",
            "None",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:SystemPerformanceRatio": {
      "additionalProperties": false,
      "description": "Ratio of annual system load to the annual system energy consumption (similar to a whole system COP). A higher value indicates less heating and/or cooling energy use to meet the loads, and therefore represents a more efficient HVAC system. SPR can be used to describe the heating, cooling, and overall HVAC systems.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:decimal"
        },
        "@auc:Source": {
          "enum": [
            "Default",
            "Estimate",
            "Agent",
            "Assessor",
            "Auditor",
            "Product specification",
            "Building Component Library",
            "Utility transfer",
            "Energy Management System",
            "Direct measurement",
            "Design files",
            "Simulation",
            "ENERGY STAR Portfolio Manager",
            "US EPA",
            "US EIA",
            "Target Finder",
            "Arch2030",
            "ASHRAE",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:TemporalStatus": {
      "additionalProperties": false,
      "description": "Temporal characteristic of this measurement.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Pre retrofit",
            "Post retrofit",
            "Baseline",
            "Current",
            "Target",
            "Design Target",
            "Last billing period",
            "Additional to last billing period",
            "Current billing period",
            "Billed to date",
            "Current day",
            "Current day last year",
            "Previous day",
            "Previous day last year",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:ThirdPartyCertification": {
      "additionalProperties": false,
      "description": "Independent organization has verified that product or appliance meets or exceeds the standard in question (ENERGY STAR, CEE, or other).",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "ENERGY STAR",
            "ENERGY STAR Most Efficient",
            "FEMP Designated",
            "CEE Tier 1",
            "CEE Tier 2",
            "CEE Tier 3",
            "Other",
            "None",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:UserDefinedFields": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "auc:UserDefinedField": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:FieldName": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:FieldValue": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:FieldName": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:FieldValue": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:string"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "minItems": 1,
              "type": "array"
            }
          ]
        }
      },
      "required": [
        "auc:UserDefinedField"
      ],
      "type": "object"
    },
    ".auc:VentilationControlMethod": {
      "additionalProperties": false,
      "description": "The method used to control the rate of outside air ventilation.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "CO2 Sensors",
            "Fixed",
            "Occupancy Sensors",
            "Scheduled",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:WaterCooledCondenserFlowControl": {
      "additionalProperties": false,
      "description": "Describes water flow control for a water-cooled condenser.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Fixed Flow",
            "Two Position Flow",
            "Variable Flow",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:WaterResource": {
      "additionalProperties": false,
      "description": "Water type used as a resource on the premises.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Potable water",
            "Wastewater",
            "Greywater",
            "Reclaimed water",
            "Captured rainwater",
            "Alternative water",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:WaterSideEconomizerType": {
      "additionalProperties": false,
      "description": "Describes water flow control for a water-cooled condenser.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "Parallel Plate and Frame Heat Exchanger",
            "Series Plate and Frame Heat Exchanger",
            "Strainer Cycle",
            "Thermo Cycle",
            "Other",
            "Unknown"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:WeatherDataSource": {
      "additionalProperties": false,
      "description": "Method for determining weather data associated with the time series",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "enum": [
            "On-Site Measurement",
            "Weather Station",
            "TMY",
            "TMY2",
            "TMY3",
            "IWEC",
            "CWEC",
            "CZRV2",
            "Other"
          ],
          "type": "string"
        }
      },
      "type": "object"
    },
    ".auc:YearInstalled": {
      "additionalProperties": false,
      "description": "Year the system was originally installed in the building. Equipment age may be used as a proxy.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:gYear"
        }
      },
      "type": "object"
    },
    ".auc:YearofManufacture": {
      "additionalProperties": false,
      "description": "Year system was manufactured.",
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "$ref": "#/definitions/xs:gYear"
        }
      },
      "type": "object"
    },
    "auc:AllResourceTotalType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "auc:ElectricitySourcedFromOnsiteRenewableSystems": {
          "additionalProperties": false,
          "description": "Total electricity produced from resources that do not deplete when their energy is harnessed, such as sunlight, wind, waves, water flow, biological processes such as anaerobic digestion and geothermal energy. (kWh)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:EndUse": {
          "additionalProperties": false,
          "description": "End use for which data is included.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/auc:EndUse"
            }
          },
          "type": "object"
        },
        "auc:EnergyCost": {
          "additionalProperties": false,
          "description": "The annual cost associated with a selected 12 month time period for a premises. It can be an individual value for different energy types, and can also be an aggregated value across all energy types. ($)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:OnsiteRenewableSystemElectricityExported": {
          "additionalProperties": false,
          "description": "The portion of energy produced from the on-site renewable energy system(s) that is exported (not used on-site). (kWh)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ResourceBoundary": {
          "$ref": "#/definitions/.auc:ResourceBoundary"
        },
        "auc:SiteEnergyUse": {
          "additionalProperties": false,
          "description": "The annual amount of all the energy the premises consumes on-site, as reported on the utility bills. (kBtu)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:SiteEnergyUseIntensity": {
          "additionalProperties": false,
          "description": "The Site Energy Use divided by the premises gross floor area. (kBtu/ft2)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:SourceEnergyUse": {
          "additionalProperties": false,
          "description": "The total annual amount of all the raw resource required to operate the premises, including losses that take place during generation, transmission, and distribution of the energy. (kBtu)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:SourceEnergyUseIntensity": {
          "additionalProperties": false,
          "description": "The Source Energy Use divided by the premises gross floor area. (kBtu/ft2)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:SummerPeak": {
          "additionalProperties": false,
          "description": "Peak demand in the summer. (kW)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:TemporalStatus": {
          "$ref": "#/definitions/.auc:TemporalStatus"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:WasteWaterVolume": {
          "additionalProperties": false,
          "description": "Annual volume of water that is returned to a wastewater treatment facility. (kgal)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WaterCost": {
          "additionalProperties": false,
          "description": "Annual cost of water. ($)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WaterIntensity": {
          "additionalProperties": false,
          "description": "Water use from different sources divided by the premises gross floor area. (kgal/ft2)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WaterResource": {
          "$ref": "#/definitions/.auc:WaterResource"
        },
        "auc:WaterUse": {
          "additionalProperties": false,
          "description": "Annual water use from different sources. (kgal)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WinterPeak": {
          "additionalProperties": false,
          "description": "Peak demand in the winter. (kW)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        }
      },
      "type": "object"
    },
    "auc:AuditorQualificationType": {
      "enum": [
        "Professional Engineer (PE)",
        "Certified Energy Manager (CEM)",
        "Building Operator Certification (BOC)",
        "Building Performance Institute (BPI) Certification",
        "Building Performance Institute: Building Analyst (BA)",
        "Building Performance Institute: Advanced Home Energy Professional (HEP)",
        "Building Performance Institute: Advanced Home Energy Professional - Energy Auditor (HEP-EA)",
        "Building Performance Institute: Advanced Home Energy Professional - Quality Control Inspector (HEP-QCI)",
        "Building Performance Institute: Multifamily Building Analyst",
        "Residential Energy Services Network (RESNET) Certification",
        "Residential Energy Services Network (RESNET) - Home Partner",
        "Registered Architect (RA)",
        "Refrigerating System Operating Engineer",
        "High Pressure Boiler Operating Engineer",
        "Certified Commissioning Professional (CCP)",
        "Associate Commissioning Professional (ACP)",
        "Existing Building Commissioning Professional (EBCP)",
        "Commissioning Process Management Professional (CPMP)",
        "Accredited Commissioning Process Authority Professional (CxAP)",
        "NYSERDA FlexTech Consultant",
        "Certified Energy Auditor (CEA)",
        "High-Performance Building Design Professional (HBDP)",
        "Other",
        "None"
      ],
      "type": "string"
    },
    "auc:CalculationMethodType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "auc:Estimated": {
          "$ref": "#/definitions/xs:anyType"
        },
        "auc:Measured": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:MeasuredEnergySource": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:DirectMeasurement": {
                  "$ref": "#/definitions/xs:anyType"
                },
                "auc:Other": {
                  "$ref": "#/definitions/xs:anyType"
                },
                "auc:UtilityBills": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:UtilityAccountNumber": {
                      "additionalProperties": false,
                      "description": "Unique account number designated by the utility",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:UtilityBillpayer": {
                      "additionalProperties": false,
                      "description": "Organization that is responsible for paying the bills associated with this meter.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:UtilityMeterNumber": {
                      "additionalProperties": false,
                      "description": "Unique identification number for the meter",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:string"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:Other": {
          "$ref": "#/definitions/xs:anyType"
        },
        "auc:Simulated": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:SoftwareProgramUsed": {
              "additionalProperties": false,
              "description": "Building energy modeling software used to estimate energy savings",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:string"
                }
              },
              "type": "object"
            },
            "auc:SoftwareProgramVersion": {
              "additionalProperties": false,
              "description": "Version number of building energy modeling software",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:string"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        }
      },
      "type": "object"
    },
    "auc:CeilingSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:CeilingColor": {
          "additionalProperties": false,
          "description": "Color of a material or component. Can be applied to opaque surfaces, materials, and so forth.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "White",
                "Light",
                "Medium",
                "Med-dark",
                "Dark",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CeilingConstruction": {
          "additionalProperties": false,
          "description": "The general description of the main structural construction method used for an opaque surface.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/auc:EnvelopeConstructionType"
            }
          },
          "type": "object"
        },
        "auc:CeilingExteriorSolarAbsorptance": {
          "additionalProperties": false,
          "description": "The fraction of incident radiation in the solar spectrum that is absorbed by the material or surface. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CeilingExteriorThermalAbsorptance": {
          "additionalProperties": false,
          "description": "The fraction of incident long wavelength infrared radiation that is absorbed by the material or surface. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CeilingFinish": {
          "additionalProperties": false,
          "description": "The final material applied to a surface, either interior or exterior. Some structural components don't have an exterior finish, such as unfinished poured concrete.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Wood",
                "Stone",
                "Tile",
                "Brick",
                "Masonry",
                "Concrete",
                "Metal",
                "Metal panel",
                "Metal panel-standing seam",
                "Sheet metal",
                "EIFS",
                "Shingles-asphalt",
                "Shingles-composition",
                "Shingles-wood",
                "Shingles-asbestos",
                "Shingles-slate or tile",
                "Shakes-wood",
                "Carpet",
                "Linoleum",
                "Asphalt or fiberglass",
                "Plastic-rubber-synthetic sheeting",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CeilingFramingDepth": {
          "additionalProperties": false,
          "description": "Dimension of the distance from the front to the back, such as the depth of structural framing in a wall or floor. It can also be the distance from the top to the bottom, such as the depth of a tank or pool of a component or material, such as the depth of the structural framing. (in.)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CeilingFramingFactor": {
          "additionalProperties": false,
          "description": "Fraction of the surface that is composed of structural framing material. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CeilingFramingMaterial": {
          "additionalProperties": false,
          "description": "The material used to create the structural integrity in an opaque surface. In many cases the framing material is not continuous across the construction.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Wood",
                "Steel",
                "Concrete",
                "Brick",
                "Masonry",
                "Other",
                "Unknown",
                "None"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CeilingFramingSpacing": {
          "additionalProperties": false,
          "description": "Dimension of the distance between two components.\n\nExamples include: Framing spacing: the dimension from centerline to centerline of a surface framing material.\n\nWindow spacing: the dimension between windows in a discrete window layout. (in.)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CeilingInsulation": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:RoofInsulationApplication": {
                  "additionalProperties": false,
                  "description": "A description of the type of insulation and how it is applied.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Loose-fill",
                        "Batt",
                        "Spray-on",
                        "Rigid",
                        "Other",
                        "Unknown",
                        "None"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:RoofInsulationCondition": {
                  "additionalProperties": false,
                  "description": "Assessed condition of installed insulation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Excellent",
                        "Good",
                        "Average",
                        "Poor",
                        "Other",
                        "Unknown",
                        "None"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:RoofInsulationContinuity": {
                  "additionalProperties": false,
                  "description": "Insulation installation type.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Cavity",
                        "Continuous",
                        "Other",
                        "Unknown",
                        "None"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:RoofInsulationMaterial": {
                  "additionalProperties": false,
                  "description": "Material used for the structural component of the surface.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/auc:InsulationMaterialType"
                    }
                  },
                  "type": "object"
                },
                "auc:RoofInsulationThickness": {
                  "additionalProperties": false,
                  "description": "Thickness of roof insulation. (in.)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:RoofInsulationApplication": {
                    "additionalProperties": false,
                    "description": "A description of the type of insulation and how it is applied.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Loose-fill",
                          "Batt",
                          "Spray-on",
                          "Rigid",
                          "Other",
                          "Unknown",
                          "None"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:RoofInsulationCondition": {
                    "additionalProperties": false,
                    "description": "Assessed condition of installed insulation.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Excellent",
                          "Good",
                          "Average",
                          "Poor",
                          "Other",
                          "Unknown",
                          "None"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:RoofInsulationContinuity": {
                    "additionalProperties": false,
                    "description": "Insulation installation type.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Cavity",
                          "Continuous",
                          "Other",
                          "Unknown",
                          "None"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:RoofInsulationMaterial": {
                    "additionalProperties": false,
                    "description": "Material used for the structural component of the surface.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/auc:InsulationMaterialType"
                      }
                    },
                    "type": "object"
                  },
                  "auc:RoofInsulationThickness": {
                    "additionalProperties": false,
                    "description": "Thickness of roof insulation. (in.)",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:decimal"
                      },
                      "@auc:Source": {
                        "enum": [
                          "Default",
                          "Estimate",
                          "Agent",
                          "Assessor",
                          "Auditor",
                          "Product specification",
                          "Building Component Library",
                          "Utility transfer",
                          "Energy Management System",
                          "Direct measurement",
                          "Design files",
                          "Simulation",
                          "ENERGY STAR Portfolio Manager",
                          "US EPA",
                          "US EIA",
                          "Target Finder",
                          "Arch2030",
                          "ASHRAE",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:CeilingRValue": {
          "additionalProperties": false,
          "description": "(Also known as thermal resistance), quantity determined by the temperature difference, at steady state, between two defined surfaces of a material or construction that induces a unit heat flow rate through unit area (R = ΔT/q). R-value is the reciprocal of thermal conductance. A unit of thermal resistance used for comparing insulating values of different materials, for the specific thickness of the material. The higher the R-value number, a material, the greater its insulating properties and the slower the heat flow through it. This R-value does not include the interior and exterior air film coefficients. (hr-ft2-F/Btu)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CeilingSlope": {
          "additionalProperties": false,
          "description": "A descriptive value for tilt, when an exact numeric angle is not known.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Flat",
                "Sloped",
                "> 2:12",
                "< 2:12",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CeilingUFactor": {
          "additionalProperties": false,
          "description": "The thermal transmission in unit time through a unit area of a particular body or assembly, including its boundary films, divided by the difference between the environmental temperatures on either side of the body or assembly. Note that the U-factor for a construction assembly, including fenestration, includes the interior and exterior film coefficients (the boundary films referenced above). (Btu/hr·ft2·°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CeilingVisibleAbsorptance": {
          "additionalProperties": false,
          "description": "The fraction of incident visible wavelength radiation that is absorbed by the material or surface. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DeckType": {
          "additionalProperties": false,
          "description": "The material used to create the structural integrity in an opaque surface. In many cases the framing material is not continuous across the construction.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Wood",
                "Steel",
                "Concrete",
                "Brick",
                "Masonry",
                "Other",
                "Unknown",
                "None"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:RadiantBarrier": {
          "additionalProperties": false,
          "description": "True if a radiant barrier is installed, false otherwise.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:boolean"
            }
          },
          "type": "object"
        },
        "auc:SpecialCeilingClassification": {
          "additionalProperties": false,
          "description": "Additional classifications for the roof construction.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Cool roof",
                "Green roof",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        }
      },
      "type": "object"
    },
    "auc:CommercialFacilityType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "auc:AspectRatio": {
          "additionalProperties": false,
          "description": "The ratio of width to length, of a premises",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Assessment": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AssessmentLevel": {
                  "additionalProperties": false,
                  "description": "Value from assessment program, such as LEED \"Platinum\"",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Bronze",
                        "Silver",
                        "Gold",
                        "Emerald",
                        "Certified",
                        "Platinum",
                        "One Star",
                        "Two Star",
                        "Three Star",
                        "Four Star",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AssessmentProgram": {
                  "additionalProperties": false,
                  "description": "Program which issues energy labels, ratings, or sustainability certifications.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "ENERGY STAR",
                        "ENERGY STAR Certified Homes",
                        "LEED",
                        "Home Energy Upgrade Certificate of Energy Efficiency Performance",
                        "Home Energy Upgrade Certificate of Energy Efficiency Improvements",
                        "Passive House",
                        "Living Building Challenge",
                        "Green Globes",
                        "Challenge Home",
                        "WaterSense",
                        "Indoor airPLUS",
                        "NGBS ICC 700",
                        "CMP Green Value Score",
                        "RESNET HERS",
                        "Home Energy Score",
                        "ASHRAE Building EQ",
                        "Commercial Building Energy Asset Score",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AssessmentValue": {
                  "additionalProperties": false,
                  "description": "Value from certifications that produce a numeric metric, such as Energy Star Score, Home Energy Rating System (HERS) Index Score, Home Energy Score",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    }
                  },
                  "type": "object"
                },
                "auc:AssessmentVersion": {
                  "additionalProperties": false,
                  "description": "Version of the assessment documentation, such as \"2.0\"",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:AssessmentYear": {
                  "additionalProperties": false,
                  "description": "Year the assessment qualifications for recognition were documented. (CCYY)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:gYear"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:AssessmentLevel": {
                    "additionalProperties": false,
                    "description": "Value from assessment program, such as LEED \"Platinum\"",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Bronze",
                          "Silver",
                          "Gold",
                          "Emerald",
                          "Certified",
                          "Platinum",
                          "One Star",
                          "Two Star",
                          "Three Star",
                          "Four Star",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:AssessmentProgram": {
                    "additionalProperties": false,
                    "description": "Program which issues energy labels, ratings, or sustainability certifications.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "ENERGY STAR",
                          "ENERGY STAR Certified Homes",
                          "LEED",
                          "Home Energy Upgrade Certificate of Energy Efficiency Performance",
                          "Home Energy Upgrade Certificate of Energy Efficiency Improvements",
                          "Passive House",
                          "Living Building Challenge",
                          "Green Globes",
                          "Challenge Home",
                          "WaterSense",
                          "Indoor airPLUS",
                          "NGBS ICC 700",
                          "CMP Green Value Score",
                          "RESNET HERS",
                          "Home Energy Score",
                          "ASHRAE Building EQ",
                          "Commercial Building Energy Asset Score",
                          "Other",
                          "Unknown"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:AssessmentValue": {
                    "additionalProperties": false,
                    "description": "Value from certifications that produce a numeric metric, such as Energy Star Score, Home Energy Rating System (HERS) Index Score, Home Energy Score",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:decimal"
                      }
                    },
                    "type": "object"
                  },
                  "auc:AssessmentVersion": {
                    "additionalProperties": false,
                    "description": "Version of the assessment documentation, such as \"2.0\"",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:AssessmentYear": {
                    "additionalProperties": false,
                    "description": "Year the assessment qualifications for recognition were documented. (CCYY)",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:gYear"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:FacilityClassification": {
          "additionalProperties": false,
          "description": "Specify the type of facility.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Commercial",
                "Residential"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FederalBuilding": {
          "additionalProperties": false,
          "description": "If exists then the building is owned by the federal government.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:Agency": {
              "additionalProperties": false,
              "description": "Federal agency, required to designate a facility as a federal property in Portfolio Manager.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:string"
                }
              },
              "type": "object"
            },
            "auc:DepartmentRegion": {
              "additionalProperties": false,
              "description": "Federal department/region, required to designate a facility as a federal property in Portfolio Manager.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:string"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:FloorAreas": {
          "$ref": "#/definitions/.auc:FloorAreas"
        },
        "auc:FloorsAboveGrade": {
          "additionalProperties": false,
          "description": "Nominal number of floors which are fully above ground.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FloorsBelowGrade": {
          "additionalProperties": false,
          "description": "Nominal number of floors which are fully underground.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:HeightDistribution": {
          "additionalProperties": false,
          "description": "Uniformity of building height.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Multiple Heights",
                "Uniform Height"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:HorizontalSurroundings": {
          "additionalProperties": false,
          "description": "Attachments to the outermost horizontal surfaces of the premises.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "No abutments",
                "Attached from Above",
                "Attached from Below",
                "Attached from Above and Below",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:NAICSCode": {
          "additionalProperties": false,
          "description": "North American Industry Classification System code.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:NumberOfBusinesses": {
          "additionalProperties": false,
          "description": "Number of separate business tenants within the premises.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:OccupancyClassification": {
          "$ref": "#/definitions/.auc:OccupancyClassification"
        },
        "auc:OccupancyLevels": {
          "$ref": "#/definitions/.auc:OccupancyLevels"
        },
        "auc:OperatorType": {
          "additionalProperties": false,
          "description": "Entity responsible for the operation of the facility.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Owner",
                "Occupant",
                "Property management company",
                "Other corporation/partnership/LLC",
                "Religious organization",
                "Other non-profit organization",
                "Privately-owned school",
                "Individual owner",
                "Other non-government owner",
                "Government",
                "Federal government",
                "State government",
                "Local government",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Ownership": {
          "$ref": "#/definitions/.auc:Ownership"
        },
        "auc:OwnershipStatus": {
          "$ref": "#/definitions/.auc:OwnershipStatus"
        },
        "auc:PercentOccupiedByOwner": {
          "additionalProperties": false,
          "description": "The percentage of gross floor area that is occupied by the owner of the premises and affiliates. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Perimeter": {
          "additionalProperties": false,
          "description": "Length of a line forming the boundary around the premises. (ft)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PortfolioManager": {
          "additionalProperties": false,
          "description": "If exists then the data for this facility is included in Portfolio Manager.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:BuildingProfileStatus": {
              "additionalProperties": false,
              "description": "The status of the building profile submission process for Portfolio Manager.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Draft",
                    "Received",
                    "Under Review",
                    "On Hold",
                    "Reviewed and Approved",
                    "Reviewed and Not Approved"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:FederalSustainabilityChecklistCompletionPercentage": {
              "additionalProperties": false,
              "description": "Percentage of the Federal High Performance Sustainability Checklist that has been completed for federal building in Portfolio Manager. (0-100)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:PMBenchmarkDate": {
              "additionalProperties": false,
              "description": "Date that the building was benchmarked in Portfolio Manager. (CCYY-MM-DD)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:date"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:PremisesIdentifiers": {
          "anyOf": [
            {
              "$ref": "#/definitions/.auc:PremisesIdentifiers"
            },
            {
              "items": {
                "$ref": "#/definitions/.auc:PremisesIdentifiers"
              },
              "type": "array"
            }
          ]
        },
        "auc:PremisesName": {
          "$ref": "#/definitions/.auc:PremisesName"
        },
        "auc:PremisesNotes": {
          "$ref": "#/definitions/.auc:PremisesNotes"
        },
        "auc:PrimaryContactID": {
          "$ref": "#/definitions/.auc:PrimaryContactID"
        },
        "auc:PubliclySubsidized": {
          "additionalProperties": false,
          "description": "Does the facility include multi-family housing that receives or received public funding for construction or operations (this does not include Section 8 or similar vouchers received by individual tenants).",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:boolean"
            }
          },
          "type": "object"
        },
        "auc:RetrocommissioningDate": {
          "additionalProperties": false,
          "description": "Date retro-commissioning or recommissioning was last conducted. (CCYY-MM-DD)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:date"
            }
          },
          "type": "object"
        },
        "auc:SpatialUnits": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:NumberOfUnits": {
                  "additionalProperties": false,
                  "description": "Number of individual units within the premises.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:integer"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:SpatialUnitType": {
                  "additionalProperties": false,
                  "description": "Unit type within the premises.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Lots",
                        "Parking spaces",
                        "Apartment units",
                        "Businesses",
                        "Guest rooms",
                        "Stations",
                        "Buildings",
                        "Areas",
                        "Thermal Zones",
                        "Floors",
                        "Rooms",
                        "Bedrooms",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:UnitDensity": {
                  "additionalProperties": false,
                  "description": "Number of units per 1,000 square feet.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:NumberOfUnits": {
                    "additionalProperties": false,
                    "description": "Number of individual units within the premises.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:integer"
                      },
                      "@auc:Source": {
                        "enum": [
                          "Default",
                          "Estimate",
                          "Agent",
                          "Assessor",
                          "Auditor",
                          "Product specification",
                          "Building Component Library",
                          "Utility transfer",
                          "Energy Management System",
                          "Direct measurement",
                          "Design files",
                          "Simulation",
                          "ENERGY STAR Portfolio Manager",
                          "US EPA",
                          "US EIA",
                          "Target Finder",
                          "Arch2030",
                          "ASHRAE",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:SpatialUnitType": {
                    "additionalProperties": false,
                    "description": "Unit type within the premises.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Lots",
                          "Parking spaces",
                          "Apartment units",
                          "Businesses",
                          "Guest rooms",
                          "Stations",
                          "Buildings",
                          "Areas",
                          "Thermal Zones",
                          "Floors",
                          "Rooms",
                          "Bedrooms",
                          "Other",
                          "Unknown"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:UnitDensity": {
                    "additionalProperties": false,
                    "description": "Number of units per 1,000 square feet.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:decimal"
                      },
                      "@auc:Source": {
                        "enum": [
                          "Default",
                          "Estimate",
                          "Agent",
                          "Assessor",
                          "Auditor",
                          "Product specification",
                          "Building Component Library",
                          "Utility transfer",
                          "Energy Management System",
                          "Direct measurement",
                          "Design files",
                          "Simulation",
                          "ENERGY STAR Portfolio Manager",
                          "US EPA",
                          "US EIA",
                          "Target Finder",
                          "Arch2030",
                          "ASHRAE",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:Subsections": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:Subsection": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "description": "Physical subsection of building for which geometric features are defined. May be one or many.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@ID": {
                      "$ref": "#/definitions/xs:ID"
                    },
                    "auc:FloorAreas": {
                      "$ref": "#/definitions/.auc:FloorAreas"
                    },
                    "auc:FloorToCeilingHeight": {
                      "additionalProperties": false,
                      "description": "Floor to ceiling height for a premises. (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:FloorToFloorHeight": {
                      "additionalProperties": false,
                      "description": "Average height of the floors in a premises, measured from floor to floor. (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:FloorsAboveGrade": {
                      "additionalProperties": false,
                      "description": "Number of floors which are fully above ground.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:integer"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:FloorsBelowGrade": {
                      "additionalProperties": false,
                      "description": "Number of floors which are fully underground.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:integer"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:FloorsPartiallyBelowGrade": {
                      "additionalProperties": false,
                      "description": "Number of floors which are partially underground.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:integer"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:FootprintShape": {
                      "additionalProperties": false,
                      "description": "General shape of subsection or building footprint as defined in the BuildingSync Geometry Reference Sheet.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "Rectangular",
                            "L-Shape",
                            "U-Shape",
                            "H-Shape",
                            "T-Shape",
                            "O-Shape",
                            "Other",
                            "Unknown"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:FoundationID": {
                      "additionalProperties": false,
                      "description": "ID number of the foundation type associated with this subsection",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        },
                        "auc:FoundationArea": {
                          "additionalProperties": false,
                          "description": "Area of slab-on-grade, basement slab, or other floor over unconditioned space. (ft2)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:SpaceID": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "ID number of the space type associated with this side of the subsection",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "ID number of the space type associated with this side of the subsection",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              "type": "array"
                            }
                          ]
                        },
                        "auc:ThermalZoneID": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "ID number of the zone type associated with this space or side of the subsection",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "ID number of the zone type associated with this space or side of the subsection",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    "auc:OccupancyClassification": {
                      "$ref": "#/definitions/.auc:OccupancyClassification"
                    },
                    "auc:OccupancyLevels": {
                      "$ref": "#/definitions/.auc:OccupancyLevels"
                    },
                    "auc:PerimeterZoneDepth": {
                      "additionalProperties": false,
                      "description": "Depth of perimeter zone relative to the outside walls. (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:PremisesIdentifiers": {
                      "anyOf": [
                        {
                          "$ref": "#/definitions/.auc:PremisesIdentifiers"
                        },
                        {
                          "items": {
                            "$ref": "#/definitions/.auc:PremisesIdentifiers"
                          },
                          "type": "array"
                        }
                      ]
                    },
                    "auc:PremisesName": {
                      "$ref": "#/definitions/.auc:PremisesName"
                    },
                    "auc:PremisesNotes": {
                      "$ref": "#/definitions/.auc:PremisesNotes"
                    },
                    "auc:PrimaryContactID": {
                      "$ref": "#/definitions/.auc:PrimaryContactID"
                    },
                    "auc:RoofID": {
                      "additionalProperties": false,
                      "description": "ID number of the roof/ceiling type associated with this subsection",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        },
                        "auc:RoofArea": {
                          "additionalProperties": false,
                          "description": "Surface area of roof. (ft2)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:RoofInsulatedArea": {
                          "additionalProperties": false,
                          "description": "Insulated area of roof or ceiling. (ft2)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:SkylightID": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "ID number of the skylight type associated with this side of the subsection",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                },
                                "auc:PercentSkylightArea": {
                                  "additionalProperties": false,
                                  "description": "The percentage of the skylight area relative to the roof area. (0-1) (%)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "ID number of the skylight type associated with this side of the subsection",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  },
                                  "auc:PercentSkylightArea": {
                                    "additionalProperties": false,
                                    "description": "The percentage of the skylight area relative to the roof area. (0-1) (%)",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "$ref": "#/definitions/xs:decimal"
                                      },
                                      "@auc:Source": {
                                        "enum": [
                                          "Default",
                                          "Estimate",
                                          "Agent",
                                          "Assessor",
                                          "Auditor",
                                          "Product specification",
                                          "Building Component Library",
                                          "Utility transfer",
                                          "Energy Management System",
                                          "Direct measurement",
                                          "Design files",
                                          "Simulation",
                                          "ENERGY STAR Portfolio Manager",
                                          "US EPA",
                                          "US EIA",
                                          "Target Finder",
                                          "Arch2030",
                                          "ASHRAE",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              "type": "array"
                            }
                          ]
                        },
                        "auc:SpaceID": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "ID number of the space type associated with this side of the subsection",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "ID number of the space type associated with this side of the subsection",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              "type": "array"
                            }
                          ]
                        },
                        "auc:ThermalZoneID": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "ID number of the zone type associated with this space or side of the subsection",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "ID number of the zone type associated with this space or side of the subsection",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    "auc:SideA1Orientation": {
                      "additionalProperties": false,
                      "description": "The orientation of the canonical A1 side of the shape, as defined in the BuildingSync Geometry Reference Sheet. (degrees clockwise from north)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:Sides": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:Side": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:DoorID": {
                                  "additionalProperties": false,
                                  "description": "ID number of the door type associated with this side of the subsection",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    },
                                    "auc:FenestrationArea": {
                                      "additionalProperties": false,
                                      "description": "Total area of this fenestration type. (ft2)",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "$ref": "#/definitions/xs:decimal"
                                        },
                                        "@auc:Source": {
                                          "enum": [
                                            "Default",
                                            "Estimate",
                                            "Agent",
                                            "Assessor",
                                            "Auditor",
                                            "Product specification",
                                            "Building Component Library",
                                            "Utility transfer",
                                            "Energy Management System",
                                            "Direct measurement",
                                            "Design files",
                                            "Simulation",
                                            "ENERGY STAR Portfolio Manager",
                                            "US EPA",
                                            "US EIA",
                                            "Target Finder",
                                            "Arch2030",
                                            "ASHRAE",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                },
                                "auc:SideLength": {
                                  "additionalProperties": false,
                                  "description": "Length of a particular side of subsection as defined in the BuildingSync Geometry Reference Sheet. (ft)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:SideNumber": {
                                  "additionalProperties": false,
                                  "description": "Alphanumeric designation of the side of a subsection as defined in the BuildingSync Geometry Reference Sheet.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "A1",
                                        "A2",
                                        "A3",
                                        "B1",
                                        "B2",
                                        "B3",
                                        "C1",
                                        "C2",
                                        "C3",
                                        "D1",
                                        "D2",
                                        "D3",
                                        "AO1",
                                        "BO1"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:SpaceID": {
                                  "additionalProperties": false,
                                  "description": "ID number of the space type associated with this side of the subsection",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                },
                                "auc:ThermalZoneID": {
                                  "additionalProperties": false,
                                  "description": "ID number of the zone type associated with this space or side of the subsection",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                },
                                "auc:WallID": {
                                  "additionalProperties": false,
                                  "description": "ID number of the wall type associated with this side of the subsection",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    },
                                    "auc:WallArea": {
                                      "additionalProperties": false,
                                      "description": "Exposed, above-grade, opaque wall area of this type. (ft2)",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "$ref": "#/definitions/xs:decimal"
                                        },
                                        "@auc:Source": {
                                          "enum": [
                                            "Default",
                                            "Estimate",
                                            "Agent",
                                            "Assessor",
                                            "Auditor",
                                            "Product specification",
                                            "Building Component Library",
                                            "Utility transfer",
                                            "Energy Management System",
                                            "Direct measurement",
                                            "Design files",
                                            "Simulation",
                                            "ENERGY STAR Portfolio Manager",
                                            "US EPA",
                                            "US EIA",
                                            "Target Finder",
                                            "Arch2030",
                                            "ASHRAE",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                },
                                "auc:WindowID": {
                                  "additionalProperties": false,
                                  "description": "ID number of the window type associated with this side of the subsection",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    },
                                    "auc:FenestrationArea": {
                                      "additionalProperties": false,
                                      "description": "Total area of this fenestration type. (ft2)",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "$ref": "#/definitions/xs:decimal"
                                        },
                                        "@auc:Source": {
                                          "enum": [
                                            "Default",
                                            "Estimate",
                                            "Agent",
                                            "Assessor",
                                            "Auditor",
                                            "Product specification",
                                            "Building Component Library",
                                            "Utility transfer",
                                            "Energy Management System",
                                            "Direct measurement",
                                            "Design files",
                                            "Simulation",
                                            "ENERGY STAR Portfolio Manager",
                                            "US EPA",
                                            "US EIA",
                                            "Target Finder",
                                            "Arch2030",
                                            "ASHRAE",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:PercentOfWindowAreaShaded": {
                                      "additionalProperties": false,
                                      "description": "The percentage of the fenestration area that is shaded by exterior objects such as trees or other buildings. (0-1) (%)",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "$ref": "#/definitions/xs:decimal"
                                        },
                                        "@auc:Source": {
                                          "enum": [
                                            "Default",
                                            "Estimate",
                                            "Agent",
                                            "Assessor",
                                            "Auditor",
                                            "Product specification",
                                            "Building Component Library",
                                            "Utility transfer",
                                            "Energy Management System",
                                            "Direct measurement",
                                            "Design files",
                                            "Simulation",
                                            "ENERGY STAR Portfolio Manager",
                                            "US EPA",
                                            "US EIA",
                                            "Target Finder",
                                            "Arch2030",
                                            "ASHRAE",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:WindowToWallRatio": {
                                      "additionalProperties": false,
                                      "description": "Ratio of total window area to total wall area. (0-1) (%)",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "$ref": "#/definitions/xs:decimal"
                                        },
                                        "@auc:Source": {
                                          "enum": [
                                            "Default",
                                            "Estimate",
                                            "Agent",
                                            "Assessor",
                                            "Auditor",
                                            "Product specification",
                                            "Building Component Library",
                                            "Utility transfer",
                                            "Energy Management System",
                                            "Direct measurement",
                                            "Design files",
                                            "Simulation",
                                            "ENERGY STAR Portfolio Manager",
                                            "US EPA",
                                            "US EIA",
                                            "Target Finder",
                                            "Arch2030",
                                            "ASHRAE",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "auc:DoorID": {
                                    "additionalProperties": false,
                                    "description": "ID number of the door type associated with this side of the subsection",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      },
                                      "auc:FenestrationArea": {
                                        "additionalProperties": false,
                                        "description": "Total area of this fenestration type. (ft2)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  },
                                  "auc:SideLength": {
                                    "additionalProperties": false,
                                    "description": "Length of a particular side of subsection as defined in the BuildingSync Geometry Reference Sheet. (ft)",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "$ref": "#/definitions/xs:decimal"
                                      },
                                      "@auc:Source": {
                                        "enum": [
                                          "Default",
                                          "Estimate",
                                          "Agent",
                                          "Assessor",
                                          "Auditor",
                                          "Product specification",
                                          "Building Component Library",
                                          "Utility transfer",
                                          "Energy Management System",
                                          "Direct measurement",
                                          "Design files",
                                          "Simulation",
                                          "ENERGY STAR Portfolio Manager",
                                          "US EPA",
                                          "US EIA",
                                          "Target Finder",
                                          "Arch2030",
                                          "ASHRAE",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:SideNumber": {
                                    "additionalProperties": false,
                                    "description": "Alphanumeric designation of the side of a subsection as defined in the BuildingSync Geometry Reference Sheet.",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "enum": [
                                          "A1",
                                          "A2",
                                          "A3",
                                          "B1",
                                          "B2",
                                          "B3",
                                          "C1",
                                          "C2",
                                          "C3",
                                          "D1",
                                          "D2",
                                          "D3",
                                          "AO1",
                                          "BO1"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:SpaceID": {
                                    "additionalProperties": false,
                                    "description": "ID number of the space type associated with this side of the subsection",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  },
                                  "auc:ThermalZoneID": {
                                    "additionalProperties": false,
                                    "description": "ID number of the zone type associated with this space or side of the subsection",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  },
                                  "auc:WallID": {
                                    "additionalProperties": false,
                                    "description": "ID number of the wall type associated with this side of the subsection",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      },
                                      "auc:WallArea": {
                                        "additionalProperties": false,
                                        "description": "Exposed, above-grade, opaque wall area of this type. (ft2)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  },
                                  "auc:WindowID": {
                                    "additionalProperties": false,
                                    "description": "ID number of the window type associated with this side of the subsection",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      },
                                      "auc:FenestrationArea": {
                                        "additionalProperties": false,
                                        "description": "Total area of this fenestration type. (ft2)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "auc:PercentOfWindowAreaShaded": {
                                        "additionalProperties": false,
                                        "description": "The percentage of the fenestration area that is shaded by exterior objects such as trees or other buildings. (0-1) (%)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "auc:WindowToWallRatio": {
                                        "additionalProperties": false,
                                        "description": "Ratio of total window area to total wall area. (0-1) (%)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:Side"
                      ],
                      "type": "object"
                    },
                    "auc:Story": {
                      "additionalProperties": false,
                      "description": "The story of the given floor area type.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:int"
                        }
                      },
                      "type": "object"
                    },
                    "auc:ThermalZoneLayout": {
                      "additionalProperties": false,
                      "description": "Type of zoning used for space conditioning",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "Perimeter",
                            "Perimeter and core",
                            "Single zone",
                            "Other",
                            "Unknown"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:ThermalZones": {
                      "additionalProperties": false,
                      "description": "Subsections of a building that share thermal control characteristics. May be one or many.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:ThermalZone": {
                          "anyOf": [
                            {
                              "$ref": "#/definitions/auc:ThermalZoneType"
                            },
                            {
                              "items": {
                                "$ref": "#/definitions/auc:ThermalZoneType"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:ThermalZone"
                      ],
                      "type": "object"
                    },
                    "auc:UserDefinedFields": {
                      "$ref": "#/definitions/.auc:UserDefinedFields"
                    },
                    "auc:XOffset": {
                      "additionalProperties": false,
                      "description": "X offset of the origin of the subsection,defined as the counter-clockwise vertex of the A1 side on the bottom floor, relative to an arbitrary fixed origin established for the facility. (See BuildingSync Geometry Reference Sheet). (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:YOffset": {
                      "additionalProperties": false,
                      "description": "Y offset of the origin of the subsection, defined as the counter-clockwise vertex of the A1 side on the bottom floor, relative to an arbitrary fixed origin established for the facility. (See BuildingSync Geometry Reference Sheet) (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:YearOfConstruction": {
                      "additionalProperties": false,
                      "description": "Year in which construction was completed on the premise. (CCYY)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:gYear"
                        }
                      },
                      "type": "object"
                    },
                    "auc:ZOffset": {
                      "additionalProperties": false,
                      "description": "Z offset of the origin of the subsection, defined as the counter-clockwise vertex of the A1 side on the bottom floor, relative to an arbitrary fixed origin established for the facility. (See BuildingSync Geometry Reference Sheet) (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "description": "Physical subsection of building for which geometric features are defined. May be one or many.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@ID": {
                        "$ref": "#/definitions/xs:ID"
                      },
                      "auc:FloorAreas": {
                        "$ref": "#/definitions/.auc:FloorAreas"
                      },
                      "auc:FloorToCeilingHeight": {
                        "additionalProperties": false,
                        "description": "Floor to ceiling height for a premises. (ft)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:FloorToFloorHeight": {
                        "additionalProperties": false,
                        "description": "Average height of the floors in a premises, measured from floor to floor. (ft)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:FloorsAboveGrade": {
                        "additionalProperties": false,
                        "description": "Number of floors which are fully above ground.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:integer"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:FloorsBelowGrade": {
                        "additionalProperties": false,
                        "description": "Number of floors which are fully underground.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:integer"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:FloorsPartiallyBelowGrade": {
                        "additionalProperties": false,
                        "description": "Number of floors which are partially underground.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:integer"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:FootprintShape": {
                        "additionalProperties": false,
                        "description": "General shape of subsection or building footprint as defined in the BuildingSync Geometry Reference Sheet.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "enum": [
                              "Rectangular",
                              "L-Shape",
                              "U-Shape",
                              "H-Shape",
                              "T-Shape",
                              "O-Shape",
                              "Other",
                              "Unknown"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:FoundationID": {
                        "additionalProperties": false,
                        "description": "ID number of the foundation type associated with this subsection",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          },
                          "auc:FoundationArea": {
                            "additionalProperties": false,
                            "description": "Area of slab-on-grade, basement slab, or other floor over unconditioned space. (ft2)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:SpaceID": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "ID number of the space type associated with this side of the subsection",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "ID number of the space type associated with this side of the subsection",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                },
                                "type": "array"
                              }
                            ]
                          },
                          "auc:ThermalZoneID": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "ID number of the zone type associated with this space or side of the subsection",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "ID number of the zone type associated with this space or side of the subsection",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                },
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "auc:OccupancyClassification": {
                        "$ref": "#/definitions/.auc:OccupancyClassification"
                      },
                      "auc:OccupancyLevels": {
                        "$ref": "#/definitions/.auc:OccupancyLevels"
                      },
                      "auc:PerimeterZoneDepth": {
                        "additionalProperties": false,
                        "description": "Depth of perimeter zone relative to the outside walls. (ft)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:PremisesIdentifiers": {
                        "anyOf": [
                          {
                            "$ref": "#/definitions/.auc:PremisesIdentifiers"
                          },
                          {
                            "items": {
                              "$ref": "#/definitions/.auc:PremisesIdentifiers"
                            },
                            "type": "array"
                          }
                        ]
                      },
                      "auc:PremisesName": {
                        "$ref": "#/definitions/.auc:PremisesName"
                      },
                      "auc:PremisesNotes": {
                        "$ref": "#/definitions/.auc:PremisesNotes"
                      },
                      "auc:PrimaryContactID": {
                        "$ref": "#/definitions/.auc:PrimaryContactID"
                      },
                      "auc:RoofID": {
                        "additionalProperties": false,
                        "description": "ID number of the roof/ceiling type associated with this subsection",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          },
                          "auc:RoofArea": {
                            "additionalProperties": false,
                            "description": "Surface area of roof. (ft2)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:RoofInsulatedArea": {
                            "additionalProperties": false,
                            "description": "Insulated area of roof or ceiling. (ft2)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:SkylightID": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "ID number of the skylight type associated with this side of the subsection",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  },
                                  "auc:PercentSkylightArea": {
                                    "additionalProperties": false,
                                    "description": "The percentage of the skylight area relative to the roof area. (0-1) (%)",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "$ref": "#/definitions/xs:decimal"
                                      },
                                      "@auc:Source": {
                                        "enum": [
                                          "Default",
                                          "Estimate",
                                          "Agent",
                                          "Assessor",
                                          "Auditor",
                                          "Product specification",
                                          "Building Component Library",
                                          "Utility transfer",
                                          "Energy Management System",
                                          "Direct measurement",
                                          "Design files",
                                          "Simulation",
                                          "ENERGY STAR Portfolio Manager",
                                          "US EPA",
                                          "US EIA",
                                          "Target Finder",
                                          "Arch2030",
                                          "ASHRAE",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "ID number of the skylight type associated with this side of the subsection",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    },
                                    "auc:PercentSkylightArea": {
                                      "additionalProperties": false,
                                      "description": "The percentage of the skylight area relative to the roof area. (0-1) (%)",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "$ref": "#/definitions/xs:decimal"
                                        },
                                        "@auc:Source": {
                                          "enum": [
                                            "Default",
                                            "Estimate",
                                            "Agent",
                                            "Assessor",
                                            "Auditor",
                                            "Product specification",
                                            "Building Component Library",
                                            "Utility transfer",
                                            "Energy Management System",
                                            "Direct measurement",
                                            "Design files",
                                            "Simulation",
                                            "ENERGY STAR Portfolio Manager",
                                            "US EPA",
                                            "US EIA",
                                            "Target Finder",
                                            "Arch2030",
                                            "ASHRAE",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                },
                                "type": "array"
                              }
                            ]
                          },
                          "auc:SpaceID": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "ID number of the space type associated with this side of the subsection",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "ID number of the space type associated with this side of the subsection",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                },
                                "type": "array"
                              }
                            ]
                          },
                          "auc:ThermalZoneID": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "ID number of the zone type associated with this space or side of the subsection",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "ID number of the zone type associated with this space or side of the subsection",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                },
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "auc:SideA1Orientation": {
                        "additionalProperties": false,
                        "description": "The orientation of the canonical A1 side of the shape, as defined in the BuildingSync Geometry Reference Sheet. (degrees clockwise from north)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:Sides": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:Side": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "auc:DoorID": {
                                    "additionalProperties": false,
                                    "description": "ID number of the door type associated with this side of the subsection",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      },
                                      "auc:FenestrationArea": {
                                        "additionalProperties": false,
                                        "description": "Total area of this fenestration type. (ft2)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  },
                                  "auc:SideLength": {
                                    "additionalProperties": false,
                                    "description": "Length of a particular side of subsection as defined in the BuildingSync Geometry Reference Sheet. (ft)",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "$ref": "#/definitions/xs:decimal"
                                      },
                                      "@auc:Source": {
                                        "enum": [
                                          "Default",
                                          "Estimate",
                                          "Agent",
                                          "Assessor",
                                          "Auditor",
                                          "Product specification",
                                          "Building Component Library",
                                          "Utility transfer",
                                          "Energy Management System",
                                          "Direct measurement",
                                          "Design files",
                                          "Simulation",
                                          "ENERGY STAR Portfolio Manager",
                                          "US EPA",
                                          "US EIA",
                                          "Target Finder",
                                          "Arch2030",
                                          "ASHRAE",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:SideNumber": {
                                    "additionalProperties": false,
                                    "description": "Alphanumeric designation of the side of a subsection as defined in the BuildingSync Geometry Reference Sheet.",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "enum": [
                                          "A1",
                                          "A2",
                                          "A3",
                                          "B1",
                                          "B2",
                                          "B3",
                                          "C1",
                                          "C2",
                                          "C3",
                                          "D1",
                                          "D2",
                                          "D3",
                                          "AO1",
                                          "BO1"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:SpaceID": {
                                    "additionalProperties": false,
                                    "description": "ID number of the space type associated with this side of the subsection",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  },
                                  "auc:ThermalZoneID": {
                                    "additionalProperties": false,
                                    "description": "ID number of the zone type associated with this space or side of the subsection",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  },
                                  "auc:WallID": {
                                    "additionalProperties": false,
                                    "description": "ID number of the wall type associated with this side of the subsection",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      },
                                      "auc:WallArea": {
                                        "additionalProperties": false,
                                        "description": "Exposed, above-grade, opaque wall area of this type. (ft2)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  },
                                  "auc:WindowID": {
                                    "additionalProperties": false,
                                    "description": "ID number of the window type associated with this side of the subsection",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      },
                                      "auc:FenestrationArea": {
                                        "additionalProperties": false,
                                        "description": "Total area of this fenestration type. (ft2)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "auc:PercentOfWindowAreaShaded": {
                                        "additionalProperties": false,
                                        "description": "The percentage of the fenestration area that is shaded by exterior objects such as trees or other buildings. (0-1) (%)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "auc:WindowToWallRatio": {
                                        "additionalProperties": false,
                                        "description": "Ratio of total window area to total wall area. (0-1) (%)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "auc:DoorID": {
                                      "additionalProperties": false,
                                      "description": "ID number of the door type associated with this side of the subsection",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "@IDref": {
                                          "$ref": "#/definitions/xs:IDREF"
                                        },
                                        "auc:FenestrationArea": {
                                          "additionalProperties": false,
                                          "description": "Total area of this fenestration type. (ft2)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        }
                                      },
                                      "required": [
                                        "@IDref"
                                      ],
                                      "type": "object"
                                    },
                                    "auc:SideLength": {
                                      "additionalProperties": false,
                                      "description": "Length of a particular side of subsection as defined in the BuildingSync Geometry Reference Sheet. (ft)",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "$ref": "#/definitions/xs:decimal"
                                        },
                                        "@auc:Source": {
                                          "enum": [
                                            "Default",
                                            "Estimate",
                                            "Agent",
                                            "Assessor",
                                            "Auditor",
                                            "Product specification",
                                            "Building Component Library",
                                            "Utility transfer",
                                            "Energy Management System",
                                            "Direct measurement",
                                            "Design files",
                                            "Simulation",
                                            "ENERGY STAR Portfolio Manager",
                                            "US EPA",
                                            "US EIA",
                                            "Target Finder",
                                            "Arch2030",
                                            "ASHRAE",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:SideNumber": {
                                      "additionalProperties": false,
                                      "description": "Alphanumeric designation of the side of a subsection as defined in the BuildingSync Geometry Reference Sheet.",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "A1",
                                            "A2",
                                            "A3",
                                            "B1",
                                            "B2",
                                            "B3",
                                            "C1",
                                            "C2",
                                            "C3",
                                            "D1",
                                            "D2",
                                            "D3",
                                            "AO1",
                                            "BO1"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:SpaceID": {
                                      "additionalProperties": false,
                                      "description": "ID number of the space type associated with this side of the subsection",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "@IDref": {
                                          "$ref": "#/definitions/xs:IDREF"
                                        }
                                      },
                                      "required": [
                                        "@IDref"
                                      ],
                                      "type": "object"
                                    },
                                    "auc:ThermalZoneID": {
                                      "additionalProperties": false,
                                      "description": "ID number of the zone type associated with this space or side of the subsection",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "@IDref": {
                                          "$ref": "#/definitions/xs:IDREF"
                                        }
                                      },
                                      "required": [
                                        "@IDref"
                                      ],
                                      "type": "object"
                                    },
                                    "auc:WallID": {
                                      "additionalProperties": false,
                                      "description": "ID number of the wall type associated with this side of the subsection",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "@IDref": {
                                          "$ref": "#/definitions/xs:IDREF"
                                        },
                                        "auc:WallArea": {
                                          "additionalProperties": false,
                                          "description": "Exposed, above-grade, opaque wall area of this type. (ft2)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        }
                                      },
                                      "required": [
                                        "@IDref"
                                      ],
                                      "type": "object"
                                    },
                                    "auc:WindowID": {
                                      "additionalProperties": false,
                                      "description": "ID number of the window type associated with this side of the subsection",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "@IDref": {
                                          "$ref": "#/definitions/xs:IDREF"
                                        },
                                        "auc:FenestrationArea": {
                                          "additionalProperties": false,
                                          "description": "Total area of this fenestration type. (ft2)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:PercentOfWindowAreaShaded": {
                                          "additionalProperties": false,
                                          "description": "The percentage of the fenestration area that is shaded by exterior objects such as trees or other buildings. (0-1) (%)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:WindowToWallRatio": {
                                          "additionalProperties": false,
                                          "description": "Ratio of total window area to total wall area. (0-1) (%)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        }
                                      },
                                      "required": [
                                        "@IDref"
                                      ],
                                      "type": "object"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:Side"
                        ],
                        "type": "object"
                      },
                      "auc:Story": {
                        "additionalProperties": false,
                        "description": "The story of the given floor area type.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:int"
                          }
                        },
                        "type": "object"
                      },
                      "auc:ThermalZoneLayout": {
                        "additionalProperties": false,
                        "description": "Type of zoning used for space conditioning",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "enum": [
                              "Perimeter",
                              "Perimeter and core",
                              "Single zone",
                              "Other",
                              "Unknown"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:ThermalZones": {
                        "additionalProperties": false,
                        "description": "Subsections of a building that share thermal control characteristics. May be one or many.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:ThermalZone": {
                            "anyOf": [
                              {
                                "$ref": "#/definitions/auc:ThermalZoneType"
                              },
                              {
                                "items": {
                                  "$ref": "#/definitions/auc:ThermalZoneType"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:ThermalZone"
                        ],
                        "type": "object"
                      },
                      "auc:UserDefinedFields": {
                        "$ref": "#/definitions/.auc:UserDefinedFields"
                      },
                      "auc:XOffset": {
                        "additionalProperties": false,
                        "description": "X offset of the origin of the subsection,defined as the counter-clockwise vertex of the A1 side on the bottom floor, relative to an arbitrary fixed origin established for the facility. (See BuildingSync Geometry Reference Sheet). (ft)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:YOffset": {
                        "additionalProperties": false,
                        "description": "Y offset of the origin of the subsection, defined as the counter-clockwise vertex of the A1 side on the bottom floor, relative to an arbitrary fixed origin established for the facility. (See BuildingSync Geometry Reference Sheet) (ft)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:YearOfConstruction": {
                        "additionalProperties": false,
                        "description": "Year in which construction was completed on the premise. (CCYY)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:gYear"
                          }
                        },
                        "type": "object"
                      },
                      "auc:ZOffset": {
                        "additionalProperties": false,
                        "description": "Z offset of the origin of the subsection, defined as the counter-clockwise vertex of the A1 side on the bottom floor, relative to an arbitrary fixed origin established for the facility. (See BuildingSync Geometry Reference Sheet) (ft)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      }
                    },
                    "type": "object"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:Subsection"
          ],
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:VerticalSurroundings": {
          "additionalProperties": false,
          "description": "Attachments to the outermost vertical surfaces of the premises. This can be used if the more detailed input for Surface Exposure is not known. Illustrations for the constrained list choices will be provided when the web site is developed.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Stand-alone",
                "Attached on one side",
                "Attached on two sides",
                "Attached on three sides",
                "Within a building",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:YearOccupied": {
          "additionalProperties": false,
          "description": "Year in which the premises was first occupied. (CCYY)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:gYear"
            }
          },
          "type": "object"
        },
        "auc:YearOfConstruction": {
          "additionalProperties": false,
          "description": "Year in which construction was completed on the premise. (CCYY)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:gYear"
            }
          },
          "type": "object"
        },
        "auc:YearOfLastEnergyAudit": {
          "additionalProperties": false,
          "description": "Year of the most recent energy audit for this building. (CCYY)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:gYear"
            }
          },
          "type": "object"
        },
        "auc:YearOfLastMajorRemodel": {
          "additionalProperties": false,
          "description": "Year of the most recent major remodel. For a remodel to be considered major, the work undertaken must have required a permit from the building department, or an inspection by a governing authority. (CCYY)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:gYear"
            }
          },
          "type": "object"
        },
        "auc:YearOfLatestRetrofit": {
          "additionalProperties": false,
          "description": "Year an energy retrofit of the facility or subsection was last completed. (CCYY)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:gYear"
            }
          },
          "type": "object"
        }
      },
      "required": [
        "auc:FacilityClassification"
      ],
      "type": "object"
    },
    "auc:CondenserPlantType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "auc:AirCooled": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:CondenserFanSpeedOperation": {
              "additionalProperties": false,
              "description": "The condenser fan control option used by the unit. If the unit has several constant-speed condenser fans that stage on in conjunction with multiple compressors, this should be set to \"Stepped Speed.\"",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Variable Volume",
                    "Stepped Speed",
                    "Constant Volume",
                    "Other",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:CondensingTemperature": {
              "additionalProperties": false,
              "description": "The saturation temperature, in degrees, corresponding to the measured refrigerant pressure at the condenser inlet. (°F)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:DesignAmbientTemperature": {
              "additionalProperties": false,
              "description": "The ambient air temperature under design conditions. (°F)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:DesignTemperatureDifference": {
              "additionalProperties": false,
              "description": "The difference between the condensing temperature of the refrigerant in the condenser and the design ambient temperature. (°F)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:EvaporativelyCooledCondenser": {
              "additionalProperties": false,
              "description": "If exists then the unit uses evaporative cooling to enhance heat rejection from the condenser coils.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "@ID": {
                  "$ref": "#/definitions/xs:ID"
                },
                "auc:EvaporativelyCooledCondenserMaximumTemperature": {
                  "additionalProperties": false,
                  "description": "The threshold outside air dry-bulb temperature below which evaporative condenser operates. (°F)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:EvaporativelyCooledCondenserMinimumTemperature": {
                  "additionalProperties": false,
                  "description": "The threshold outside air dry-bulb temperature above which evaporative condenser operates. (°F)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:SplitCondenser": {
              "additionalProperties": false,
              "description": "True if a valve is used to split the condenser loop to better control head pressure.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:boolean"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:GlycolCooledDryCooler": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:CondensingTemperature": {
              "additionalProperties": false,
              "description": "The saturation temperature, in degrees, corresponding to the measured refrigerant pressure at the condenser inlet. (°F)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:GroundSource": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:CondenserWaterTemperature": {
              "additionalProperties": false,
              "description": "The temperature of water supplied to a water-cooled condenser under normal operating conditions. (°F)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:CondensingTemperature": {
              "additionalProperties": false,
              "description": "The saturation temperature, in degrees, corresponding to the measured refrigerant pressure at the condenser inlet. (°F)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:GroundSourceType": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Open loop ground water",
                    "Closed loop ground source",
                    "Other",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:WaterCooledCondenserFlowControl": {
              "$ref": "#/definitions/.auc:WaterCooledCondenserFlowControl"
            },
            "auc:WaterSideEconomizer": {
              "additionalProperties": false,
              "description": "If exists then the cooling system has a water-side economizer to provide free cooling.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "@ID": {
                  "$ref": "#/definitions/xs:ID"
                },
                "auc:WaterSideEconomizerDBTemperatureMaximum": {
                  "additionalProperties": false,
                  "description": "The control temperature (outside air dry-bulb temperature) above which the water-side economizer is disabled. (°F)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WaterSideEconomizerTemperatureMaximum": {
                  "additionalProperties": false,
                  "description": "The control temperature of the condenser water supply temperature above which the water-side economizer is disabled. (°F)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WaterSideEconomizerTemperatureSetpoint": {
                  "additionalProperties": false,
                  "description": "The water temperature that the equipment supplies, such as the chilled water temperature setpoint for a chiller, or hot water temperature setpoint for water leaving a boiler. (°F)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WaterSideEconomizerType": {
                  "$ref": "#/definitions/.auc:WaterSideEconomizerType"
                }
              },
              "type": "object"
            },
            "auc:WellCount": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:integer"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:Other": {
          "$ref": "#/definitions/xs:anyType"
        },
        "auc:Unknown": {
          "$ref": "#/definitions/xs:anyType"
        },
        "auc:WaterCooled": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:CellCount": {
              "additionalProperties": false,
              "description": "The number of cells in the cooling tower. Each cell has its own fan, water flow allowing for responding to lower load conditions.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:integer"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:CondenserWaterTemperature": {
              "additionalProperties": false,
              "description": "The temperature of water supplied to a water-cooled condenser under normal operating conditions. (°F)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:CondensingTemperature": {
              "additionalProperties": false,
              "description": "The saturation temperature, in degrees, corresponding to the measured refrigerant pressure at the condenser inlet. (°F)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:CoolingTowerControlType": {
              "additionalProperties": false,
              "description": "Type of system operation control.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Wet Bulb Reset",
                    "Max Cells",
                    "Min Cells",
                    "Other",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:WaterCooledCondenserFlowControl": {
              "$ref": "#/definitions/.auc:WaterCooledCondenserFlowControl"
            },
            "auc:WaterCooledCondenserType": {
              "additionalProperties": false,
              "description": "Type of system operation control.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Cooling tower",
                    "Other",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:WaterSideEconomizer": {
              "additionalProperties": false,
              "description": "If exists then the cooling system has a water-side economizer to provide free cooling.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "@ID": {
                  "$ref": "#/definitions/xs:ID"
                },
                "auc:WaterSideEconomizerDBTemperatureMaximum": {
                  "additionalProperties": false,
                  "description": "The control temperature (outside air dry-bulb temperature) above which the water-side economizer is disabled. (°F)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WaterSideEconomizerTemperatureMaximum": {
                  "additionalProperties": false,
                  "description": "The control temperature of the condenser water supply temperature above which the water-side economizer is disabled. (°F)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WaterSideEconomizerType": {
                  "$ref": "#/definitions/.auc:WaterSideEconomizerType"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        }
      },
      "type": "object"
    },
    "auc:ContactType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "auc:Address": {
          "$ref": "#/definitions/.auc:Address"
        },
        "auc:ContactCompany": {
          "additionalProperties": false,
          "description": "Company name associated with the contact, if applicable.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:ContactEmailAddresses": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ContactEmailAddress": {
                  "$ref": "#/definitions/.auc:ContactEmailAddress"
                },
                "auc:ContactEmailAddressLabel": {
                  "additionalProperties": false,
                  "description": "The type of email address, to distinguish between multiple instances of Contact Email Address.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Personal",
                        "Work",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:ContactEmailAddress": {
                    "$ref": "#/definitions/.auc:ContactEmailAddress"
                  },
                  "auc:ContactEmailAddressLabel": {
                    "additionalProperties": false,
                    "description": "The type of email address, to distinguish between multiple instances of Contact Email Address.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Personal",
                          "Work",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:ContactName": {
          "additionalProperties": false,
          "description": "The name, first and last, associated with the contact",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:ContactRole": {
          "additionalProperties": false,
          "description": "Characterization of the contact.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Premises",
                "Occupant",
                "Agency",
                "Owner",
                "Customer",
                "Customer agreement",
                "Administrator",
                "Qualified Assessor",
                "Contributor",
                "Property Management Company",
                "Operator",
                "Energy Auditor",
                "Energy Modeler",
                "Contractor",
                "Implementer",
                "Financier",
                "Commissioning Agent",
                "MV Agent",
                "Evaluator",
                "Builder",
                "Service",
                "Billing",
                "Architect",
                "Mechanical Engineer",
                "Energy Consultant",
                "Service and Product Provider",
                "Authority Having Jurisdiction",
                "Utility",
                "Power plant",
                "Electric Distribution Utility (EDU)",
                "ESCO",
                "Facilitator",
                "Finance Specialist",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ContactTelephoneNumbers": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ContactTelephoneNumber": {
                  "additionalProperties": false,
                  "description": "Telephone number associated with the contact. Format: NNN-NNN-NNNN",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:ContactTelephoneNumberLabel": {
                  "additionalProperties": false,
                  "description": "The type of telephone number, to distinguish between multiple instances of Telephone Number.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Days",
                        "Evenings",
                        "Cell",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:ContactTelephoneNumber": {
                    "additionalProperties": false,
                    "description": "Telephone number associated with the contact. Format: NNN-NNN-NNNN",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:ContactTelephoneNumberLabel": {
                    "additionalProperties": false,
                    "description": "The type of telephone number, to distinguish between multiple instances of Telephone Number.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Days",
                          "Evenings",
                          "Cell",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        }
      },
      "type": "object"
    },
    "auc:ConveyanceSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:ConveyanceLoadType": {
          "additionalProperties": false,
          "description": "Type of load that the conveyance system usually transports.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "People",
                "Freight",
                "Goods",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ConveyancePeakPower": {
          "additionalProperties": false,
          "description": "The equipment's rated, maximum-power-point power at standard testing conditions (STC). (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ConveyanceStandbyPower": {
          "additionalProperties": false,
          "description": "Electric power consumed by while the equipment is switched off or in a standby mode. (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ConveyanceSystemType": {
          "additionalProperties": false,
          "description": "Type of vertical or horizontal transportation equipment that moves people or goods between levels, floors, or sections.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Escalator",
                "Elevator",
                "Conveyor Belt",
                "Overhead Conveyor",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:CookingSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:Capacity": {
          "$ref": "#/definitions/.auc:Capacity"
        },
        "auc:CapacityUnits": {
          "$ref": "#/definitions/.auc:CapacityUnits"
        },
        "auc:CookingEnergyPerMeal": {
          "additionalProperties": false,
          "description": "Energy use per meal for this equipment. (Btu)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DailyWaterUse": {
          "additionalProperties": false,
          "description": "Total volume of water (hot and cold) used per day for this equipment. (gal/day)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:NumberOfMeals": {
          "additionalProperties": false,
          "description": "Number of meals cooked per year using this equipment.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:TypeOfCookingEquipment": {
          "additionalProperties": false,
          "description": "Short description of the type and purpose of cooking equipment",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Hot top range",
                "Open burner range",
                "Wok range",
                "Braising pan",
                "Underfired broiler",
                "Overfired broiler",
                "Conveyor broiler",
                "Salamander broiler",
                "Broiler",
                "Microwave oven",
                "Toaster",
                "Standard fryer",
                "Large vat fryer",
                "Split vat fryer",
                "Convection oven",
                "Combination oven",
                "Standard oven",
                "Conveyor oven",
                "Slow cook-and-hold oven",
                "Deck oven",
                "Mini-Rack oven",
                "Rack (Roll-In) oven",
                "Range oven",
                "Rapid cook oven",
                "Rotisserie oven",
                "Retherm oven",
                "Convection toaster oven",
                "Steam cooker",
                "Steam kettle",
                "Drawer warmer",
                "Heated transparent merchandising cabinets",
                "Cook-and-hold appliance",
                "Proofing cabinet",
                "Single-sided griddle",
                "Double-sided griddle",
                "Griddle",
                "Fry-top griddle",
                "Automatic drip filter coffee maker",
                "Single-serve coffee maker",
                "Espresso machine",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:CoolingPlantTypeType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:Chiller": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:AbsorptionHeatSource": {
              "additionalProperties": false,
              "description": "Source of heating energy for regeneration",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Steam",
                    "Solar energy",
                    "Combustion",
                    "Waste heat",
                    "Other",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:AbsorptionStages": {
              "additionalProperties": false,
              "description": "Number of stages in regeneration process",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Single effect",
                    "Double effect",
                    "Other",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:ActiveDehumidification": {
              "additionalProperties": false,
              "description": "True if an active dehumidification system is available (in addition to the dehumidification that takes place during normal DX cooling operation).",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:boolean"
                }
              },
              "type": "object"
            },
            "auc:AnnualCoolingEfficiencyUnits": {
              "$ref": "#/definitions/.auc:AnnualCoolingEfficiencyUnits"
            },
            "auc:AnnualCoolingEfficiencyValue": {
              "additionalProperties": false,
              "description": "Overall annual efficiency of a cooling system.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:Capacity": {
              "$ref": "#/definitions/.auc:Capacity"
            },
            "auc:CapacityUnits": {
              "$ref": "#/definitions/.auc:CapacityUnits"
            },
            "auc:ChilledWaterResetControl": {
              "additionalProperties": false,
              "description": "Times when the HVAC equipment is setback. For example, when the heat is lowered during the heating season, or the cooling setpoint increased during the cooling season.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "During the day",
                    "At night",
                    "During sleeping and unoccupied hours",
                    "Seasonal",
                    "Never-rarely",
                    "Other",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:ChilledWaterSupplyTemperature": {
              "additionalProperties": false,
              "description": "The water temperature that the equipment supplies , such as the chilled water temperature setpoint for a chiller, or hot water temperature setpoint for water leaving a boiler. (°F)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:ChillerCompressorDriver": {
              "additionalProperties": false,
              "description": "Vehicle for driving the compressor used in a chiller",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Electric Motor",
                    "Steam",
                    "Gas Turbine",
                    "Other",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:ChillerCompressorType": {
              "additionalProperties": false,
              "description": "Type of compressor in the chiller.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Reciprocating",
                    "Screw",
                    "Scroll",
                    "Centrifugal",
                    "Other",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:ChillerType": {
              "additionalProperties": false,
              "description": "Vehicle for driving the compressor used in a chiller",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Vapor compression",
                    "Absorption",
                    "Other",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:CompressorStaging": {
              "$ref": "#/definitions/.auc:CompressorStaging"
            },
            "auc:CondenserPlantID": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "description": "ID numbers of the central condenser plants serving as the source for this cooling system.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@IDref": {
                      "$ref": "#/definitions/xs:IDREF"
                    }
                  },
                  "required": [
                    "@IDref"
                  ],
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "description": "ID numbers of the central condenser plants serving as the source for this cooling system.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@IDref": {
                        "$ref": "#/definitions/xs:IDREF"
                      }
                    },
                    "required": [
                      "@IDref"
                    ],
                    "type": "object"
                  },
                  "type": "array"
                }
              ]
            },
            "auc:CoolingStageCapacity": {
              "additionalProperties": false,
              "description": "Average capacity of each cooling stage, at ARI rated conditions, expressed as a fraction of total capacity. (0-1) (%)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:MinimumPartLoadRatio": {
              "additionalProperties": false,
              "description": "The minimum part load ratio at which the system is able to operate. (0-1) (%)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:NumberOfDiscreteCoolingStages": {
              "additionalProperties": false,
              "description": "The number of discrete operating stages, excluding \"off.\"",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:integer"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:PartLoadRatioBelowWhichHotGasBypassOperates": {
              "additionalProperties": false,
              "description": "The part load ratio of the chiller below which hot gas bypass (HGBP) operates. (0-1) (%)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:Quantity": {
              "$ref": "#/definitions/.auc:Quantity"
            },
            "auc:RatedCoolingSensibleHeatRatio": {
              "additionalProperties": false,
              "description": "The fraction of total energy transfer between the evaporator coil and air that is associated with sensible capacity (change in air temperature) expressed as a dimensionless value, and at the rated conditions prescribed for this system. (0-1) (%)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:Refrigerant": {
              "$ref": "#/definitions/.auc:Refrigerant"
            },
            "auc:RefrigerantChargeFactor": {
              "additionalProperties": false,
              "description": "Used to adjust cooling efficiency for assumed slightly degraded performance if refrigerant charge is not verified through acceptance test procedures. (0-1) (%)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:ThirdPartyCertification": {
              "$ref": "#/definitions/.auc:ThirdPartyCertification"
            }
          },
          "type": "object"
        },
        "auc:DistrictChilledWater": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:ActiveDehumidification": {
              "additionalProperties": false,
              "description": "True if an active dehumidification system is available (in addition to the dehumidification that takes place during normal DX cooling operation).",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:boolean"
                }
              },
              "type": "object"
            },
            "auc:AnnualCoolingEfficiencyUnits": {
              "$ref": "#/definitions/.auc:AnnualCoolingEfficiencyUnits"
            },
            "auc:AnnualCoolingEfficiencyValue": {
              "additionalProperties": false,
              "description": "Overall annual efficiency of a cooling system.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:Capacity": {
              "$ref": "#/definitions/.auc:Capacity"
            },
            "auc:CapacityUnits": {
              "$ref": "#/definitions/.auc:CapacityUnits"
            },
            "auc:ChilledWaterSupplyTemperature": {
              "additionalProperties": false,
              "description": "The water temperature that the equipment supplies , such as the chilled water temperature setpoint for a chiller, or hot water temperature setpoint for water leaving a boiler. (°F)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:RatedCoolingSensibleHeatRatio": {
              "additionalProperties": false,
              "description": "The fraction of total energy transfer between the evaporator coil and air that is associated with sensible capacity (change in air temperature) expressed as a dimensionless value, and at the rated conditions prescribed for this system. (0-1) (%)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:NoCooling": {
          "$ref": "#/definitions/xs:anyType"
        },
        "auc:OtherCombination": {
          "$ref": "#/definitions/xs:anyType"
        },
        "auc:Unknown": {
          "$ref": "#/definitions/xs:anyType"
        }
      },
      "type": "object"
    },
    "auc:CriticalITSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:ITNominalPower": {
          "additionalProperties": false,
          "description": "Average electrical load for critical IT system category. (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ITPeakPower": {
          "additionalProperties": false,
          "description": "The equipment's rated, maximum-power-point power at standard testing conditions (STC). (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ITStandbyPower": {
          "additionalProperties": false,
          "description": "Electric power consumed by while the equipment is switched off or in a standby mode. (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ITSystemType": {
          "additionalProperties": false,
          "description": "Type of critical information technology (IT) system, including data centers, network, and security systems.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Server",
                "Networking",
                "Security",
                "Telephoning",
                "UPS",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:DishwasherSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:DishwasherClassification": {
          "additionalProperties": false,
          "description": "The sector where dishwasher equipment is commonly used.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Industrial",
                "Commercial",
                "Residential",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DishwasherConfiguration": {
          "additionalProperties": false,
          "description": "A machine designed to clean and sanitize plates, pots, pans, glasses, cups, bowls, utensils, and trays by applying sprays of detergent solution (with or without blasting media granules) and a sanitizing rinse.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Counter-top",
                "Stationary Under Counter",
                "Stationary Single Tank Door Type",
                "Stationary Pot-pan-utensil",
                "Stationary glasswashing",
                "Single Tank Conveyor",
                "Multiple Tank Conveyor",
                "Single Tank Flight Conveyor",
                "Multiple Tank Flight Conveyor",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DishwasherEnergyFactor": {
          "additionalProperties": false,
          "description": "Energy Factor (EF) was the ENERGY STAR dishwasher energy performance metric prior to 2009. EF is expressed in cycles per kWh. A higher EF value means a dishwasher is more efficient. EF is the reciprocal of the sum of the machine electrical energy per cycle, M, plus the water heating energy consumption per cycle, W: EF = 1/(M + W). This equation may vary based on dishwasher features such as water heating boosters or truncated cycles. The federal EnergyGuide label on dishwashers shows the annual energy consumption and cost, which use the energy factor, average cycles per year, and the average cost of energy. The EF does not appear on the EnergyGuide label. Unlike annual energy use, the EF does not take into account the estimated annual energy use in standby mode. (cycles/kWh)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DishwasherHotWaterUse": {
          "additionalProperties": false,
          "description": "The estimated per cycle water of a dishwasher under typical conditions, expressed as the number of gallons of water delivered to the machine during one cycle. Measured by DOE test procedure. Water use depends on settings chosen. (gal/cycle)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DishwasherLoadsPerWeek": {
          "additionalProperties": false,
          "description": "Average number of loads of dishes washed per week.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DishwasherMachineType": {
          "additionalProperties": false,
          "description": "They type of dishwasher machine such as being either stationary rack or conveyor.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Stationary Rack Machine",
                "Conveyor Machine",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:DomesticHotWaterSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:Capacity": {
          "$ref": "#/definitions/.auc:Capacity"
        },
        "auc:CapacityUnits": {
          "$ref": "#/definitions/.auc:CapacityUnits"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:DailyHotWaterDraw": {
          "additionalProperties": false,
          "description": "Average daily volume of hot water provided by this system. (gal)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DomesticHotWaterType": {
          "additionalProperties": false,
          "description": "Type of water heating equipment for hot running water.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:HeatExchanger": {
              "$ref": "#/definitions/xs:anyType"
            },
            "auc:Instantaneous": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:InstantaneousWaterHeatingSource": {
                  "additionalProperties": false,
                  "description": "Source of heat for instantaneous water heater.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:Combustion": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:DraftType": {
                          "$ref": "#/definitions/.auc:DraftType"
                        }
                      },
                      "type": "object"
                    },
                    "auc:ElectricResistance": {
                      "$ref": "#/definitions/xs:anyType"
                    },
                    "auc:Other": {
                      "$ref": "#/definitions/xs:anyType"
                    },
                    "auc:Unknown": {
                      "$ref": "#/definitions/xs:anyType"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Other": {
              "$ref": "#/definitions/xs:anyType"
            },
            "auc:StorageTank": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:OffCycleHeatLossCoefficient": {
                  "additionalProperties": false,
                  "description": "The heat loss coefficient to ambient conditions. (UA) (Btu/hr/ft2/°F)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:RecoveryEfficiency": {
                  "additionalProperties": false,
                  "description": "The ratio of energy delivered to heat cold water compared to the energy consumed by the water heater, as determined following standardized DOE testing procedure. (0-1) (%)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:StorageTankInsulationRValue": {
                  "additionalProperties": false,
                  "description": "(Also known as thermal resistance), quantity determined by the temperature difference, at steady state, between two defined surfaces of a material or construction that induces a unit heat flow rate through unit area (R = ΔT/q). R-value is the reciprocal of thermal conductance. (hr-ft2-F/Btu)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:StorageTankInsulationThickness": {
                  "additionalProperties": false,
                  "description": "Insulation thickness of hot water storage tank. (in.)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:TankHeatingType": {
                  "additionalProperties": false,
                  "description": "Direct or indirect heating of hot water tank.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:Direct": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:DirectTankHeatingSource": {
                          "additionalProperties": false,
                          "description": "Direct source of heat for hot water tank.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:Combustion": {
                              "additionalProperties": false,
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:DraftType": {
                                  "$ref": "#/definitions/.auc:DraftType"
                                }
                              },
                              "type": "object"
                            },
                            "auc:ElectricResistance": {
                              "$ref": "#/definitions/xs:anyType"
                            },
                            "auc:Other": {
                              "$ref": "#/definitions/xs:anyType"
                            },
                            "auc:Unknown": {
                              "$ref": "#/definitions/xs:anyType"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    },
                    "auc:Indirect": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:IndirectTankHeatingSource": {
                          "additionalProperties": false,
                          "description": "Source of heat for indirect-fired hot water tank.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:HeatPump": {
                              "additionalProperties": false,
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:HPWHMinimumAirTemperature": {
                                  "additionalProperties": false,
                                  "description": "The minimum ambient operating temperature for the compressor. This can be inferred from the operating range of the heat pump. Below this value, the heat pump will not operate and the supplemental heating system is required to produce hot water, thus reducing the efficiency of the heat pump water heater. (°F)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:RatedHeatPumpSensibleHeatRatio": {
                                  "additionalProperties": false,
                                  "description": "The fraction of total energy transfer between the evaporator coil and air that is associated with sensible capacity (change in air temperature) expressed as a dimensionless value, and at the rated conditions prescribed for this system.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:Refrigerant": {
                                  "$ref": "#/definitions/.auc:Refrigerant"
                                }
                              },
                              "type": "object"
                            },
                            "auc:Other": {
                              "$ref": "#/definitions/xs:anyType"
                            },
                            "auc:Solar": {
                              "additionalProperties": false,
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:ControlTechnology": {
                                  "$ref": "#/definitions/.auc:ControlTechnology"
                                },
                                "auc:Location": {
                                  "$ref": "#/definitions/.auc:Location"
                                },
                                "auc:Manufacturer": {
                                  "$ref": "#/definitions/.auc:Manufacturer"
                                },
                                "auc:ModelNumber": {
                                  "$ref": "#/definitions/.auc:ModelNumber"
                                },
                                "auc:Quantity": {
                                  "$ref": "#/definitions/.auc:Quantity"
                                },
                                "auc:SolarThermalSystemCollectorArea": {
                                  "additionalProperties": false,
                                  "description": "Area of solar collector exposed to solar radiation (ft2)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:SolarThermalSystemCollectorAzimuth": {
                                  "additionalProperties": false,
                                  "description": "Degrees clockwise from North. For a premises, it is the azimuth of the front facing element. It can also be applied to envelope components, such as walls, windows (fenestration), as well as on-site generation technologies, such as photovoltaic panels. Legal Values: 0 - 360. (degrees)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:SolarThermalSystemCollectorLoopType": {
                                  "additionalProperties": false,
                                  "description": "Heat transfer medium and controls used for the solar collector loop.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Air direct",
                                        "Air indirect",
                                        "Liquid direct",
                                        "Liquid indirect",
                                        "Passive thermosyphon",
                                        "Other",
                                        "Unknown"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:SolarThermalSystemCollectorTilt": {
                                  "additionalProperties": false,
                                  "description": "The angle from a horizontal surface; can be applied to an opaque surface, a fenestration unit, a solar panel, etc. (degrees)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:SolarThermalSystemCollectorType": {
                                  "additionalProperties": false,
                                  "description": "Type of solar energy collector used in a solar hot water or space heating system.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Single glazing black",
                                        "Single glazing selective",
                                        "Double glazing black",
                                        "Double glazing selective",
                                        "Evacuated tube",
                                        "Integrated collector storage",
                                        "Other",
                                        "Unknown"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:SolarThermalSystemStorageVolume": {
                                  "additionalProperties": false,
                                  "description": "Volume of any separate solar energy storage tank, not the primary service hot water tank.(gal)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:SolarThermalSystemType": {
                                  "additionalProperties": false,
                                  "description": "Basic function of solar thermal system",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Hot water",
                                        "Hot water and space heating",
                                        "Space heating",
                                        "Hybrid system",
                                        "Other",
                                        "Unknown"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:YearInstalled": {
                                  "$ref": "#/definitions/.auc:YearInstalled"
                                },
                                "auc:YearofManufacture": {
                                  "$ref": "#/definitions/.auc:YearofManufacture"
                                }
                              },
                              "type": "object"
                            },
                            "auc:SpaceHeatingSystem": {
                              "additionalProperties": false,
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:HeatingPlantID": {
                                  "additionalProperties": false,
                                  "description": "ID number of heating plant serving as the source for this hot water system.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    }
                                  },
                                  "type": "object"
                                }
                              },
                              "type": "object"
                            },
                            "auc:Unknown": {
                              "$ref": "#/definitions/xs:anyType"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    },
                    "auc:Other": {
                      "$ref": "#/definitions/xs:anyType"
                    },
                    "auc:Unknown": {
                      "$ref": "#/definitions/xs:anyType"
                    }
                  },
                  "type": "object"
                },
                "auc:TankHeight": {
                  "additionalProperties": false,
                  "description": "Vertical height of hot water tank. (in.)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:TankPerimeter": {
                  "additionalProperties": false,
                  "description": "Perimeter of hot water tank. (in.)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:TankVolume": {
                  "additionalProperties": false,
                  "description": "Hot water tank volume. (gal)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Unknown": {
              "$ref": "#/definitions/xs:anyType"
            }
          },
          "type": "object"
        },
        "auc:HotWaterDistributionType": {
          "additionalProperties": false,
          "description": "Manner in which hot water is distributed.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Looped",
                "Distributed",
                "Point-of-use",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:HotWaterSetpointTemperature": {
          "additionalProperties": false,
          "description": "The water temperature that the equipment supplies, such as the chilled water temperature setpoint for a chiller, or hot water temperature setpoint for water leaving a boiler. (°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:ParasiticFuelConsumptionRate": {
          "additionalProperties": false,
          "description": "A unit of thermal resistance used for comparing insulating values of different materials, for the specific thickness of the material. The higher the R-value number, a material, the greater its insulating properties and the slower the heat flow through it. (Btu/hr)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:Recirculation": {
          "additionalProperties": false,
          "description": "If exists then recirculation loops are used to minimize wait times for hot water.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:PipeInsulationThickness": {
              "additionalProperties": false,
              "description": "Defines how thick insulation on pipes in a heating, cooling, water heating system is. (in.)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:PipeLocation": {
              "additionalProperties": false,
              "description": "Percentage of pipe length in conditioned space. (0-1) (%)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:RecirculationControlType": {
              "additionalProperties": false,
              "description": "Type of control for recirculation loop",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Continuous",
                    "Temperature",
                    "Timer",
                    "Demand",
                    "Other",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:RecirculationEnergyLossRate": {
              "additionalProperties": false,
              "description": "Rate of heat loss from the recirculation loop when operating. (MMBtu/hr)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:RecirculationFlowRate": {
              "additionalProperties": false,
              "description": "Flow rate in primary hot water recirculation loop. Zero or blank if there is no recirculation loop. (gal/hr)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:RecirculationLoopCount": {
              "additionalProperties": false,
              "description": "The total number of hot water recirculation loops coming from and returning to a specific water heater.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:integer"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:WaterHeaterEfficiency": {
          "additionalProperties": false,
          "description": "A factor is used to compare the relative efficiency of water heaters, dishwashers, clothes washers, and clothes dryers.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WaterHeaterEfficiencyType": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Energy Factor",
                "Thermal Efficiency"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:DuctSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:CoolingDeliveryID": {
          "additionalProperties": false,
          "description": "Cooling delivery system supported by the air-distribution system",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "@IDref": {
              "$ref": "#/definitions/xs:IDREF"
            }
          },
          "required": [
            "@IDref"
          ],
          "type": "object"
        },
        "auc:DuctConfiguration": {
          "additionalProperties": false,
          "description": "Configuration of ducts.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Single",
                "Dual",
                "Three",
                "Ductless",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DuctInsulationCondition": {
          "additionalProperties": false,
          "description": "Condition of duct insulation.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Excellent",
                "Good",
                "Average",
                "Fair",
                "Poor",
                "Very poor",
                "None",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DuctInsulationRValue": {
          "additionalProperties": false,
          "description": "R-value of duct insulation. (ft2-F-hr/Btu)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DuctLeakageTestMethod": {
          "additionalProperties": false,
          "description": "Method used to estimate duct leakage",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Duct leakage tester",
                "Blower door subtract",
                "Pressure pan",
                "Visual inspection",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DuctPressureTestLeakagePercentage": {
          "additionalProperties": false,
          "description": "Duct leakage found from pressure test. Reported as a percentage. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DuctPressureTestLeakageRate": {
          "additionalProperties": false,
          "description": "Duct leakage found from pressure test. (cfm)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DuctSealing": {
          "additionalProperties": false,
          "description": "Condition of duct sealing",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Connections sealed with mastic",
                "No observable leaks",
                "Some observable leaks",
                "Significant leaks",
                "Catastrophic leaks",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DuctSurfaceArea": {
          "additionalProperties": false,
          "description": "Total surface area of ducts associated with this air distribution system. (ft2)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DuctType": {
          "additionalProperties": false,
          "description": "Type of duct material.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Flex uncategorized",
                "Grey flex",
                "Mylar flex",
                "Duct board",
                "Sheet metal",
                "Galvanized",
                "Flexible",
                "Fiberboard",
                "No ducting",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:HeatingDeliveryID": {
          "additionalProperties": false,
          "description": "Heating delivery system supported by the air-distribution system",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "@IDref": {
              "$ref": "#/definitions/xs:IDREF"
            }
          },
          "required": [
            "@IDref"
          ],
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:MaximumOAFlowRate": {
          "additionalProperties": false,
          "description": "The maximum flow rate of outside air that the system is able to deliver. For systems with economizing or demand controlled ventilation capability, this is the outdoor air flow rate when the OA damper is fully open and the fan speed is at maximum. (cfm)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:MinimumOutsideAirPercentage": {
          "additionalProperties": false,
          "description": "Minimum outside air percentage allowed.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ReturnDuctPercentConditionedSpace": {
          "additionalProperties": false,
          "description": "Percentage of return duct surface area, including the air handler, that is located within conditioned space. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:StaticPressureInstalled": {
          "additionalProperties": false,
          "description": "The expected or installed internal static pressure of the system at full supply fan speed including all filters, coils, and accessories. (Pa)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:SupplyDuctPercentConditionedSpace": {
          "additionalProperties": false,
          "description": "Percentage of supply duct surface area that is located within conditioned space. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:SupplyFractionOfDuctLeakage": {
          "additionalProperties": false,
          "description": "Fraction of total duct leakage that is on the supply side. Remainder is assumed to be on the return side. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:EndUse": {
      "enum": [
        "Whole building",
        "Total lighting",
        "Interior lighting",
        "Exterior lighting",
        "Heating",
        "Cooling",
        "Ventilation",
        "Pump",
        "IT",
        "Plug-in electric vehicle",
        "Plug load",
        "Process load",
        "Conveyance",
        "Domestic hot water",
        "Refrigeration",
        "Cooking",
        "Dishwasher",
        "Laundry",
        "Pool heating",
        "On-site generation"
      ],
      "type": "string"
    },
    "auc:EnvelopeConstructionType": {
      "enum": [
        "Masonry",
        "Structural brick",
        "Stone",
        "Concrete masonry unit",
        "Concrete-solid",
        "Concrete-lightweight",
        "Concrete-panels",
        "Concrete-poured",
        "Concrete-load bearing",
        "Concrete-insulated forms",
        "Concrete-aerated",
        "Steel frame",
        "Wood frame",
        "Double wood frame",
        "Structural insulated panel",
        "Log-solid wood",
        "Straw bale",
        "Built-up",
        "Other",
        "Unknown"
      ],
      "type": "string"
    },
    "auc:FanSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:BeltType": {
          "additionalProperties": false,
          "description": "Type of belt drive in fan unit",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Direct drive",
                "Standard belt",
                "Cogged belt",
                "Synchronous belts",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:DesignStaticPressure": {
          "additionalProperties": false,
          "description": "The design static pressure for the fan. (Pa)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FanApplication": {
          "additionalProperties": false,
          "description": "Application of fan (supply, return, or exhaust)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Supply",
                "Return",
                "Exhaust",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FanControlType": {
          "additionalProperties": false,
          "description": "Type of air flow control.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Variable Volume",
                "Stepped",
                "Constant Volume",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FanEfficiency": {
          "additionalProperties": false,
          "description": "Efficiency of the fan, excluding motor and drive. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FanPlacement": {
          "additionalProperties": false,
          "description": "Placement of fan relative to the air stream.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Series",
                "Parallel",
                "Draw Through",
                "Blow Through",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FanPowerMinimumRatio": {
          "additionalProperties": false,
          "description": "The minimum power draw of the fan, expressed as a ratio of the full load fan power. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FanSize": {
          "additionalProperties": false,
          "description": "Maximum air flow produced by the fan. (cfm)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FanType": {
          "additionalProperties": false,
          "description": "Method of generating air flow.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Axial",
                "Centrifugal",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:InstalledFlowRate": {
          "additionalProperties": false,
          "description": "Actual flow rate of fan under normal operating conditions. (cfm)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LinkedSystemID": {
          "anyOf": [
            {
              "additionalProperties": false,
              "description": "ID number of system(s) supported by this equipment",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "@IDref": {
                  "$ref": "#/definitions/xs:IDREF"
                }
              },
              "required": [
                "@IDref"
              ],
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "description": "ID number of system(s) supported by this equipment",
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "@IDref": {
                    "$ref": "#/definitions/xs:IDREF"
                  }
                },
                "required": [
                  "@IDref"
                ],
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:MaximumFanPower": {
          "additionalProperties": false,
          "description": "Fan power at maximum flow rate (full load). (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:MinimumFlowRate": {
          "additionalProperties": false,
          "description": "The lowest rated flow rate for a fan. (cfm)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:MotorLocationRelativeToAirStream": {
          "additionalProperties": false,
          "description": "True if the fan motor is located within the air stream.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:boolean"
            }
          },
          "type": "object"
        },
        "auc:NumberOfDiscreteFanSpeedsCooling": {
          "additionalProperties": false,
          "description": "The number of discrete operating speeds for the supply-fan motor when the unit is in cooling mode, excluding \"off.\" Only used if flow control is \"stepped\".",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:NumberOfDiscreteFanSpeedsHeating": {
          "additionalProperties": false,
          "description": "The number of discrete operating speeds for the supply-fan motor when the unit is in heating mode, excluding \"off.\" Only used if flow control is \"stepped\".",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:FenestrationSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:FenestrationFrameMaterial": {
          "additionalProperties": false,
          "description": "The construction and material used in the frame of the fenestration product. Some frames are made of combinations of materials. This characterization also include whether an aluminum frame has a thermal break as part of the construction",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Aluminum-uncategorized",
                "Aluminum-no thermal break",
                "Aluminum-thermal break",
                "Clad",
                "Composite",
                "Fiberglass",
                "Steel",
                "Vinyl",
                "Wood",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FenestrationGasFill": {
          "additionalProperties": false,
          "description": "For a sealed glazing system (commonly called an Insulated Glass Unit (IGU), the gas that is found between the panes of glass.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Argon",
                "Krypton",
                "Other Insulating Gas",
                "Air",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FenestrationGlassLayers": {
          "additionalProperties": false,
          "description": "A description of the number of layers of glass in a fenestration glazing system.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Single-pane",
                "Double-pane",
                "Triple-pane",
                "Single-paned with storm panel",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FenestrationOperation": {
          "additionalProperties": false,
          "description": "True if the fenestration product can be opened and closed as desired by the occupant to provide better control of office space conditions.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:boolean"
            }
          },
          "type": "object"
        },
        "auc:FenestrationRValue": {
          "additionalProperties": false,
          "description": "(Also known as thermal resistance), quantity determined by the temperature difference, at steady state, between two defined surfaces of a material or construction that induces a unit heat flow rate through unit area (R = ΔT/q). R-value is the reciprocal of thermal conductance. A unit of thermal resistance used for comparing insulating values of different materials, for the specific thickness of the material. The higher the R-value number, a material, the greater its insulating properties and the slower the heat flow through it. This R-value does not include the interior and exterior air film coefficients. (hr-ft2-F/Btu)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FenestrationType": {
          "additionalProperties": false,
          "description": "Type of fenestration in this group (windows, skylights, doors)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:Door": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:DoorGlazedAreaFraction": {
                  "additionalProperties": false,
                  "description": "Percentage of door area that is glazed. (0-1) (%)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:DoorOperation": {
                  "additionalProperties": false,
                  "description": "Non-swinging includes sliding doors and roll-up doors.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "NonSwinging",
                        "Swinging",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ExteriorDoorType": {
                  "additionalProperties": false,
                  "description": "Type of door construction.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Solid wood",
                        "Hollow wood",
                        "Uninsulated metal",
                        "Insulated metal",
                        "Glass",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:Vestibule": {
                  "additionalProperties": false,
                  "description": "True if door is connected to a vestibule.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:boolean"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Other": {
              "$ref": "#/definitions/xs:anyType"
            },
            "auc:Skylight": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AssemblyType": {
                  "additionalProperties": false,
                  "description": "Skylight assembly type.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Curbed Mounted"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:SkylightLayout": {
                  "additionalProperties": false,
                  "description": "Zones daylit by skylights.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "All Zones",
                        "Core Only",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:SkylightPitch": {
                  "additionalProperties": false,
                  "description": "Skylight angle from horizontal expressed as height over horizontal distance. (degrees)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:SkylightSolarTube": {
                  "additionalProperties": false,
                  "description": "True if skylights are solar tubes or tubular daylighting devices, false otherwise.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:boolean"
                    }
                  },
                  "type": "object"
                },
                "auc:SkylightWindowTreatments": {
                  "additionalProperties": false,
                  "description": "Type of film or shading applied to skylight.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Window film",
                        "Solar screen",
                        "Shading",
                        "None",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Window": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AssemblyType": {
                  "additionalProperties": false,
                  "description": "Window assembly type.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Double Hung"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:DistanceBetweenVerticalFins": {
                  "additionalProperties": false,
                  "description": "Horizontal spacing between individual fins. (ft)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ExteriorShadingType": {
                  "additionalProperties": false,
                  "description": "Any type of overhang or awning on the outside of the building designed to limit solar penetration.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Overhang",
                        "Fin",
                        "Awning",
                        "Solar screen",
                        "Solar film",
                        "Louver",
                        "Screen",
                        "None",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:InteriorShadingType": {
                  "additionalProperties": false,
                  "description": "Type of interior shading.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Blind",
                        "Curtain",
                        "Shade",
                        "None",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:LightShelves": {
                  "additionalProperties": false,
                  "description": "If exists then light shelves are used with this window group, otherwise false.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:LightShelfDistanceFromTop": {
                      "additionalProperties": false,
                      "description": "Vertical distance from top of window to the light shelf. (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:LightShelfExteriorProtrusion": {
                      "additionalProperties": false,
                      "description": "Horizontal distance that the light shelf extends exterior to the window. (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:LightShelfInteriorProtrusion": {
                      "additionalProperties": false,
                      "description": "Horizontal distance that the light shelf extends interior to the window. (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                },
                "auc:OverhangHeightAboveWindow": {
                  "additionalProperties": false,
                  "description": "Vertical distance from top of window to base of overhang. (ft)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:OverhangProjection": {
                  "additionalProperties": false,
                  "description": "Horizontal distance that the overhang extends beyond the wall. (ft)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:VerticalEdgeFinOnly": {
                  "additionalProperties": false,
                  "description": "True if edge fins, otherwise false.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:boolean"
                    }
                  },
                  "type": "object"
                },
                "auc:VerticalFinDepth": {
                  "additionalProperties": false,
                  "description": "Horizontal distance that the fins extend beyond the wall. (ft)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WindowHeight": {
                  "additionalProperties": false,
                  "description": "Vertical height of each window. (ft)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WindowHorizontalSpacing": {
                  "additionalProperties": false,
                  "description": "Horizontal distance between the centers of adjacent windows. (ft)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WindowLayout": {
                  "additionalProperties": false,
                  "description": "The pattern of distribution of the fenestration system on the wall.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Continuous",
                        "Discrete",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WindowOrientation": {
                  "additionalProperties": false,
                  "description": "Orientation of a surface or premises in terms of the attributes of North, South, East and West. Can be applied to the orientation of the front of the building, of a specific surface (wall, roof), window or skylight, or on-site generation technology, such as photovoltaic panels.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "North",
                        "Northeast",
                        "East",
                        "Southeast",
                        "South",
                        "Southwest",
                        "West",
                        "Northwest",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WindowSillHeight": {
                  "additionalProperties": false,
                  "description": "Vertical distance from the floor to the window sill. (ft)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WindowWidth": {
                  "additionalProperties": false,
                  "description": "Horizontal width of each window. (ft)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:FenestrationUFactor": {
          "additionalProperties": false,
          "description": "The thermal transmission in unit time through a unit area of a particular body or assembly, including its boundary films, divided by the difference between the environmental temperatures on either side of the body or assembly. Note that the U-factor for a construction assembly, including fenestration, includes the interior and exterior film coefficients (the boundary films referenced above). (Btu/hr·ft2·°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:GlassType": {
          "additionalProperties": false,
          "description": "Type of glass used in this fenestration group.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Clear uncoated",
                "Low-e",
                "Tinted",
                "Tinted + low-e",
                "Reflective",
                "Reflective on tint",
                "High performance tint",
                "Sunbelt low-E (low SHGC)",
                "Suspended film",
                "Plastic",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:SolarHeatGainCoefficient": {
          "additionalProperties": false,
          "description": "The ratio of the solar heat gain entering the space through the fenestration product to the incident solar radiation. Solar heat gain includes directly transmitted solar heat and that portion of the absorbed solar radiation which is then reradiated, conducted, or convected into the space. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:TightnessFitCondition": {
          "additionalProperties": false,
          "description": "Indicator of expected air leakage through fenestration",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Tight",
                "Average",
                "Loose",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:VisibleTransmittance": {
          "additionalProperties": false,
          "description": "The fraction of radiation in the visible solar spectrum (0.4 to 0.7 micrometers) that passes through a the glazed portion of fenestration. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Weatherstripped": {
          "additionalProperties": false,
          "description": "True if fenestration is weatherstripped, false otherwise.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:boolean"
            }
          },
          "type": "object"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        }
      },
      "type": "object"
    },
    "auc:FoundationSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:FloorCovering": {
          "additionalProperties": false,
          "description": "Material covering the slab or floor over unconditioned space.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Carpet",
                "Tile",
                "Hardwood",
                "Vinyl",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:GroundCoupling": {
          "anyOf": [
            {
              "additionalProperties": false,
              "description": "The manner in which the building is connected to the ground.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:Basement": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:BasementConditioning": {
                      "additionalProperties": false,
                      "description": "Extent of space conditioning in basement.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "Conditioned Basement",
                            "Unconditioned Basement",
                            "Half Conditioned Basement",
                            "Other",
                            "Unknown"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:FoundationHeightAboveGrade": {
                      "additionalProperties": false,
                      "description": "Height of the building foundation that is above the ground. (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:FoundationWallConstruction": {
                      "additionalProperties": false,
                      "description": "Basement or crawlspace wall construction.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/auc:EnvelopeConstructionType"
                        }
                      },
                      "type": "object"
                    },
                    "auc:FoundationWallInsulationCondition": {
                      "$ref": "#/definitions/.auc:FoundationWallInsulationCondition"
                    },
                    "auc:FoundationWallInsulationContinuity": {
                      "$ref": "#/definitions/.auc:FoundationWallInsulationContinuity"
                    },
                    "auc:FoundationWallInsulationThickness": {
                      "additionalProperties": false,
                      "description": "Thickness of insulation at basement or crawlspace wall. (in.)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:FoundationWallRValue": {
                      "additionalProperties": false,
                      "description": "(Also known as thermal resistance), quantity determined by the temperature difference, at steady state, between two defined surfaces of a material or construction that induces a unit heat flow rate through unit area (R = ΔT/q). R-value is the reciprocal of thermal conductance. A unit of thermal resistance used for comparing insulating values of different materials, for the specific thickness of the material. The higher the R-value number, a material, the greater its insulating properties and the slower the heat flow through it. This R-value does not include the interior and exterior air film coefficients. (hr-ft2-F/Btu)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:SlabArea": {
                      "additionalProperties": false,
                      "description": "Area of slab-on-grade, basement slab, or other floor over unconditioned space. (ft2)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:SlabExposedPerimeter": {
                      "additionalProperties": false,
                      "description": "Perimeter of slab-on-grade or basement slab exposed to outside air conditions. (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:SlabHeating": {
                      "$ref": "#/definitions/.auc:SlabHeating"
                    },
                    "auc:SlabInsulationCondition": {
                      "$ref": "#/definitions/.auc:SlabInsulationCondition"
                    },
                    "auc:SlabInsulationOrientation": {
                      "$ref": "#/definitions/.auc:SlabInsulationOrientation"
                    },
                    "auc:SlabInsulationThickness": {
                      "additionalProperties": false,
                      "description": "Thickness of insulation around perimeter or under slab. (in.)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:SlabPerimeter": {
                      "additionalProperties": false,
                      "description": "Total perimeter of slab-on-grade or basement slab. (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                },
                "auc:Crawlspace": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:CrawlspaceVenting": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:OtherCombination": {
                          "$ref": "#/definitions/xs:anyType"
                        },
                        "auc:Unknown": {
                          "$ref": "#/definitions/xs:anyType"
                        },
                        "auc:Unvented": {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:FoundationHeightAboveGrade": {
                              "additionalProperties": false,
                              "description": "Height of the building foundation that is above the ground. (ft)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:FoundationWallConstruction": {
                              "additionalProperties": false,
                              "description": "Basement or crawlspace wall construction.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/auc:EnvelopeConstructionType"
                                }
                              },
                              "type": "object"
                            },
                            "auc:FoundationWallInsulationCondition": {
                              "$ref": "#/definitions/.auc:FoundationWallInsulationCondition"
                            },
                            "auc:FoundationWallInsulationContinuity": {
                              "$ref": "#/definitions/.auc:FoundationWallInsulationContinuity"
                            },
                            "auc:FoundationWallInsulationThickness": {
                              "additionalProperties": false,
                              "description": "Thickness of insulation at basement or crawlspace wall. (in.)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            }
                          },
                          "type": "object"
                        },
                        "auc:Vented": {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:FloorConstructionType": {
                              "additionalProperties": false,
                              "description": "Construction type for floors over unconditioned space.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/auc:EnvelopeConstructionType"
                                }
                              },
                              "type": "object"
                            },
                            "auc:FloorFramingDepth": {
                              "additionalProperties": false,
                              "description": "Dimension of the distance from the front to the back, such as the depth of structural framing in a wall or floor. It can also be the distance from the top to the bottom, such as the depth of a tank or pool of a component or material, such as the depth of the structural framing. (in.)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:FloorFramingFactor": {
                              "additionalProperties": false,
                              "description": "Fraction of the surface that is composed of structural framing material. (0-1) (%)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:FloorFramingSpacing": {
                              "additionalProperties": false,
                              "description": "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tDimension of the distance between two components.\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tExamples include:\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tFraming spacing: the dimension from centerline to centerline of a surface framing material\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tWindow spacing: the dimension between windows in a discrete window layout. (in.)\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:FloorInsulationCondition": {
                              "additionalProperties": false,
                              "description": "General physical condition of floor insulation.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Excellent",
                                    "Good",
                                    "Average",
                                    "Poor",
                                    "Other",
                                    "Unknown",
                                    "None"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:FloorInsulationThickness": {
                              "additionalProperties": false,
                              "description": "Thickness of insulation under floor over unconditioned space. (in.)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:FloorRValue": {
                              "additionalProperties": false,
                              "description": "(Also known as thermal resistance), quantity determined by the temperature difference, at steady state, between two defined surfaces of a material or construction that induces a unit heat flow rate through unit area (R = ΔT/q). R-value is the reciprocal of thermal conductance. A unit of thermal resistance used for comparing insulating values of different materials, for the specific thickness of the material. The higher the R-value number, a material, the greater its insulating properties and the slower the heat flow through it. This R-value does not include the interior and exterior air film coefficients. (hr-ft2-F/Btu)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:FloorUFactor": {
                              "additionalProperties": false,
                              "description": "The thermal transmission in unit time through a unit area of a particular body or assembly, including its boundary films, divided by the difference between the environmental temperatures on either side of the body or assembly. Note that the U-factor for a construction assembly, including fenestration, includes the interior and exterior film coefficients (the boundary films referenced above). (Btu/hr·ft2·°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                },
                "auc:Other": {
                  "$ref": "#/definitions/xs:anyType"
                },
                "auc:SlabOnGrade": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:SlabArea": {
                      "additionalProperties": false,
                      "description": "Area of slab-on-grade, basement slab, or other floor over unconditioned space. (ft2)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:SlabExposedPerimeter": {
                      "additionalProperties": false,
                      "description": "Perimeter of slab-on-grade or basement slab exposed to outside air conditions. (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:SlabHeating": {
                      "$ref": "#/definitions/.auc:SlabHeating"
                    },
                    "auc:SlabInsulationCondition": {
                      "$ref": "#/definitions/.auc:SlabInsulationCondition"
                    },
                    "auc:SlabInsulationOrientation": {
                      "$ref": "#/definitions/.auc:SlabInsulationOrientation"
                    },
                    "auc:SlabInsulationThickness": {
                      "additionalProperties": false,
                      "description": "Thickness of insulation around perimeter or under slab. (in.)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:SlabPerimeter": {
                      "additionalProperties": false,
                      "description": "Total perimeter of slab-on-grade or basement slab. (ft)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                },
                "auc:Unknown": {
                  "$ref": "#/definitions/xs:anyType"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "description": "The manner in which the building is connected to the ground.",
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:Basement": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:BasementConditioning": {
                        "additionalProperties": false,
                        "description": "Extent of space conditioning in basement.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "enum": [
                              "Conditioned Basement",
                              "Unconditioned Basement",
                              "Half Conditioned Basement",
                              "Other",
                              "Unknown"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:FoundationHeightAboveGrade": {
                        "additionalProperties": false,
                        "description": "Height of the building foundation that is above the ground. (ft)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:FoundationWallConstruction": {
                        "additionalProperties": false,
                        "description": "Basement or crawlspace wall construction.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/auc:EnvelopeConstructionType"
                          }
                        },
                        "type": "object"
                      },
                      "auc:FoundationWallInsulationCondition": {
                        "$ref": "#/definitions/.auc:FoundationWallInsulationCondition"
                      },
                      "auc:FoundationWallInsulationContinuity": {
                        "$ref": "#/definitions/.auc:FoundationWallInsulationContinuity"
                      },
                      "auc:FoundationWallInsulationThickness": {
                        "additionalProperties": false,
                        "description": "Thickness of insulation at basement or crawlspace wall. (in.)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:FoundationWallRValue": {
                        "additionalProperties": false,
                        "description": "(Also known as thermal resistance), quantity determined by the temperature difference, at steady state, between two defined surfaces of a material or construction that induces a unit heat flow rate through unit area (R = ΔT/q). R-value is the reciprocal of thermal conductance. A unit of thermal resistance used for comparing insulating values of different materials, for the specific thickness of the material. The higher the R-value number, a material, the greater its insulating properties and the slower the heat flow through it. This R-value does not include the interior and exterior air film coefficients. (hr-ft2-F/Btu)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:SlabArea": {
                        "additionalProperties": false,
                        "description": "Area of slab-on-grade, basement slab, or other floor over unconditioned space. (ft2)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:SlabExposedPerimeter": {
                        "additionalProperties": false,
                        "description": "Perimeter of slab-on-grade or basement slab exposed to outside air conditions. (ft)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:SlabHeating": {
                        "$ref": "#/definitions/.auc:SlabHeating"
                      },
                      "auc:SlabInsulationCondition": {
                        "$ref": "#/definitions/.auc:SlabInsulationCondition"
                      },
                      "auc:SlabInsulationOrientation": {
                        "$ref": "#/definitions/.auc:SlabInsulationOrientation"
                      },
                      "auc:SlabInsulationThickness": {
                        "additionalProperties": false,
                        "description": "Thickness of insulation around perimeter or under slab. (in.)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:SlabPerimeter": {
                        "additionalProperties": false,
                        "description": "Total perimeter of slab-on-grade or basement slab. (ft)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      }
                    },
                    "type": "object"
                  },
                  "auc:Crawlspace": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:CrawlspaceVenting": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:OtherCombination": {
                            "$ref": "#/definitions/xs:anyType"
                          },
                          "auc:Unknown": {
                            "$ref": "#/definitions/xs:anyType"
                          },
                          "auc:Unvented": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:FoundationHeightAboveGrade": {
                                "additionalProperties": false,
                                "description": "Height of the building foundation that is above the ground. (ft)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:FoundationWallConstruction": {
                                "additionalProperties": false,
                                "description": "Basement or crawlspace wall construction.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/auc:EnvelopeConstructionType"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:FoundationWallInsulationCondition": {
                                "$ref": "#/definitions/.auc:FoundationWallInsulationCondition"
                              },
                              "auc:FoundationWallInsulationContinuity": {
                                "$ref": "#/definitions/.auc:FoundationWallInsulationContinuity"
                              },
                              "auc:FoundationWallInsulationThickness": {
                                "additionalProperties": false,
                                "description": "Thickness of insulation at basement or crawlspace wall. (in.)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          },
                          "auc:Vented": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:FloorConstructionType": {
                                "additionalProperties": false,
                                "description": "Construction type for floors over unconditioned space.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/auc:EnvelopeConstructionType"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:FloorFramingDepth": {
                                "additionalProperties": false,
                                "description": "Dimension of the distance from the front to the back, such as the depth of structural framing in a wall or floor. It can also be the distance from the top to the bottom, such as the depth of a tank or pool of a component or material, such as the depth of the structural framing. (in.)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:FloorFramingFactor": {
                                "additionalProperties": false,
                                "description": "Fraction of the surface that is composed of structural framing material. (0-1) (%)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:FloorFramingSpacing": {
                                "additionalProperties": false,
                                "description": "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tDimension of the distance between two components.\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tExamples include:\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tFraming spacing: the dimension from centerline to centerline of a surface framing material\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tWindow spacing: the dimension between windows in a discrete window layout. (in.)\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:FloorInsulationCondition": {
                                "additionalProperties": false,
                                "description": "General physical condition of floor insulation.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Excellent",
                                      "Good",
                                      "Average",
                                      "Poor",
                                      "Other",
                                      "Unknown",
                                      "None"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:FloorInsulationThickness": {
                                "additionalProperties": false,
                                "description": "Thickness of insulation under floor over unconditioned space. (in.)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:FloorRValue": {
                                "additionalProperties": false,
                                "description": "(Also known as thermal resistance), quantity determined by the temperature difference, at steady state, between two defined surfaces of a material or construction that induces a unit heat flow rate through unit area (R = ΔT/q). R-value is the reciprocal of thermal conductance. A unit of thermal resistance used for comparing insulating values of different materials, for the specific thickness of the material. The higher the R-value number, a material, the greater its insulating properties and the slower the heat flow through it. This R-value does not include the interior and exterior air film coefficients. (hr-ft2-F/Btu)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:FloorUFactor": {
                                "additionalProperties": false,
                                "description": "The thermal transmission in unit time through a unit area of a particular body or assembly, including its boundary films, divided by the difference between the environmental temperatures on either side of the body or assembly. Note that the U-factor for a construction assembly, including fenestration, includes the interior and exterior film coefficients (the boundary films referenced above). (Btu/hr·ft2·°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          }
                        },
                        "type": "object"
                      }
                    },
                    "type": "object"
                  },
                  "auc:Other": {
                    "$ref": "#/definitions/xs:anyType"
                  },
                  "auc:SlabOnGrade": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:SlabArea": {
                        "additionalProperties": false,
                        "description": "Area of slab-on-grade, basement slab, or other floor over unconditioned space. (ft2)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:SlabExposedPerimeter": {
                        "additionalProperties": false,
                        "description": "Perimeter of slab-on-grade or basement slab exposed to outside air conditions. (ft)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:SlabHeating": {
                        "$ref": "#/definitions/.auc:SlabHeating"
                      },
                      "auc:SlabInsulationCondition": {
                        "$ref": "#/definitions/.auc:SlabInsulationCondition"
                      },
                      "auc:SlabInsulationOrientation": {
                        "$ref": "#/definitions/.auc:SlabInsulationOrientation"
                      },
                      "auc:SlabInsulationThickness": {
                        "additionalProperties": false,
                        "description": "Thickness of insulation around perimeter or under slab. (in.)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:SlabPerimeter": {
                        "additionalProperties": false,
                        "description": "Total perimeter of slab-on-grade or basement slab. (ft)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      }
                    },
                    "type": "object"
                  },
                  "auc:Unknown": {
                    "$ref": "#/definitions/xs:anyType"
                  }
                },
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:PlumbingPenetrationSealing": {
          "additionalProperties": false,
          "description": "Type of plumbing penetration sealing.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Flashing",
                "Fitting",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        }
      },
      "type": "object"
    },
    "auc:FuelTypes": {
      "enum": [
        "Electricity",
        "Natural gas",
        "Fuel oil",
        "Fuel oil No-1",
        "Fuel oil No-2",
        "Fuel oil No-4",
        "Fuel oil No-5 and No-6",
        "District steam",
        "District hot water",
        "District chilled water",
        "Propane",
        "Liquid propane",
        "Kerosene",
        "Diesel",
        "Coal",
        "Coal (anthracite)",
        "Coal (bituminous)",
        "Coke",
        "Wood",
        "Wood pellets",
        "Hydropower",
        "Biofuel",
        "Wind",
        "Geothermal",
        "Solar",
        "Biomass",
        "Hydrothermal",
        "Dry steam",
        "Flash steam",
        "Ethanol",
        "Biodiesel",
        "Waste heat",
        "Combination",
        "Other",
        "Unknown"
      ],
      "type": "string"
    },
    "auc:HVACSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:DuctSystems": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:DuctSystems": {
              "anyOf": [
                {
                  "$ref": "#/definitions/auc:DuctSystemType"
                },
                {
                  "items": {
                    "$ref": "#/definitions/auc:DuctSystemType"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:DuctSystems"
          ],
          "type": "object"
        },
        "auc:FrequencyOfMaintenance": {
          "additionalProperties": false,
          "description": "Frequency of maintenance on the premises or equipment.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "As needed",
                "Daily",
                "Weekly",
                "Bi-weekly",
                "Monthly",
                "Semi-quarterly",
                "Quarterly",
                "Semi-annually",
                "Annually",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:HeatingAndCoolingSystems": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:CoolingSource": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@ID": {
                      "$ref": "#/definitions/xs:ID"
                    },
                    "@auc:Status": {
                      "enum": [
                        "Existing",
                        "Proposed",
                        "Past",
                        "Reference",
                        "Other"
                      ],
                      "type": "string"
                    },
                    "auc:AnnualCoolingEfficiencyUnits": {
                      "$ref": "#/definitions/.auc:AnnualCoolingEfficiencyUnits"
                    },
                    "auc:AnnualCoolingEfficiencyValue": {
                      "additionalProperties": false,
                      "description": "Overall annual efficiency of a cooling system.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:Capacity": {
                      "$ref": "#/definitions/.auc:Capacity"
                    },
                    "auc:CapacityUnits": {
                      "$ref": "#/definitions/.auc:CapacityUnits"
                    },
                    "auc:ControlTechnology": {
                      "$ref": "#/definitions/.auc:ControlTechnology"
                    },
                    "auc:CoolingMedium": {
                      "additionalProperties": false,
                      "description": "Medium used to transport cooling energy from a central cooling system to individual zones.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "Chilled water",
                            "Refrigerant",
                            "Air",
                            "Glycol",
                            "Other",
                            "Unknown"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:CoolingSourceType": {
                      "additionalProperties": false,
                      "description": "Source of energy used for cooling the zone.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:CoolingPlantID": {
                          "additionalProperties": false,
                          "description": "ID number of cooling plant serving as the source for this zonal system.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "@IDref": {
                              "$ref": "#/definitions/xs:IDREF"
                            }
                          },
                          "required": [
                            "@IDref"
                          ],
                          "type": "object"
                        },
                        "auc:DX": {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:ActiveDehumidification": {
                              "additionalProperties": false,
                              "description": "True if an active dehumidification system is available (in addition to the dehumidification that takes place during normal DX cooling operation).",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:boolean"
                                }
                              },
                              "type": "object"
                            },
                            "auc:CompressorStaging": {
                              "$ref": "#/definitions/.auc:CompressorStaging"
                            },
                            "auc:CompressorType": {
                              "additionalProperties": false,
                              "description": "Type of compressor in the chiller.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Reciprocating",
                                    "Screw",
                                    "Scroll",
                                    "Centrifugal",
                                    "Other",
                                    "Unknown"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:CondenserPlantID": {
                              "anyOf": [
                                {
                                  "additionalProperties": false,
                                  "description": "ID numbers of the central condenser plants serving as the source for this cooling system.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "@IDref": {
                                      "$ref": "#/definitions/xs:IDREF"
                                    }
                                  },
                                  "required": [
                                    "@IDref"
                                  ],
                                  "type": "object"
                                },
                                {
                                  "items": {
                                    "additionalProperties": false,
                                    "description": "ID numbers of the central condenser plants serving as the source for this cooling system.",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  },
                                  "type": "array"
                                }
                              ]
                            },
                            "auc:DXSystemType": {
                              "additionalProperties": false,
                              "description": "General type of heat pump used for space heating.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Split DX air conditioner",
                                    "Packaged terminal air conditioner (PTAC)",
                                    "Split heat pump",
                                    "Packaged terminal heat pump (PTHP)",
                                    "Variable refrigerant flow",
                                    "Packaged/unitary direct expansion/RTU",
                                    "Packaged/unitary heat pump",
                                    "Single package vertical air conditioner",
                                    "Single package vertical heat pump",
                                    "Other",
                                    "Unknown"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:Refrigerant": {
                              "$ref": "#/definitions/.auc:Refrigerant"
                            },
                            "auc:RefrigerantChargeFactor": {
                              "additionalProperties": false,
                              "description": "Used to adjust cooling efficiency for assumed slightly degraded performance if refrigerant charge is not verified through acceptance test procedures. (0-1) (%)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            }
                          },
                          "type": "object"
                        },
                        "auc:EvaporativeCooler": {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:EvaporativeCoolingType": {
                              "additionalProperties": false,
                              "description": "Defines the type of evaporative cooler operation",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Direct",
                                    "Direct indirect",
                                    "Indirect",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            }
                          },
                          "type": "object"
                        },
                        "auc:NoCooling": {
                          "$ref": "#/definitions/xs:anyType"
                        },
                        "auc:OtherCombination": {
                          "$ref": "#/definitions/xs:anyType"
                        },
                        "auc:Unknown": {
                          "$ref": "#/definitions/xs:anyType"
                        }
                      },
                      "type": "object"
                    },
                    "auc:CoolingStageCapacity": {
                      "additionalProperties": false,
                      "description": "Average capacity of each cooling stage, at ARI rated conditions, expressed as a fraction of total capacity. (0-1) (%)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:Manufacturer": {
                      "$ref": "#/definitions/.auc:Manufacturer"
                    },
                    "auc:MinimumPartLoadRatio": {
                      "additionalProperties": false,
                      "description": "The minimum part load ratio at which the system is able to operate. (0-1) (%)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:ModelNumber": {
                      "$ref": "#/definitions/.auc:ModelNumber"
                    },
                    "auc:NumberOfDiscreteCoolingStages": {
                      "additionalProperties": false,
                      "description": "The number of discrete operating stages, excluding \"off.\"",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:integer"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:PrimaryFuel": {
                      "$ref": "#/definitions/.auc:PrimaryFuel"
                    },
                    "auc:Quantity": {
                      "$ref": "#/definitions/.auc:Quantity"
                    },
                    "auc:RatedCoolingSensibleHeatRatio": {
                      "additionalProperties": false,
                      "description": "The fraction of total energy transfer between the evaporator coil and air that is associated with sensible capacity (change in air temperature) expressed as a dimensionless value, and at the rated conditions prescribed for this system. (0-1) (%)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:ThirdPartyCertification": {
                      "$ref": "#/definitions/.auc:ThirdPartyCertification"
                    },
                    "auc:YearInstalled": {
                      "$ref": "#/definitions/.auc:YearInstalled"
                    },
                    "auc:YearofManufacture": {
                      "$ref": "#/definitions/.auc:YearofManufacture"
                    }
                  },
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@ID": {
                        "$ref": "#/definitions/xs:ID"
                      },
                      "@auc:Status": {
                        "enum": [
                          "Existing",
                          "Proposed",
                          "Past",
                          "Reference",
                          "Other"
                        ],
                        "type": "string"
                      },
                      "auc:AnnualCoolingEfficiencyUnits": {
                        "$ref": "#/definitions/.auc:AnnualCoolingEfficiencyUnits"
                      },
                      "auc:AnnualCoolingEfficiencyValue": {
                        "additionalProperties": false,
                        "description": "Overall annual efficiency of a cooling system.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:Capacity": {
                        "$ref": "#/definitions/.auc:Capacity"
                      },
                      "auc:CapacityUnits": {
                        "$ref": "#/definitions/.auc:CapacityUnits"
                      },
                      "auc:ControlTechnology": {
                        "$ref": "#/definitions/.auc:ControlTechnology"
                      },
                      "auc:CoolingMedium": {
                        "additionalProperties": false,
                        "description": "Medium used to transport cooling energy from a central cooling system to individual zones.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "enum": [
                              "Chilled water",
                              "Refrigerant",
                              "Air",
                              "Glycol",
                              "Other",
                              "Unknown"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:CoolingSourceType": {
                        "additionalProperties": false,
                        "description": "Source of energy used for cooling the zone.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:CoolingPlantID": {
                            "additionalProperties": false,
                            "description": "ID number of cooling plant serving as the source for this zonal system.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          "auc:DX": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:ActiveDehumidification": {
                                "additionalProperties": false,
                                "description": "True if an active dehumidification system is available (in addition to the dehumidification that takes place during normal DX cooling operation).",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:boolean"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:CompressorStaging": {
                                "$ref": "#/definitions/.auc:CompressorStaging"
                              },
                              "auc:CompressorType": {
                                "additionalProperties": false,
                                "description": "Type of compressor in the chiller.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Reciprocating",
                                      "Screw",
                                      "Scroll",
                                      "Centrifugal",
                                      "Other",
                                      "Unknown"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:CondenserPlantID": {
                                "anyOf": [
                                  {
                                    "additionalProperties": false,
                                    "description": "ID numbers of the central condenser plants serving as the source for this cooling system.",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "@IDref": {
                                        "$ref": "#/definitions/xs:IDREF"
                                      }
                                    },
                                    "required": [
                                      "@IDref"
                                    ],
                                    "type": "object"
                                  },
                                  {
                                    "items": {
                                      "additionalProperties": false,
                                      "description": "ID numbers of the central condenser plants serving as the source for this cooling system.",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "@IDref": {
                                          "$ref": "#/definitions/xs:IDREF"
                                        }
                                      },
                                      "required": [
                                        "@IDref"
                                      ],
                                      "type": "object"
                                    },
                                    "type": "array"
                                  }
                                ]
                              },
                              "auc:DXSystemType": {
                                "additionalProperties": false,
                                "description": "General type of heat pump used for space heating.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Split DX air conditioner",
                                      "Packaged terminal air conditioner (PTAC)",
                                      "Split heat pump",
                                      "Packaged terminal heat pump (PTHP)",
                                      "Variable refrigerant flow",
                                      "Packaged/unitary direct expansion/RTU",
                                      "Packaged/unitary heat pump",
                                      "Single package vertical air conditioner",
                                      "Single package vertical heat pump",
                                      "Other",
                                      "Unknown"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:Refrigerant": {
                                "$ref": "#/definitions/.auc:Refrigerant"
                              },
                              "auc:RefrigerantChargeFactor": {
                                "additionalProperties": false,
                                "description": "Used to adjust cooling efficiency for assumed slightly degraded performance if refrigerant charge is not verified through acceptance test procedures. (0-1) (%)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          },
                          "auc:EvaporativeCooler": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:EvaporativeCoolingType": {
                                "additionalProperties": false,
                                "description": "Defines the type of evaporative cooler operation",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Direct",
                                      "Direct indirect",
                                      "Indirect",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          },
                          "auc:NoCooling": {
                            "$ref": "#/definitions/xs:anyType"
                          },
                          "auc:OtherCombination": {
                            "$ref": "#/definitions/xs:anyType"
                          },
                          "auc:Unknown": {
                            "$ref": "#/definitions/xs:anyType"
                          }
                        },
                        "type": "object"
                      },
                      "auc:CoolingStageCapacity": {
                        "additionalProperties": false,
                        "description": "Average capacity of each cooling stage, at ARI rated conditions, expressed as a fraction of total capacity. (0-1) (%)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:Manufacturer": {
                        "$ref": "#/definitions/.auc:Manufacturer"
                      },
                      "auc:MinimumPartLoadRatio": {
                        "additionalProperties": false,
                        "description": "The minimum part load ratio at which the system is able to operate. (0-1) (%)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:ModelNumber": {
                        "$ref": "#/definitions/.auc:ModelNumber"
                      },
                      "auc:NumberOfDiscreteCoolingStages": {
                        "additionalProperties": false,
                        "description": "The number of discrete operating stages, excluding \"off.\"",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:integer"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:PrimaryFuel": {
                        "$ref": "#/definitions/.auc:PrimaryFuel"
                      },
                      "auc:Quantity": {
                        "$ref": "#/definitions/.auc:Quantity"
                      },
                      "auc:RatedCoolingSensibleHeatRatio": {
                        "additionalProperties": false,
                        "description": "The fraction of total energy transfer between the evaporator coil and air that is associated with sensible capacity (change in air temperature) expressed as a dimensionless value, and at the rated conditions prescribed for this system. (0-1) (%)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:ThirdPartyCertification": {
                        "$ref": "#/definitions/.auc:ThirdPartyCertification"
                      },
                      "auc:YearInstalled": {
                        "$ref": "#/definitions/.auc:YearInstalled"
                      },
                      "auc:YearofManufacture": {
                        "$ref": "#/definitions/.auc:YearofManufacture"
                      }
                    },
                    "type": "object"
                  },
                  "type": "array"
                }
              ]
            },
            "auc:Delivery": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@ID": {
                      "$ref": "#/definitions/xs:ID"
                    },
                    "@auc:Status": {
                      "enum": [
                        "Existing",
                        "Proposed",
                        "Past",
                        "Reference",
                        "Other"
                      ],
                      "type": "string"
                    },
                    "auc:Capacity": {
                      "$ref": "#/definitions/.auc:Capacity"
                    },
                    "auc:CapacityUnits": {
                      "$ref": "#/definitions/.auc:CapacityUnits"
                    },
                    "auc:ControlTechnology": {
                      "$ref": "#/definitions/.auc:ControlTechnology"
                    },
                    "auc:CoolingSourceID": {
                      "additionalProperties": false,
                      "description": "ID number of cooling system associated with this delivery mechanism.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    "auc:DeliveryType": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:Convection": {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:ConvectionType": {
                              "additionalProperties": false,
                              "description": "Type of convection equipment used for heating and cooling at the zone.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Perimeter baseboard",
                                    "Chilled beam",
                                    "Other",
                                    "Unknown"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:PipeInsulationThickness": {
                              "additionalProperties": false,
                              "description": "Defines how thick insulation on pipes in a heating, cooling, water heating system is. (in.)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:PipeLocation": {
                              "additionalProperties": false,
                              "description": "Percent of pipe length in conditioned space. (0-1) (%)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            }
                          },
                          "type": "object"
                        },
                        "auc:FanBased": {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:AirSideEconomizer": {
                              "additionalProperties": false,
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@ID": {
                                  "$ref": "#/definitions/xs:ID"
                                },
                                "@auc:Status": {
                                  "enum": [
                                    "Existing",
                                    "Proposed",
                                    "Past",
                                    "Reference",
                                    "Other"
                                  ],
                                  "type": "string"
                                },
                                "auc:AirSideEconomizerType": {
                                  "additionalProperties": false,
                                  "description": "Type of air economizer system associated with a cooling system.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Dry bulb temperature",
                                        "Enthalpy",
                                        "Demand controlled ventilation",
                                        "Nonintegrated",
                                        "Other",
                                        "Unknown"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:EconomizerControl": {
                                  "additionalProperties": false,
                                  "description": "Logic used for economizer control.",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Fixed",
                                        "Differential",
                                        "Other",
                                        "Unknown"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:EconomizerDryBulbControlPoint": {
                                  "additionalProperties": false,
                                  "description": "Dry bulb temperature setting for use of economizer for cooling (fixed or differential) (°F)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:EconomizerEnthalpyControlPoint": {
                                  "additionalProperties": false,
                                  "description": "Maximum enthalpy setting for use of economizer for cooling (fixed or differential) (Btu/lb)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:EconomizerLowTemperatureLockout": {
                                  "additionalProperties": false,
                                  "description": "The outside air temperature below which the economizer will return to the minimum position. (°F)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                }
                              },
                              "type": "object"
                            },
                            "auc:CoolingSupplyAirTemperature": {
                              "additionalProperties": false,
                              "description": "Temperature setting of supply air for cooling under normal conditions. (°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:CoolingSupplyAirTemperatureControlType": {
                              "additionalProperties": false,
                              "description": "Defines the control method for controlling cooling supply air temperature.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Fixed",
                                    "Outside Air Reset",
                                    "Scheduled",
                                    "Warmest Reset",
                                    "Other",
                                    "Unknown"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:FanBasedDistributionType": {
                              "additionalProperties": false,
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:CentralAirDistribution": {
                                  "additionalProperties": false,
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "auc:AirDeliveryType": {
                                      "additionalProperties": false,
                                      "description": "Method for delivering air for heating and cooling to the zone.",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "Central fan",
                                            "Induction units",
                                            "Low pressure under floor",
                                            "Local fan",
                                            "Other",
                                            "Unknown"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:ReheatControlMethod": {
                                      "additionalProperties": false,
                                      "description": "The air/temperature control strategy for VAV systems with reheat boxes.",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "Dual Maximum",
                                            "Single Maximum",
                                            "Other",
                                            "Unknown"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:ReheatPlantID": {
                                      "additionalProperties": false,
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "$ref": "#/definitions/xs:IDREF"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:ReheatSource": {
                                      "additionalProperties": false,
                                      "description": "Energy source used to provide reheat energy at a terminal unit.",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "Heating plant",
                                            "Local electric resistance",
                                            "Local gas",
                                            "Other",
                                            "Unknown"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:TerminalUnit": {
                                      "additionalProperties": false,
                                      "description": "Type of terminal unit serving each zone of a central air distribution system.",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "CAV terminal box with reheat",
                                            "VAV terminal box fan powered no reheat",
                                            "VAV terminal box fan powered with reheat",
                                            "VAV terminal box not fan powered no reheat",
                                            "VAV terminal box not fan powered with reheat",
                                            "Automatically controlled register",
                                            "Manually controlled register",
                                            "Uncontrolled register",
                                            "Other",
                                            "Unknown"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:FanCoil": {
                                  "additionalProperties": false,
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "auc:FanCoilType": {
                                      "additionalProperties": false,
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "Mini-split",
                                            "Multi-split",
                                            "Terminal reheat",
                                            "Fan coil 2 pipe",
                                            "Fan coil 4 pipe",
                                            "VRF terminal units",
                                            "Other",
                                            "Unknown"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:HVACPipeConfiguration": {
                                      "additionalProperties": false,
                                      "description": "Number of pipes for distributing steam, refrigerant, or water to individual zones.",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "1 pipe",
                                            "2 pipe",
                                            "3 pipe",
                                            "4 pipe",
                                            "Other",
                                            "Unknown"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:PipeInsulationThickness": {
                                      "additionalProperties": false,
                                      "description": "Defines how thick insulation on pipes in a heating, cooling, water heating system is. (in.)",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "$ref": "#/definitions/xs:decimal"
                                        },
                                        "@auc:Source": {
                                          "enum": [
                                            "Default",
                                            "Estimate",
                                            "Agent",
                                            "Assessor",
                                            "Auditor",
                                            "Product specification",
                                            "Building Component Library",
                                            "Utility transfer",
                                            "Energy Management System",
                                            "Direct measurement",
                                            "Design files",
                                            "Simulation",
                                            "ENERGY STAR Portfolio Manager",
                                            "US EPA",
                                            "US EIA",
                                            "Target Finder",
                                            "Arch2030",
                                            "ASHRAE",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "auc:PipeLocation": {
                                      "additionalProperties": false,
                                      "description": "Percent of pipe length in conditioned space. (0-1) (%)",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "$ref": "#/definitions/xs:decimal"
                                        },
                                        "@auc:Source": {
                                          "enum": [
                                            "Default",
                                            "Estimate",
                                            "Agent",
                                            "Assessor",
                                            "Auditor",
                                            "Product specification",
                                            "Building Component Library",
                                            "Utility transfer",
                                            "Energy Management System",
                                            "Direct measurement",
                                            "Design files",
                                            "Simulation",
                                            "ENERGY STAR Portfolio Manager",
                                            "US EPA",
                                            "US EIA",
                                            "Target Finder",
                                            "Arch2030",
                                            "ASHRAE",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    }
                                  },
                                  "type": "object"
                                }
                              },
                              "type": "object"
                            },
                            "auc:HeatingSupplyAirTemperature": {
                              "additionalProperties": false,
                              "description": "Temperature setting of supply air for heating or cooling. (°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:HeatingSupplyAirTemperatureControl": {
                              "additionalProperties": false,
                              "description": "Defines the control method for heating supply air temperature.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Coldest Reset",
                                    "Fixed",
                                    "Outside Air Reset",
                                    "Scheduled",
                                    "Staged Setpoint",
                                    "Other",
                                    "Unknown"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:OutsideAirResetMaximumCoolingSupplyTemperature": {
                              "additionalProperties": false,
                              "description": "Maximum temperature setting of supply air for cooling during outside air reset. (°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:OutsideAirResetMaximumHeatingSupplyTemperature": {
                              "additionalProperties": false,
                              "description": "Maximum temperature setting of supply air for heating during outside air reset. (°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:OutsideAirResetMinimumCoolingSupplyTemperature": {
                              "additionalProperties": false,
                              "description": "Minimum temperature setting of supply air for cooling during outside air reset. (°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:OutsideAirResetMinimumHeatingSupplyTemperature": {
                              "additionalProperties": false,
                              "description": "Minimum temperature setting of supply air for heating during outside air reset. (°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:OutsideAirTemperatureLowerLimitCoolingResetControl": {
                              "additionalProperties": false,
                              "description": "Minimum outside air temperature where supply air temperature is reset for cooling. (°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:OutsideAirTemperatureLowerLimitHeatingResetControl": {
                              "additionalProperties": false,
                              "description": "Minimum outside air temperature where supply air temperature is reset for heating. (°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:OutsideAirTemperatureUpperLimitCoolingResetControl": {
                              "additionalProperties": false,
                              "description": "Maximum outside air temperature where supply air temperature is reset for cooling. (°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:OutsideAirTemperatureUpperLimitHeatingResetControl": {
                              "additionalProperties": false,
                              "description": "Maximum outside air temperature where supply air temperature is reset for heating. (°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:StaticPressureResetControl": {
                              "additionalProperties": false,
                              "description": "True if duct static pressure can be reset to keep it only as high as is needed to satisfy the neediest zone, false otherwise.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:boolean"
                                }
                              },
                              "type": "object"
                            },
                            "auc:SupplyAirTemperatureResetControl": {
                              "additionalProperties": false,
                              "description": "True if the supply-air-temperature set point can be reset based on the outside air temperature, false otherwise.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:boolean"
                                }
                              },
                              "type": "object"
                            }
                          },
                          "type": "object"
                        },
                        "auc:Other": {
                          "$ref": "#/definitions/xs:anyType"
                        },
                        "auc:Radiant": {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:PipeInsulationThickness": {
                              "additionalProperties": false,
                              "description": "Defines how thick insulation on pipes in a heating, cooling, water heating system is. (in.)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:PipeLocation": {
                              "additionalProperties": false,
                              "description": "Percent of pipe length in conditioned space. (0-1) (%)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:RadiantType": {
                              "additionalProperties": false,
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Radiator",
                                    "Radiant floor or ceiling",
                                    "Other",
                                    "Unknown"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    },
                    "auc:HeatingSourceID": {
                      "additionalProperties": false,
                      "description": "ID number of heating system associated with this delivery mechanism.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    "auc:Manufacturer": {
                      "$ref": "#/definitions/.auc:Manufacturer"
                    },
                    "auc:ModelNumber": {
                      "$ref": "#/definitions/.auc:ModelNumber"
                    },
                    "auc:PrimaryFuel": {
                      "$ref": "#/definitions/.auc:PrimaryFuel"
                    },
                    "auc:Quantity": {
                      "$ref": "#/definitions/.auc:Quantity"
                    },
                    "auc:ThirdPartyCertification": {
                      "$ref": "#/definitions/.auc:ThirdPartyCertification"
                    },
                    "auc:YearInstalled": {
                      "$ref": "#/definitions/.auc:YearInstalled"
                    },
                    "auc:YearofManufacture": {
                      "$ref": "#/definitions/.auc:YearofManufacture"
                    }
                  },
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@ID": {
                        "$ref": "#/definitions/xs:ID"
                      },
                      "@auc:Status": {
                        "enum": [
                          "Existing",
                          "Proposed",
                          "Past",
                          "Reference",
                          "Other"
                        ],
                        "type": "string"
                      },
                      "auc:Capacity": {
                        "$ref": "#/definitions/.auc:Capacity"
                      },
                      "auc:CapacityUnits": {
                        "$ref": "#/definitions/.auc:CapacityUnits"
                      },
                      "auc:ControlTechnology": {
                        "$ref": "#/definitions/.auc:ControlTechnology"
                      },
                      "auc:CoolingSourceID": {
                        "additionalProperties": false,
                        "description": "ID number of cooling system associated with this delivery mechanism.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "auc:DeliveryType": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:Convection": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:ConvectionType": {
                                "additionalProperties": false,
                                "description": "Type of convection equipment used for heating and cooling at the zone.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Perimeter baseboard",
                                      "Chilled beam",
                                      "Other",
                                      "Unknown"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:PipeInsulationThickness": {
                                "additionalProperties": false,
                                "description": "Defines how thick insulation on pipes in a heating, cooling, water heating system is. (in.)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:PipeLocation": {
                                "additionalProperties": false,
                                "description": "Percent of pipe length in conditioned space. (0-1) (%)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          },
                          "auc:FanBased": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:AirSideEconomizer": {
                                "additionalProperties": false,
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@ID": {
                                    "$ref": "#/definitions/xs:ID"
                                  },
                                  "@auc:Status": {
                                    "enum": [
                                      "Existing",
                                      "Proposed",
                                      "Past",
                                      "Reference",
                                      "Other"
                                    ],
                                    "type": "string"
                                  },
                                  "auc:AirSideEconomizerType": {
                                    "additionalProperties": false,
                                    "description": "Type of air economizer system associated with a cooling system.",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "enum": [
                                          "Dry bulb temperature",
                                          "Enthalpy",
                                          "Demand controlled ventilation",
                                          "Nonintegrated",
                                          "Other",
                                          "Unknown"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:EconomizerControl": {
                                    "additionalProperties": false,
                                    "description": "Logic used for economizer control.",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "enum": [
                                          "Fixed",
                                          "Differential",
                                          "Other",
                                          "Unknown"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:EconomizerDryBulbControlPoint": {
                                    "additionalProperties": false,
                                    "description": "Dry bulb temperature setting for use of economizer for cooling (fixed or differential) (°F)",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "$ref": "#/definitions/xs:decimal"
                                      },
                                      "@auc:Source": {
                                        "enum": [
                                          "Default",
                                          "Estimate",
                                          "Agent",
                                          "Assessor",
                                          "Auditor",
                                          "Product specification",
                                          "Building Component Library",
                                          "Utility transfer",
                                          "Energy Management System",
                                          "Direct measurement",
                                          "Design files",
                                          "Simulation",
                                          "ENERGY STAR Portfolio Manager",
                                          "US EPA",
                                          "US EIA",
                                          "Target Finder",
                                          "Arch2030",
                                          "ASHRAE",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:EconomizerEnthalpyControlPoint": {
                                    "additionalProperties": false,
                                    "description": "Maximum enthalpy setting for use of economizer for cooling (fixed or differential) (Btu/lb)",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "$ref": "#/definitions/xs:decimal"
                                      },
                                      "@auc:Source": {
                                        "enum": [
                                          "Default",
                                          "Estimate",
                                          "Agent",
                                          "Assessor",
                                          "Auditor",
                                          "Product specification",
                                          "Building Component Library",
                                          "Utility transfer",
                                          "Energy Management System",
                                          "Direct measurement",
                                          "Design files",
                                          "Simulation",
                                          "ENERGY STAR Portfolio Manager",
                                          "US EPA",
                                          "US EIA",
                                          "Target Finder",
                                          "Arch2030",
                                          "ASHRAE",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:EconomizerLowTemperatureLockout": {
                                    "additionalProperties": false,
                                    "description": "The outside air temperature below which the economizer will return to the minimum position. (°F)",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "$": {
                                        "$ref": "#/definitions/xs:decimal"
                                      },
                                      "@auc:Source": {
                                        "enum": [
                                          "Default",
                                          "Estimate",
                                          "Agent",
                                          "Assessor",
                                          "Auditor",
                                          "Product specification",
                                          "Building Component Library",
                                          "Utility transfer",
                                          "Energy Management System",
                                          "Direct measurement",
                                          "Design files",
                                          "Simulation",
                                          "ENERGY STAR Portfolio Manager",
                                          "US EPA",
                                          "US EIA",
                                          "Target Finder",
                                          "Arch2030",
                                          "ASHRAE",
                                          "Other"
                                        ],
                                        "type": "string"
                                      }
                                    },
                                    "type": "object"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:CoolingSupplyAirTemperature": {
                                "additionalProperties": false,
                                "description": "Temperature setting of supply air for cooling under normal conditions. (°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:CoolingSupplyAirTemperatureControlType": {
                                "additionalProperties": false,
                                "description": "Defines the control method for controlling cooling supply air temperature.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Fixed",
                                      "Outside Air Reset",
                                      "Scheduled",
                                      "Warmest Reset",
                                      "Other",
                                      "Unknown"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:FanBasedDistributionType": {
                                "additionalProperties": false,
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "auc:CentralAirDistribution": {
                                    "additionalProperties": false,
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "auc:AirDeliveryType": {
                                        "additionalProperties": false,
                                        "description": "Method for delivering air for heating and cooling to the zone.",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Central fan",
                                              "Induction units",
                                              "Low pressure under floor",
                                              "Local fan",
                                              "Other",
                                              "Unknown"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "auc:ReheatControlMethod": {
                                        "additionalProperties": false,
                                        "description": "The air/temperature control strategy for VAV systems with reheat boxes.",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Dual Maximum",
                                              "Single Maximum",
                                              "Other",
                                              "Unknown"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "auc:ReheatPlantID": {
                                        "additionalProperties": false,
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:IDREF"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "auc:ReheatSource": {
                                        "additionalProperties": false,
                                        "description": "Energy source used to provide reheat energy at a terminal unit.",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Heating plant",
                                              "Local electric resistance",
                                              "Local gas",
                                              "Other",
                                              "Unknown"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "auc:TerminalUnit": {
                                        "additionalProperties": false,
                                        "description": "Type of terminal unit serving each zone of a central air distribution system.",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "CAV terminal box with reheat",
                                              "VAV terminal box fan powered no reheat",
                                              "VAV terminal box fan powered with reheat",
                                              "VAV terminal box not fan powered no reheat",
                                              "VAV terminal box not fan powered with reheat",
                                              "Automatically controlled register",
                                              "Manually controlled register",
                                              "Uncontrolled register",
                                              "Other",
                                              "Unknown"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "auc:FanCoil": {
                                    "additionalProperties": false,
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "auc:FanCoilType": {
                                        "additionalProperties": false,
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Mini-split",
                                              "Multi-split",
                                              "Terminal reheat",
                                              "Fan coil 2 pipe",
                                              "Fan coil 4 pipe",
                                              "VRF terminal units",
                                              "Other",
                                              "Unknown"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "auc:HVACPipeConfiguration": {
                                        "additionalProperties": false,
                                        "description": "Number of pipes for distributing steam, refrigerant, or water to individual zones.",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "1 pipe",
                                              "2 pipe",
                                              "3 pipe",
                                              "4 pipe",
                                              "Other",
                                              "Unknown"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "auc:PipeInsulationThickness": {
                                        "additionalProperties": false,
                                        "description": "Defines how thick insulation on pipes in a heating, cooling, water heating system is. (in.)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "auc:PipeLocation": {
                                        "additionalProperties": false,
                                        "description": "Percent of pipe length in conditioned space. (0-1) (%)",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "$ref": "#/definitions/xs:decimal"
                                          },
                                          "@auc:Source": {
                                            "enum": [
                                              "Default",
                                              "Estimate",
                                              "Agent",
                                              "Assessor",
                                              "Auditor",
                                              "Product specification",
                                              "Building Component Library",
                                              "Utility transfer",
                                              "Energy Management System",
                                              "Direct measurement",
                                              "Design files",
                                              "Simulation",
                                              "ENERGY STAR Portfolio Manager",
                                              "US EPA",
                                              "US EIA",
                                              "Target Finder",
                                              "Arch2030",
                                              "ASHRAE",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      }
                                    },
                                    "type": "object"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:HeatingSupplyAirTemperature": {
                                "additionalProperties": false,
                                "description": "Temperature setting of supply air for heating or cooling. (°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:HeatingSupplyAirTemperatureControl": {
                                "additionalProperties": false,
                                "description": "Defines the control method for heating supply air temperature.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Coldest Reset",
                                      "Fixed",
                                      "Outside Air Reset",
                                      "Scheduled",
                                      "Staged Setpoint",
                                      "Other",
                                      "Unknown"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:OutsideAirResetMaximumCoolingSupplyTemperature": {
                                "additionalProperties": false,
                                "description": "Maximum temperature setting of supply air for cooling during outside air reset. (°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:OutsideAirResetMaximumHeatingSupplyTemperature": {
                                "additionalProperties": false,
                                "description": "Maximum temperature setting of supply air for heating during outside air reset. (°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:OutsideAirResetMinimumCoolingSupplyTemperature": {
                                "additionalProperties": false,
                                "description": "Minimum temperature setting of supply air for cooling during outside air reset. (°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:OutsideAirResetMinimumHeatingSupplyTemperature": {
                                "additionalProperties": false,
                                "description": "Minimum temperature setting of supply air for heating during outside air reset. (°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:OutsideAirTemperatureLowerLimitCoolingResetControl": {
                                "additionalProperties": false,
                                "description": "Minimum outside air temperature where supply air temperature is reset for cooling. (°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:OutsideAirTemperatureLowerLimitHeatingResetControl": {
                                "additionalProperties": false,
                                "description": "Minimum outside air temperature where supply air temperature is reset for heating. (°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:OutsideAirTemperatureUpperLimitCoolingResetControl": {
                                "additionalProperties": false,
                                "description": "Maximum outside air temperature where supply air temperature is reset for cooling. (°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:OutsideAirTemperatureUpperLimitHeatingResetControl": {
                                "additionalProperties": false,
                                "description": "Maximum outside air temperature where supply air temperature is reset for heating. (°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:StaticPressureResetControl": {
                                "additionalProperties": false,
                                "description": "True if duct static pressure can be reset to keep it only as high as is needed to satisfy the neediest zone, false otherwise.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:boolean"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:SupplyAirTemperatureResetControl": {
                                "additionalProperties": false,
                                "description": "True if the supply-air-temperature set point can be reset based on the outside air temperature, false otherwise.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:boolean"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          },
                          "auc:Other": {
                            "$ref": "#/definitions/xs:anyType"
                          },
                          "auc:Radiant": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:PipeInsulationThickness": {
                                "additionalProperties": false,
                                "description": "Defines how thick insulation on pipes in a heating, cooling, water heating system is. (in.)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:PipeLocation": {
                                "additionalProperties": false,
                                "description": "Percent of pipe length in conditioned space. (0-1) (%)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:RadiantType": {
                                "additionalProperties": false,
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Radiator",
                                      "Radiant floor or ceiling",
                                      "Other",
                                      "Unknown"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          }
                        },
                        "type": "object"
                      },
                      "auc:HeatingSourceID": {
                        "additionalProperties": false,
                        "description": "ID number of heating system associated with this delivery mechanism.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "auc:Manufacturer": {
                        "$ref": "#/definitions/.auc:Manufacturer"
                      },
                      "auc:ModelNumber": {
                        "$ref": "#/definitions/.auc:ModelNumber"
                      },
                      "auc:PrimaryFuel": {
                        "$ref": "#/definitions/.auc:PrimaryFuel"
                      },
                      "auc:Quantity": {
                        "$ref": "#/definitions/.auc:Quantity"
                      },
                      "auc:ThirdPartyCertification": {
                        "$ref": "#/definitions/.auc:ThirdPartyCertification"
                      },
                      "auc:YearInstalled": {
                        "$ref": "#/definitions/.auc:YearInstalled"
                      },
                      "auc:YearofManufacture": {
                        "$ref": "#/definitions/.auc:YearofManufacture"
                      }
                    },
                    "type": "object"
                  },
                  "type": "array"
                }
              ]
            },
            "auc:HeatingSource": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@ID": {
                      "$ref": "#/definitions/xs:ID"
                    },
                    "@auc:Status": {
                      "enum": [
                        "Existing",
                        "Proposed",
                        "Past",
                        "Reference",
                        "Other"
                      ],
                      "type": "string"
                    },
                    "auc:AnnualHeatingEfficiencyUnit": {
                      "$ref": "#/definitions/.auc:AnnualHeatingEfficiencyUnit"
                    },
                    "auc:AnnualHeatingEfficiencyValue": {
                      "additionalProperties": false,
                      "description": "Overall annual efficiency of a heating system",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:CapacityUnits": {
                      "$ref": "#/definitions/.auc:CapacityUnits"
                    },
                    "auc:ControlTechnology": {
                      "$ref": "#/definitions/.auc:ControlTechnology"
                    },
                    "auc:HeatingMedium": {
                      "additionalProperties": false,
                      "description": "Medium used to transport heat from a central heating system to individual zones.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "Hot water",
                            "Steam",
                            "Refrigerant",
                            "Air",
                            "Glycol",
                            "Other",
                            "Unknown"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:HeatingSourceType": {
                      "additionalProperties": false,
                      "description": "Source of energy used for heating the zone.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:Furnace": {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:BurnerTurndownRatio": {
                              "additionalProperties": false,
                              "description": "If applicable, the turndown ratio for the burner (full input/minimum input).",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:BurnerType": {
                              "$ref": "#/definitions/.auc:BurnerType"
                            },
                            "auc:CombustionEfficiency": {
                              "additionalProperties": false,
                              "description": "The measure of how much energy is extracted from the fuel and is the ratio of heat transferred to the combustion air divided by the heat input of the fuel. (0-1) (%)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:CondensingOperation": {
                              "additionalProperties": false,
                              "description": "True if the boiler or furnace is capable of condensing the water vapor in the exhaust flue gas to obtain a higher efficiency.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:boolean"
                                }
                              },
                              "type": "object"
                            },
                            "auc:DraftType": {
                              "$ref": "#/definitions/.auc:DraftType"
                            },
                            "auc:FurnaceType": {
                              "additionalProperties": false,
                              "description": "General type of furnace used for space heating.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Warm air",
                                    "Fireplace",
                                    "Heating stove",
                                    "Built-in heater",
                                    "Individual space heater",
                                    "Other",
                                    "Unknown"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:IgnitionType": {
                              "$ref": "#/definitions/.auc:IgnitionType"
                            },
                            "auc:ThermalEfficiency": {
                              "additionalProperties": false,
                              "description": "The efficiency of heat transfer between the combustion process and the heated steam, water, or air. (0-1) (%)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:ThirdPartyCertification": {
                              "$ref": "#/definitions/.auc:ThirdPartyCertification"
                            }
                          },
                          "type": "object"
                        },
                        "auc:HeatPump": {
                          "additionalProperties": false,
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:CoolingSourceID": {
                              "additionalProperties": false,
                              "description": "ID number of the cooling system with the cooling mode performance characteristics of this heat pump.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            "auc:HeatPumpBackupAFUE": {
                              "additionalProperties": false,
                              "description": "Annual Fuel Utilization Efficiency of backup system for heat pump",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:HeatPumpBackupHeatingSwitchoverTemperature": {
                              "additionalProperties": false,
                              "description": "Minimum outside temperature at which the heat pump can operate. (°F)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:HeatPumpBackupSystemFuel": {
                              "additionalProperties": false,
                              "description": "Backup fuel used by the heat pump",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/auc:FuelTypes"
                                }
                              },
                              "type": "object"
                            },
                            "auc:HeatPumpType": {
                              "additionalProperties": false,
                              "description": "General type of heat pump used for space heating.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Split",
                                    "Packaged Terminal",
                                    "Packaged Unitary",
                                    "Other",
                                    "Unknown"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:LinkedHeatingPlantID": {
                              "additionalProperties": false,
                              "description": "ID number of heating plant serving as the source for this heat pump.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "@IDref": {
                                  "$ref": "#/definitions/xs:IDREF"
                                }
                              },
                              "required": [
                                "@IDref"
                              ],
                              "type": "object"
                            },
                            "auc:ThirdPartyCertification": {
                              "$ref": "#/definitions/.auc:ThirdPartyCertification"
                            }
                          },
                          "type": "object"
                        },
                        "auc:NoHeating": {
                          "$ref": "#/definitions/xs:anyType"
                        },
                        "auc:OtherCombination": {
                          "$ref": "#/definitions/xs:anyType"
                        },
                        "auc:SourceHeatingPlantID": {
                          "additionalProperties": false,
                          "description": "ID number of heating plant serving as the source for this zonal system.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "@IDref": {
                              "$ref": "#/definitions/xs:IDREF"
                            }
                          },
                          "required": [
                            "@IDref"
                          ],
                          "type": "object"
                        },
                        "auc:Unknown": {
                          "$ref": "#/definitions/xs:anyType"
                        }
                      },
                      "type": "object"
                    },
                    "auc:HeatingStageCapacityFraction": {
                      "additionalProperties": false,
                      "description": "Average capacity of each heating stage, at ARI rated conditions, expressed as a fraction of total capacity. (0-1) (%)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:HeatingStaging": {
                      "$ref": "#/definitions/.auc:HeatingStaging"
                    },
                    "auc:InputCapacity": {
                      "additionalProperties": false,
                      "description": "The rate of energy consumption of the heating equipment at full load.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:Manufacturer": {
                      "$ref": "#/definitions/.auc:Manufacturer"
                    },
                    "auc:ModelNumber": {
                      "$ref": "#/definitions/.auc:ModelNumber"
                    },
                    "auc:NumberOfHeatingStages": {
                      "additionalProperties": false,
                      "description": "The number of heating stages, excluding \"off.\"",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:integer"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:OutputCapacity": {
                      "additionalProperties": false,
                      "description": "Output capacity of equipment.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:PrimaryFuel": {
                      "$ref": "#/definitions/.auc:PrimaryFuel"
                    },
                    "auc:Quantity": {
                      "$ref": "#/definitions/.auc:Quantity"
                    },
                    "auc:YearInstalled": {
                      "$ref": "#/definitions/.auc:YearInstalled"
                    },
                    "auc:YearofManufacture": {
                      "$ref": "#/definitions/.auc:YearofManufacture"
                    }
                  },
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@ID": {
                        "$ref": "#/definitions/xs:ID"
                      },
                      "@auc:Status": {
                        "enum": [
                          "Existing",
                          "Proposed",
                          "Past",
                          "Reference",
                          "Other"
                        ],
                        "type": "string"
                      },
                      "auc:AnnualHeatingEfficiencyUnit": {
                        "$ref": "#/definitions/.auc:AnnualHeatingEfficiencyUnit"
                      },
                      "auc:AnnualHeatingEfficiencyValue": {
                        "additionalProperties": false,
                        "description": "Overall annual efficiency of a heating system",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:CapacityUnits": {
                        "$ref": "#/definitions/.auc:CapacityUnits"
                      },
                      "auc:ControlTechnology": {
                        "$ref": "#/definitions/.auc:ControlTechnology"
                      },
                      "auc:HeatingMedium": {
                        "additionalProperties": false,
                        "description": "Medium used to transport heat from a central heating system to individual zones.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "enum": [
                              "Hot water",
                              "Steam",
                              "Refrigerant",
                              "Air",
                              "Glycol",
                              "Other",
                              "Unknown"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:HeatingSourceType": {
                        "additionalProperties": false,
                        "description": "Source of energy used for heating the zone.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:Furnace": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:BurnerTurndownRatio": {
                                "additionalProperties": false,
                                "description": "If applicable, the turndown ratio for the burner (full input/minimum input).",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:BurnerType": {
                                "$ref": "#/definitions/.auc:BurnerType"
                              },
                              "auc:CombustionEfficiency": {
                                "additionalProperties": false,
                                "description": "The measure of how much energy is extracted from the fuel and is the ratio of heat transferred to the combustion air divided by the heat input of the fuel. (0-1) (%)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:CondensingOperation": {
                                "additionalProperties": false,
                                "description": "True if the boiler or furnace is capable of condensing the water vapor in the exhaust flue gas to obtain a higher efficiency.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:boolean"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:DraftType": {
                                "$ref": "#/definitions/.auc:DraftType"
                              },
                              "auc:FurnaceType": {
                                "additionalProperties": false,
                                "description": "General type of furnace used for space heating.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Warm air",
                                      "Fireplace",
                                      "Heating stove",
                                      "Built-in heater",
                                      "Individual space heater",
                                      "Other",
                                      "Unknown"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:IgnitionType": {
                                "$ref": "#/definitions/.auc:IgnitionType"
                              },
                              "auc:ThermalEfficiency": {
                                "additionalProperties": false,
                                "description": "The efficiency of heat transfer between the combustion process and the heated steam, water, or air. (0-1) (%)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:ThirdPartyCertification": {
                                "$ref": "#/definitions/.auc:ThirdPartyCertification"
                              }
                            },
                            "type": "object"
                          },
                          "auc:HeatPump": {
                            "additionalProperties": false,
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:CoolingSourceID": {
                                "additionalProperties": false,
                                "description": "ID number of the cooling system with the cooling mode performance characteristics of this heat pump.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              "auc:HeatPumpBackupAFUE": {
                                "additionalProperties": false,
                                "description": "Annual Fuel Utilization Efficiency of backup system for heat pump",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:HeatPumpBackupHeatingSwitchoverTemperature": {
                                "additionalProperties": false,
                                "description": "Minimum outside temperature at which the heat pump can operate. (°F)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:HeatPumpBackupSystemFuel": {
                                "additionalProperties": false,
                                "description": "Backup fuel used by the heat pump",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/auc:FuelTypes"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:HeatPumpType": {
                                "additionalProperties": false,
                                "description": "General type of heat pump used for space heating.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Split",
                                      "Packaged Terminal",
                                      "Packaged Unitary",
                                      "Other",
                                      "Unknown"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "auc:LinkedHeatingPlantID": {
                                "additionalProperties": false,
                                "description": "ID number of heating plant serving as the source for this heat pump.",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "@IDref": {
                                    "$ref": "#/definitions/xs:IDREF"
                                  }
                                },
                                "required": [
                                  "@IDref"
                                ],
                                "type": "object"
                              },
                              "auc:ThirdPartyCertification": {
                                "$ref": "#/definitions/.auc:ThirdPartyCertification"
                              }
                            },
                            "type": "object"
                          },
                          "auc:NoHeating": {
                            "$ref": "#/definitions/xs:anyType"
                          },
                          "auc:OtherCombination": {
                            "$ref": "#/definitions/xs:anyType"
                          },
                          "auc:SourceHeatingPlantID": {
                            "additionalProperties": false,
                            "description": "ID number of heating plant serving as the source for this zonal system.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "@IDref": {
                                "$ref": "#/definitions/xs:IDREF"
                              }
                            },
                            "required": [
                              "@IDref"
                            ],
                            "type": "object"
                          },
                          "auc:Unknown": {
                            "$ref": "#/definitions/xs:anyType"
                          }
                        },
                        "type": "object"
                      },
                      "auc:HeatingStageCapacityFraction": {
                        "additionalProperties": false,
                        "description": "Average capacity of each heating stage, at ARI rated conditions, expressed as a fraction of total capacity. (0-1) (%)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:HeatingStaging": {
                        "$ref": "#/definitions/.auc:HeatingStaging"
                      },
                      "auc:InputCapacity": {
                        "additionalProperties": false,
                        "description": "The rate of energy consumption of the heating equipment at full load.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:Manufacturer": {
                        "$ref": "#/definitions/.auc:Manufacturer"
                      },
                      "auc:ModelNumber": {
                        "$ref": "#/definitions/.auc:ModelNumber"
                      },
                      "auc:NumberOfHeatingStages": {
                        "additionalProperties": false,
                        "description": "The number of heating stages, excluding \"off.\"",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:integer"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:OutputCapacity": {
                        "additionalProperties": false,
                        "description": "Output capacity of equipment.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:PrimaryFuel": {
                        "$ref": "#/definitions/.auc:PrimaryFuel"
                      },
                      "auc:Quantity": {
                        "$ref": "#/definitions/.auc:Quantity"
                      },
                      "auc:YearInstalled": {
                        "$ref": "#/definitions/.auc:YearInstalled"
                      },
                      "auc:YearofManufacture": {
                        "$ref": "#/definitions/.auc:YearofManufacture"
                      }
                    },
                    "type": "object"
                  },
                  "type": "array"
                }
              ]
            },
            "auc:ZoningSystemType": {
              "additionalProperties": false,
              "description": "Identifies whether a system is single or multi-zone.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "enum": [
                    "Single zone",
                    "Multi zone",
                    "Unknown"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:OtherHVACSystems": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:OtherHVACSystem": {
              "anyOf": [
                {
                  "$ref": "#/definitions/auc:OtherHVACSystemType"
                },
                {
                  "items": {
                    "$ref": "#/definitions/auc:OtherHVACSystemType"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:OtherHVACSystem"
          ],
          "type": "object"
        },
        "auc:Plants": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:CondenserPlant": {
              "anyOf": [
                {
                  "$ref": "#/definitions/auc:CondenserPlantType",
                  "description": "Type of condenser used for refrigerant-based systems."
                },
                {
                  "items": {
                    "$ref": "#/definitions/auc:CondenserPlantType",
                    "description": "Type of condenser used for refrigerant-based systems."
                  },
                  "type": "array"
                }
              ]
            },
            "auc:CoolingPlantType": {
              "anyOf": [
                {
                  "$ref": "#/definitions/auc:CoolingPlantTypeType",
                  "description": "Type of cooling plant. Zonal cooling is recorded in a separate data field. Use of fans or blowers by themselves without chilled air or water is not included in this definition of cooling. Stand-alone dehumidifiers are also not included."
                },
                {
                  "items": {
                    "$ref": "#/definitions/auc:CoolingPlantTypeType",
                    "description": "Type of cooling plant. Zonal cooling is recorded in a separate data field. Use of fans or blowers by themselves without chilled air or water is not included in this definition of cooling. Stand-alone dehumidifiers are also not included."
                  },
                  "type": "array"
                }
              ]
            },
            "auc:HeatingPlantType": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "description": "Type of central heating system, defined as any source of heating energy separate from the zone being heated. Local heating systems (such as packaged systems and fan-coils) are recorded in a separate data field.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@ID": {
                      "$ref": "#/definitions/xs:ID"
                    },
                    "@auc:Status": {
                      "enum": [
                        "Existing",
                        "Proposed",
                        "Past",
                        "Reference",
                        "Other"
                      ],
                      "type": "string"
                    },
                    "auc:Boiler": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:AnnualHeatingEfficiencyUnit": {
                          "$ref": "#/definitions/.auc:AnnualHeatingEfficiencyUnit"
                        },
                        "auc:AnnualHeatingEfficiencyValue": {
                          "additionalProperties": false,
                          "description": "Overall annual efficiency of a heating system",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:BoilerEWT": {
                          "additionalProperties": false,
                          "description": "Temperature of water returning to the equipment. [°F]",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:BoilerInsulationRValue": {
                          "additionalProperties": false,
                          "description": "Insulation R-Value of hot water storage tank. (hr-ft2-F/Btu)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:BoilerInsulationThickness": {
                          "additionalProperties": false,
                          "description": "Insulation thickness of hot water storage tank. [in.]",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:BoilerLWT": {
                          "additionalProperties": false,
                          "description": "The water temperature that the equipment supplies , such as the chilled water temperature setpoint for a chiller, or hot water temperature setpoint for water leaving a boiler. [°F]",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:BoilerPercentCondensateReturn": {
                          "additionalProperties": false,
                          "description": "The percentage of condensed steam that is returned to the boiler. (0-1) (%)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:BoilerType": {
                          "additionalProperties": false,
                          "description": "General type of boiler used for space or water heating",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "enum": [
                                "Steam",
                                "Hot water",
                                "Other",
                                "Unknown"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:BurnerTurndownRatio": {
                          "additionalProperties": false,
                          "description": "If applicable, the turndown ratio for the burner (full input/minimum input).",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:BurnerType": {
                          "$ref": "#/definitions/.auc:BurnerType"
                        },
                        "auc:CapacityUnits": {
                          "$ref": "#/definitions/.auc:CapacityUnits"
                        },
                        "auc:CombustionEfficiency": {
                          "additionalProperties": false,
                          "description": "The measure of how much energy is extracted from the fuel and is the ratio of heat transferred to the combustion air divided by the heat input of the fuel. (0-1) (%)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:CondensingOperation": {
                          "additionalProperties": false,
                          "description": "True if the boiler or furnace is capable of condensing the water vapor in the exhaust flue gas to obtain a higher efficiency.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:boolean"
                            }
                          },
                          "type": "object"
                        },
                        "auc:DraftType": {
                          "$ref": "#/definitions/.auc:DraftType"
                        },
                        "auc:HeatingStageCapacityFraction": {
                          "additionalProperties": false,
                          "description": "Average capacity of each heating stage, at ARI rated conditions, expressed as a fraction of total capacity. (0-1) (%)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:HeatingStaging": {
                          "$ref": "#/definitions/.auc:HeatingStaging"
                        },
                        "auc:HotWaterBoilerMaximumFlowRate": {
                          "additionalProperties": false,
                          "description": "The maximum flow rate of water that the boiler is designed to accept. [gpm]",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:HotWaterBoilerMinimumFlowRate": {
                          "additionalProperties": false,
                          "description": "The minimum flow rate of water required while the boiler is firing. [gpm]",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:HotWaterResetControl": {
                          "additionalProperties": false,
                          "description": "Times when the HVAC equipment is setback. For example, when the heat is lowered during the heating season, or the cooling setpoint increased during the cooling season.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "enum": [
                                "During the day",
                                "At night",
                                "During sleeping and unoccupied hours",
                                "Seasonal",
                                "Never-rarely",
                                "Other",
                                "Unknown"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:IgnitionType": {
                          "$ref": "#/definitions/.auc:IgnitionType"
                        },
                        "auc:InputCapacity": {
                          "additionalProperties": false,
                          "description": "The rate of energy consumption of the heating equipment at full load.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:NumberOfHeatingStages": {
                          "additionalProperties": false,
                          "description": "The number of heating stages, excluding \"off.\"",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:integer"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:OutputCapacity": {
                          "additionalProperties": false,
                          "description": "Output capacity of equipment.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:Priority": {
                          "$ref": "#/definitions/.auc:Priority"
                        },
                        "auc:Quantity": {
                          "$ref": "#/definitions/.auc:Quantity"
                        },
                        "auc:SteamBoilerMaximumOperatingPressure": {
                          "additionalProperties": false,
                          "description": "The maximum amount of steam pressure allowed during boiler operation. This should be input as gauge pressure. [psi]",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:SteamBoilerMinimumOperatingPressure": {
                          "additionalProperties": false,
                          "description": "The minimum amount of steam pressure required during boiler operation. This should be input as gauge pressure. [psi]",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:ThermalEfficiency": {
                          "additionalProperties": false,
                          "description": "The efficiency of heat transfer between the combustion process and the heated steam, water, or air. (0-1) (%)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:ThirdPartyCertification": {
                          "$ref": "#/definitions/.auc:ThirdPartyCertification"
                        }
                      },
                      "type": "object"
                    },
                    "auc:DistrictHeating": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:AnnualHeatingEfficiencyUnit": {
                          "$ref": "#/definitions/.auc:AnnualHeatingEfficiencyUnit"
                        },
                        "auc:AnnualHeatingEfficiencyValue": {
                          "additionalProperties": false,
                          "description": "Overall annual efficiency of a heating system",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:BoilerLWT": {
                          "additionalProperties": false,
                          "description": "The water temperature that the equipment supplies , such as the chilled water temperature setpoint for a chiller, or hot water temperature setpoint for water leaving a boiler. [°F]",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:CapacityUnits": {
                          "$ref": "#/definitions/.auc:CapacityUnits"
                        },
                        "auc:DistrictHeatingType": {
                          "additionalProperties": false,
                          "description": "General type of district heating used for space or water heating",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "enum": [
                                "Hot water",
                                "Direct steam",
                                "Steam to hot water heat exchanger",
                                "Other",
                                "Unknown"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:HotWaterBoilerMaximumFlowRate": {
                          "additionalProperties": false,
                          "description": "The maximum flow rate of water that the boiler is designed to accept. [gpm]",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:OutputCapacity": {
                          "additionalProperties": false,
                          "description": "Output capacity of equipment.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:Quantity": {
                          "$ref": "#/definitions/.auc:Quantity"
                        },
                        "auc:SteamBoilerMaximumOperatingPressure": {
                          "additionalProperties": false,
                          "description": "The maximum amount of steam pressure allowed during boiler operation. This should be input as gauge pressure. [psi]",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:SteamBoilerMinimumOperatingPressure": {
                          "additionalProperties": false,
                          "description": "The minimum amount of steam pressure required during boiler operation. This should be input as gauge pressure. [psi]",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    },
                    "auc:NoHeating": {
                      "$ref": "#/definitions/xs:anyType"
                    },
                    "auc:OtherCombination": {
                      "$ref": "#/definitions/xs:anyType"
                    },
                    "auc:SolarThermal": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:AnnualHeatingEfficiencyUnit": {
                          "$ref": "#/definitions/.auc:AnnualHeatingEfficiencyUnit"
                        },
                        "auc:AnnualHeatingEfficiencyValue": {
                          "additionalProperties": false,
                          "description": "Overall annual efficiency of a heating system",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:CapacityUnits": {
                          "$ref": "#/definitions/.auc:CapacityUnits"
                        },
                        "auc:OutputCapacity": {
                          "additionalProperties": false,
                          "description": "Output capacity of equipment.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:Quantity": {
                          "$ref": "#/definitions/.auc:Quantity"
                        }
                      },
                      "type": "object"
                    },
                    "auc:Unknown": {
                      "$ref": "#/definitions/xs:anyType"
                    }
                  },
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "description": "Type of central heating system, defined as any source of heating energy separate from the zone being heated. Local heating systems (such as packaged systems and fan-coils) are recorded in a separate data field.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@ID": {
                        "$ref": "#/definitions/xs:ID"
                      },
                      "@auc:Status": {
                        "enum": [
                          "Existing",
                          "Proposed",
                          "Past",
                          "Reference",
                          "Other"
                        ],
                        "type": "string"
                      },
                      "auc:Boiler": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:AnnualHeatingEfficiencyUnit": {
                            "$ref": "#/definitions/.auc:AnnualHeatingEfficiencyUnit"
                          },
                          "auc:AnnualHeatingEfficiencyValue": {
                            "additionalProperties": false,
                            "description": "Overall annual efficiency of a heating system",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:BoilerEWT": {
                            "additionalProperties": false,
                            "description": "Temperature of water returning to the equipment. [°F]",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:BoilerInsulationRValue": {
                            "additionalProperties": false,
                            "description": "Insulation R-Value of hot water storage tank. (hr-ft2-F/Btu)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:BoilerInsulationThickness": {
                            "additionalProperties": false,
                            "description": "Insulation thickness of hot water storage tank. [in.]",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:BoilerLWT": {
                            "additionalProperties": false,
                            "description": "The water temperature that the equipment supplies , such as the chilled water temperature setpoint for a chiller, or hot water temperature setpoint for water leaving a boiler. [°F]",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:BoilerPercentCondensateReturn": {
                            "additionalProperties": false,
                            "description": "The percentage of condensed steam that is returned to the boiler. (0-1) (%)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:BoilerType": {
                            "additionalProperties": false,
                            "description": "General type of boiler used for space or water heating",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "enum": [
                                  "Steam",
                                  "Hot water",
                                  "Other",
                                  "Unknown"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:BurnerTurndownRatio": {
                            "additionalProperties": false,
                            "description": "If applicable, the turndown ratio for the burner (full input/minimum input).",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:BurnerType": {
                            "$ref": "#/definitions/.auc:BurnerType"
                          },
                          "auc:CapacityUnits": {
                            "$ref": "#/definitions/.auc:CapacityUnits"
                          },
                          "auc:CombustionEfficiency": {
                            "additionalProperties": false,
                            "description": "The measure of how much energy is extracted from the fuel and is the ratio of heat transferred to the combustion air divided by the heat input of the fuel. (0-1) (%)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:CondensingOperation": {
                            "additionalProperties": false,
                            "description": "True if the boiler or furnace is capable of condensing the water vapor in the exhaust flue gas to obtain a higher efficiency.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:boolean"
                              }
                            },
                            "type": "object"
                          },
                          "auc:DraftType": {
                            "$ref": "#/definitions/.auc:DraftType"
                          },
                          "auc:HeatingStageCapacityFraction": {
                            "additionalProperties": false,
                            "description": "Average capacity of each heating stage, at ARI rated conditions, expressed as a fraction of total capacity. (0-1) (%)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:HeatingStaging": {
                            "$ref": "#/definitions/.auc:HeatingStaging"
                          },
                          "auc:HotWaterBoilerMaximumFlowRate": {
                            "additionalProperties": false,
                            "description": "The maximum flow rate of water that the boiler is designed to accept. [gpm]",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:HotWaterBoilerMinimumFlowRate": {
                            "additionalProperties": false,
                            "description": "The minimum flow rate of water required while the boiler is firing. [gpm]",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:HotWaterResetControl": {
                            "additionalProperties": false,
                            "description": "Times when the HVAC equipment is setback. For example, when the heat is lowered during the heating season, or the cooling setpoint increased during the cooling season.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "enum": [
                                  "During the day",
                                  "At night",
                                  "During sleeping and unoccupied hours",
                                  "Seasonal",
                                  "Never-rarely",
                                  "Other",
                                  "Unknown"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:IgnitionType": {
                            "$ref": "#/definitions/.auc:IgnitionType"
                          },
                          "auc:InputCapacity": {
                            "additionalProperties": false,
                            "description": "The rate of energy consumption of the heating equipment at full load.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:NumberOfHeatingStages": {
                            "additionalProperties": false,
                            "description": "The number of heating stages, excluding \"off.\"",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:integer"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:OutputCapacity": {
                            "additionalProperties": false,
                            "description": "Output capacity of equipment.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:Priority": {
                            "$ref": "#/definitions/.auc:Priority"
                          },
                          "auc:Quantity": {
                            "$ref": "#/definitions/.auc:Quantity"
                          },
                          "auc:SteamBoilerMaximumOperatingPressure": {
                            "additionalProperties": false,
                            "description": "The maximum amount of steam pressure allowed during boiler operation. This should be input as gauge pressure. [psi]",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:SteamBoilerMinimumOperatingPressure": {
                            "additionalProperties": false,
                            "description": "The minimum amount of steam pressure required during boiler operation. This should be input as gauge pressure. [psi]",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:ThermalEfficiency": {
                            "additionalProperties": false,
                            "description": "The efficiency of heat transfer between the combustion process and the heated steam, water, or air. (0-1) (%)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:ThirdPartyCertification": {
                            "$ref": "#/definitions/.auc:ThirdPartyCertification"
                          }
                        },
                        "type": "object"
                      },
                      "auc:DistrictHeating": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:AnnualHeatingEfficiencyUnit": {
                            "$ref": "#/definitions/.auc:AnnualHeatingEfficiencyUnit"
                          },
                          "auc:AnnualHeatingEfficiencyValue": {
                            "additionalProperties": false,
                            "description": "Overall annual efficiency of a heating system",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:BoilerLWT": {
                            "additionalProperties": false,
                            "description": "The water temperature that the equipment supplies , such as the chilled water temperature setpoint for a chiller, or hot water temperature setpoint for water leaving a boiler. [°F]",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:CapacityUnits": {
                            "$ref": "#/definitions/.auc:CapacityUnits"
                          },
                          "auc:DistrictHeatingType": {
                            "additionalProperties": false,
                            "description": "General type of district heating used for space or water heating",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "enum": [
                                  "Hot water",
                                  "Direct steam",
                                  "Steam to hot water heat exchanger",
                                  "Other",
                                  "Unknown"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:HotWaterBoilerMaximumFlowRate": {
                            "additionalProperties": false,
                            "description": "The maximum flow rate of water that the boiler is designed to accept. [gpm]",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:OutputCapacity": {
                            "additionalProperties": false,
                            "description": "Output capacity of equipment.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:Quantity": {
                            "$ref": "#/definitions/.auc:Quantity"
                          },
                          "auc:SteamBoilerMaximumOperatingPressure": {
                            "additionalProperties": false,
                            "description": "The maximum amount of steam pressure allowed during boiler operation. This should be input as gauge pressure. [psi]",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:SteamBoilerMinimumOperatingPressure": {
                            "additionalProperties": false,
                            "description": "The minimum amount of steam pressure required during boiler operation. This should be input as gauge pressure. [psi]",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          }
                        },
                        "type": "object"
                      },
                      "auc:NoHeating": {
                        "$ref": "#/definitions/xs:anyType"
                      },
                      "auc:OtherCombination": {
                        "$ref": "#/definitions/xs:anyType"
                      },
                      "auc:SolarThermal": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:AnnualHeatingEfficiencyUnit": {
                            "$ref": "#/definitions/.auc:AnnualHeatingEfficiencyUnit"
                          },
                          "auc:AnnualHeatingEfficiencyValue": {
                            "additionalProperties": false,
                            "description": "Overall annual efficiency of a heating system",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:CapacityUnits": {
                            "$ref": "#/definitions/.auc:CapacityUnits"
                          },
                          "auc:OutputCapacity": {
                            "additionalProperties": false,
                            "description": "Output capacity of equipment.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:Quantity": {
                            "$ref": "#/definitions/.auc:Quantity"
                          }
                        },
                        "type": "object"
                      },
                      "auc:Unknown": {
                        "$ref": "#/definitions/xs:anyType"
                      }
                    },
                    "type": "object"
                  },
                  "type": "array"
                }
              ]
            }
          },
          "type": "object"
        },
        "auc:PrimaryHVACControlStrategy": {
          "additionalProperties": false,
          "description": "Primary HVAC equipment control strategy.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Pneumatic",
                "Electric",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Priority": {
          "$ref": "#/definitions/.auc:Priority"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        }
      },
      "type": "object"
    },
    "auc:HeatRecoverySystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:EnergyRecoveryEfficiency": {
          "additionalProperties": false,
          "description": "The net total energy (sensible plus latent, also called enthalpy) recovered by the supply airstream adjusted by electric consumption, case heat loss or heat gain, air leakage and airflow mass imbalance between the two airstreams, as a percent of the potential total energy that could be recovered plus associated fan energy. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:HeatRecoveryEfficiency": {
          "additionalProperties": false,
          "description": "Efficiency of sensible heat recovery. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:HeatRecoveryType": {
          "additionalProperties": false,
          "description": "Type of heat recovery between two systems.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Run-around coil",
                "Thermal wheel",
                "Heat pipe",
                "Air to air heat exchanger",
                "Earth to air heat exchanger",
                "Earth to water heat exchanger",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:SystemIDProvidingHeat": {
          "additionalProperties": false,
          "description": "ID number of the system that usually provides heat to another system.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "@IDref": {
              "$ref": "#/definitions/xs:IDREF"
            }
          },
          "required": [
            "@IDref"
          ],
          "type": "object"
        },
        "auc:SystemIDReceivingHeat": {
          "additionalProperties": false,
          "description": "ID number of the system that usually receives heat from another system",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "@IDref": {
              "$ref": "#/definitions/xs:IDREF"
            }
          },
          "required": [
            "@IDref"
          ],
          "type": "object"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:InsulationMaterialType": {
      "enum": [
        "Fiberglass",
        "Cellulose",
        "EPS",
        "XPS",
        "Rock wool",
        "Insulsafe",
        "Recycled cotton",
        "ISOCY",
        "Icynene",
        "Other",
        "Unknown",
        "None"
      ],
      "type": "string"
    },
    "auc:LaundrySystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:LaundryEquipmentUsage": {
          "additionalProperties": false,
          "description": "Number of loads of laundry per week. (loads/wk)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LaundryType": {
          "additionalProperties": false,
          "description": "Type of laundry system.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:Combination": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ClothesWasherCapacity": {
                  "additionalProperties": false,
                  "description": "Volume of clothes washer tub. (ft3)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ClothesWasherClassification": {
                  "$ref": "#/definitions/.auc:ClothesWasherClassification"
                },
                "auc:ClothesWasherLoaderType": {
                  "$ref": "#/definitions/.auc:ClothesWasherLoaderType"
                },
                "auc:ClothesWasherModifiedEnergyFactor": {
                  "additionalProperties": false,
                  "description": "Modified Energy Factor, MEF, is the energy performance metric for ENERGY STAR qualified clothes washers and all clothes washers as of February 1, 2013. MEF is the quotient of the capacity of the clothes container, C, divided by the total clothes washer energy consumption per cycle, with such energy consumption expressed as the sum of the machine electrical energy consumption, M, the hot water energy consumption, E, and the energy required for removal of the remaining moisture in the wash load, D. The higher MEF, the more efficient the clothes washer. The equation is: MEF = C/(M + E + D).",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ClothesWasherWaterFactor": {
                  "additionalProperties": false,
                  "description": "Water Factor, WF, is the quotient of the total weighted per-cycle water consumption, Q, divided by the capacity of the clothes washer, C. The lower the value, the more water efficient the clothes washer is. The equation is: WF = Q/C. WF is the ENERGY STAR water performance metric that allows the comparison of clothes washer water consumption independent of clothes washer capacity. (gal/cycle/ft3)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:DryerElectricEnergyUsePerLoad": {
                  "additionalProperties": false,
                  "description": "Measure of dryer efficiency based on electricity. (kWh/load)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:DryerGasEnergyUsePerLoad": {
                  "additionalProperties": false,
                  "description": "Measure of dryer efficiency based on natural gas. (Btu/load)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:DryerType": {
                  "$ref": "#/definitions/.auc:DryerType"
                },
                "auc:WasherDryerType": {
                  "additionalProperties": false,
                  "description": "Type of washer/dryer combination unit.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Combination All-In One Clothes Washer-Dryer",
                        "Unitized/Stacked Washer Dryer Pair",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Dryer": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:DryerElectricEnergyUsePerLoad": {
                  "additionalProperties": false,
                  "description": "Measure of dryer efficiency based on electricity. (kWh/load)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:DryerGasEnergyUsePerLoad": {
                  "additionalProperties": false,
                  "description": "Measure of dryer efficiency based on natural gas. (Btu/load)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:DryerType": {
                  "$ref": "#/definitions/.auc:DryerType"
                }
              },
              "type": "object"
            },
            "auc:Other": {
              "$ref": "#/definitions/xs:anyType"
            },
            "auc:Unknown": {
              "$ref": "#/definitions/xs:anyType"
            },
            "auc:Washer": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ClothesWasherCapacity": {
                  "additionalProperties": false,
                  "description": "Volume of clothes washer tub. (ft3)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ClothesWasherClassification": {
                  "$ref": "#/definitions/.auc:ClothesWasherClassification"
                },
                "auc:ClothesWasherLoaderType": {
                  "$ref": "#/definitions/.auc:ClothesWasherLoaderType"
                },
                "auc:ClothesWasherModifiedEnergyFactor": {
                  "additionalProperties": false,
                  "description": "Modified Energy Factor, MEF, is the energy performance metric for ENERGY STAR qualified clothes washers and all clothes washers as of February 1, 2013. MEF is the quotient of the capacity of the clothes container, C, divided by the total clothes washer energy consumption per cycle, with such energy consumption expressed as the sum of the machine electrical energy consumption, M, the hot water energy consumption, E, and the energy required for removal of the remaining moisture in the wash load, D. The higher MEF, the more efficient the clothes washer. The equation is: MEF = C/(M + E + D).",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ClothesWasherWaterFactor": {
                  "additionalProperties": false,
                  "description": "Water Factor, WF, is the quotient of the total weighted per-cycle water consumption, Q, divided by the capacity of the clothes washer, C. The lower the value, the more water efficient the clothes washer is. The equation is: WF = Q/C. WF is the ENERGY STAR water performance metric that allows the comparison of clothes washer water consumption independent of clothes washer capacity. (gal/cycle/ft3)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:QuantityOfLaundry": {
          "additionalProperties": false,
          "description": "Quantity of laundry processed on-site annually (lb/yr)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:LightingSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:BallastType": {
          "additionalProperties": false,
          "description": "A ballast is a piece of equipment required to control the starting and operating voltages of electrical gas discharge lights.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Electromagnetic",
                "Electronic",
                "Integrated",
                "Core and Coil",
                "F-Can",
                "Other",
                "No Ballast"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:DaylightingControlSteps": {
          "additionalProperties": false,
          "description": "For stepped dimming, the number of equally spaced control steps",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DimmingCapability": {
          "additionalProperties": false,
          "description": "If exists then the lighting system can be dimmed across a range of outputs.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:MinimumDimmingLightFraction": {
              "additionalProperties": false,
              "description": "Minimum light output of controlled lighting when fully dimmed. Minimum light fraction = (Minimum light output) / (Rated light output). (0-1) (%)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:MinimumDimmingPowerFraction": {
              "additionalProperties": false,
              "description": "The minimum power fraction when controlled lighting is fully dimmed. Minimum power fraction = (Minimum power) / (Full rated power). (0-1) (%)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:FixtureSpacing": {
          "additionalProperties": false,
          "description": "Average horizontal spacing of fixtures. (ft)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:InputVoltage": {
          "additionalProperties": false,
          "description": "Voltage rating for this lighting system.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "120",
                "208",
                "240",
                "277",
                "347",
                "480",
                "120/277 (dual)",
                "120-277 (universal)",
                "347-480 (high voltage)",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:InstallationType": {
          "additionalProperties": false,
          "description": "Installation of lamp relative to mounting surface",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Plug-in",
                "Recessed",
                "Surface",
                "Suspended",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:InstalledPower": {
          "additionalProperties": false,
          "description": "Installed power for this system. (kW)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LampPower": {
          "additionalProperties": false,
          "description": "The number of watts per lamp. (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LampType": {
          "additionalProperties": false,
          "description": "A lamp is a replaceable component, or bulb, which is designed to produce light from electricity, though, non-electric lamps also exist.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:CompactFluorescent": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:FluorescentStartType": {
                  "$ref": "#/definitions/.auc:FluorescentStartType"
                },
                "auc:LampLabel": {
                  "additionalProperties": false,
                  "description": "Specific lamp subtype used in the luminaire",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "2D",
                        "A-series",
                        "Circline",
                        "Spiral",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Halogen": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:LampLabel": {
                  "additionalProperties": false,
                  "description": "Specific lamp subtype used in the luminaire",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "A-shape",
                        "BR30",
                        "BR40",
                        "MR11",
                        "MR16",
                        "MR8",
                        "PAR20",
                        "PAR30",
                        "PAR38",
                        "Pin Base",
                        "R20",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:TransformerNeeded": {
                  "additionalProperties": false,
                  "description": "True if the lamps require a transformer to lower the voltage (such as halogen or LEDs).",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:boolean"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:HighIntensityDischarge": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:LampLabel": {
                  "additionalProperties": false,
                  "description": "Specific lamp subtype used in the luminaire",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Sodium Vapor High Pressure",
                        "Sodium Vapor Low Pressure",
                        "Metal Halide",
                        "Mercury Vapor",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:MetalHalideStartType": {
                  "additionalProperties": false,
                  "description": "Start technology used with metal halide ballasts",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Probe start",
                        "Pulse start",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Incandescent": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:LampLabel": {
                  "additionalProperties": false,
                  "description": "Specific lamp subtype used in the luminaire",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "A19",
                        "A21",
                        "G16C",
                        "G25M",
                        "G40M",
                        "MR16",
                        "PAR16",
                        "PAR20",
                        "PAR30",
                        "PAR38",
                        "PS-Series",
                        "R20",
                        "R30",
                        "R40",
                        "TC",
                        "TM",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Induction": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:FluorescentStartType": {
                  "$ref": "#/definitions/.auc:FluorescentStartType"
                }
              },
              "type": "object"
            },
            "auc:LinearFluorescent": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:LampLabel": {
                  "additionalProperties": false,
                  "description": "Specific lamp subtype used in the luminaire",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Super T8",
                        "T12",
                        "T5",
                        "T5HO",
                        "T8",
                        "T12U",
                        "T8U",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:LampLength": {
                  "additionalProperties": false,
                  "description": "Length of fluorescent lamps.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "2 ft",
                        "4 ft",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Neon": {
              "$ref": "#/definitions/xs:anyType"
            },
            "auc:OtherCombination": {
              "$ref": "#/definitions/xs:anyType"
            },
            "auc:Photoluminescent": {
              "$ref": "#/definitions/xs:anyType"
            },
            "auc:Plasma": {
              "$ref": "#/definitions/xs:anyType"
            },
            "auc:SelfLuminous": {
              "$ref": "#/definitions/xs:anyType"
            },
            "auc:SolidStateLighting": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:LampLabel": {
                  "additionalProperties": false,
                  "description": "Specific lamp subtype used in the luminaire",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "LED",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:TransformerNeeded": {
                  "additionalProperties": false,
                  "description": "True if the lamps require a transformer to lower the voltage (such as halogen or LEDs).",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:boolean"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Unknown": {
              "$ref": "#/definitions/xs:anyType"
            }
          },
          "type": "object"
        },
        "auc:LightingControlTypeDaylighting": {
          "additionalProperties": false,
          "description": "Type of daylighting controls used to manage lighting.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Continuous",
                "Continuous Plus Off",
                "Stepped Dimming",
                "Stepped Switching",
                "Other",
                "None",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LightingControlTypeManual": {
          "additionalProperties": false,
          "description": "Type of manual controls used to manage lighting.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Manual On/Off",
                "Manual Dimming",
                "Bi-level Control",
                "Tri-level Control",
                "Other",
                "None",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LightingControlTypeOccupancy": {
          "additionalProperties": false,
          "description": "Type of occupancy controls used to manage lighting.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Occupancy Sensors",
                "Vacancy Sensors",
                "Other",
                "None",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LightingControlTypeTimer": {
          "additionalProperties": false,
          "description": "Type of timer controls used to manage lighting.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Astronomical",
                "Chronological",
                "Other",
                "None",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LightingDirection": {
          "additionalProperties": false,
          "description": "Directional characteristics of lighting fixtures.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Direct",
                "Indirect",
                "Direct-Indirect",
                "Spotlight",
                "Floodlighting",
                "Omnidirectional",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LightingEfficacy": {
          "additionalProperties": false,
          "description": "The amount of light (luminous flux) produced by a light source, usually measured in lumens, as a ratio of the amount of power consumed to produce it, usually measured in watts. (lm/W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:LuminaireHeight": {
          "additionalProperties": false,
          "description": "Vertical height of luminaire above the finished floor/ground. (ft)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:NumberOfBallastsPerLuminaire": {
          "additionalProperties": false,
          "description": "The number of ballasts installed in each luminaire or fixture",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:NumberOfLampsPerBallast": {
          "additionalProperties": false,
          "description": "The number of lamps driven by the ballast.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:NumberOfLampsPerLuminaire": {
          "additionalProperties": false,
          "description": "The number of lamps in the luminaire.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:NumberOfLuminaires": {
          "additionalProperties": false,
          "description": "Total number of luminaires/fixtures in this system.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:OutsideLighting": {
          "additionalProperties": false,
          "description": "True if lighting system is primarily for outside lighting, false otherwise.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:boolean"
            }
          },
          "type": "object"
        },
        "auc:PercentPremisesServed": {
          "additionalProperties": false,
          "description": "The percentage of the premises that the lighting system applies to. This may be for the whole building or an individual space, depending on the LinkedPremises field.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:RatedLampLife": {
          "additionalProperties": false,
          "description": "The expected remaining service life of a component. (hrs)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ReflectorType": {
          "additionalProperties": false,
          "description": "Type of reflector used to distribute light to the space.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Specular Reflector",
                "Prismatic Reflector",
                "Other",
                "Unknown",
                "None"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:WorkPlaneHeight": {
          "additionalProperties": false,
          "description": "Distance from the finished floor to the work plane. Used to calculate vertical distance from the work plane to the centerline of the lighting fixture (ft)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:MeasureType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "auc:CapitalReplacementCost": {
          "additionalProperties": false,
          "description": "Estimated cost of replacing the measure at the end of its useful life, in current year dollars. ($)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DiscardReason": {
          "additionalProperties": false,
          "description": "Reason why the proposed measure was discarded, if appropriate.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Not discarded",
                "Long payback",
                "Requires permit",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:EndDate": {
          "additionalProperties": false,
          "description": "Date when majority of the project or measure was completed and implemented. (substantial completion). (CCYY-MM-DD)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:date"
            }
          },
          "type": "object"
        },
        "auc:ImplementationStatus": {
          "additionalProperties": false,
          "description": "Implementation status of measure.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Proposed",
                "Evaluated",
                "Selected",
                "Initiated",
                "Discarded",
                "In Progress",
                "Completed",
                "MV",
                "Verified",
                "Unsatisfactory",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LongDescription": {
          "additionalProperties": false,
          "description": "Long description of measure.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:MVCost": {
          "additionalProperties": false,
          "description": "Annual cost to verify energy savings. ($/year)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:MVOption": {
          "additionalProperties": false,
          "description": "Recommended approach for verification of energy savings for this measure, based on IPMVP",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Option A: Retrofit Isolation With Partial Measurement",
                "Option B: Retrofit Isolation With Full Measurement",
                "Option C: Whole Building Measurement",
                "Option D: Calibrated Simulation",
                "Combination",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:MeasureTotalFirstCost": {
          "additionalProperties": false,
          "description": "The sum of the initial expenditures to implement each occurrence of the measure; includes items such as equipment, transportation, installation, preparation for service, as well as other costs directly related to the measure. Soft costs related to project planning and management should not be included for individual measures. ($)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:MeasureSavingsAnalysis": {
          "additionalProperties": false,
          "description": "Energy and cost effectiveness data for an individual measure. In most cases, this data depends on the other measures included in the package, and should be entered at the package level under Scenarios.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:AnnualDemandSavingsCost": {
              "additionalProperties": false,
              "description": "Cost savings per year due to reduction in peak electricity demand. ($/year)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:integer"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:AnnualSavingsByFuels": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AnnualSavingsByFuel": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:AnnualSavingsNativeUnits": {
                          "additionalProperties": false,
                          "description": "Site energy savings per year for this resource type, in the original units. (units/yr)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:EnergyResource": {
                          "$ref": "#/definitions/.auc:EnergyResource"
                        },
                        "auc:ResourceUnits": {
                          "$ref": "#/definitions/.auc:ResourceUnits"
                        }
                      },
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:AnnualSavingsNativeUnits": {
                            "additionalProperties": false,
                            "description": "Site energy savings per year for this resource type, in the original units. (units/yr)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:EnergyResource": {
                            "$ref": "#/definitions/.auc:EnergyResource"
                          },
                          "auc:ResourceUnits": {
                            "$ref": "#/definitions/.auc:ResourceUnits"
                          }
                        },
                        "type": "object"
                      },
                      "minItems": 1,
                      "type": "array"
                    }
                  ]
                }
              },
              "required": [
                "auc:AnnualSavingsByFuel"
              ],
              "type": "object"
            },
            "auc:AnnualSavingsCost": {
              "additionalProperties": false,
              "description": "Cost savings per year, including energy, demand, change in rate schedule, and other cost impacts on utility bills. ($/year)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:integer"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:AnnualSavingsSiteEnergy": {
              "additionalProperties": false,
              "description": "Site energy savings per year. [MMBtu/year]",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:AnnualSavingsSourceEnergy": {
              "additionalProperties": false,
              "description": "Source energy savings per year. [MMBtu/year]",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:AnnualWaterCostSavings": {
              "additionalProperties": false,
              "description": "Total annual reduction in water costs, not including water heating costs (hot and cold). ($/year)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:AnnualWaterSavings": {
              "additionalProperties": false,
              "description": "Total annual water savings (hot and cold). (gal/year)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:CalculationMethod": {
              "$ref": "#/definitions/.auc:CalculationMethod"
            },
            "auc:CostEffectivenessScreeningMethod": {
              "$ref": "#/definitions/.auc:CostEffectivenessScreeningMethod"
            },
            "auc:EquipmentDisposalAndSalvageCosts": {
              "additionalProperties": false,
              "description": "The net cost of disposing of equipment being replaced or removed. In some cases the salvage value may exceed disposal costs, resulting in a negative value. ($)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:FundingFromIncentives": {
              "additionalProperties": false,
              "description": "Funding obtained through incentives to implement the measure or project ($)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:FundingFromTaxCredits": {
              "additionalProperties": false,
              "description": "Funding obtained through utility or state tax credits to implement the measure or project. ($)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:InternalRateOfReturn": {
              "additionalProperties": false,
              "description": "Internal rate of return (IRR) of measure or package (%)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:MeasureRank": {
              "additionalProperties": false,
              "description": "Sequence in which the measure was analyzed relative to other measures. Ranking should be 1 if it is analyzed first, 2 if analyzed after Measure 1 is applied, etc. This accounts for interactive effects between measures. Ranking may be 1 for all measures if they are not analyzed as a package",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:integer"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:NPVofTaxImplications": {
              "additionalProperties": false,
              "description": "Net present value of impacts on depreciation and other tax deductions. ($)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:NetPresentValue": {
              "additionalProperties": false,
              "description": "Net Present Value (NPV) of measure or package ($)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:OMCostAnnualSavings": {
              "additionalProperties": false,
              "description": "Annual cost savings for operation, maintenance, and repair. ($)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:OtherCostAnnualSavings": {
              "additionalProperties": false,
              "description": "Annual savings for other non-energy costs, or increased revenue caused by measure implementation. ($)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:ReferenceCase": {
              "$ref": "#/definitions/.auc:ReferenceCase"
            },
            "auc:SimplePayback": {
              "additionalProperties": false,
              "description": "The length of time required for the investment to pay for itself. (yrs)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:SummerPeakElectricityReduction": {
              "additionalProperties": false,
              "description": "Reduction in largest 15 minute peak demand for the summer months as defined in the utility rate schedule (for electrical energy use only). (kW)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:WinterPeakElectricityReduction": {
              "additionalProperties": false,
              "description": "Reduction in largest 15 minute peak demand for the winter months as defined in the utility rate schedule (for electrical energy use only) (kW)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:MeasureScaleOfApplication": {
          "additionalProperties": false,
          "description": "Scale at which the measure is applied, such as an individual system, multiple systems, or entire facility",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Individual system",
                "Multiple systems",
                "Individual premise",
                "Multiple premises",
                "Entire facility",
                "Entire site"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PremisesAffected": {
          "additionalProperties": false,
          "description": "List of Premises Affected.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:PremiseAffected": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "description": "ID numbers of premises (site, facility, space, zone, or subsections) for measures that apply broadly to an entire building, or modify the properties of a building.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@IDref": {
                      "$ref": "#/definitions/xs:IDREF"
                    },
                    "auc:MeasureCoverage": {
                      "additionalProperties": false,
                      "description": "Percentage of the premises affected by the measure that's either proposed, implemented or evaluated. [%]",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "required": [
                    "@IDref"
                  ],
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "description": "ID numbers of premises (site, facility, space, zone, or subsections) for measures that apply broadly to an entire building, or modify the properties of a building.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "@IDref": {
                        "$ref": "#/definitions/xs:IDREF"
                      },
                      "auc:MeasureCoverage": {
                        "additionalProperties": false,
                        "description": "Percentage of the premises affected by the measure that's either proposed, implemented or evaluated. [%]",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      }
                    },
                    "required": [
                      "@IDref"
                    ],
                    "type": "object"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:PremiseAffected"
          ],
          "type": "object"
        },
        "auc:Recommended": {
          "additionalProperties": false,
          "description": "True if measure is recommended",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:boolean"
            }
          },
          "type": "object"
        },
        "auc:ResidualValue": {
          "additionalProperties": false,
          "description": "The remaining value of the equipment associated with a measure or package at the end of the analysis period. ($)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:StartDate": {
          "additionalProperties": false,
          "description": "Start Date for implementation of a project or a measure. (CCYY-MM-DD)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:date"
            }
          },
          "type": "object"
        },
        "auc:SystemCategoryAffected": {
          "additionalProperties": false,
          "description": "Category of building system(s) affected by the measure. In some cases a single measure may include multiple components affecting multiple systems.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Air Distribution",
                "Heating System",
                "Cooling System",
                "Other HVAC",
                "Lighting",
                "Domestic Hot Water",
                "Cooking",
                "Refrigeration",
                "Dishwasher",
                "Laundry",
                "Pump",
                "Fan",
                "Motor",
                "Heat Recovery",
                "Wall",
                "Roof / Ceiling",
                "Fenestration",
                "Foundation",
                "General Controls and Operations",
                "Critical IT System",
                "Plug Load",
                "Process Load",
                "Conveyance",
                "On-Site Storage, Transmission, Generation",
                "Pool",
                "Water Use",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:TechnologyCategories": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:TechnologyCategory": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "description": "Authorized technology category as defined by the Federal Energy Management Program. In some cases a single measure may include multiple components affecting multiple categories.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:AdvancedMeteringSystems": {
                      "additionalProperties": false,
                      "description": "Advanced Metering Systems",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Install advanced metering systems",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Install advanced metering systems",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:BoilerPlantImprovements": {
                      "additionalProperties": false,
                      "description": "Boiler Plant Improvements",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Replace boiler",
                                    "Replace burner",
                                    "Decentralize boiler",
                                    "Insulate boiler room",
                                    "Add energy recovery",
                                    "Convert gas-fired unit to boiler loop",
                                    "Convert system from steam to hot water",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Replace boiler",
                                      "Replace burner",
                                      "Decentralize boiler",
                                      "Insulate boiler room",
                                      "Add energy recovery",
                                      "Convert gas-fired unit to boiler loop",
                                      "Convert system from steam to hot water",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:BuildingAutomationSystems": {
                      "additionalProperties": false,
                      "description": "Building Automation Systems/Energy Management Control Systems (EMCS)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Add heat recovery",
                                    "Add or upgrade BAS/EMS/EMCS",
                                    "Add or upgrade controls",
                                    "Convert pneumatic controls to DDC",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Add heat recovery",
                                      "Add or upgrade BAS/EMS/EMCS",
                                      "Add or upgrade controls",
                                      "Convert pneumatic controls to DDC",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:BuildingEnvelopeModifications": {
                      "additionalProperties": false,
                      "description": "Building Envelope Modifications",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Air seal envelope",
                                    "Increase wall insulation",
                                    "Insulate thermal bypasses",
                                    "Increase ceiling insulation",
                                    "Increase roof insulation",
                                    "Insulate attic hatch / stair box",
                                    "Add attic/knee wall insulation",
                                    "Install cool/green roof",
                                    "Add shading devices",
                                    "Add window films",
                                    "Install or replace solar screens",
                                    "Replace glazing",
                                    "Replace windows",
                                    "Increase floor insulation",
                                    "Insulate foundation",
                                    "Clean and/or repair",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Air seal envelope",
                                      "Increase wall insulation",
                                      "Insulate thermal bypasses",
                                      "Increase ceiling insulation",
                                      "Increase roof insulation",
                                      "Insulate attic hatch / stair box",
                                      "Add attic/knee wall insulation",
                                      "Install cool/green roof",
                                      "Add shading devices",
                                      "Add window films",
                                      "Install or replace solar screens",
                                      "Replace glazing",
                                      "Replace windows",
                                      "Increase floor insulation",
                                      "Insulate foundation",
                                      "Clean and/or repair",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:ChilledWaterHotWaterAndSteamDistributionSystems": {
                      "additionalProperties": false,
                      "description": "Chilled Water, Hot Water, and Steam Distribution Systems",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Add pipe insulation",
                                    "Repair and/or replace steam traps",
                                    "Retrofit and replace chiller plant pumping, piping, and controls",
                                    "Repair or replace existing condensate return systems or install new condensate return systems",
                                    "Add recirculating pumps",
                                    "Replace or upgrade water heater",
                                    "Add energy recovery",
                                    "Install solar hot water system",
                                    "Separate DHW from heating",
                                    "Replace with higher efficiency pump",
                                    "Replace with variable speed pump",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Add pipe insulation",
                                      "Repair and/or replace steam traps",
                                      "Retrofit and replace chiller plant pumping, piping, and controls",
                                      "Repair or replace existing condensate return systems or install new condensate return systems",
                                      "Add recirculating pumps",
                                      "Replace or upgrade water heater",
                                      "Add energy recovery",
                                      "Install solar hot water system",
                                      "Separate DHW from heating",
                                      "Replace with higher efficiency pump",
                                      "Replace with variable speed pump",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:ChillerPlantImprovements": {
                      "additionalProperties": false,
                      "description": "Chiller Plant Improvements",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Add energy recovery",
                                    "Install VSD on electric centrifugal chillers",
                                    "Replace chiller",
                                    "Install gas cooling",
                                    "Add or repair economizer cycle",
                                    "Add or replace cooling tower",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Add energy recovery",
                                      "Install VSD on electric centrifugal chillers",
                                      "Replace chiller",
                                      "Install gas cooling",
                                      "Add or repair economizer cycle",
                                      "Add or replace cooling tower",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:DistributedGeneration": {
                      "additionalProperties": false,
                      "description": "Distributed Generation",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Install CHP/cogeneration systems",
                                    "Install fuel cells",
                                    "Install microturbines",
                                    "Convert fuels",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Install CHP/cogeneration systems",
                                      "Install fuel cells",
                                      "Install microturbines",
                                      "Convert fuels",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:ElectricMotorsAndDrives": {
                      "additionalProperties": false,
                      "description": "Electric Motors and Drives",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Add drive controls",
                                    "Replace with higher efficiency",
                                    "Add VSD motor controller",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Add drive controls",
                                      "Replace with higher efficiency",
                                      "Add VSD motor controller",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:ElectricalPeakShavingLoadShifting": {
                      "additionalProperties": false,
                      "description": "Electrical Peak Shaving/Load Shifting",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Install thermal energy storage",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Install thermal energy storage",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:EnergyCostReductionThroughRateAdjustments": {
                      "additionalProperties": false,
                      "description": "Energy Cost Reduction Through Rate Adjustments",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Change to more favorable rate schedule",
                                    "Energy cost reduction through rate adjustments - uncategorized",
                                    "Energy service billing and meter auditing recommendations",
                                    "Change to lower energy cost supplier(s)",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Change to more favorable rate schedule",
                                      "Energy cost reduction through rate adjustments - uncategorized",
                                      "Energy service billing and meter auditing recommendations",
                                      "Change to lower energy cost supplier(s)",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:EnergyDistributionSystems": {
                      "additionalProperties": false,
                      "description": "Energy/Utility Distribution Systems",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Implement power factor corrections",
                                    "Implement power quality upgrades",
                                    "Upgrade transformers",
                                    "Install gas distribution systems",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Implement power factor corrections",
                                      "Implement power quality upgrades",
                                      "Upgrade transformers",
                                      "Install gas distribution systems",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:EnergyRelatedProcessImprovements": {
                      "additionalProperties": false,
                      "description": "Energy Related Process Improvements",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Implement industrial process improvements",
                                    "Implement production and/or manufacturing improvements",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Implement industrial process improvements",
                                      "Implement production and/or manufacturing improvements",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:FutureOtherECMs": {
                      "additionalProperties": false,
                      "description": "Future/Other ECMs",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:HealthAndSafety": {
                      "additionalProperties": false,
                      "description": "Category heading for measures that are necessary for health, comfort, or safety reasons, not for energy efficiency reasons.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:LightingImprovements": {
                      "additionalProperties": false,
                      "description": "Lighting Improvements",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Retrofit with CFLs",
                                    "Retrofit with T-5",
                                    "Retrofit with T-8",
                                    "Install spectrally enhanced lighting",
                                    "Retrofit with fiber optic lighting technologies",
                                    "Retrofit with light emitting diode technologies",
                                    "Add daylight controls",
                                    "Add occupancy sensors",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Retrofit with CFLs",
                                      "Retrofit with T-5",
                                      "Retrofit with T-8",
                                      "Install spectrally enhanced lighting",
                                      "Retrofit with fiber optic lighting technologies",
                                      "Retrofit with light emitting diode technologies",
                                      "Add daylight controls",
                                      "Add occupancy sensors",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:OtherHVAC": {
                      "additionalProperties": false,
                      "description": "Heating, Ventilating, and Air Conditioning",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Replace or modify AHU",
                                    "Improve distribution fans",
                                    "Improve ventilation fans",
                                    "Convert CV system to VAV system",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Repair leaks / seal ducts",
                                    "Add duct insulation",
                                    "Balance ventilation/distribution system",
                                    "Repair or replace HVAC damper and controller",
                                    "Replace burner",
                                    "Replace package units",
                                    "Replace packaged terminal units",
                                    "Install passive solar heating",
                                    "Replace AC and heating units with ground coupled heat pump systems",
                                    "Add energy recovery",
                                    "Add or replace cooling tower",
                                    "Install gas cooling",
                                    "Add or repair economizer",
                                    "Add enhanced dehumidification",
                                    "Install solar ventilation preheating system",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Other heating",
                                    "Other cooling",
                                    "Other ventilation",
                                    "Other distribution",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Replace or modify AHU",
                                      "Improve distribution fans",
                                      "Improve ventilation fans",
                                      "Convert CV system to VAV system",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Repair leaks / seal ducts",
                                      "Add duct insulation",
                                      "Balance ventilation/distribution system",
                                      "Repair or replace HVAC damper and controller",
                                      "Replace burner",
                                      "Replace package units",
                                      "Replace packaged terminal units",
                                      "Install passive solar heating",
                                      "Replace AC and heating units with ground coupled heat pump systems",
                                      "Add energy recovery",
                                      "Add or replace cooling tower",
                                      "Install gas cooling",
                                      "Add or repair economizer",
                                      "Add enhanced dehumidification",
                                      "Install solar ventilation preheating system",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Other heating",
                                      "Other cooling",
                                      "Other ventilation",
                                      "Other distribution",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:PlugLoadReductions": {
                      "additionalProperties": false,
                      "description": "Appliance and plug-load reductions",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Replace with ENERGY STAR rated",
                                    "Install plug load controls",
                                    "De-lamp vending machines",
                                    "Improve data center efficiency",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Replace with ENERGY STAR rated",
                                      "Install plug load controls",
                                      "De-lamp vending machines",
                                      "Improve data center efficiency",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:Refrigeration": {
                      "additionalProperties": false,
                      "description": "Refrigeration",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Replace ice/refrigeration equipment with high efficiency units",
                                    "Replace air-cooled ice/refrigeration equipment",
                                    "Replace refrigerators",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Replace ice/refrigeration equipment with high efficiency units",
                                      "Replace air-cooled ice/refrigeration equipment",
                                      "Replace refrigerators",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:RenewableEnergySystems": {
                      "additionalProperties": false,
                      "description": "Renewable Energy Systems",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Install landfill gas, wastewater treatment plant digester gas, or coal bed methane power plant",
                                    "Install photovoltaic system",
                                    "Install wind energy system",
                                    "Install wood waste or other organic waste stream heating or power plant",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Install landfill gas, wastewater treatment plant digester gas, or coal bed methane power plant",
                                      "Install photovoltaic system",
                                      "Install wind energy system",
                                      "Install wood waste or other organic waste stream heating or power plant",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:Uncategorized": {
                      "additionalProperties": false,
                      "description": "Category heading for measures that don't fit into another category",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    },
                    "auc:WaterAndSewerConservationSystems": {
                      "additionalProperties": false,
                      "description": "Water and Sewer Conservation Systems",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:MeasureName": {
                          "anyOf": [
                            {
                              "additionalProperties": false,
                              "description": "Short description of measure",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Install low-flow faucets and showerheads",
                                    "Install low-flow plumbing equipment",
                                    "Install on-site sewer treatment systems",
                                    "Implement water efficient irrigation",
                                    "Clean and/or repair",
                                    "Implement training and/or documentation",
                                    "Upgrade operating protocols, calibration, and/or sequencing",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            {
                              "items": {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Install low-flow faucets and showerheads",
                                      "Install low-flow plumbing equipment",
                                      "Install on-site sewer treatment systems",
                                      "Implement water efficient irrigation",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              "minItems": 1,
                              "type": "array"
                            }
                          ]
                        }
                      },
                      "required": [
                        "auc:MeasureName"
                      ],
                      "type": "object"
                    }
                  },
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "description": "Authorized technology category as defined by the Federal Energy Management Program. In some cases a single measure may include multiple components affecting multiple categories.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:AdvancedMeteringSystems": {
                        "additionalProperties": false,
                        "description": "Advanced Metering Systems",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Install advanced metering systems",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Install advanced metering systems",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:BoilerPlantImprovements": {
                        "additionalProperties": false,
                        "description": "Boiler Plant Improvements",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Replace boiler",
                                      "Replace burner",
                                      "Decentralize boiler",
                                      "Insulate boiler room",
                                      "Add energy recovery",
                                      "Convert gas-fired unit to boiler loop",
                                      "Convert system from steam to hot water",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Replace boiler",
                                        "Replace burner",
                                        "Decentralize boiler",
                                        "Insulate boiler room",
                                        "Add energy recovery",
                                        "Convert gas-fired unit to boiler loop",
                                        "Convert system from steam to hot water",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:BuildingAutomationSystems": {
                        "additionalProperties": false,
                        "description": "Building Automation Systems/Energy Management Control Systems (EMCS)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Add heat recovery",
                                      "Add or upgrade BAS/EMS/EMCS",
                                      "Add or upgrade controls",
                                      "Convert pneumatic controls to DDC",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Add heat recovery",
                                        "Add or upgrade BAS/EMS/EMCS",
                                        "Add or upgrade controls",
                                        "Convert pneumatic controls to DDC",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:BuildingEnvelopeModifications": {
                        "additionalProperties": false,
                        "description": "Building Envelope Modifications",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Air seal envelope",
                                      "Increase wall insulation",
                                      "Insulate thermal bypasses",
                                      "Increase ceiling insulation",
                                      "Increase roof insulation",
                                      "Insulate attic hatch / stair box",
                                      "Add attic/knee wall insulation",
                                      "Install cool/green roof",
                                      "Add shading devices",
                                      "Add window films",
                                      "Install or replace solar screens",
                                      "Replace glazing",
                                      "Replace windows",
                                      "Increase floor insulation",
                                      "Insulate foundation",
                                      "Clean and/or repair",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Air seal envelope",
                                        "Increase wall insulation",
                                        "Insulate thermal bypasses",
                                        "Increase ceiling insulation",
                                        "Increase roof insulation",
                                        "Insulate attic hatch / stair box",
                                        "Add attic/knee wall insulation",
                                        "Install cool/green roof",
                                        "Add shading devices",
                                        "Add window films",
                                        "Install or replace solar screens",
                                        "Replace glazing",
                                        "Replace windows",
                                        "Increase floor insulation",
                                        "Insulate foundation",
                                        "Clean and/or repair",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:ChilledWaterHotWaterAndSteamDistributionSystems": {
                        "additionalProperties": false,
                        "description": "Chilled Water, Hot Water, and Steam Distribution Systems",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Add pipe insulation",
                                      "Repair and/or replace steam traps",
                                      "Retrofit and replace chiller plant pumping, piping, and controls",
                                      "Repair or replace existing condensate return systems or install new condensate return systems",
                                      "Add recirculating pumps",
                                      "Replace or upgrade water heater",
                                      "Add energy recovery",
                                      "Install solar hot water system",
                                      "Separate DHW from heating",
                                      "Replace with higher efficiency pump",
                                      "Replace with variable speed pump",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Add pipe insulation",
                                        "Repair and/or replace steam traps",
                                        "Retrofit and replace chiller plant pumping, piping, and controls",
                                        "Repair or replace existing condensate return systems or install new condensate return systems",
                                        "Add recirculating pumps",
                                        "Replace or upgrade water heater",
                                        "Add energy recovery",
                                        "Install solar hot water system",
                                        "Separate DHW from heating",
                                        "Replace with higher efficiency pump",
                                        "Replace with variable speed pump",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:ChillerPlantImprovements": {
                        "additionalProperties": false,
                        "description": "Chiller Plant Improvements",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Add energy recovery",
                                      "Install VSD on electric centrifugal chillers",
                                      "Replace chiller",
                                      "Install gas cooling",
                                      "Add or repair economizer cycle",
                                      "Add or replace cooling tower",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Add energy recovery",
                                        "Install VSD on electric centrifugal chillers",
                                        "Replace chiller",
                                        "Install gas cooling",
                                        "Add or repair economizer cycle",
                                        "Add or replace cooling tower",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:DistributedGeneration": {
                        "additionalProperties": false,
                        "description": "Distributed Generation",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Install CHP/cogeneration systems",
                                      "Install fuel cells",
                                      "Install microturbines",
                                      "Convert fuels",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Install CHP/cogeneration systems",
                                        "Install fuel cells",
                                        "Install microturbines",
                                        "Convert fuels",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:ElectricMotorsAndDrives": {
                        "additionalProperties": false,
                        "description": "Electric Motors and Drives",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Add drive controls",
                                      "Replace with higher efficiency",
                                      "Add VSD motor controller",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Add drive controls",
                                        "Replace with higher efficiency",
                                        "Add VSD motor controller",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:ElectricalPeakShavingLoadShifting": {
                        "additionalProperties": false,
                        "description": "Electrical Peak Shaving/Load Shifting",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Install thermal energy storage",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Install thermal energy storage",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:EnergyCostReductionThroughRateAdjustments": {
                        "additionalProperties": false,
                        "description": "Energy Cost Reduction Through Rate Adjustments",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Change to more favorable rate schedule",
                                      "Energy cost reduction through rate adjustments - uncategorized",
                                      "Energy service billing and meter auditing recommendations",
                                      "Change to lower energy cost supplier(s)",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Change to more favorable rate schedule",
                                        "Energy cost reduction through rate adjustments - uncategorized",
                                        "Energy service billing and meter auditing recommendations",
                                        "Change to lower energy cost supplier(s)",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:EnergyDistributionSystems": {
                        "additionalProperties": false,
                        "description": "Energy/Utility Distribution Systems",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Implement power factor corrections",
                                      "Implement power quality upgrades",
                                      "Upgrade transformers",
                                      "Install gas distribution systems",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Implement power factor corrections",
                                        "Implement power quality upgrades",
                                        "Upgrade transformers",
                                        "Install gas distribution systems",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:EnergyRelatedProcessImprovements": {
                        "additionalProperties": false,
                        "description": "Energy Related Process Improvements",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Implement industrial process improvements",
                                      "Implement production and/or manufacturing improvements",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Implement industrial process improvements",
                                        "Implement production and/or manufacturing improvements",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:FutureOtherECMs": {
                        "additionalProperties": false,
                        "description": "Future/Other ECMs",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:HealthAndSafety": {
                        "additionalProperties": false,
                        "description": "Category heading for measures that are necessary for health, comfort, or safety reasons, not for energy efficiency reasons.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:LightingImprovements": {
                        "additionalProperties": false,
                        "description": "Lighting Improvements",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Retrofit with CFLs",
                                      "Retrofit with T-5",
                                      "Retrofit with T-8",
                                      "Install spectrally enhanced lighting",
                                      "Retrofit with fiber optic lighting technologies",
                                      "Retrofit with light emitting diode technologies",
                                      "Add daylight controls",
                                      "Add occupancy sensors",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Retrofit with CFLs",
                                        "Retrofit with T-5",
                                        "Retrofit with T-8",
                                        "Install spectrally enhanced lighting",
                                        "Retrofit with fiber optic lighting technologies",
                                        "Retrofit with light emitting diode technologies",
                                        "Add daylight controls",
                                        "Add occupancy sensors",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:OtherHVAC": {
                        "additionalProperties": false,
                        "description": "Heating, Ventilating, and Air Conditioning",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Replace or modify AHU",
                                      "Improve distribution fans",
                                      "Improve ventilation fans",
                                      "Convert CV system to VAV system",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Repair leaks / seal ducts",
                                      "Add duct insulation",
                                      "Balance ventilation/distribution system",
                                      "Repair or replace HVAC damper and controller",
                                      "Replace burner",
                                      "Replace package units",
                                      "Replace packaged terminal units",
                                      "Install passive solar heating",
                                      "Replace AC and heating units with ground coupled heat pump systems",
                                      "Add energy recovery",
                                      "Add or replace cooling tower",
                                      "Install gas cooling",
                                      "Add or repair economizer",
                                      "Add enhanced dehumidification",
                                      "Install solar ventilation preheating system",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Other heating",
                                      "Other cooling",
                                      "Other ventilation",
                                      "Other distribution",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Replace or modify AHU",
                                        "Improve distribution fans",
                                        "Improve ventilation fans",
                                        "Convert CV system to VAV system",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Repair leaks / seal ducts",
                                        "Add duct insulation",
                                        "Balance ventilation/distribution system",
                                        "Repair or replace HVAC damper and controller",
                                        "Replace burner",
                                        "Replace package units",
                                        "Replace packaged terminal units",
                                        "Install passive solar heating",
                                        "Replace AC and heating units with ground coupled heat pump systems",
                                        "Add energy recovery",
                                        "Add or replace cooling tower",
                                        "Install gas cooling",
                                        "Add or repair economizer",
                                        "Add enhanced dehumidification",
                                        "Install solar ventilation preheating system",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Other heating",
                                        "Other cooling",
                                        "Other ventilation",
                                        "Other distribution",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:PlugLoadReductions": {
                        "additionalProperties": false,
                        "description": "Appliance and plug-load reductions",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Replace with ENERGY STAR rated",
                                      "Install plug load controls",
                                      "De-lamp vending machines",
                                      "Improve data center efficiency",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Replace with ENERGY STAR rated",
                                        "Install plug load controls",
                                        "De-lamp vending machines",
                                        "Improve data center efficiency",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:Refrigeration": {
                        "additionalProperties": false,
                        "description": "Refrigeration",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Replace ice/refrigeration equipment with high efficiency units",
                                      "Replace air-cooled ice/refrigeration equipment",
                                      "Replace refrigerators",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Replace ice/refrigeration equipment with high efficiency units",
                                        "Replace air-cooled ice/refrigeration equipment",
                                        "Replace refrigerators",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:RenewableEnergySystems": {
                        "additionalProperties": false,
                        "description": "Renewable Energy Systems",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Install landfill gas, wastewater treatment plant digester gas, or coal bed methane power plant",
                                      "Install photovoltaic system",
                                      "Install wind energy system",
                                      "Install wood waste or other organic waste stream heating or power plant",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Install landfill gas, wastewater treatment plant digester gas, or coal bed methane power plant",
                                        "Install photovoltaic system",
                                        "Install wind energy system",
                                        "Install wood waste or other organic waste stream heating or power plant",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:Uncategorized": {
                        "additionalProperties": false,
                        "description": "Category heading for measures that don't fit into another category",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      },
                      "auc:WaterAndSewerConservationSystems": {
                        "additionalProperties": false,
                        "description": "Water and Sewer Conservation Systems",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:MeasureName": {
                            "anyOf": [
                              {
                                "additionalProperties": false,
                                "description": "Short description of measure",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "enum": [
                                      "Install low-flow faucets and showerheads",
                                      "Install low-flow plumbing equipment",
                                      "Install on-site sewer treatment systems",
                                      "Implement water efficient irrigation",
                                      "Clean and/or repair",
                                      "Implement training and/or documentation",
                                      "Upgrade operating protocols, calibration, and/or sequencing",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              },
                              {
                                "items": {
                                  "additionalProperties": false,
                                  "description": "Short description of measure",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "enum": [
                                        "Install low-flow faucets and showerheads",
                                        "Install low-flow plumbing equipment",
                                        "Install on-site sewer treatment systems",
                                        "Implement water efficient irrigation",
                                        "Clean and/or repair",
                                        "Implement training and/or documentation",
                                        "Upgrade operating protocols, calibration, and/or sequencing",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                },
                                "minItems": 1,
                                "type": "array"
                              }
                            ]
                          }
                        },
                        "required": [
                          "auc:MeasureName"
                        ],
                        "type": "object"
                      }
                    },
                    "type": "object"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:TechnologyCategory"
          ],
          "type": "object"
        },
        "auc:TypeOfMeasure": {
          "additionalProperties": false,
          "description": "Type of action associated with the measure",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:Addition": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AlternativeSystemAdded": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of alternative systems that would be added as part of the measure.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of alternative systems that would be added as part of the measure.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:ExistingScheduleAffected": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of schedules replaced by the measure.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of schedules replaced by the measure.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:ModifiedSchedule": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of schedules associated with the improved systems.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of schedules associated with the improved systems.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                }
              },
              "type": "object"
            },
            "auc:ModificationRetrocommissioning": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ExistingScheduleAffected": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of schedules replaced by the measure.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of schedules replaced by the measure.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:ExistingSystemAffected": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of any existing systems affected by the measure.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of any existing systems affected by the measure.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:ModifiedSchedule": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of schedules associated with the improved systems.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of schedules associated with the improved systems.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:ModifiedSystem": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of alternative systems that represent \"improvements\" to existing systems.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of alternative systems that represent \"improvements\" to existing systems.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                }
              },
              "type": "object"
            },
            "auc:Removal": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ExistingScheduleAffected": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of schedules replaced by the measure.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of schedules replaced by the measure.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:ExistingSystemRemoved": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of any existing systems removed as part of the measure.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of any existing systems removed as part of the measure.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:ModifiedSchedule": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of schedules associated with the improved systems.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of schedules associated with the improved systems.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                }
              },
              "type": "object"
            },
            "auc:Replacement": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AlternativeSystemReplacement": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of alternative systems that would replace the existing systems.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of alternative systems that would replace the existing systems.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:ExistingScheduleAffected": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of schedules replaced by the measure.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of schedules replaced by the measure.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:ExistingSystemReplaced": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of any existing systems replaced by the measure.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of any existing systems replaced by the measure.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:ModifiedSchedule": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of schedules associated with the improved systems.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of schedules associated with the improved systems.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:UsefulLife": {
          "additionalProperties": false,
          "description": "Productive life that can be expected of measure or a project. (yrs)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        }
      },
      "type": "object"
    },
    "auc:MotorSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:DriveEfficiency": {
          "additionalProperties": false,
          "description": "A measure of how much power transferred through the drive is lost as heat, expressed as a percentage. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:FullLoadAmps": {
          "additionalProperties": false,
          "description": "Current draw of motor at full capacity. (amps)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LinkedSystemID": {
          "anyOf": [
            {
              "additionalProperties": false,
              "description": "ID number of system(s) supported by this equipment",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "@IDref": {
                  "$ref": "#/definitions/xs:IDREF"
                }
              },
              "required": [
                "@IDref"
              ],
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "description": "ID number of system(s) supported by this equipment",
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "@IDref": {
                    "$ref": "#/definitions/xs:IDREF"
                  }
                },
                "required": [
                  "@IDref"
                ],
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:MotorApplication": {
          "additionalProperties": false,
          "description": "Type of system served by the motor.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Fan",
                "Pump",
                "Conveyance",
                "Plug Load",
                "Process Load",
                "Compressor",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:MotorBrakeHP": {
          "additionalProperties": false,
          "description": "The brake horsepower of the motor before the loss in power caused by the gearbox, alternator, differential, water pump, and other auxiliary components. (hp)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:MotorEfficiency": {
          "additionalProperties": false,
          "description": "Indicates how well the motor converts electrical power into mechanical power and is defined as output power divided by input power expressed as a percentage. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:MotorEnclosureType": {
          "additionalProperties": false,
          "description": "Defines if the motor is open or enclosed",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Open",
                "Enclosed",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:MotorHP": {
          "additionalProperties": false,
          "description": "The nameplate (rated) horsepower of the motor. (hp)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:MotorPoleCount": {
          "additionalProperties": false,
          "description": "The number of pole electromagnetic windings in the motor's stator and used to assign Motor Efficiency. Pole count is always a multiple of 2.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:MotorRPM": {
          "additionalProperties": false,
          "description": "The number of full revolutions in a unit of time and is used to assign MotorEfficiency. 2008 NR ACM table N2-20 has four speeds: 3600 rpm, 1800 rpm, 1200 rpm, 900 rpm.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:OnSiteStorageTransmissionGenerationSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:BackupGenerator": {
          "additionalProperties": false,
          "description": "True if system is only used for backup purposes.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:boolean"
            }
          },
          "type": "object"
        },
        "auc:Capacity": {
          "$ref": "#/definitions/.auc:Capacity"
        },
        "auc:CapacityUnits": {
          "$ref": "#/definitions/.auc:CapacityUnits"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:DemandReduction": {
          "additionalProperties": false,
          "description": "True if system is used for demand reduction purposes",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:boolean"
            }
          },
          "type": "object"
        },
        "auc:EnergyConversionType": {
          "additionalProperties": false,
          "description": "Type of energy conversion provided by the system.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:Generation": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ExternalPowerSupply": {
                  "additionalProperties": false,
                  "description": "Designed to convert line voltage ac input into lower voltage ac or dc output, convert to only one output voltage at a time, contained in a separate physical enclosure from the end-use product, and does not have batteries or battery packs that physically attach directly (including those that are removable) to the power supply unit.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "AC-to-AC",
                        "AC-to-DC",
                        "Low Voltage",
                        "No-Load Mode",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:OnSiteGenerationType": {
                  "additionalProperties": false,
                  "description": "Identifies whether the onsite generation is provided by a photovoltaic system or by another technology.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:Other": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:OtherEnergyGenerationTechnology": {
                          "additionalProperties": false,
                          "description": "Technology utilized on the premises to generate non-purchased energy, including renewable energy that is passively collected. This includes energy collected from the environment such as air, water, or ground-source heat pump systems. Technology equipment may exist as facade systems and roofing systems. Technology equipment may also exist on a premises off of a building envelope including on the ground, awnings, or carports as well as underground.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "enum": [
                                "Standby generator",
                                "Turbine",
                                "Microturbine",
                                "Fuel cell",
                                "Gasification",
                                "Binary Cycle",
                                "Anaerobic biodigester",
                                "Hydrokinetic",
                                "Solar parabolic trough",
                                "Solar power tower",
                                "Other",
                                "Unknown"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:OutputResourceType": {
                          "additionalProperties": false,
                          "description": "Resource or fuel produced by the generation system and used as energy on the premises.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/auc:FuelTypes"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    },
                    "auc:PV": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:PhotovoltaicModuleLength": {
                          "additionalProperties": false,
                          "description": "The total length of the module including the frame. Length here is defined as the longest side of the module. (in.)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:PhotovoltaicModuleRatedPower": {
                          "additionalProperties": false,
                          "description": "The module's rated, maximum-power-point power at standard testing conditions (STC) (SAM Help, 2013). Where STC is defined as light spectrum AM 1.5, cell temperature of 25 degrees Celsius, and irradiance of 1000 W/m2 (IEC 61853-1 Standard 7.2-2010). (W)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:PhotovoltaicModuleWidth": {
                          "additionalProperties": false,
                          "description": "The total width of the module including the frame. Width is here defined as the distance between the two longest module sides. (in.)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:PhotovoltaicSystemArrayAzimuth": {
                          "additionalProperties": false,
                          "description": "Degrees clockwise from North. For a premises, it is the azimuth of the front facing element. It can also be applied to envelope components, such as walls, windows (fenestration), as well as on-site generation technologies, such as photovoltaic panels. Legal Values: 0 - 360. (degrees)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:PhotovoltaicSystemInverterEfficiency": {
                          "additionalProperties": false,
                          "description": "Percentage of power that is converted to usable AC efficiency. (0-1) (%)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:PhotovoltaicSystemLocation": {
                          "additionalProperties": false,
                          "description": "Location where PV system is mounted.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "enum": [
                                "Roof",
                                "Ground",
                                "Building-integrated",
                                "Other",
                                "Unknown"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:PhotovoltaicSystemMaximumPowerOutput": {
                          "additionalProperties": false,
                          "description": "Peak power as supplied by the manufacturer. (Wdc)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:PhotovoltaicSystemNumberOfArrays": {
                          "additionalProperties": false,
                          "description": "Number of arrays in a photovoltaic system.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:integer"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:PhotovoltaicSystemNumberOfModulesPerArray": {
                          "additionalProperties": false,
                          "description": "Number of modules in each array of a photovoltaic system.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:integer"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:PhotovoltaicSystemRackingSystemTiltAngleMax": {
                          "additionalProperties": false,
                          "description": "Maximum PV mounting angle relative to horizontal. Minimum and maximum tilt angles are the same for fixed systems. (degrees)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:PhotovoltaicSystemRackingSystemTiltAngleMin": {
                          "additionalProperties": false,
                          "description": "Minimum PV mounting angle relative to horizontal. Minimum and maximum tilt angles are the same for fixed systems. (degrees)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Storage": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:EnergyStorageTechnology": {
                  "additionalProperties": false,
                  "description": "A few different forms of energy storage systems exist including: potential, kinetic, chemical and thermal. The critical factors of any storage device are application (type and size), costs, cycle efficiency and longevity.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Battery",
                        "Thermal Energy Storage (TES)",
                        "Pumped-Storage Hydroelectricity (PSH)",
                        "Fly wheel",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ThermalMedium": {
                  "additionalProperties": false,
                  "description": "Type of material used in thermal energy storage technology.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Air",
                        "Ice",
                        "Pool water",
                        "Domestic water",
                        "Molten salt",
                        "Sand",
                        "Rock",
                        "Chemical oxides",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:OtherHVACSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:Integration": {
          "additionalProperties": false,
          "description": "Level of integration with primary heating and cooling sources and delivery systems.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Integrated with central air distribution",
                "Integrated with local air distribution",
                "Stand-alone",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LinkedDeliveryID": {
          "additionalProperties": false,
          "description": "Connect to an air distribution system",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:IDREF"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:OtherHVACType": {
          "additionalProperties": false,
          "description": "Type of space conditioning equipment that is not classified as heating, cooling, or air-distribution. This category includes ventilation, dehumidification, humidification, and air cleaning systems.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:AirCleaner": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:Capacity": {
                  "$ref": "#/definitions/.auc:Capacity"
                },
                "auc:CapacityUnits": {
                  "$ref": "#/definitions/.auc:CapacityUnits"
                },
                "auc:DutyCycle": {
                  "$ref": "#/definitions/.auc:DutyCycle"
                },
                "auc:SystemPerformanceRatio": {
                  "$ref": "#/definitions/.auc:SystemPerformanceRatio"
                }
              },
              "type": "object"
            },
            "auc:Dehumidifier": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:Capacity": {
                  "$ref": "#/definitions/.auc:Capacity"
                },
                "auc:CapacityUnits": {
                  "$ref": "#/definitions/.auc:CapacityUnits"
                },
                "auc:DehumidificationType": {
                  "additionalProperties": false,
                  "description": "Dehumidification type in air-distribution system.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Desiccant wheel",
                        "Liquid desiccant",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:DutyCycle": {
                  "$ref": "#/definitions/.auc:DutyCycle"
                },
                "auc:HumidityControlMaximum": {
                  "additionalProperties": false,
                  "description": "Relative humidity above which moisture is removed from the space. (0-1) (%)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:SystemPerformanceRatio": {
                  "$ref": "#/definitions/.auc:SystemPerformanceRatio"
                },
                "auc:ThirdPartyCertification": {
                  "$ref": "#/definitions/.auc:ThirdPartyCertification"
                }
              },
              "type": "object"
            },
            "auc:Humidifier": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:Capacity": {
                  "$ref": "#/definitions/.auc:Capacity"
                },
                "auc:CapacityUnits": {
                  "$ref": "#/definitions/.auc:CapacityUnits"
                },
                "auc:DutyCycle": {
                  "$ref": "#/definitions/.auc:DutyCycle"
                },
                "auc:HumidificationType": {
                  "additionalProperties": false,
                  "description": "Humidification type in air-distribution system.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Steam",
                        "Water Spray",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:HumidityControlMinimum": {
                  "additionalProperties": false,
                  "description": "Relative humidity below which moisture is added to the space. (0-1) (%)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:SystemPerformanceRatio": {
                  "$ref": "#/definitions/.auc:SystemPerformanceRatio"
                }
              },
              "type": "object"
            },
            "auc:MechanicalVentilation": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:Capacity": {
                  "$ref": "#/definitions/.auc:Capacity"
                },
                "auc:CapacityUnits": {
                  "$ref": "#/definitions/.auc:CapacityUnits"
                },
                "auc:DemandControlVentilation": {
                  "additionalProperties": false,
                  "description": "True if ventilation system is controlled based on level of occupancy or pollutants, false otherwise",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:boolean"
                    }
                  },
                  "type": "object"
                },
                "auc:DutyCycle": {
                  "$ref": "#/definitions/.auc:DutyCycle"
                },
                "auc:MakeupAirSourceID": {
                  "additionalProperties": false,
                  "description": "ID number of the Space that provides makeup air for exhaust ventilation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:IDREF"
                    },
                    "@IDref": {
                      "$ref": "#/definitions/xs:IDREF"
                    }
                  },
                  "type": "object"
                },
                "auc:RequiredVentilationRate": {
                  "additionalProperties": false,
                  "description": "Minimum ventilation rate required by local code. (cfm)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:SystemPerformanceRatio": {
                  "$ref": "#/definitions/.auc:SystemPerformanceRatio"
                },
                "auc:ThirdPartyCertification": {
                  "$ref": "#/definitions/.auc:ThirdPartyCertification"
                },
                "auc:VentilationControlMethod": {
                  "$ref": "#/definitions/.auc:VentilationControlMethod"
                },
                "auc:VentilationRate": {
                  "additionalProperties": false,
                  "description": "Installed flow rate for mechanical ventilation system. (cfm)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:VentilationType": {
                  "additionalProperties": false,
                  "description": "Type of ventilation, and use of heat recovery",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Exhaust only",
                        "Supply only",
                        "Dedicated outdoor air system",
                        "Heat recovery ventilator",
                        "Energy recovery ventilator",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:VentilationZoneControl": {
                  "additionalProperties": false,
                  "description": "Method used to determine overall ventilation rate for multiple zones.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Average Flow",
                        "Critical Zone",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:NaturalVentilation": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:Capacity": {
                  "$ref": "#/definitions/.auc:Capacity"
                },
                "auc:CapacityUnits": {
                  "$ref": "#/definitions/.auc:CapacityUnits"
                },
                "auc:DutyCycle": {
                  "$ref": "#/definitions/.auc:DutyCycle"
                },
                "auc:NaturalVentilationMethod": {
                  "additionalProperties": false,
                  "description": "Strategy for introducing natural ventilation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Air changes per hour",
                        "Flow per area",
                        "Flow per person",
                        "Flow per zone",
                        "Wind and stack open area",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:NaturalVentilationRate": {
                  "additionalProperties": false,
                  "description": "Average rate of natural ventilation when used. Units depend on ventilation method. (cfm)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:VentilationControlMethod": {
                  "$ref": "#/definitions/.auc:VentilationControlMethod"
                }
              },
              "type": "object"
            },
            "auc:OtherCombination": {
              "$ref": "#/definitions/xs:anyType"
            },
            "auc:SpotExhaust": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:Capacity": {
                  "$ref": "#/definitions/.auc:Capacity"
                },
                "auc:CapacityUnits": {
                  "$ref": "#/definitions/.auc:CapacityUnits"
                },
                "auc:DutyCycle": {
                  "$ref": "#/definitions/.auc:DutyCycle"
                },
                "auc:ExhaustLocation": {
                  "additionalProperties": false,
                  "description": "Location of spot exhaust ventilation system.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Bathroom",
                        "Kitchen hood",
                        "Laboratory hood",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:MakeupAirSourceID": {
                  "additionalProperties": false,
                  "description": "ID number of the Space that provides makeup air for exhaust ventilation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:IDREF"
                    },
                    "@IDref": {
                      "$ref": "#/definitions/xs:IDREF"
                    }
                  },
                  "type": "object"
                },
                "auc:RequiredVentilationRate": {
                  "additionalProperties": false,
                  "description": "Minimum ventilation rate required by local code. (cfm)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:SystemPerformanceRatio": {
                  "$ref": "#/definitions/.auc:SystemPerformanceRatio"
                },
                "auc:ThirdPartyCertification": {
                  "$ref": "#/definitions/.auc:ThirdPartyCertification"
                },
                "auc:VentilationControlMethod": {
                  "$ref": "#/definitions/.auc:VentilationControlMethod"
                },
                "auc:VentilationRate": {
                  "additionalProperties": false,
                  "description": "Installed flow rate for mechanical ventilation system. (cfm)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Unknown": {
              "$ref": "#/definitions/xs:anyType"
            }
          },
          "type": "object"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:PlugLoadType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:PlugLoadNominalPower": {
          "additionalProperties": false,
          "description": "Nominal electrical load for plug load category. (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PlugLoadPeakPower": {
          "additionalProperties": false,
          "description": "The equipment's rated, maximum-power-point power at standard testing conditions (STC). (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PlugLoadStandbyPower": {
          "additionalProperties": false,
          "description": "Electric power consumed by while the equipment is switched off or in a standby mode. (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PlugLoadType": {
          "additionalProperties": false,
          "description": "General category of plug load, including non-critical IT systems, task lighting, and other small electronic loads.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Personal Computer",
                "Task Lighting",
                "Printing",
                "Cash Register",
                "Audio",
                "Display",
                "Set Top",
                "Business Equipment",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:PoolType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:Heated": {
          "additionalProperties": false,
          "description": "If exists then the pool is heated.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:ControlTechnology": {
              "$ref": "#/definitions/.auc:ControlTechnology"
            },
            "auc:HoursUncovered": {
              "additionalProperties": false,
              "description": "Average hours per day the pool is uncovered. (hrs/day)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            },
            "auc:PrimaryFuel": {
              "$ref": "#/definitions/.auc:PrimaryFuel"
            },
            "auc:WaterTemperature": {
              "additionalProperties": false,
              "description": "Set point for pool heating. (°F)",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "$": {
                  "$ref": "#/definitions/xs:decimal"
                },
                "@auc:Source": {
                  "enum": [
                    "Default",
                    "Estimate",
                    "Agent",
                    "Assessor",
                    "Auditor",
                    "Product specification",
                    "Building Component Library",
                    "Utility transfer",
                    "Energy Management System",
                    "Direct measurement",
                    "Design files",
                    "Simulation",
                    "ENERGY STAR Portfolio Manager",
                    "US EPA",
                    "US EIA",
                    "Target Finder",
                    "Arch2030",
                    "ASHRAE",
                    "Other"
                  ],
                  "type": "string"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:PoolArea": {
          "additionalProperties": false,
          "description": "Surface area of pool. (ft2)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PoolSizeCategory": {
          "additionalProperties": false,
          "description": "Classification of the pool size.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Olympic",
                "Recreational",
                "Short Course",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PoolType": {
          "additionalProperties": false,
          "description": "General category of the pool.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Hot Tub",
                "Pool",
                "Fountain",
                "Water Fall",
                "Stream",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PoolVolume": {
          "additionalProperties": false,
          "description": "Volume of the pool. (gal)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PumpDutyCycle": {
          "additionalProperties": false,
          "description": "Average duty cycle of pool pump. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        }
      },
      "type": "object"
    },
    "auc:ProcessLoadType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:DutyCycle": {
          "additionalProperties": false,
          "description": "Average percentage of installed power over the course of a day. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:HeatGainFraction": {
          "additionalProperties": false,
          "description": "Fraction of installed power that results in heat gain to the space. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:ProcessLoadPeakPower": {
          "additionalProperties": false,
          "description": "The equipment's rated, maximum-power-point power at standard testing conditions (STC). (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ProcessLoadStandbyPower": {
          "additionalProperties": false,
          "description": "Electric power consumed by while the equipment is switched off or in a standby mode. (W)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ProcessLoadType": {
          "additionalProperties": false,
          "description": "Type of gas or electric equipment not categorized elsewhere.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Medical Equipment",
                "Laboratory Equipment",
                "Machinery",
                "Motor",
                "Air Compressor",
                "Fume Hood",
                "Appliance",
                "Gaming/Hobby/Leisure",
                "Infrastructure",
                "Electric Vehicle Charging",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:PumpSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:LinkedSystemID": {
          "anyOf": [
            {
              "additionalProperties": false,
              "description": "ID number of system(s) supported by this equipment",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "@IDref": {
                  "$ref": "#/definitions/xs:IDREF"
                }
              },
              "required": [
                "@IDref"
              ],
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "description": "ID number of system(s) supported by this equipment",
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "@IDref": {
                    "$ref": "#/definitions/xs:IDREF"
                  }
                },
                "required": [
                  "@IDref"
                ],
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:PumpApplication": {
          "additionalProperties": false,
          "description": "Type of system served by the pump.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Boiler",
                "Chilled Water",
                "Domestic Hot Water",
                "Solar Hot Water",
                "Condenser",
                "Cooling Tower",
                "Ground Loop",
                "Pool",
                "Recirculation",
                "Process Hot Water",
                "Process Cold Water",
                "Potable Cold Water",
                "Refrigerant",
                "Air",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PumpControlType": {
          "additionalProperties": false,
          "description": "Type of pump speed control",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Constant Volume",
                "Variable Volume",
                "VFD",
                "Multi-Speed",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PumpEfficiency": {
          "additionalProperties": false,
          "description": "Efficiency of the pump under rated conditions. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PumpInstalledFlowRate": {
          "additionalProperties": false,
          "description": "Actual flow rate of pump under normal operating conditions. (gal/min)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PumpMaximumFlowRate": {
          "additionalProperties": false,
          "description": "The maximum flow rate of fluid through the pump in gallons per minute. (gal/min)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PumpMinimumFlowRate": {
          "additionalProperties": false,
          "description": "The minimum flow rate of fluid through the pump in gallons per minute. (gal/min)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PumpOperation": {
          "additionalProperties": false,
          "description": "Defines how pump operation is controlled.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "On Demand",
                "Standby",
                "Schedule",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PumpPowerDemand": {
          "additionalProperties": false,
          "description": "Pump power at maximum flow rate. (kW)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PumpingConfiguration": {
          "additionalProperties": false,
          "description": "Primary, secondary, or tertiary pump.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Primary",
                "Secondary",
                "Tertiary",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:RankType": {
      "enum": [
        "Fair",
        "Good",
        "Superior"
      ],
      "type": "string"
    },
    "auc:RefrigerationSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:PrimaryFuel": {
          "$ref": "#/definitions/.auc:PrimaryFuel"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:RefrigerationSystemCategory": {
          "additionalProperties": false,
          "description": "Basic type of refrigeration equipment",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:CentralRefrigerationSystem": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:CaseReturnLineDiameter": {
                  "additionalProperties": false,
                  "description": "Diameter of the refrigerant return line exiting refrigerated cases. (in.)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:CondenserPlantID": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers of the condenser plants serving as the source for this cooling system.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers of the condenser plants serving as the source for this cooling system.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:EvaporatorPressureRegulators": {
                  "additionalProperties": false,
                  "description": "True if mechanical or electronic regulators are used to maintain the suction temperature in the individual cases.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:boolean"
                    }
                  },
                  "type": "object"
                },
                "auc:NetRefrigerationCapacity": {
                  "additionalProperties": false,
                  "description": "That portion of the total refrigeration capacity of a liquid cooler that produces useful cooling. This is the product of the mass flow rate of liquid, specific heat of the liquid, and the difference between entering and leaving liquid temperatures, expressed in energy units per unit of time. It is represented also by the total refrigeration capacity less the heat leakage rate (MMBtu/hr)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:NumberOfRefrigerantReturnLines": {
                  "additionalProperties": false,
                  "description": "Number of return lines from refrigerated cases to the compressor.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:integer"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:Refrigerant": {
                  "$ref": "#/definitions/.auc:Refrigerant"
                },
                "auc:RefrigerantSubcooler": {
                  "additionalProperties": false,
                  "description": "True if there is a heat exchanger, after the condenser, for subcooling the condensed refrigerant.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:boolean"
                    }
                  },
                  "type": "object"
                },
                "auc:RefrigerationCompressor": {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "@ID": {
                      "$ref": "#/definitions/xs:ID"
                    },
                    "auc:CompressorUnloader": {
                      "additionalProperties": false,
                      "description": "If exists then a device is used for controlling compressor capacity by rendering one or more cylinders ineffective.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:CompressorUnloaderStages": {
                          "additionalProperties": false,
                          "description": "Number of stages available for unloading the compressor.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:integer"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    },
                    "auc:CrankcaseHeater": {
                      "additionalProperties": false,
                      "description": "True if a crankcase heater is used to prevent condensation when the unit is off.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:boolean"
                        }
                      },
                      "type": "object"
                    },
                    "auc:DesuperheatValve": {
                      "additionalProperties": false,
                      "description": "True if the level of refrigerant superheat is controlled using a desuperheat valve",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:boolean"
                        }
                      },
                      "type": "object"
                    },
                    "auc:RefrigerationCompressorType": {
                      "additionalProperties": false,
                      "description": "Type of compressor in the refrigeration system.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "Reciprocating",
                            "Screw",
                            "Scroll",
                            "Centrifugal",
                            "Other",
                            "Unknown"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                },
                "auc:SuctionVaporTemperature": {
                  "additionalProperties": false,
                  "description": "The temperature of the refrigerant vapor returning to the compressor or condensing unit. (°F)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:TotalHeatRejection": {
                  "additionalProperties": false,
                  "description": "Amount of heat energy rejected to its surroundings by a condenser. (MMBtu/hr)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:RefrigerationUnit": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AntiSweatHeaters": {
                  "additionalProperties": false,
                  "description": "If exists then refrigerated cases include anti-sweat heaters",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:AntiSweatHeaterControls": {
                      "additionalProperties": false,
                      "description": "True if anti-sweat heaters are controlled to minimize energy use",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:boolean"
                        }
                      },
                      "type": "object"
                    },
                    "auc:AntiSweatHeaterPower": {
                      "additionalProperties": false,
                      "description": "The total power associated with anti-sweat heaters for glass display doors for a refrigerated cases of this type. (W)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:Manufacturer": {
                      "$ref": "#/definitions/.auc:Manufacturer"
                    },
                    "auc:ModelNumber": {
                      "$ref": "#/definitions/.auc:ModelNumber"
                    }
                  },
                  "type": "object"
                },
                "auc:CaseDoorOrientation": {
                  "additionalProperties": false,
                  "description": "Orientation of refrigerated case doors used for display cases at stores, food-service establishments.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Horizontal",
                        "Vertical",
                        "Combination",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:DefrostingType": {
                  "additionalProperties": false,
                  "description": "Type of defrost strategy used for refrigerated cases.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Electric",
                        "Off-cycle",
                        "Hot-gas",
                        "Reverse cycle",
                        "Water",
                        "Cool gas",
                        "None",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:DoorConfiguration": {
                  "additionalProperties": false,
                  "description": "Door configuration of the refrigerator/freezer unit.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Side-by-side",
                        "Top and bottom",
                        "Walk-in",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:LampPower": {
                  "additionalProperties": false,
                  "description": "Average power used by lamps in refrigerated cases. (W)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:RefrigeratedCaseDoors": {
                  "additionalProperties": false,
                  "description": "True if refrigerated equipment has doors, false if not.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:boolean"
                    }
                  },
                  "type": "object"
                },
                "auc:RefrigerationEnergy": {
                  "additionalProperties": false,
                  "description": "Power for refrigeration equipment. (W)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:RefrigerationUnitSize": {
                  "additionalProperties": false,
                  "description": "Size of refrigeration equipment. (ft3)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:RefrigerationUnitType": {
                  "additionalProperties": false,
                  "description": "Refrigeration equipment includes a refrigerator or freezer used for storing food products at specified temperatures, with the condensing unit and compressor built into the cabinet, and designed for use by commercial or institutional premises, other than laboratory settings. These units may be vertical or chest configurations and may contain a worktop surface.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Refrigerator",
                        "Freezer",
                        "Combination",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:ResourceUseType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "auc:AnnualFuelUseConsistentUnits": {
          "additionalProperties": false,
          "description": "Sum of all time series values for a particular or typical year, converted into million Btu of site energy. (MMBtu/yr)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:AnnualFuelUseNativeUnits": {
          "additionalProperties": false,
          "description": "Sum of all time series values for the past year, in the original units. (units/yr)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Emissions": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AvoidedEmissions": {
                  "additionalProperties": false,
                  "description": "The avoided Greenhouse gas (GHG) emissions resulting from a renewable energy source or a system. (kgCO2e)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:EmissionBoundary": {
                  "additionalProperties": false,
                  "description": "The boundary that encompasses the measured emissions.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Direct",
                        "Indirect",
                        "Net",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:EmissionsFactor": {
                  "additionalProperties": false,
                  "description": "Emissions factor associated with a Resource. (kg/MMBtu)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:EmissionsFactorSource": {
                  "additionalProperties": false,
                  "description": "Data source for emissions factors.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "EIA (US Energy Information Administration)",
                        "EPA (US Environmental Protection Agency)",
                        "Utility",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:EmissionsType": {
                  "additionalProperties": false,
                  "description": "Category of greenhouse gas or other emission.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Co2e",
                        "Co2",
                        "CH4",
                        "N2O",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:GHGEmissions": {
                  "additionalProperties": false,
                  "description": "Emissions that result in gases that trap heat in the atmosphere. (kgCO2e)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:AvoidedEmissions": {
                    "additionalProperties": false,
                    "description": "The avoided Greenhouse gas (GHG) emissions resulting from a renewable energy source or a system. (kgCO2e)",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:decimal"
                      },
                      "@auc:Source": {
                        "enum": [
                          "Default",
                          "Estimate",
                          "Agent",
                          "Assessor",
                          "Auditor",
                          "Product specification",
                          "Building Component Library",
                          "Utility transfer",
                          "Energy Management System",
                          "Direct measurement",
                          "Design files",
                          "Simulation",
                          "ENERGY STAR Portfolio Manager",
                          "US EPA",
                          "US EIA",
                          "Target Finder",
                          "Arch2030",
                          "ASHRAE",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:EmissionBoundary": {
                    "additionalProperties": false,
                    "description": "The boundary that encompasses the measured emissions.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Direct",
                          "Indirect",
                          "Net",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:EmissionsFactor": {
                    "additionalProperties": false,
                    "description": "Emissions factor associated with a Resource. (kg/MMBtu)",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:decimal"
                      },
                      "@auc:Source": {
                        "enum": [
                          "Default",
                          "Estimate",
                          "Agent",
                          "Assessor",
                          "Auditor",
                          "Product specification",
                          "Building Component Library",
                          "Utility transfer",
                          "Energy Management System",
                          "Direct measurement",
                          "Design files",
                          "Simulation",
                          "ENERGY STAR Portfolio Manager",
                          "US EPA",
                          "US EIA",
                          "Target Finder",
                          "Arch2030",
                          "ASHRAE",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:EmissionsFactorSource": {
                    "additionalProperties": false,
                    "description": "Data source for emissions factors.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "EIA (US Energy Information Administration)",
                          "EPA (US Environmental Protection Agency)",
                          "Utility",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:EmissionsType": {
                    "additionalProperties": false,
                    "description": "Category of greenhouse gas or other emission.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Co2e",
                          "Co2",
                          "CH4",
                          "N2O",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:GHGEmissions": {
                    "additionalProperties": false,
                    "description": "Emissions that result in gases that trap heat in the atmosphere. (kgCO2e)",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:decimal"
                      },
                      "@auc:Source": {
                        "enum": [
                          "Default",
                          "Estimate",
                          "Agent",
                          "Assessor",
                          "Auditor",
                          "Product specification",
                          "Building Component Library",
                          "Utility transfer",
                          "Energy Management System",
                          "Direct measurement",
                          "Design files",
                          "Simulation",
                          "ENERGY STAR Portfolio Manager",
                          "US EPA",
                          "US EIA",
                          "Target Finder",
                          "Arch2030",
                          "ASHRAE",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:EndUse": {
          "additionalProperties": false,
          "description": "End use that the resource primarily applies to.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/auc:EndUse"
            }
          },
          "type": "object"
        },
        "auc:EnergyResource": {
          "$ref": "#/definitions/.auc:EnergyResource"
        },
        "auc:FuelUseIntensity": {
          "additionalProperties": false,
          "description": "Fuel use intensity is the energy associated with the selected fuel type divided by the gross floor area. (units/ft2/yr)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PercentEndUse": {
          "additionalProperties": false,
          "description": "Percentage of total consumption of this fuel type for the specified end use represented by this Energy Use record. (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            }
          },
          "type": "object"
        },
        "auc:PercentResource": {
          "additionalProperties": false,
          "description": "Percentage of total consumption of this fuel type represented by this Energy Use record. (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            }
          },
          "type": "object"
        },
        "auc:ResourceBoundary": {
          "$ref": "#/definitions/.auc:ResourceBoundary"
        },
        "auc:ResourceUnits": {
          "$ref": "#/definitions/.auc:ResourceUnits"
        },
        "auc:SharedResourceSystem": {
          "additionalProperties": false,
          "description": "Situation that applies if a resource is shared with multiple premises, such as shared chilled water among buildings.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Shared energy systems or meters for multiple building on a single lot",
                "Shared energy systems or meter for multiple buildings on multiple lots",
                "Energy system or meter not shared",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:Utility": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ElectricDistributionUtility": {
                  "additionalProperties": false,
                  "description": "The company responsible for maintaining the utility lines and the electric distribution to the property. Note that the EDU is not the just \"the utility company.\" In some states the energy markets are deregulated. This means that a property may contract with Company A to provide the power supply (energy from the power plant), while Company B will continue to provide the electric distribution (Company B is the EDU).",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:FuelInterruptibility": {
                  "additionalProperties": false,
                  "description": "This refers to the practice of supplementing fuel (electricity, natural gas, fuel oil.) by other means when there are interruptions in supply from the utility.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Interruptible",
                        "Firm",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:MeteringConfiguration": {
                  "additionalProperties": false,
                  "description": "The structure of how the various meters are arranged",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Direct metering (tenants directly metered)",
                        "Master meter without sub-metering (Tenants not directly metered or sub-metered)",
                        "Master meter with sub-metering (Tenants sub-metered by building owner)",
                        "Combination",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:PowerPlant": {
                  "additionalProperties": false,
                  "description": "Name of an individual power plant to which the property is directly connected",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:RateSchedule": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "Rate structure characteristics.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@ID": {
                          "$ref": "#/definitions/xs:ID"
                        },
                        "auc:AverageMarginalCostRate": {
                          "additionalProperties": false,
                          "description": "The annual average cost of providing an additional unit of energy or water. Units should be consistent with Resource Units. ($/unit)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:FixedMonthlyCharge": {
                          "additionalProperties": false,
                          "description": "Fixed Monthly Charge is the Meter Charge Rate per meter per day averaged out for a month. ($/month)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:MinimumPowerFactorWithoutPenalty": {
                          "additionalProperties": false,
                          "description": "Minimum power factor that needs to maintained without any penalties. (0-1) (%)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:NetMetering": {
                          "additionalProperties": false,
                          "description": "True if a billing mechanism is employed by utilities to credit onsite energy generation for this rate structure.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:AverageMarginalSellRate": {
                              "additionalProperties": false,
                              "description": "Annual average rate to sell a unit of electricity back to the utility from customer site electricity generation through PV, wind etc. ($/kWh)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            }
                          },
                          "type": "object"
                        },
                        "auc:RateStructureEffectiveDate": {
                          "additionalProperties": false,
                          "description": "The first date the rate schedule becomes applicable. (CCYY-MM-DD)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:date"
                            }
                          },
                          "type": "object"
                        },
                        "auc:RateStructureEndDate": {
                          "additionalProperties": false,
                          "description": "The date at which the rate schedule is no longer applicable. (CCYY-MM-DD)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:date"
                            }
                          },
                          "type": "object"
                        },
                        "auc:RateStructureName": {
                          "additionalProperties": false,
                          "description": "The name or title of the rate structure.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:RateStructureSector": {
                          "additionalProperties": false,
                          "description": "Sector to which the rate structure is applicable.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "enum": [
                                "Residential",
                                "Commercial",
                                "Industrial",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:ReactivePowerCharge": {
                          "additionalProperties": false,
                          "description": "The additional charge for low power factor. ($/kVAR)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:ReferenceForRateStructure": {
                          "additionalProperties": false,
                          "description": "Reference or hyper link for the rate schedule, tariff book",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:TypeOfRateStructure": {
                          "additionalProperties": false,
                          "description": "Basic type of rate structure used by the utility.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "auc:FlatRate": {
                              "additionalProperties": false,
                              "description": "A consumer will pay one flat rate no matter what the usage level is",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:RatePeriod": {
                                  "anyOf": [
                                    {
                                      "additionalProperties": false,
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "auc:ApplicableEndDateForDemandRate": {
                                          "additionalProperties": false,
                                          "description": "The date after which the rate is not applicable. (--MM-DD)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:gMonthDay"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:ApplicableEndDateForEnergyRate": {
                                          "additionalProperties": false,
                                          "description": "The date after which the rate is not applicable. (--MM-DD)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:gMonthDay"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:ApplicableStartDateForDemandRate": {
                                          "additionalProperties": false,
                                          "description": "The date from which the rate is applicable. (--MM-DD)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:gMonthDay"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:ApplicableStartDateForEnergyRate": {
                                          "additionalProperties": false,
                                          "description": "The date from which the rate is applicable. (--MM-DD)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:gMonthDay"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:DemandRatchetPercentage": {
                                          "additionalProperties": false,
                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:DemandRateAdjustment": {
                                          "additionalProperties": false,
                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:DemandWindow": {
                                          "additionalProperties": false,
                                          "description": "The time period of measurement through which the demand is established. (min)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:integer"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:ElectricDemandRate": {
                                          "additionalProperties": false,
                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:EnergyCostRate": {
                                          "additionalProperties": false,
                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:EnergyRateAdjustment": {
                                          "additionalProperties": false,
                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:EnergySellRate": {
                                          "additionalProperties": false,
                                          "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:RatePeriodName": {
                                          "additionalProperties": false,
                                          "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:string"
                                            }
                                          },
                                          "type": "object"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    {
                                      "items": {
                                        "additionalProperties": false,
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "auc:ApplicableEndDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableEndDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:DemandRatchetPercentage": {
                                            "additionalProperties": false,
                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:DemandRateAdjustment": {
                                            "additionalProperties": false,
                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:DemandWindow": {
                                            "additionalProperties": false,
                                            "description": "The time period of measurement through which the demand is established. (min)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:integer"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ElectricDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergyCostRate": {
                                            "additionalProperties": false,
                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergyRateAdjustment": {
                                            "additionalProperties": false,
                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergySellRate": {
                                            "additionalProperties": false,
                                            "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:RatePeriodName": {
                                            "additionalProperties": false,
                                            "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:string"
                                              }
                                            },
                                            "type": "object"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "type": "array"
                                    }
                                  ]
                                }
                              },
                              "type": "object"
                            },
                            "auc:Other": {
                              "$ref": "#/definitions/xs:anyType",
                              "description": "Other type of rate structure, or combination of other types."
                            },
                            "auc:TieredRate": {
                              "anyOf": [
                                {
                                  "additionalProperties": false,
                                  "description": "Tiered rates increase the per-unit price of a utility as usage increases",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "auc:RatePeriod": {
                                      "anyOf": [
                                        {
                                          "additionalProperties": false,
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "auc:ApplicableEndDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergySellRate": {
                                              "additionalProperties": false,
                                              "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:RatePeriodName": {
                                              "additionalProperties": false,
                                              "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:RateTier": {
                                              "anyOf": [
                                                {
                                                  "additionalProperties": false,
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "auc:ConsumptionEnergyTierDesignation": {
                                                      "additionalProperties": false,
                                                      "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:integer"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRatchetPercentage": {
                                                      "additionalProperties": false,
                                                      "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandWindow": {
                                                      "additionalProperties": false,
                                                      "description": "The time period of measurement through which the demand is established. (min)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:integer"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ElectricDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyCostRate": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:MaxkWUsage": {
                                                      "additionalProperties": false,
                                                      "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:MaxkWhUsage": {
                                                      "additionalProperties": false,
                                                      "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                {
                                                  "items": {
                                                    "additionalProperties": false,
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "auc:ConsumptionEnergyTierDesignation": {
                                                        "additionalProperties": false,
                                                        "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRatchetPercentage": {
                                                        "additionalProperties": false,
                                                        "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandWindow": {
                                                        "additionalProperties": false,
                                                        "description": "The time period of measurement through which the demand is established. (min)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ElectricDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyCostRate": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:MaxkWUsage": {
                                                        "additionalProperties": false,
                                                        "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:MaxkWhUsage": {
                                                        "additionalProperties": false,
                                                        "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "type": "array"
                                                }
                                              ]
                                            }
                                          },
                                          "type": "object"
                                        },
                                        {
                                          "items": {
                                            "additionalProperties": false,
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "auc:ApplicableEndDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:ApplicableStartDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date from which the rate is applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:EnergySellRate": {
                                                "additionalProperties": false,
                                                "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:decimal"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:RatePeriodName": {
                                                "additionalProperties": false,
                                                "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:RateTier": {
                                                "anyOf": [
                                                  {
                                                    "additionalProperties": false,
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "auc:ConsumptionEnergyTierDesignation": {
                                                        "additionalProperties": false,
                                                        "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRatchetPercentage": {
                                                        "additionalProperties": false,
                                                        "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandWindow": {
                                                        "additionalProperties": false,
                                                        "description": "The time period of measurement through which the demand is established. (min)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ElectricDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyCostRate": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:MaxkWUsage": {
                                                        "additionalProperties": false,
                                                        "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:MaxkWhUsage": {
                                                        "additionalProperties": false,
                                                        "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  {
                                                    "items": {
                                                      "additionalProperties": false,
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "auc:ConsumptionEnergyTierDesignation": {
                                                          "additionalProperties": false,
                                                          "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRatchetPercentage": {
                                                          "additionalProperties": false,
                                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandWindow": {
                                                          "additionalProperties": false,
                                                          "description": "The time period of measurement through which the demand is established. (min)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ElectricDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyCostRate": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWhUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "type": "array"
                                                  }
                                                ]
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "type": "array"
                                        }
                                      ]
                                    },
                                    "auc:TierDirection": {
                                      "additionalProperties": false,
                                      "description": "Whether the rates increase or decrease as energy use increases.",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "$": {
                                          "enum": [
                                            "Increasing",
                                            "Decreasing",
                                            "Other"
                                          ],
                                          "type": "string"
                                        }
                                      },
                                      "type": "object"
                                    }
                                  },
                                  "type": "object"
                                },
                                {
                                  "items": {
                                    "additionalProperties": false,
                                    "description": "Tiered rates increase the per-unit price of a utility as usage increases",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "auc:RatePeriod": {
                                        "anyOf": [
                                          {
                                            "additionalProperties": false,
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "auc:ApplicableEndDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:ApplicableStartDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date from which the rate is applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:EnergySellRate": {
                                                "additionalProperties": false,
                                                "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:decimal"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:RatePeriodName": {
                                                "additionalProperties": false,
                                                "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:RateTier": {
                                                "anyOf": [
                                                  {
                                                    "additionalProperties": false,
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "auc:ConsumptionEnergyTierDesignation": {
                                                        "additionalProperties": false,
                                                        "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRatchetPercentage": {
                                                        "additionalProperties": false,
                                                        "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandWindow": {
                                                        "additionalProperties": false,
                                                        "description": "The time period of measurement through which the demand is established. (min)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ElectricDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyCostRate": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:MaxkWUsage": {
                                                        "additionalProperties": false,
                                                        "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:MaxkWhUsage": {
                                                        "additionalProperties": false,
                                                        "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  {
                                                    "items": {
                                                      "additionalProperties": false,
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "auc:ConsumptionEnergyTierDesignation": {
                                                          "additionalProperties": false,
                                                          "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRatchetPercentage": {
                                                          "additionalProperties": false,
                                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandWindow": {
                                                          "additionalProperties": false,
                                                          "description": "The time period of measurement through which the demand is established. (min)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ElectricDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyCostRate": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWhUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "type": "array"
                                                  }
                                                ]
                                              }
                                            },
                                            "type": "object"
                                          },
                                          {
                                            "items": {
                                              "additionalProperties": false,
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "auc:ApplicableEndDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:ApplicableStartDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date from which the rate is applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:EnergySellRate": {
                                                  "additionalProperties": false,
                                                  "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:decimal"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RatePeriodName": {
                                                  "additionalProperties": false,
                                                  "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RateTier": {
                                                  "anyOf": [
                                                    {
                                                      "additionalProperties": false,
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "auc:ConsumptionEnergyTierDesignation": {
                                                          "additionalProperties": false,
                                                          "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRatchetPercentage": {
                                                          "additionalProperties": false,
                                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandWindow": {
                                                          "additionalProperties": false,
                                                          "description": "The time period of measurement through which the demand is established. (min)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ElectricDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyCostRate": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWhUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    {
                                                      "items": {
                                                        "additionalProperties": false,
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "auc:ConsumptionEnergyTierDesignation": {
                                                            "additionalProperties": false,
                                                            "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRatchetPercentage": {
                                                            "additionalProperties": false,
                                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandWindow": {
                                                            "additionalProperties": false,
                                                            "description": "The time period of measurement through which the demand is established. (min)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:ElectricDemandRate": {
                                                            "additionalProperties": false,
                                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyCostRate": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWhUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "type": "array"
                                                    }
                                                  ]
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "type": "array"
                                          }
                                        ]
                                      },
                                      "auc:TierDirection": {
                                        "additionalProperties": false,
                                        "description": "Whether the rates increase or decrease as energy use increases.",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Increasing",
                                              "Decreasing",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  "type": "array"
                                }
                              ]
                            },
                            "auc:TimeOfUseRate": {
                              "additionalProperties": false,
                              "description": "TOU rates vary by time of day and time of year",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:RatePeriod": {
                                  "anyOf": [
                                    {
                                      "additionalProperties": false,
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "auc:ApplicableEndDateForDemandRate": {
                                          "additionalProperties": false,
                                          "description": "The date after which the rate is not applicable. (--MM-DD)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:gMonthDay"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:ApplicableEndDateForEnergyRate": {
                                          "additionalProperties": false,
                                          "description": "The date after which the rate is not applicable. (--MM-DD)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:gMonthDay"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:ApplicableStartDateForDemandRate": {
                                          "additionalProperties": false,
                                          "description": "The date from which the rate is applicable. (--MM-DD)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:gMonthDay"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:ApplicableStartDateForEnergyRate": {
                                          "additionalProperties": false,
                                          "description": "The date from which the rate is applicable. (--MM-DD)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:gMonthDay"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:EnergySellRate": {
                                          "additionalProperties": false,
                                          "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:decimal"
                                            },
                                            "@auc:Source": {
                                              "enum": [
                                                "Default",
                                                "Estimate",
                                                "Agent",
                                                "Assessor",
                                                "Auditor",
                                                "Product specification",
                                                "Building Component Library",
                                                "Utility transfer",
                                                "Energy Management System",
                                                "Direct measurement",
                                                "Design files",
                                                "Simulation",
                                                "ENERGY STAR Portfolio Manager",
                                                "US EPA",
                                                "US EIA",
                                                "Target Finder",
                                                "Arch2030",
                                                "ASHRAE",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:RatePeriodName": {
                                          "additionalProperties": false,
                                          "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "$ref": "#/definitions/xs:string"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "auc:TimeOfUsePeriod": {
                                          "anyOf": [
                                            {
                                              "additionalProperties": false,
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "auc:ApplicableEndTimeForDemandRate": {
                                                  "additionalProperties": false,
                                                  "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:ApplicableEndTimeForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:ApplicableStartTimeForDemandRate": {
                                                  "additionalProperties": false,
                                                  "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:ApplicableStartTimeForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:DemandRatchetPercentage": {
                                                  "additionalProperties": false,
                                                  "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:decimal"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:DemandRateAdjustment": {
                                                  "additionalProperties": false,
                                                  "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:decimal"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:DemandWindow": {
                                                  "additionalProperties": false,
                                                  "description": "The time period of measurement through which the demand is established. (min)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:integer"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:ElectricDemandRate": {
                                                  "additionalProperties": false,
                                                  "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:decimal"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:EnergyCostRate": {
                                                  "additionalProperties": false,
                                                  "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:decimal"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:EnergyRateAdjustment": {
                                                  "additionalProperties": false,
                                                  "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:decimal"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:TOUNumberForRateStructure": {
                                                  "additionalProperties": false,
                                                  "description": "The number associated with the TOU period.",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:int"
                                                    }
                                                  },
                                                  "type": "object"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            {
                                              "items": {
                                                "additionalProperties": false,
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "auc:ApplicableEndTimeForDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableEndTimeForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartTimeForDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartTimeForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandRatchetPercentage": {
                                                    "additionalProperties": false,
                                                    "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandRateAdjustment": {
                                                    "additionalProperties": false,
                                                    "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandWindow": {
                                                    "additionalProperties": false,
                                                    "description": "The time period of measurement through which the demand is established. (min)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:integer"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ElectricDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergyCostRate": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergyRateAdjustment": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:TOUNumberForRateStructure": {
                                                    "additionalProperties": false,
                                                    "description": "The number associated with the TOU period.",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:int"
                                                      }
                                                    },
                                                    "type": "object"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "type": "array"
                                            }
                                          ]
                                        }
                                      },
                                      "type": "object"
                                    },
                                    {
                                      "items": {
                                        "additionalProperties": false,
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "auc:ApplicableEndDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableEndDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergySellRate": {
                                            "additionalProperties": false,
                                            "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:RatePeriodName": {
                                            "additionalProperties": false,
                                            "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:TimeOfUsePeriod": {
                                            "anyOf": [
                                              {
                                                "additionalProperties": false,
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "auc:ApplicableEndTimeForDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableEndTimeForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartTimeForDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartTimeForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandRatchetPercentage": {
                                                    "additionalProperties": false,
                                                    "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandRateAdjustment": {
                                                    "additionalProperties": false,
                                                    "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandWindow": {
                                                    "additionalProperties": false,
                                                    "description": "The time period of measurement through which the demand is established. (min)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:integer"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ElectricDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergyCostRate": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergyRateAdjustment": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:TOUNumberForRateStructure": {
                                                    "additionalProperties": false,
                                                    "description": "The number associated with the TOU period.",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:int"
                                                      }
                                                    },
                                                    "type": "object"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              {
                                                "items": {
                                                  "additionalProperties": false,
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "auc:ApplicableEndTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableEndTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRatchetPercentage": {
                                                      "additionalProperties": false,
                                                      "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandWindow": {
                                                      "additionalProperties": false,
                                                      "description": "The time period of measurement through which the demand is established. (min)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:integer"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ElectricDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyCostRate": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:TOUNumberForRateStructure": {
                                                      "additionalProperties": false,
                                                      "description": "The number associated with the TOU period.",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:int"
                                                        }
                                                      },
                                                      "type": "object"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "type": "array"
                                              }
                                            ]
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "type": "array"
                                    }
                                  ]
                                }
                              },
                              "type": "object"
                            },
                            "auc:Unknown": {
                              "$ref": "#/definitions/xs:anyType"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "Rate structure characteristics.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@ID": {
                            "$ref": "#/definitions/xs:ID"
                          },
                          "auc:AverageMarginalCostRate": {
                            "additionalProperties": false,
                            "description": "The annual average cost of providing an additional unit of energy or water. Units should be consistent with Resource Units. ($/unit)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:FixedMonthlyCharge": {
                            "additionalProperties": false,
                            "description": "Fixed Monthly Charge is the Meter Charge Rate per meter per day averaged out for a month. ($/month)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:MinimumPowerFactorWithoutPenalty": {
                            "additionalProperties": false,
                            "description": "Minimum power factor that needs to maintained without any penalties. (0-1) (%)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:NetMetering": {
                            "additionalProperties": false,
                            "description": "True if a billing mechanism is employed by utilities to credit onsite energy generation for this rate structure.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:AverageMarginalSellRate": {
                                "additionalProperties": false,
                                "description": "Annual average rate to sell a unit of electricity back to the utility from customer site electricity generation through PV, wind etc. ($/kWh)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          },
                          "auc:RateStructureEffectiveDate": {
                            "additionalProperties": false,
                            "description": "The first date the rate schedule becomes applicable. (CCYY-MM-DD)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:date"
                              }
                            },
                            "type": "object"
                          },
                          "auc:RateStructureEndDate": {
                            "additionalProperties": false,
                            "description": "The date at which the rate schedule is no longer applicable. (CCYY-MM-DD)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:date"
                              }
                            },
                            "type": "object"
                          },
                          "auc:RateStructureName": {
                            "additionalProperties": false,
                            "description": "The name or title of the rate structure.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:RateStructureSector": {
                            "additionalProperties": false,
                            "description": "Sector to which the rate structure is applicable.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "enum": [
                                  "Residential",
                                  "Commercial",
                                  "Industrial",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:ReactivePowerCharge": {
                            "additionalProperties": false,
                            "description": "The additional charge for low power factor. ($/kVAR)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:ReferenceForRateStructure": {
                            "additionalProperties": false,
                            "description": "Reference or hyper link for the rate schedule, tariff book",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:TypeOfRateStructure": {
                            "additionalProperties": false,
                            "description": "Basic type of rate structure used by the utility.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:FlatRate": {
                                "additionalProperties": false,
                                "description": "A consumer will pay one flat rate no matter what the usage level is",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "auc:RatePeriod": {
                                    "anyOf": [
                                      {
                                        "additionalProperties": false,
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "auc:ApplicableEndDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableEndDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:DemandRatchetPercentage": {
                                            "additionalProperties": false,
                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:DemandRateAdjustment": {
                                            "additionalProperties": false,
                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:DemandWindow": {
                                            "additionalProperties": false,
                                            "description": "The time period of measurement through which the demand is established. (min)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:integer"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ElectricDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergyCostRate": {
                                            "additionalProperties": false,
                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergyRateAdjustment": {
                                            "additionalProperties": false,
                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergySellRate": {
                                            "additionalProperties": false,
                                            "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:RatePeriodName": {
                                            "additionalProperties": false,
                                            "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:string"
                                              }
                                            },
                                            "type": "object"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      {
                                        "items": {
                                          "additionalProperties": false,
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "auc:ApplicableEndDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableEndDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:DemandRatchetPercentage": {
                                              "additionalProperties": false,
                                              "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:DemandRateAdjustment": {
                                              "additionalProperties": false,
                                              "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:DemandWindow": {
                                              "additionalProperties": false,
                                              "description": "The time period of measurement through which the demand is established. (min)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:integer"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ElectricDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The rate to buy electric demand from the utility. ($/kW)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergyCostRate": {
                                              "additionalProperties": false,
                                              "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergyRateAdjustment": {
                                              "additionalProperties": false,
                                              "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergySellRate": {
                                              "additionalProperties": false,
                                              "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:RatePeriodName": {
                                              "additionalProperties": false,
                                              "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:string"
                                                }
                                              },
                                              "type": "object"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "type": "array"
                                      }
                                    ]
                                  }
                                },
                                "type": "object"
                              },
                              "auc:Other": {
                                "$ref": "#/definitions/xs:anyType",
                                "description": "Other type of rate structure, or combination of other types."
                              },
                              "auc:TieredRate": {
                                "anyOf": [
                                  {
                                    "additionalProperties": false,
                                    "description": "Tiered rates increase the per-unit price of a utility as usage increases",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "auc:RatePeriod": {
                                        "anyOf": [
                                          {
                                            "additionalProperties": false,
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "auc:ApplicableEndDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:ApplicableStartDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date from which the rate is applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:EnergySellRate": {
                                                "additionalProperties": false,
                                                "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:decimal"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:RatePeriodName": {
                                                "additionalProperties": false,
                                                "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:RateTier": {
                                                "anyOf": [
                                                  {
                                                    "additionalProperties": false,
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "auc:ConsumptionEnergyTierDesignation": {
                                                        "additionalProperties": false,
                                                        "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRatchetPercentage": {
                                                        "additionalProperties": false,
                                                        "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandWindow": {
                                                        "additionalProperties": false,
                                                        "description": "The time period of measurement through which the demand is established. (min)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ElectricDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyCostRate": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:MaxkWUsage": {
                                                        "additionalProperties": false,
                                                        "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:MaxkWhUsage": {
                                                        "additionalProperties": false,
                                                        "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  {
                                                    "items": {
                                                      "additionalProperties": false,
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "auc:ConsumptionEnergyTierDesignation": {
                                                          "additionalProperties": false,
                                                          "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRatchetPercentage": {
                                                          "additionalProperties": false,
                                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandWindow": {
                                                          "additionalProperties": false,
                                                          "description": "The time period of measurement through which the demand is established. (min)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ElectricDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyCostRate": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWhUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "type": "array"
                                                  }
                                                ]
                                              }
                                            },
                                            "type": "object"
                                          },
                                          {
                                            "items": {
                                              "additionalProperties": false,
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "auc:ApplicableEndDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:ApplicableStartDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date from which the rate is applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:EnergySellRate": {
                                                  "additionalProperties": false,
                                                  "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:decimal"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RatePeriodName": {
                                                  "additionalProperties": false,
                                                  "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RateTier": {
                                                  "anyOf": [
                                                    {
                                                      "additionalProperties": false,
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "auc:ConsumptionEnergyTierDesignation": {
                                                          "additionalProperties": false,
                                                          "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRatchetPercentage": {
                                                          "additionalProperties": false,
                                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandWindow": {
                                                          "additionalProperties": false,
                                                          "description": "The time period of measurement through which the demand is established. (min)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ElectricDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyCostRate": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWhUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    {
                                                      "items": {
                                                        "additionalProperties": false,
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "auc:ConsumptionEnergyTierDesignation": {
                                                            "additionalProperties": false,
                                                            "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRatchetPercentage": {
                                                            "additionalProperties": false,
                                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandWindow": {
                                                            "additionalProperties": false,
                                                            "description": "The time period of measurement through which the demand is established. (min)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:ElectricDemandRate": {
                                                            "additionalProperties": false,
                                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyCostRate": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWhUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "type": "array"
                                                    }
                                                  ]
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "type": "array"
                                          }
                                        ]
                                      },
                                      "auc:TierDirection": {
                                        "additionalProperties": false,
                                        "description": "Whether the rates increase or decrease as energy use increases.",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Increasing",
                                              "Decreasing",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  {
                                    "items": {
                                      "additionalProperties": false,
                                      "description": "Tiered rates increase the per-unit price of a utility as usage increases",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "auc:RatePeriod": {
                                          "anyOf": [
                                            {
                                              "additionalProperties": false,
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "auc:ApplicableEndDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:ApplicableStartDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date from which the rate is applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:EnergySellRate": {
                                                  "additionalProperties": false,
                                                  "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:decimal"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RatePeriodName": {
                                                  "additionalProperties": false,
                                                  "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RateTier": {
                                                  "anyOf": [
                                                    {
                                                      "additionalProperties": false,
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "auc:ConsumptionEnergyTierDesignation": {
                                                          "additionalProperties": false,
                                                          "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRatchetPercentage": {
                                                          "additionalProperties": false,
                                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandWindow": {
                                                          "additionalProperties": false,
                                                          "description": "The time period of measurement through which the demand is established. (min)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ElectricDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyCostRate": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWhUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    {
                                                      "items": {
                                                        "additionalProperties": false,
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "auc:ConsumptionEnergyTierDesignation": {
                                                            "additionalProperties": false,
                                                            "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRatchetPercentage": {
                                                            "additionalProperties": false,
                                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandWindow": {
                                                            "additionalProperties": false,
                                                            "description": "The time period of measurement through which the demand is established. (min)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:ElectricDemandRate": {
                                                            "additionalProperties": false,
                                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyCostRate": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWhUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "type": "array"
                                                    }
                                                  ]
                                                }
                                              },
                                              "type": "object"
                                            },
                                            {
                                              "items": {
                                                "additionalProperties": false,
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "auc:ApplicableEndDateForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartDateForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The date from which the rate is applicable. (--MM-DD)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergySellRate": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:RatePeriodName": {
                                                    "additionalProperties": false,
                                                    "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:RateTier": {
                                                    "anyOf": [
                                                      {
                                                        "additionalProperties": false,
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "auc:ConsumptionEnergyTierDesignation": {
                                                            "additionalProperties": false,
                                                            "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRatchetPercentage": {
                                                            "additionalProperties": false,
                                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandWindow": {
                                                            "additionalProperties": false,
                                                            "description": "The time period of measurement through which the demand is established. (min)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:ElectricDemandRate": {
                                                            "additionalProperties": false,
                                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyCostRate": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWhUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      {
                                                        "items": {
                                                          "additionalProperties": false,
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "auc:ConsumptionEnergyTierDesignation": {
                                                              "additionalProperties": false,
                                                              "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:integer"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandRatchetPercentage": {
                                                              "additionalProperties": false,
                                                              "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandRateAdjustment": {
                                                              "additionalProperties": false,
                                                              "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandWindow": {
                                                              "additionalProperties": false,
                                                              "description": "The time period of measurement through which the demand is established. (min)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:integer"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:ElectricDemandRate": {
                                                              "additionalProperties": false,
                                                              "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:EnergyCostRate": {
                                                              "additionalProperties": false,
                                                              "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:EnergyRateAdjustment": {
                                                              "additionalProperties": false,
                                                              "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:MaxkWUsage": {
                                                              "additionalProperties": false,
                                                              "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:MaxkWhUsage": {
                                                              "additionalProperties": false,
                                                              "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "type": "array"
                                                      }
                                                    ]
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "type": "array"
                                            }
                                          ]
                                        },
                                        "auc:TierDirection": {
                                          "additionalProperties": false,
                                          "description": "Whether the rates increase or decrease as energy use increases.",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "enum": [
                                                "Increasing",
                                                "Decreasing",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "type": "array"
                                  }
                                ]
                              },
                              "auc:TimeOfUseRate": {
                                "additionalProperties": false,
                                "description": "TOU rates vary by time of day and time of year",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "auc:RatePeriod": {
                                    "anyOf": [
                                      {
                                        "additionalProperties": false,
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "auc:ApplicableEndDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableEndDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergySellRate": {
                                            "additionalProperties": false,
                                            "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:RatePeriodName": {
                                            "additionalProperties": false,
                                            "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:TimeOfUsePeriod": {
                                            "anyOf": [
                                              {
                                                "additionalProperties": false,
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "auc:ApplicableEndTimeForDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableEndTimeForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartTimeForDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartTimeForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandRatchetPercentage": {
                                                    "additionalProperties": false,
                                                    "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandRateAdjustment": {
                                                    "additionalProperties": false,
                                                    "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandWindow": {
                                                    "additionalProperties": false,
                                                    "description": "The time period of measurement through which the demand is established. (min)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:integer"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ElectricDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergyCostRate": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergyRateAdjustment": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:TOUNumberForRateStructure": {
                                                    "additionalProperties": false,
                                                    "description": "The number associated with the TOU period.",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:int"
                                                      }
                                                    },
                                                    "type": "object"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              {
                                                "items": {
                                                  "additionalProperties": false,
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "auc:ApplicableEndTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableEndTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRatchetPercentage": {
                                                      "additionalProperties": false,
                                                      "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandWindow": {
                                                      "additionalProperties": false,
                                                      "description": "The time period of measurement through which the demand is established. (min)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:integer"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ElectricDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyCostRate": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:TOUNumberForRateStructure": {
                                                      "additionalProperties": false,
                                                      "description": "The number associated with the TOU period.",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:int"
                                                        }
                                                      },
                                                      "type": "object"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "type": "array"
                                              }
                                            ]
                                          }
                                        },
                                        "type": "object"
                                      },
                                      {
                                        "items": {
                                          "additionalProperties": false,
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "auc:ApplicableEndDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableEndDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergySellRate": {
                                              "additionalProperties": false,
                                              "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:RatePeriodName": {
                                              "additionalProperties": false,
                                              "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:TimeOfUsePeriod": {
                                              "anyOf": [
                                                {
                                                  "additionalProperties": false,
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "auc:ApplicableEndTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableEndTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRatchetPercentage": {
                                                      "additionalProperties": false,
                                                      "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandWindow": {
                                                      "additionalProperties": false,
                                                      "description": "The time period of measurement through which the demand is established. (min)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:integer"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ElectricDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyCostRate": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:TOUNumberForRateStructure": {
                                                      "additionalProperties": false,
                                                      "description": "The number associated with the TOU period.",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:int"
                                                        }
                                                      },
                                                      "type": "object"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                {
                                                  "items": {
                                                    "additionalProperties": false,
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "auc:ApplicableEndTimeForDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableEndTimeForEnergyRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableStartTimeForDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableStartTimeForEnergyRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRatchetPercentage": {
                                                        "additionalProperties": false,
                                                        "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandWindow": {
                                                        "additionalProperties": false,
                                                        "description": "The time period of measurement through which the demand is established. (min)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ElectricDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyCostRate": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:TOUNumberForRateStructure": {
                                                        "additionalProperties": false,
                                                        "description": "The number associated with the TOU period.",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:int"
                                                          }
                                                        },
                                                        "type": "object"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "type": "array"
                                                }
                                              ]
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "type": "array"
                                      }
                                    ]
                                  }
                                },
                                "type": "object"
                              },
                              "auc:Unknown": {
                                "$ref": "#/definitions/xs:anyType"
                              }
                            },
                            "type": "object"
                          }
                        },
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:SourceSiteRatio": {
                  "additionalProperties": false,
                  "description": "Ratio of energy consumed at a central power plant to that delivered to a customer.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:TypeOfResourceMeter": {
                  "additionalProperties": false,
                  "description": "Meters can be divided into several categories based on their capabilities",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Revenue grade meter",
                        "Advanced resource meter",
                        "Analog",
                        "Interval",
                        "Net",
                        "Smart meter",
                        "PDU input meter",
                        "IT equipment input meter",
                        "Supply UPS output meter",
                        "PDU output meter",
                        "Other",
                        "Unknown"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:UtilityAccountNumber": {
                  "additionalProperties": false,
                  "description": "Unique account number designated by the utility",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:UtilityBillpayer": {
                  "additionalProperties": false,
                  "description": "Organization that is responsible for paying the bills associated with this meter.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                },
                "auc:UtilityMeterNumber": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "Unique identification number for the meter",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:string"
                        }
                      },
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "Unique identification number for the meter",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:string"
                          }
                        },
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:UtilityName": {
                  "additionalProperties": false,
                  "description": "Name of utility company billing a Resource.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:ElectricDistributionUtility": {
                    "additionalProperties": false,
                    "description": "The company responsible for maintaining the utility lines and the electric distribution to the property. Note that the EDU is not the just \"the utility company.\" In some states the energy markets are deregulated. This means that a property may contract with Company A to provide the power supply (energy from the power plant), while Company B will continue to provide the electric distribution (Company B is the EDU).",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:FuelInterruptibility": {
                    "additionalProperties": false,
                    "description": "This refers to the practice of supplementing fuel (electricity, natural gas, fuel oil.) by other means when there are interruptions in supply from the utility.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Interruptible",
                          "Firm",
                          "Other",
                          "Unknown"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:MeteringConfiguration": {
                    "additionalProperties": false,
                    "description": "The structure of how the various meters are arranged",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Direct metering (tenants directly metered)",
                          "Master meter without sub-metering (Tenants not directly metered or sub-metered)",
                          "Master meter with sub-metering (Tenants sub-metered by building owner)",
                          "Combination",
                          "Unknown"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:PowerPlant": {
                    "additionalProperties": false,
                    "description": "Name of an individual power plant to which the property is directly connected",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:RateSchedule": {
                    "anyOf": [
                      {
                        "additionalProperties": false,
                        "description": "Rate structure characteristics.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@ID": {
                            "$ref": "#/definitions/xs:ID"
                          },
                          "auc:AverageMarginalCostRate": {
                            "additionalProperties": false,
                            "description": "The annual average cost of providing an additional unit of energy or water. Units should be consistent with Resource Units. ($/unit)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:FixedMonthlyCharge": {
                            "additionalProperties": false,
                            "description": "Fixed Monthly Charge is the Meter Charge Rate per meter per day averaged out for a month. ($/month)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:MinimumPowerFactorWithoutPenalty": {
                            "additionalProperties": false,
                            "description": "Minimum power factor that needs to maintained without any penalties. (0-1) (%)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:NetMetering": {
                            "additionalProperties": false,
                            "description": "True if a billing mechanism is employed by utilities to credit onsite energy generation for this rate structure.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:AverageMarginalSellRate": {
                                "additionalProperties": false,
                                "description": "Annual average rate to sell a unit of electricity back to the utility from customer site electricity generation through PV, wind etc. ($/kWh)",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "$": {
                                    "$ref": "#/definitions/xs:decimal"
                                  },
                                  "@auc:Source": {
                                    "enum": [
                                      "Default",
                                      "Estimate",
                                      "Agent",
                                      "Assessor",
                                      "Auditor",
                                      "Product specification",
                                      "Building Component Library",
                                      "Utility transfer",
                                      "Energy Management System",
                                      "Direct measurement",
                                      "Design files",
                                      "Simulation",
                                      "ENERGY STAR Portfolio Manager",
                                      "US EPA",
                                      "US EIA",
                                      "Target Finder",
                                      "Arch2030",
                                      "ASHRAE",
                                      "Other"
                                    ],
                                    "type": "string"
                                  }
                                },
                                "type": "object"
                              }
                            },
                            "type": "object"
                          },
                          "auc:RateStructureEffectiveDate": {
                            "additionalProperties": false,
                            "description": "The first date the rate schedule becomes applicable. (CCYY-MM-DD)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:date"
                              }
                            },
                            "type": "object"
                          },
                          "auc:RateStructureEndDate": {
                            "additionalProperties": false,
                            "description": "The date at which the rate schedule is no longer applicable. (CCYY-MM-DD)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:date"
                              }
                            },
                            "type": "object"
                          },
                          "auc:RateStructureName": {
                            "additionalProperties": false,
                            "description": "The name or title of the rate structure.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:RateStructureSector": {
                            "additionalProperties": false,
                            "description": "Sector to which the rate structure is applicable.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "enum": [
                                  "Residential",
                                  "Commercial",
                                  "Industrial",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:ReactivePowerCharge": {
                            "additionalProperties": false,
                            "description": "The additional charge for low power factor. ($/kVAR)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:ReferenceForRateStructure": {
                            "additionalProperties": false,
                            "description": "Reference or hyper link for the rate schedule, tariff book",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:TypeOfRateStructure": {
                            "additionalProperties": false,
                            "description": "Basic type of rate structure used by the utility.",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "auc:FlatRate": {
                                "additionalProperties": false,
                                "description": "A consumer will pay one flat rate no matter what the usage level is",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "auc:RatePeriod": {
                                    "anyOf": [
                                      {
                                        "additionalProperties": false,
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "auc:ApplicableEndDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableEndDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:DemandRatchetPercentage": {
                                            "additionalProperties": false,
                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:DemandRateAdjustment": {
                                            "additionalProperties": false,
                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:DemandWindow": {
                                            "additionalProperties": false,
                                            "description": "The time period of measurement through which the demand is established. (min)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:integer"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ElectricDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergyCostRate": {
                                            "additionalProperties": false,
                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergyRateAdjustment": {
                                            "additionalProperties": false,
                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergySellRate": {
                                            "additionalProperties": false,
                                            "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:RatePeriodName": {
                                            "additionalProperties": false,
                                            "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:string"
                                              }
                                            },
                                            "type": "object"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      {
                                        "items": {
                                          "additionalProperties": false,
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "auc:ApplicableEndDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableEndDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:DemandRatchetPercentage": {
                                              "additionalProperties": false,
                                              "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:DemandRateAdjustment": {
                                              "additionalProperties": false,
                                              "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:DemandWindow": {
                                              "additionalProperties": false,
                                              "description": "The time period of measurement through which the demand is established. (min)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:integer"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ElectricDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The rate to buy electric demand from the utility. ($/kW)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergyCostRate": {
                                              "additionalProperties": false,
                                              "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergyRateAdjustment": {
                                              "additionalProperties": false,
                                              "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergySellRate": {
                                              "additionalProperties": false,
                                              "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:RatePeriodName": {
                                              "additionalProperties": false,
                                              "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:string"
                                                }
                                              },
                                              "type": "object"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "type": "array"
                                      }
                                    ]
                                  }
                                },
                                "type": "object"
                              },
                              "auc:Other": {
                                "$ref": "#/definitions/xs:anyType",
                                "description": "Other type of rate structure, or combination of other types."
                              },
                              "auc:TieredRate": {
                                "anyOf": [
                                  {
                                    "additionalProperties": false,
                                    "description": "Tiered rates increase the per-unit price of a utility as usage increases",
                                    "patternProperties": {
                                      "^@xmlns(:\\w+)?$": {
                                        "type": "string"
                                      },
                                      "^@xsi:\\w+$": {
                                        "type": "string"
                                      },
                                      "^\\?\\w+$": {
                                        "type": "string"
                                      }
                                    },
                                    "properties": {
                                      "#": {
                                        "type": "string"
                                      },
                                      "auc:RatePeriod": {
                                        "anyOf": [
                                          {
                                            "additionalProperties": false,
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "auc:ApplicableEndDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:ApplicableStartDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date from which the rate is applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:EnergySellRate": {
                                                "additionalProperties": false,
                                                "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:decimal"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:RatePeriodName": {
                                                "additionalProperties": false,
                                                "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:RateTier": {
                                                "anyOf": [
                                                  {
                                                    "additionalProperties": false,
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "auc:ConsumptionEnergyTierDesignation": {
                                                        "additionalProperties": false,
                                                        "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRatchetPercentage": {
                                                        "additionalProperties": false,
                                                        "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandWindow": {
                                                        "additionalProperties": false,
                                                        "description": "The time period of measurement through which the demand is established. (min)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ElectricDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyCostRate": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:MaxkWUsage": {
                                                        "additionalProperties": false,
                                                        "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:MaxkWhUsage": {
                                                        "additionalProperties": false,
                                                        "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  {
                                                    "items": {
                                                      "additionalProperties": false,
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "auc:ConsumptionEnergyTierDesignation": {
                                                          "additionalProperties": false,
                                                          "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRatchetPercentage": {
                                                          "additionalProperties": false,
                                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandWindow": {
                                                          "additionalProperties": false,
                                                          "description": "The time period of measurement through which the demand is established. (min)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ElectricDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyCostRate": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWhUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "type": "array"
                                                  }
                                                ]
                                              }
                                            },
                                            "type": "object"
                                          },
                                          {
                                            "items": {
                                              "additionalProperties": false,
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "auc:ApplicableEndDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:ApplicableStartDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date from which the rate is applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:EnergySellRate": {
                                                  "additionalProperties": false,
                                                  "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:decimal"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RatePeriodName": {
                                                  "additionalProperties": false,
                                                  "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RateTier": {
                                                  "anyOf": [
                                                    {
                                                      "additionalProperties": false,
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "auc:ConsumptionEnergyTierDesignation": {
                                                          "additionalProperties": false,
                                                          "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRatchetPercentage": {
                                                          "additionalProperties": false,
                                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandWindow": {
                                                          "additionalProperties": false,
                                                          "description": "The time period of measurement through which the demand is established. (min)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ElectricDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyCostRate": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWhUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    {
                                                      "items": {
                                                        "additionalProperties": false,
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "auc:ConsumptionEnergyTierDesignation": {
                                                            "additionalProperties": false,
                                                            "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRatchetPercentage": {
                                                            "additionalProperties": false,
                                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandWindow": {
                                                            "additionalProperties": false,
                                                            "description": "The time period of measurement through which the demand is established. (min)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:ElectricDemandRate": {
                                                            "additionalProperties": false,
                                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyCostRate": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWhUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "type": "array"
                                                    }
                                                  ]
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "type": "array"
                                          }
                                        ]
                                      },
                                      "auc:TierDirection": {
                                        "additionalProperties": false,
                                        "description": "Whether the rates increase or decrease as energy use increases.",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "$": {
                                            "enum": [
                                              "Increasing",
                                              "Decreasing",
                                              "Other"
                                            ],
                                            "type": "string"
                                          }
                                        },
                                        "type": "object"
                                      }
                                    },
                                    "type": "object"
                                  },
                                  {
                                    "items": {
                                      "additionalProperties": false,
                                      "description": "Tiered rates increase the per-unit price of a utility as usage increases",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "auc:RatePeriod": {
                                          "anyOf": [
                                            {
                                              "additionalProperties": false,
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "auc:ApplicableEndDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:ApplicableStartDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date from which the rate is applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:EnergySellRate": {
                                                  "additionalProperties": false,
                                                  "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:decimal"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RatePeriodName": {
                                                  "additionalProperties": false,
                                                  "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RateTier": {
                                                  "anyOf": [
                                                    {
                                                      "additionalProperties": false,
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "auc:ConsumptionEnergyTierDesignation": {
                                                          "additionalProperties": false,
                                                          "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRatchetPercentage": {
                                                          "additionalProperties": false,
                                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandWindow": {
                                                          "additionalProperties": false,
                                                          "description": "The time period of measurement through which the demand is established. (min)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ElectricDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyCostRate": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWhUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    {
                                                      "items": {
                                                        "additionalProperties": false,
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "auc:ConsumptionEnergyTierDesignation": {
                                                            "additionalProperties": false,
                                                            "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRatchetPercentage": {
                                                            "additionalProperties": false,
                                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandWindow": {
                                                            "additionalProperties": false,
                                                            "description": "The time period of measurement through which the demand is established. (min)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:ElectricDemandRate": {
                                                            "additionalProperties": false,
                                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyCostRate": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWhUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "type": "array"
                                                    }
                                                  ]
                                                }
                                              },
                                              "type": "object"
                                            },
                                            {
                                              "items": {
                                                "additionalProperties": false,
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "auc:ApplicableEndDateForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartDateForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The date from which the rate is applicable. (--MM-DD)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergySellRate": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:RatePeriodName": {
                                                    "additionalProperties": false,
                                                    "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:RateTier": {
                                                    "anyOf": [
                                                      {
                                                        "additionalProperties": false,
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "auc:ConsumptionEnergyTierDesignation": {
                                                            "additionalProperties": false,
                                                            "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRatchetPercentage": {
                                                            "additionalProperties": false,
                                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandWindow": {
                                                            "additionalProperties": false,
                                                            "description": "The time period of measurement through which the demand is established. (min)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:ElectricDemandRate": {
                                                            "additionalProperties": false,
                                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyCostRate": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWhUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      {
                                                        "items": {
                                                          "additionalProperties": false,
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "auc:ConsumptionEnergyTierDesignation": {
                                                              "additionalProperties": false,
                                                              "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:integer"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandRatchetPercentage": {
                                                              "additionalProperties": false,
                                                              "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandRateAdjustment": {
                                                              "additionalProperties": false,
                                                              "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandWindow": {
                                                              "additionalProperties": false,
                                                              "description": "The time period of measurement through which the demand is established. (min)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:integer"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:ElectricDemandRate": {
                                                              "additionalProperties": false,
                                                              "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:EnergyCostRate": {
                                                              "additionalProperties": false,
                                                              "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:EnergyRateAdjustment": {
                                                              "additionalProperties": false,
                                                              "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:MaxkWUsage": {
                                                              "additionalProperties": false,
                                                              "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:MaxkWhUsage": {
                                                              "additionalProperties": false,
                                                              "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "type": "array"
                                                      }
                                                    ]
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "type": "array"
                                            }
                                          ]
                                        },
                                        "auc:TierDirection": {
                                          "additionalProperties": false,
                                          "description": "Whether the rates increase or decrease as energy use increases.",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "enum": [
                                                "Increasing",
                                                "Decreasing",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    "type": "array"
                                  }
                                ]
                              },
                              "auc:TimeOfUseRate": {
                                "additionalProperties": false,
                                "description": "TOU rates vary by time of day and time of year",
                                "patternProperties": {
                                  "^@xmlns(:\\w+)?$": {
                                    "type": "string"
                                  },
                                  "^@xsi:\\w+$": {
                                    "type": "string"
                                  },
                                  "^\\?\\w+$": {
                                    "type": "string"
                                  }
                                },
                                "properties": {
                                  "#": {
                                    "type": "string"
                                  },
                                  "auc:RatePeriod": {
                                    "anyOf": [
                                      {
                                        "additionalProperties": false,
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "auc:ApplicableEndDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableEndDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date after which the rate is not applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForDemandRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:ApplicableStartDateForEnergyRate": {
                                            "additionalProperties": false,
                                            "description": "The date from which the rate is applicable. (--MM-DD)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:gMonthDay"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:EnergySellRate": {
                                            "additionalProperties": false,
                                            "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:decimal"
                                              },
                                              "@auc:Source": {
                                                "enum": [
                                                  "Default",
                                                  "Estimate",
                                                  "Agent",
                                                  "Assessor",
                                                  "Auditor",
                                                  "Product specification",
                                                  "Building Component Library",
                                                  "Utility transfer",
                                                  "Energy Management System",
                                                  "Direct measurement",
                                                  "Design files",
                                                  "Simulation",
                                                  "ENERGY STAR Portfolio Manager",
                                                  "US EPA",
                                                  "US EIA",
                                                  "Target Finder",
                                                  "Arch2030",
                                                  "ASHRAE",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:RatePeriodName": {
                                            "additionalProperties": false,
                                            "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "$ref": "#/definitions/xs:string"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "auc:TimeOfUsePeriod": {
                                            "anyOf": [
                                              {
                                                "additionalProperties": false,
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "auc:ApplicableEndTimeForDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableEndTimeForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartTimeForDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartTimeForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandRatchetPercentage": {
                                                    "additionalProperties": false,
                                                    "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandRateAdjustment": {
                                                    "additionalProperties": false,
                                                    "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:DemandWindow": {
                                                    "additionalProperties": false,
                                                    "description": "The time period of measurement through which the demand is established. (min)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:integer"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ElectricDemandRate": {
                                                    "additionalProperties": false,
                                                    "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergyCostRate": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergyRateAdjustment": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:TOUNumberForRateStructure": {
                                                    "additionalProperties": false,
                                                    "description": "The number associated with the TOU period.",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:int"
                                                      }
                                                    },
                                                    "type": "object"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              {
                                                "items": {
                                                  "additionalProperties": false,
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "auc:ApplicableEndTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableEndTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRatchetPercentage": {
                                                      "additionalProperties": false,
                                                      "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandWindow": {
                                                      "additionalProperties": false,
                                                      "description": "The time period of measurement through which the demand is established. (min)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:integer"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ElectricDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyCostRate": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:TOUNumberForRateStructure": {
                                                      "additionalProperties": false,
                                                      "description": "The number associated with the TOU period.",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:int"
                                                        }
                                                      },
                                                      "type": "object"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "type": "array"
                                              }
                                            ]
                                          }
                                        },
                                        "type": "object"
                                      },
                                      {
                                        "items": {
                                          "additionalProperties": false,
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "auc:ApplicableEndDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableEndDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergySellRate": {
                                              "additionalProperties": false,
                                              "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:RatePeriodName": {
                                              "additionalProperties": false,
                                              "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:TimeOfUsePeriod": {
                                              "anyOf": [
                                                {
                                                  "additionalProperties": false,
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "auc:ApplicableEndTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableEndTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRatchetPercentage": {
                                                      "additionalProperties": false,
                                                      "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandWindow": {
                                                      "additionalProperties": false,
                                                      "description": "The time period of measurement through which the demand is established. (min)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:integer"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ElectricDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyCostRate": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:TOUNumberForRateStructure": {
                                                      "additionalProperties": false,
                                                      "description": "The number associated with the TOU period.",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:int"
                                                        }
                                                      },
                                                      "type": "object"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                {
                                                  "items": {
                                                    "additionalProperties": false,
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "auc:ApplicableEndTimeForDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableEndTimeForEnergyRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableStartTimeForDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableStartTimeForEnergyRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRatchetPercentage": {
                                                        "additionalProperties": false,
                                                        "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandWindow": {
                                                        "additionalProperties": false,
                                                        "description": "The time period of measurement through which the demand is established. (min)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ElectricDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyCostRate": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:TOUNumberForRateStructure": {
                                                        "additionalProperties": false,
                                                        "description": "The number associated with the TOU period.",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:int"
                                                          }
                                                        },
                                                        "type": "object"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "type": "array"
                                                }
                                              ]
                                            }
                                          },
                                          "type": "object"
                                        },
                                        "type": "array"
                                      }
                                    ]
                                  }
                                },
                                "type": "object"
                              },
                              "auc:Unknown": {
                                "$ref": "#/definitions/xs:anyType"
                              }
                            },
                            "type": "object"
                          }
                        },
                        "type": "object"
                      },
                      {
                        "items": {
                          "additionalProperties": false,
                          "description": "Rate structure characteristics.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "@ID": {
                              "$ref": "#/definitions/xs:ID"
                            },
                            "auc:AverageMarginalCostRate": {
                              "additionalProperties": false,
                              "description": "The annual average cost of providing an additional unit of energy or water. Units should be consistent with Resource Units. ($/unit)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:FixedMonthlyCharge": {
                              "additionalProperties": false,
                              "description": "Fixed Monthly Charge is the Meter Charge Rate per meter per day averaged out for a month. ($/month)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:MinimumPowerFactorWithoutPenalty": {
                              "additionalProperties": false,
                              "description": "Minimum power factor that needs to maintained without any penalties. (0-1) (%)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:NetMetering": {
                              "additionalProperties": false,
                              "description": "True if a billing mechanism is employed by utilities to credit onsite energy generation for this rate structure.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:AverageMarginalSellRate": {
                                  "additionalProperties": false,
                                  "description": "Annual average rate to sell a unit of electricity back to the utility from customer site electricity generation through PV, wind etc. ($/kWh)",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "$": {
                                      "$ref": "#/definitions/xs:decimal"
                                    },
                                    "@auc:Source": {
                                      "enum": [
                                        "Default",
                                        "Estimate",
                                        "Agent",
                                        "Assessor",
                                        "Auditor",
                                        "Product specification",
                                        "Building Component Library",
                                        "Utility transfer",
                                        "Energy Management System",
                                        "Direct measurement",
                                        "Design files",
                                        "Simulation",
                                        "ENERGY STAR Portfolio Manager",
                                        "US EPA",
                                        "US EIA",
                                        "Target Finder",
                                        "Arch2030",
                                        "ASHRAE",
                                        "Other"
                                      ],
                                      "type": "string"
                                    }
                                  },
                                  "type": "object"
                                }
                              },
                              "type": "object"
                            },
                            "auc:RateStructureEffectiveDate": {
                              "additionalProperties": false,
                              "description": "The first date the rate schedule becomes applicable. (CCYY-MM-DD)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:date"
                                }
                              },
                              "type": "object"
                            },
                            "auc:RateStructureEndDate": {
                              "additionalProperties": false,
                              "description": "The date at which the rate schedule is no longer applicable. (CCYY-MM-DD)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:date"
                                }
                              },
                              "type": "object"
                            },
                            "auc:RateStructureName": {
                              "additionalProperties": false,
                              "description": "The name or title of the rate structure.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:RateStructureSector": {
                              "additionalProperties": false,
                              "description": "Sector to which the rate structure is applicable.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "enum": [
                                    "Residential",
                                    "Commercial",
                                    "Industrial",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:ReactivePowerCharge": {
                              "additionalProperties": false,
                              "description": "The additional charge for low power factor. ($/kVAR)",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:decimal"
                                },
                                "@auc:Source": {
                                  "enum": [
                                    "Default",
                                    "Estimate",
                                    "Agent",
                                    "Assessor",
                                    "Auditor",
                                    "Product specification",
                                    "Building Component Library",
                                    "Utility transfer",
                                    "Energy Management System",
                                    "Direct measurement",
                                    "Design files",
                                    "Simulation",
                                    "ENERGY STAR Portfolio Manager",
                                    "US EPA",
                                    "US EIA",
                                    "Target Finder",
                                    "Arch2030",
                                    "ASHRAE",
                                    "Other"
                                  ],
                                  "type": "string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:ReferenceForRateStructure": {
                              "additionalProperties": false,
                              "description": "Reference or hyper link for the rate schedule, tariff book",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "$": {
                                  "$ref": "#/definitions/xs:string"
                                }
                              },
                              "type": "object"
                            },
                            "auc:TypeOfRateStructure": {
                              "additionalProperties": false,
                              "description": "Basic type of rate structure used by the utility.",
                              "patternProperties": {
                                "^@xmlns(:\\w+)?$": {
                                  "type": "string"
                                },
                                "^@xsi:\\w+$": {
                                  "type": "string"
                                },
                                "^\\?\\w+$": {
                                  "type": "string"
                                }
                              },
                              "properties": {
                                "#": {
                                  "type": "string"
                                },
                                "auc:FlatRate": {
                                  "additionalProperties": false,
                                  "description": "A consumer will pay one flat rate no matter what the usage level is",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "auc:RatePeriod": {
                                      "anyOf": [
                                        {
                                          "additionalProperties": false,
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "auc:ApplicableEndDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableEndDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:DemandRatchetPercentage": {
                                              "additionalProperties": false,
                                              "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:DemandRateAdjustment": {
                                              "additionalProperties": false,
                                              "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:DemandWindow": {
                                              "additionalProperties": false,
                                              "description": "The time period of measurement through which the demand is established. (min)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:integer"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ElectricDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The rate to buy electric demand from the utility. ($/kW)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergyCostRate": {
                                              "additionalProperties": false,
                                              "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergyRateAdjustment": {
                                              "additionalProperties": false,
                                              "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergySellRate": {
                                              "additionalProperties": false,
                                              "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:RatePeriodName": {
                                              "additionalProperties": false,
                                              "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:string"
                                                }
                                              },
                                              "type": "object"
                                            }
                                          },
                                          "type": "object"
                                        },
                                        {
                                          "items": {
                                            "additionalProperties": false,
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "auc:ApplicableEndDateForDemandRate": {
                                                "additionalProperties": false,
                                                "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:ApplicableEndDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:ApplicableStartDateForDemandRate": {
                                                "additionalProperties": false,
                                                "description": "The date from which the rate is applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:ApplicableStartDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date from which the rate is applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:DemandRatchetPercentage": {
                                                "additionalProperties": false,
                                                "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:decimal"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:DemandRateAdjustment": {
                                                "additionalProperties": false,
                                                "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:decimal"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:DemandWindow": {
                                                "additionalProperties": false,
                                                "description": "The time period of measurement through which the demand is established. (min)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:integer"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:ElectricDemandRate": {
                                                "additionalProperties": false,
                                                "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:decimal"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:EnergyCostRate": {
                                                "additionalProperties": false,
                                                "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:decimal"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:EnergyRateAdjustment": {
                                                "additionalProperties": false,
                                                "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:decimal"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:EnergySellRate": {
                                                "additionalProperties": false,
                                                "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:decimal"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:RatePeriodName": {
                                                "additionalProperties": false,
                                                "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:string"
                                                  }
                                                },
                                                "type": "object"
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "type": "array"
                                        }
                                      ]
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:Other": {
                                  "$ref": "#/definitions/xs:anyType",
                                  "description": "Other type of rate structure, or combination of other types."
                                },
                                "auc:TieredRate": {
                                  "anyOf": [
                                    {
                                      "additionalProperties": false,
                                      "description": "Tiered rates increase the per-unit price of a utility as usage increases",
                                      "patternProperties": {
                                        "^@xmlns(:\\w+)?$": {
                                          "type": "string"
                                        },
                                        "^@xsi:\\w+$": {
                                          "type": "string"
                                        },
                                        "^\\?\\w+$": {
                                          "type": "string"
                                        }
                                      },
                                      "properties": {
                                        "#": {
                                          "type": "string"
                                        },
                                        "auc:RatePeriod": {
                                          "anyOf": [
                                            {
                                              "additionalProperties": false,
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "auc:ApplicableEndDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:ApplicableStartDateForEnergyRate": {
                                                  "additionalProperties": false,
                                                  "description": "The date from which the rate is applicable. (--MM-DD)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:gMonthDay"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:EnergySellRate": {
                                                  "additionalProperties": false,
                                                  "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:decimal"
                                                    },
                                                    "@auc:Source": {
                                                      "enum": [
                                                        "Default",
                                                        "Estimate",
                                                        "Agent",
                                                        "Assessor",
                                                        "Auditor",
                                                        "Product specification",
                                                        "Building Component Library",
                                                        "Utility transfer",
                                                        "Energy Management System",
                                                        "Direct measurement",
                                                        "Design files",
                                                        "Simulation",
                                                        "ENERGY STAR Portfolio Manager",
                                                        "US EPA",
                                                        "US EIA",
                                                        "Target Finder",
                                                        "Arch2030",
                                                        "ASHRAE",
                                                        "Other"
                                                      ],
                                                      "type": "string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RatePeriodName": {
                                                  "additionalProperties": false,
                                                  "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "$": {
                                                      "$ref": "#/definitions/xs:string"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "auc:RateTier": {
                                                  "anyOf": [
                                                    {
                                                      "additionalProperties": false,
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "auc:ConsumptionEnergyTierDesignation": {
                                                          "additionalProperties": false,
                                                          "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRatchetPercentage": {
                                                          "additionalProperties": false,
                                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandWindow": {
                                                          "additionalProperties": false,
                                                          "description": "The time period of measurement through which the demand is established. (min)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ElectricDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyCostRate": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:MaxkWhUsage": {
                                                          "additionalProperties": false,
                                                          "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    {
                                                      "items": {
                                                        "additionalProperties": false,
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "auc:ConsumptionEnergyTierDesignation": {
                                                            "additionalProperties": false,
                                                            "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRatchetPercentage": {
                                                            "additionalProperties": false,
                                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandWindow": {
                                                            "additionalProperties": false,
                                                            "description": "The time period of measurement through which the demand is established. (min)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:ElectricDemandRate": {
                                                            "additionalProperties": false,
                                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyCostRate": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWhUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "type": "array"
                                                    }
                                                  ]
                                                }
                                              },
                                              "type": "object"
                                            },
                                            {
                                              "items": {
                                                "additionalProperties": false,
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "auc:ApplicableEndDateForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartDateForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The date from which the rate is applicable. (--MM-DD)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergySellRate": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:RatePeriodName": {
                                                    "additionalProperties": false,
                                                    "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:RateTier": {
                                                    "anyOf": [
                                                      {
                                                        "additionalProperties": false,
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "auc:ConsumptionEnergyTierDesignation": {
                                                            "additionalProperties": false,
                                                            "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRatchetPercentage": {
                                                            "additionalProperties": false,
                                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandWindow": {
                                                            "additionalProperties": false,
                                                            "description": "The time period of measurement through which the demand is established. (min)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:ElectricDemandRate": {
                                                            "additionalProperties": false,
                                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyCostRate": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWhUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      {
                                                        "items": {
                                                          "additionalProperties": false,
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "auc:ConsumptionEnergyTierDesignation": {
                                                              "additionalProperties": false,
                                                              "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:integer"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandRatchetPercentage": {
                                                              "additionalProperties": false,
                                                              "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandRateAdjustment": {
                                                              "additionalProperties": false,
                                                              "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandWindow": {
                                                              "additionalProperties": false,
                                                              "description": "The time period of measurement through which the demand is established. (min)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:integer"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:ElectricDemandRate": {
                                                              "additionalProperties": false,
                                                              "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:EnergyCostRate": {
                                                              "additionalProperties": false,
                                                              "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:EnergyRateAdjustment": {
                                                              "additionalProperties": false,
                                                              "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:MaxkWUsage": {
                                                              "additionalProperties": false,
                                                              "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:MaxkWhUsage": {
                                                              "additionalProperties": false,
                                                              "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "type": "array"
                                                      }
                                                    ]
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "type": "array"
                                            }
                                          ]
                                        },
                                        "auc:TierDirection": {
                                          "additionalProperties": false,
                                          "description": "Whether the rates increase or decrease as energy use increases.",
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "$": {
                                              "enum": [
                                                "Increasing",
                                                "Decreasing",
                                                "Other"
                                              ],
                                              "type": "string"
                                            }
                                          },
                                          "type": "object"
                                        }
                                      },
                                      "type": "object"
                                    },
                                    {
                                      "items": {
                                        "additionalProperties": false,
                                        "description": "Tiered rates increase the per-unit price of a utility as usage increases",
                                        "patternProperties": {
                                          "^@xmlns(:\\w+)?$": {
                                            "type": "string"
                                          },
                                          "^@xsi:\\w+$": {
                                            "type": "string"
                                          },
                                          "^\\?\\w+$": {
                                            "type": "string"
                                          }
                                        },
                                        "properties": {
                                          "#": {
                                            "type": "string"
                                          },
                                          "auc:RatePeriod": {
                                            "anyOf": [
                                              {
                                                "additionalProperties": false,
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "auc:ApplicableEndDateForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:ApplicableStartDateForEnergyRate": {
                                                    "additionalProperties": false,
                                                    "description": "The date from which the rate is applicable. (--MM-DD)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:gMonthDay"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:EnergySellRate": {
                                                    "additionalProperties": false,
                                                    "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:decimal"
                                                      },
                                                      "@auc:Source": {
                                                        "enum": [
                                                          "Default",
                                                          "Estimate",
                                                          "Agent",
                                                          "Assessor",
                                                          "Auditor",
                                                          "Product specification",
                                                          "Building Component Library",
                                                          "Utility transfer",
                                                          "Energy Management System",
                                                          "Direct measurement",
                                                          "Design files",
                                                          "Simulation",
                                                          "ENERGY STAR Portfolio Manager",
                                                          "US EPA",
                                                          "US EIA",
                                                          "Target Finder",
                                                          "Arch2030",
                                                          "ASHRAE",
                                                          "Other"
                                                        ],
                                                        "type": "string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:RatePeriodName": {
                                                    "additionalProperties": false,
                                                    "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "$": {
                                                        "$ref": "#/definitions/xs:string"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "auc:RateTier": {
                                                    "anyOf": [
                                                      {
                                                        "additionalProperties": false,
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "auc:ConsumptionEnergyTierDesignation": {
                                                            "additionalProperties": false,
                                                            "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRatchetPercentage": {
                                                            "additionalProperties": false,
                                                            "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:DemandWindow": {
                                                            "additionalProperties": false,
                                                            "description": "The time period of measurement through which the demand is established. (min)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:integer"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:ElectricDemandRate": {
                                                            "additionalProperties": false,
                                                            "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyCostRate": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:EnergyRateAdjustment": {
                                                            "additionalProperties": false,
                                                            "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "auc:MaxkWhUsage": {
                                                            "additionalProperties": false,
                                                            "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "$": {
                                                                "$ref": "#/definitions/xs:decimal"
                                                              },
                                                              "@auc:Source": {
                                                                "enum": [
                                                                  "Default",
                                                                  "Estimate",
                                                                  "Agent",
                                                                  "Assessor",
                                                                  "Auditor",
                                                                  "Product specification",
                                                                  "Building Component Library",
                                                                  "Utility transfer",
                                                                  "Energy Management System",
                                                                  "Direct measurement",
                                                                  "Design files",
                                                                  "Simulation",
                                                                  "ENERGY STAR Portfolio Manager",
                                                                  "US EPA",
                                                                  "US EIA",
                                                                  "Target Finder",
                                                                  "Arch2030",
                                                                  "ASHRAE",
                                                                  "Other"
                                                                ],
                                                                "type": "string"
                                                              }
                                                            },
                                                            "type": "object"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      {
                                                        "items": {
                                                          "additionalProperties": false,
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "auc:ConsumptionEnergyTierDesignation": {
                                                              "additionalProperties": false,
                                                              "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:integer"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandRatchetPercentage": {
                                                              "additionalProperties": false,
                                                              "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandRateAdjustment": {
                                                              "additionalProperties": false,
                                                              "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandWindow": {
                                                              "additionalProperties": false,
                                                              "description": "The time period of measurement through which the demand is established. (min)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:integer"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:ElectricDemandRate": {
                                                              "additionalProperties": false,
                                                              "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:EnergyCostRate": {
                                                              "additionalProperties": false,
                                                              "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:EnergyRateAdjustment": {
                                                              "additionalProperties": false,
                                                              "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:MaxkWUsage": {
                                                              "additionalProperties": false,
                                                              "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:MaxkWhUsage": {
                                                              "additionalProperties": false,
                                                              "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "type": "array"
                                                      }
                                                    ]
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              {
                                                "items": {
                                                  "additionalProperties": false,
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "auc:ApplicableEndDateForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartDateForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The date from which the rate is applicable. (--MM-DD)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergySellRate": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:RatePeriodName": {
                                                      "additionalProperties": false,
                                                      "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:RateTier": {
                                                      "anyOf": [
                                                        {
                                                          "additionalProperties": false,
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "auc:ConsumptionEnergyTierDesignation": {
                                                              "additionalProperties": false,
                                                              "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:integer"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandRatchetPercentage": {
                                                              "additionalProperties": false,
                                                              "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandRateAdjustment": {
                                                              "additionalProperties": false,
                                                              "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:DemandWindow": {
                                                              "additionalProperties": false,
                                                              "description": "The time period of measurement through which the demand is established. (min)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:integer"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:ElectricDemandRate": {
                                                              "additionalProperties": false,
                                                              "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:EnergyCostRate": {
                                                              "additionalProperties": false,
                                                              "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:EnergyRateAdjustment": {
                                                              "additionalProperties": false,
                                                              "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:MaxkWUsage": {
                                                              "additionalProperties": false,
                                                              "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            },
                                                            "auc:MaxkWhUsage": {
                                                              "additionalProperties": false,
                                                              "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                              "patternProperties": {
                                                                "^@xmlns(:\\w+)?$": {
                                                                  "type": "string"
                                                                },
                                                                "^@xsi:\\w+$": {
                                                                  "type": "string"
                                                                },
                                                                "^\\?\\w+$": {
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "properties": {
                                                                "#": {
                                                                  "type": "string"
                                                                },
                                                                "$": {
                                                                  "$ref": "#/definitions/xs:decimal"
                                                                },
                                                                "@auc:Source": {
                                                                  "enum": [
                                                                    "Default",
                                                                    "Estimate",
                                                                    "Agent",
                                                                    "Assessor",
                                                                    "Auditor",
                                                                    "Product specification",
                                                                    "Building Component Library",
                                                                    "Utility transfer",
                                                                    "Energy Management System",
                                                                    "Direct measurement",
                                                                    "Design files",
                                                                    "Simulation",
                                                                    "ENERGY STAR Portfolio Manager",
                                                                    "US EPA",
                                                                    "US EIA",
                                                                    "Target Finder",
                                                                    "Arch2030",
                                                                    "ASHRAE",
                                                                    "Other"
                                                                  ],
                                                                  "type": "string"
                                                                }
                                                              },
                                                              "type": "object"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        {
                                                          "items": {
                                                            "additionalProperties": false,
                                                            "patternProperties": {
                                                              "^@xmlns(:\\w+)?$": {
                                                                "type": "string"
                                                              },
                                                              "^@xsi:\\w+$": {
                                                                "type": "string"
                                                              },
                                                              "^\\?\\w+$": {
                                                                "type": "string"
                                                              }
                                                            },
                                                            "properties": {
                                                              "#": {
                                                                "type": "string"
                                                              },
                                                              "auc:ConsumptionEnergyTierDesignation": {
                                                                "additionalProperties": false,
                                                                "description": "For electricity pricing that is based on tiered pricing, each tier is allotted a certain maximum (kWh), above which the user is moved to the next tier that has a different unit pricing.",
                                                                "patternProperties": {
                                                                  "^@xmlns(:\\w+)?$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^@xsi:\\w+$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^\\?\\w+$": {
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "properties": {
                                                                  "#": {
                                                                    "type": "string"
                                                                  },
                                                                  "$": {
                                                                    "$ref": "#/definitions/xs:integer"
                                                                  },
                                                                  "@auc:Source": {
                                                                    "enum": [
                                                                      "Default",
                                                                      "Estimate",
                                                                      "Agent",
                                                                      "Assessor",
                                                                      "Auditor",
                                                                      "Product specification",
                                                                      "Building Component Library",
                                                                      "Utility transfer",
                                                                      "Energy Management System",
                                                                      "Direct measurement",
                                                                      "Design files",
                                                                      "Simulation",
                                                                      "ENERGY STAR Portfolio Manager",
                                                                      "US EPA",
                                                                      "US EIA",
                                                                      "Target Finder",
                                                                      "Arch2030",
                                                                      "ASHRAE",
                                                                      "Other"
                                                                    ],
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "type": "object"
                                                              },
                                                              "auc:DemandRatchetPercentage": {
                                                                "additionalProperties": false,
                                                                "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                                "patternProperties": {
                                                                  "^@xmlns(:\\w+)?$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^@xsi:\\w+$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^\\?\\w+$": {
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "properties": {
                                                                  "#": {
                                                                    "type": "string"
                                                                  },
                                                                  "$": {
                                                                    "$ref": "#/definitions/xs:decimal"
                                                                  },
                                                                  "@auc:Source": {
                                                                    "enum": [
                                                                      "Default",
                                                                      "Estimate",
                                                                      "Agent",
                                                                      "Assessor",
                                                                      "Auditor",
                                                                      "Product specification",
                                                                      "Building Component Library",
                                                                      "Utility transfer",
                                                                      "Energy Management System",
                                                                      "Direct measurement",
                                                                      "Design files",
                                                                      "Simulation",
                                                                      "ENERGY STAR Portfolio Manager",
                                                                      "US EPA",
                                                                      "US EIA",
                                                                      "Target Finder",
                                                                      "Arch2030",
                                                                      "ASHRAE",
                                                                      "Other"
                                                                    ],
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "type": "object"
                                                              },
                                                              "auc:DemandRateAdjustment": {
                                                                "additionalProperties": false,
                                                                "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                                "patternProperties": {
                                                                  "^@xmlns(:\\w+)?$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^@xsi:\\w+$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^\\?\\w+$": {
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "properties": {
                                                                  "#": {
                                                                    "type": "string"
                                                                  },
                                                                  "$": {
                                                                    "$ref": "#/definitions/xs:decimal"
                                                                  },
                                                                  "@auc:Source": {
                                                                    "enum": [
                                                                      "Default",
                                                                      "Estimate",
                                                                      "Agent",
                                                                      "Assessor",
                                                                      "Auditor",
                                                                      "Product specification",
                                                                      "Building Component Library",
                                                                      "Utility transfer",
                                                                      "Energy Management System",
                                                                      "Direct measurement",
                                                                      "Design files",
                                                                      "Simulation",
                                                                      "ENERGY STAR Portfolio Manager",
                                                                      "US EPA",
                                                                      "US EIA",
                                                                      "Target Finder",
                                                                      "Arch2030",
                                                                      "ASHRAE",
                                                                      "Other"
                                                                    ],
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "type": "object"
                                                              },
                                                              "auc:DemandWindow": {
                                                                "additionalProperties": false,
                                                                "description": "The time period of measurement through which the demand is established. (min)",
                                                                "patternProperties": {
                                                                  "^@xmlns(:\\w+)?$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^@xsi:\\w+$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^\\?\\w+$": {
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "properties": {
                                                                  "#": {
                                                                    "type": "string"
                                                                  },
                                                                  "$": {
                                                                    "$ref": "#/definitions/xs:integer"
                                                                  },
                                                                  "@auc:Source": {
                                                                    "enum": [
                                                                      "Default",
                                                                      "Estimate",
                                                                      "Agent",
                                                                      "Assessor",
                                                                      "Auditor",
                                                                      "Product specification",
                                                                      "Building Component Library",
                                                                      "Utility transfer",
                                                                      "Energy Management System",
                                                                      "Direct measurement",
                                                                      "Design files",
                                                                      "Simulation",
                                                                      "ENERGY STAR Portfolio Manager",
                                                                      "US EPA",
                                                                      "US EIA",
                                                                      "Target Finder",
                                                                      "Arch2030",
                                                                      "ASHRAE",
                                                                      "Other"
                                                                    ],
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "type": "object"
                                                              },
                                                              "auc:ElectricDemandRate": {
                                                                "additionalProperties": false,
                                                                "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                                "patternProperties": {
                                                                  "^@xmlns(:\\w+)?$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^@xsi:\\w+$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^\\?\\w+$": {
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "properties": {
                                                                  "#": {
                                                                    "type": "string"
                                                                  },
                                                                  "$": {
                                                                    "$ref": "#/definitions/xs:decimal"
                                                                  },
                                                                  "@auc:Source": {
                                                                    "enum": [
                                                                      "Default",
                                                                      "Estimate",
                                                                      "Agent",
                                                                      "Assessor",
                                                                      "Auditor",
                                                                      "Product specification",
                                                                      "Building Component Library",
                                                                      "Utility transfer",
                                                                      "Energy Management System",
                                                                      "Direct measurement",
                                                                      "Design files",
                                                                      "Simulation",
                                                                      "ENERGY STAR Portfolio Manager",
                                                                      "US EPA",
                                                                      "US EIA",
                                                                      "Target Finder",
                                                                      "Arch2030",
                                                                      "ASHRAE",
                                                                      "Other"
                                                                    ],
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "type": "object"
                                                              },
                                                              "auc:EnergyCostRate": {
                                                                "additionalProperties": false,
                                                                "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                                "patternProperties": {
                                                                  "^@xmlns(:\\w+)?$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^@xsi:\\w+$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^\\?\\w+$": {
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "properties": {
                                                                  "#": {
                                                                    "type": "string"
                                                                  },
                                                                  "$": {
                                                                    "$ref": "#/definitions/xs:decimal"
                                                                  },
                                                                  "@auc:Source": {
                                                                    "enum": [
                                                                      "Default",
                                                                      "Estimate",
                                                                      "Agent",
                                                                      "Assessor",
                                                                      "Auditor",
                                                                      "Product specification",
                                                                      "Building Component Library",
                                                                      "Utility transfer",
                                                                      "Energy Management System",
                                                                      "Direct measurement",
                                                                      "Design files",
                                                                      "Simulation",
                                                                      "ENERGY STAR Portfolio Manager",
                                                                      "US EPA",
                                                                      "US EIA",
                                                                      "Target Finder",
                                                                      "Arch2030",
                                                                      "ASHRAE",
                                                                      "Other"
                                                                    ],
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "type": "object"
                                                              },
                                                              "auc:EnergyRateAdjustment": {
                                                                "additionalProperties": false,
                                                                "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                                "patternProperties": {
                                                                  "^@xmlns(:\\w+)?$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^@xsi:\\w+$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^\\?\\w+$": {
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "properties": {
                                                                  "#": {
                                                                    "type": "string"
                                                                  },
                                                                  "$": {
                                                                    "$ref": "#/definitions/xs:decimal"
                                                                  },
                                                                  "@auc:Source": {
                                                                    "enum": [
                                                                      "Default",
                                                                      "Estimate",
                                                                      "Agent",
                                                                      "Assessor",
                                                                      "Auditor",
                                                                      "Product specification",
                                                                      "Building Component Library",
                                                                      "Utility transfer",
                                                                      "Energy Management System",
                                                                      "Direct measurement",
                                                                      "Design files",
                                                                      "Simulation",
                                                                      "ENERGY STAR Portfolio Manager",
                                                                      "US EPA",
                                                                      "US EIA",
                                                                      "Target Finder",
                                                                      "Arch2030",
                                                                      "ASHRAE",
                                                                      "Other"
                                                                    ],
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "type": "object"
                                                              },
                                                              "auc:MaxkWUsage": {
                                                                "additionalProperties": false,
                                                                "description": "The maximum amount of kW used at which a kW rate is applied (kW)",
                                                                "patternProperties": {
                                                                  "^@xmlns(:\\w+)?$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^@xsi:\\w+$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^\\?\\w+$": {
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "properties": {
                                                                  "#": {
                                                                    "type": "string"
                                                                  },
                                                                  "$": {
                                                                    "$ref": "#/definitions/xs:decimal"
                                                                  },
                                                                  "@auc:Source": {
                                                                    "enum": [
                                                                      "Default",
                                                                      "Estimate",
                                                                      "Agent",
                                                                      "Assessor",
                                                                      "Auditor",
                                                                      "Product specification",
                                                                      "Building Component Library",
                                                                      "Utility transfer",
                                                                      "Energy Management System",
                                                                      "Direct measurement",
                                                                      "Design files",
                                                                      "Simulation",
                                                                      "ENERGY STAR Portfolio Manager",
                                                                      "US EPA",
                                                                      "US EIA",
                                                                      "Target Finder",
                                                                      "Arch2030",
                                                                      "ASHRAE",
                                                                      "Other"
                                                                    ],
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "type": "object"
                                                              },
                                                              "auc:MaxkWhUsage": {
                                                                "additionalProperties": false,
                                                                "description": "The maximum amount of kWh used at which a kWh rate is applied (kWh)",
                                                                "patternProperties": {
                                                                  "^@xmlns(:\\w+)?$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^@xsi:\\w+$": {
                                                                    "type": "string"
                                                                  },
                                                                  "^\\?\\w+$": {
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "properties": {
                                                                  "#": {
                                                                    "type": "string"
                                                                  },
                                                                  "$": {
                                                                    "$ref": "#/definitions/xs:decimal"
                                                                  },
                                                                  "@auc:Source": {
                                                                    "enum": [
                                                                      "Default",
                                                                      "Estimate",
                                                                      "Agent",
                                                                      "Assessor",
                                                                      "Auditor",
                                                                      "Product specification",
                                                                      "Building Component Library",
                                                                      "Utility transfer",
                                                                      "Energy Management System",
                                                                      "Direct measurement",
                                                                      "Design files",
                                                                      "Simulation",
                                                                      "ENERGY STAR Portfolio Manager",
                                                                      "US EPA",
                                                                      "US EIA",
                                                                      "Target Finder",
                                                                      "Arch2030",
                                                                      "ASHRAE",
                                                                      "Other"
                                                                    ],
                                                                    "type": "string"
                                                                  }
                                                                },
                                                                "type": "object"
                                                              }
                                                            },
                                                            "type": "object"
                                                          },
                                                          "type": "array"
                                                        }
                                                      ]
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                "type": "array"
                                              }
                                            ]
                                          },
                                          "auc:TierDirection": {
                                            "additionalProperties": false,
                                            "description": "Whether the rates increase or decrease as energy use increases.",
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "$": {
                                                "enum": [
                                                  "Increasing",
                                                  "Decreasing",
                                                  "Other"
                                                ],
                                                "type": "string"
                                              }
                                            },
                                            "type": "object"
                                          }
                                        },
                                        "type": "object"
                                      },
                                      "type": "array"
                                    }
                                  ]
                                },
                                "auc:TimeOfUseRate": {
                                  "additionalProperties": false,
                                  "description": "TOU rates vary by time of day and time of year",
                                  "patternProperties": {
                                    "^@xmlns(:\\w+)?$": {
                                      "type": "string"
                                    },
                                    "^@xsi:\\w+$": {
                                      "type": "string"
                                    },
                                    "^\\?\\w+$": {
                                      "type": "string"
                                    }
                                  },
                                  "properties": {
                                    "#": {
                                      "type": "string"
                                    },
                                    "auc:RatePeriod": {
                                      "anyOf": [
                                        {
                                          "additionalProperties": false,
                                          "patternProperties": {
                                            "^@xmlns(:\\w+)?$": {
                                              "type": "string"
                                            },
                                            "^@xsi:\\w+$": {
                                              "type": "string"
                                            },
                                            "^\\?\\w+$": {
                                              "type": "string"
                                            }
                                          },
                                          "properties": {
                                            "#": {
                                              "type": "string"
                                            },
                                            "auc:ApplicableEndDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableEndDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date after which the rate is not applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForDemandRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:ApplicableStartDateForEnergyRate": {
                                              "additionalProperties": false,
                                              "description": "The date from which the rate is applicable. (--MM-DD)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:gMonthDay"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:EnergySellRate": {
                                              "additionalProperties": false,
                                              "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:decimal"
                                                },
                                                "@auc:Source": {
                                                  "enum": [
                                                    "Default",
                                                    "Estimate",
                                                    "Agent",
                                                    "Assessor",
                                                    "Auditor",
                                                    "Product specification",
                                                    "Building Component Library",
                                                    "Utility transfer",
                                                    "Energy Management System",
                                                    "Direct measurement",
                                                    "Design files",
                                                    "Simulation",
                                                    "ENERGY STAR Portfolio Manager",
                                                    "US EPA",
                                                    "US EIA",
                                                    "Target Finder",
                                                    "Arch2030",
                                                    "ASHRAE",
                                                    "Other"
                                                  ],
                                                  "type": "string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:RatePeriodName": {
                                              "additionalProperties": false,
                                              "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                              "patternProperties": {
                                                "^@xmlns(:\\w+)?$": {
                                                  "type": "string"
                                                },
                                                "^@xsi:\\w+$": {
                                                  "type": "string"
                                                },
                                                "^\\?\\w+$": {
                                                  "type": "string"
                                                }
                                              },
                                              "properties": {
                                                "#": {
                                                  "type": "string"
                                                },
                                                "$": {
                                                  "$ref": "#/definitions/xs:string"
                                                }
                                              },
                                              "type": "object"
                                            },
                                            "auc:TimeOfUsePeriod": {
                                              "anyOf": [
                                                {
                                                  "additionalProperties": false,
                                                  "patternProperties": {
                                                    "^@xmlns(:\\w+)?$": {
                                                      "type": "string"
                                                    },
                                                    "^@xsi:\\w+$": {
                                                      "type": "string"
                                                    },
                                                    "^\\?\\w+$": {
                                                      "type": "string"
                                                    }
                                                  },
                                                  "properties": {
                                                    "#": {
                                                      "type": "string"
                                                    },
                                                    "auc:ApplicableEndTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableEndTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ApplicableStartTimeForEnergyRate": {
                                                      "additionalProperties": false,
                                                      "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:gMonthDay"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRatchetPercentage": {
                                                      "additionalProperties": false,
                                                      "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:DemandWindow": {
                                                      "additionalProperties": false,
                                                      "description": "The time period of measurement through which the demand is established. (min)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:integer"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:ElectricDemandRate": {
                                                      "additionalProperties": false,
                                                      "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyCostRate": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:EnergyRateAdjustment": {
                                                      "additionalProperties": false,
                                                      "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:decimal"
                                                        },
                                                        "@auc:Source": {
                                                          "enum": [
                                                            "Default",
                                                            "Estimate",
                                                            "Agent",
                                                            "Assessor",
                                                            "Auditor",
                                                            "Product specification",
                                                            "Building Component Library",
                                                            "Utility transfer",
                                                            "Energy Management System",
                                                            "Direct measurement",
                                                            "Design files",
                                                            "Simulation",
                                                            "ENERGY STAR Portfolio Manager",
                                                            "US EPA",
                                                            "US EIA",
                                                            "Target Finder",
                                                            "Arch2030",
                                                            "ASHRAE",
                                                            "Other"
                                                          ],
                                                          "type": "string"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "auc:TOUNumberForRateStructure": {
                                                      "additionalProperties": false,
                                                      "description": "The number associated with the TOU period.",
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "$": {
                                                          "$ref": "#/definitions/xs:int"
                                                        }
                                                      },
                                                      "type": "object"
                                                    }
                                                  },
                                                  "type": "object"
                                                },
                                                {
                                                  "items": {
                                                    "additionalProperties": false,
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "auc:ApplicableEndTimeForDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableEndTimeForEnergyRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableStartTimeForDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableStartTimeForEnergyRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRatchetPercentage": {
                                                        "additionalProperties": false,
                                                        "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandWindow": {
                                                        "additionalProperties": false,
                                                        "description": "The time period of measurement through which the demand is established. (min)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ElectricDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyCostRate": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:TOUNumberForRateStructure": {
                                                        "additionalProperties": false,
                                                        "description": "The number associated with the TOU period.",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:int"
                                                          }
                                                        },
                                                        "type": "object"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  "type": "array"
                                                }
                                              ]
                                            }
                                          },
                                          "type": "object"
                                        },
                                        {
                                          "items": {
                                            "additionalProperties": false,
                                            "patternProperties": {
                                              "^@xmlns(:\\w+)?$": {
                                                "type": "string"
                                              },
                                              "^@xsi:\\w+$": {
                                                "type": "string"
                                              },
                                              "^\\?\\w+$": {
                                                "type": "string"
                                              }
                                            },
                                            "properties": {
                                              "#": {
                                                "type": "string"
                                              },
                                              "auc:ApplicableEndDateForDemandRate": {
                                                "additionalProperties": false,
                                                "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:ApplicableEndDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date after which the rate is not applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:ApplicableStartDateForDemandRate": {
                                                "additionalProperties": false,
                                                "description": "The date from which the rate is applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:ApplicableStartDateForEnergyRate": {
                                                "additionalProperties": false,
                                                "description": "The date from which the rate is applicable. (--MM-DD)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:gMonthDay"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:EnergySellRate": {
                                                "additionalProperties": false,
                                                "description": "Energy rate to sell a unit of electricity back to the utility from customer site generation through PV, wind etc. ($/kWh)",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:decimal"
                                                  },
                                                  "@auc:Source": {
                                                    "enum": [
                                                      "Default",
                                                      "Estimate",
                                                      "Agent",
                                                      "Assessor",
                                                      "Auditor",
                                                      "Product specification",
                                                      "Building Component Library",
                                                      "Utility transfer",
                                                      "Energy Management System",
                                                      "Direct measurement",
                                                      "Design files",
                                                      "Simulation",
                                                      "ENERGY STAR Portfolio Manager",
                                                      "US EPA",
                                                      "US EIA",
                                                      "Target Finder",
                                                      "Arch2030",
                                                      "ASHRAE",
                                                      "Other"
                                                    ],
                                                    "type": "string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:RatePeriodName": {
                                                "additionalProperties": false,
                                                "description": "The name or title of rate period.This is intended to capture the seasonal changes in rates.",
                                                "patternProperties": {
                                                  "^@xmlns(:\\w+)?$": {
                                                    "type": "string"
                                                  },
                                                  "^@xsi:\\w+$": {
                                                    "type": "string"
                                                  },
                                                  "^\\?\\w+$": {
                                                    "type": "string"
                                                  }
                                                },
                                                "properties": {
                                                  "#": {
                                                    "type": "string"
                                                  },
                                                  "$": {
                                                    "$ref": "#/definitions/xs:string"
                                                  }
                                                },
                                                "type": "object"
                                              },
                                              "auc:TimeOfUsePeriod": {
                                                "anyOf": [
                                                  {
                                                    "additionalProperties": false,
                                                    "patternProperties": {
                                                      "^@xmlns(:\\w+)?$": {
                                                        "type": "string"
                                                      },
                                                      "^@xsi:\\w+$": {
                                                        "type": "string"
                                                      },
                                                      "^\\?\\w+$": {
                                                        "type": "string"
                                                      }
                                                    },
                                                    "properties": {
                                                      "#": {
                                                        "type": "string"
                                                      },
                                                      "auc:ApplicableEndTimeForDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableEndTimeForEnergyRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableStartTimeForDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ApplicableStartTimeForEnergyRate": {
                                                        "additionalProperties": false,
                                                        "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:gMonthDay"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRatchetPercentage": {
                                                        "additionalProperties": false,
                                                        "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:DemandWindow": {
                                                        "additionalProperties": false,
                                                        "description": "The time period of measurement through which the demand is established. (min)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:integer"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:ElectricDemandRate": {
                                                        "additionalProperties": false,
                                                        "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyCostRate": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:EnergyRateAdjustment": {
                                                        "additionalProperties": false,
                                                        "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:decimal"
                                                          },
                                                          "@auc:Source": {
                                                            "enum": [
                                                              "Default",
                                                              "Estimate",
                                                              "Agent",
                                                              "Assessor",
                                                              "Auditor",
                                                              "Product specification",
                                                              "Building Component Library",
                                                              "Utility transfer",
                                                              "Energy Management System",
                                                              "Direct measurement",
                                                              "Design files",
                                                              "Simulation",
                                                              "ENERGY STAR Portfolio Manager",
                                                              "US EPA",
                                                              "US EIA",
                                                              "Target Finder",
                                                              "Arch2030",
                                                              "ASHRAE",
                                                              "Other"
                                                            ],
                                                            "type": "string"
                                                          }
                                                        },
                                                        "type": "object"
                                                      },
                                                      "auc:TOUNumberForRateStructure": {
                                                        "additionalProperties": false,
                                                        "description": "The number associated with the TOU period.",
                                                        "patternProperties": {
                                                          "^@xmlns(:\\w+)?$": {
                                                            "type": "string"
                                                          },
                                                          "^@xsi:\\w+$": {
                                                            "type": "string"
                                                          },
                                                          "^\\?\\w+$": {
                                                            "type": "string"
                                                          }
                                                        },
                                                        "properties": {
                                                          "#": {
                                                            "type": "string"
                                                          },
                                                          "$": {
                                                            "$ref": "#/definitions/xs:int"
                                                          }
                                                        },
                                                        "type": "object"
                                                      }
                                                    },
                                                    "type": "object"
                                                  },
                                                  {
                                                    "items": {
                                                      "additionalProperties": false,
                                                      "patternProperties": {
                                                        "^@xmlns(:\\w+)?$": {
                                                          "type": "string"
                                                        },
                                                        "^@xsi:\\w+$": {
                                                          "type": "string"
                                                        },
                                                        "^\\?\\w+$": {
                                                          "type": "string"
                                                        }
                                                      },
                                                      "properties": {
                                                        "#": {
                                                          "type": "string"
                                                        },
                                                        "auc:ApplicableEndTimeForDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:gMonthDay"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ApplicableEndTimeForEnergyRate": {
                                                          "additionalProperties": false,
                                                          "description": "The time of day after which the rate is not applicable. (hh:mm:ss)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:gMonthDay"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ApplicableStartTimeForDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:gMonthDay"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ApplicableStartTimeForEnergyRate": {
                                                          "additionalProperties": false,
                                                          "description": "The time of day from which the rate is applicable. (hh:mm:ss)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:gMonthDay"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRatchetPercentage": {
                                                          "additionalProperties": false,
                                                          "description": "Certain rate schedules incorporate demand ratchet percentage to ensure minimum billing demands based on historical peak demands. Billing demand in these cases is based comparing the month's demand and maximum of previous 11 month's demand times the demand ratchet percentage. (0-1) (%)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "The rate for any fees, riders, fuel adjustments ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:DemandWindow": {
                                                          "additionalProperties": false,
                                                          "description": "The time period of measurement through which the demand is established. (min)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:integer"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:ElectricDemandRate": {
                                                          "additionalProperties": false,
                                                          "description": "The rate to buy electric demand from the utility. ($/kW)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyCostRate": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate to buy a unit of energy consumption. ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:EnergyRateAdjustment": {
                                                          "additionalProperties": false,
                                                          "description": "Energy rate adjustment for any fees, riders, fuel adjustments ($/unit)",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:decimal"
                                                            },
                                                            "@auc:Source": {
                                                              "enum": [
                                                                "Default",
                                                                "Estimate",
                                                                "Agent",
                                                                "Assessor",
                                                                "Auditor",
                                                                "Product specification",
                                                                "Building Component Library",
                                                                "Utility transfer",
                                                                "Energy Management System",
                                                                "Direct measurement",
                                                                "Design files",
                                                                "Simulation",
                                                                "ENERGY STAR Portfolio Manager",
                                                                "US EPA",
                                                                "US EIA",
                                                                "Target Finder",
                                                                "Arch2030",
                                                                "ASHRAE",
                                                                "Other"
                                                              ],
                                                              "type": "string"
                                                            }
                                                          },
                                                          "type": "object"
                                                        },
                                                        "auc:TOUNumberForRateStructure": {
                                                          "additionalProperties": false,
                                                          "description": "The number associated with the TOU period.",
                                                          "patternProperties": {
                                                            "^@xmlns(:\\w+)?$": {
                                                              "type": "string"
                                                            },
                                                            "^@xsi:\\w+$": {
                                                              "type": "string"
                                                            },
                                                            "^\\?\\w+$": {
                                                              "type": "string"
                                                            }
                                                          },
                                                          "properties": {
                                                            "#": {
                                                              "type": "string"
                                                            },
                                                            "$": {
                                                              "$ref": "#/definitions/xs:int"
                                                            }
                                                          },
                                                          "type": "object"
                                                        }
                                                      },
                                                      "type": "object"
                                                    },
                                                    "type": "array"
                                                  }
                                                ]
                                              }
                                            },
                                            "type": "object"
                                          },
                                          "type": "array"
                                        }
                                      ]
                                    }
                                  },
                                  "type": "object"
                                },
                                "auc:Unknown": {
                                  "$ref": "#/definitions/xs:anyType"
                                }
                              },
                              "type": "object"
                            }
                          },
                          "type": "object"
                        },
                        "type": "array"
                      }
                    ]
                  },
                  "auc:SourceSiteRatio": {
                    "additionalProperties": false,
                    "description": "Ratio of energy consumed at a central power plant to that delivered to a customer.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:decimal"
                      },
                      "@auc:Source": {
                        "enum": [
                          "Default",
                          "Estimate",
                          "Agent",
                          "Assessor",
                          "Auditor",
                          "Product specification",
                          "Building Component Library",
                          "Utility transfer",
                          "Energy Management System",
                          "Direct measurement",
                          "Design files",
                          "Simulation",
                          "ENERGY STAR Portfolio Manager",
                          "US EPA",
                          "US EIA",
                          "Target Finder",
                          "Arch2030",
                          "ASHRAE",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:TypeOfResourceMeter": {
                    "additionalProperties": false,
                    "description": "Meters can be divided into several categories based on their capabilities",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Revenue grade meter",
                          "Advanced resource meter",
                          "Analog",
                          "Interval",
                          "Net",
                          "Smart meter",
                          "PDU input meter",
                          "IT equipment input meter",
                          "Supply UPS output meter",
                          "PDU output meter",
                          "Other",
                          "Unknown"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:UtilityAccountNumber": {
                    "additionalProperties": false,
                    "description": "Unique account number designated by the utility",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:UtilityBillpayer": {
                    "additionalProperties": false,
                    "description": "Organization that is responsible for paying the bills associated with this meter.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:UtilityMeterNumber": {
                    "anyOf": [
                      {
                        "additionalProperties": false,
                        "description": "Unique identification number for the meter",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:string"
                          }
                        },
                        "type": "object"
                      },
                      {
                        "items": {
                          "additionalProperties": false,
                          "description": "Unique identification number for the meter",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:string"
                            }
                          },
                          "type": "object"
                        },
                        "type": "array"
                      }
                    ]
                  },
                  "auc:UtilityName": {
                    "additionalProperties": false,
                    "description": "Name of utility company billing a Resource.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:string"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:WaterResource": {
          "$ref": "#/definitions/.auc:WaterResource"
        }
      },
      "type": "object"
    },
    "auc:RoofSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:DeckType": {
          "additionalProperties": false,
          "description": "The material used to create the structural integrity in an opaque surface. In many cases the framing material is not continuous across the construction.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Wood",
                "Steel",
                "Concrete",
                "Brick",
                "Masonry",
                "Other",
                "Unknown",
                "None"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:RadiantBarrier": {
          "additionalProperties": false,
          "description": "True if a radiant barrier is installed, false otherwise.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:boolean"
            }
          },
          "type": "object"
        },
        "auc:RoofColor": {
          "additionalProperties": false,
          "description": "Color of a material or component. Can be applied to opaque surfaces, materials, and so forth.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "White",
                "Light",
                "Medium",
                "Med-dark",
                "Dark",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:RoofConstruction": {
          "additionalProperties": false,
          "description": "The general description of the main structural construction method used for an opaque surface.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/auc:EnvelopeConstructionType"
            }
          },
          "type": "object"
        },
        "auc:RoofExteriorSolarAbsorptance": {
          "additionalProperties": false,
          "description": "The fraction of incident radiation in the solar spectrum that is absorbed by the material or surface. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:RoofExteriorThermalAbsorptance": {
          "additionalProperties": false,
          "description": "The fraction of incident long wavelength infrared radiation that is absorbed by the material or surface. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:RoofFinish": {
          "additionalProperties": false,
          "description": "The final material applied to a surface, either interior or exterior. Some structural components don't have an exterior finish, such as unfinished poured concrete.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Wood",
                "Stone",
                "Tile",
                "Brick",
                "Masonry",
                "Concrete",
                "Metal",
                "Metal panel",
                "Metal panel-standing seam",
                "Sheet metal",
                "EIFS",
                "Shingles-asphalt",
                "Shingles-composition",
                "Shingles-wood",
                "Shingles-asbestos",
                "Shingles-slate or tile",
                "Shakes-wood",
                "Carpet",
                "Linoleum",
                "Asphalt or fiberglass",
                "Plastic-rubber-synthetic sheeting",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:RoofFramingDepth": {
          "additionalProperties": false,
          "description": "Dimension of the distance from the front to the back, such as the depth of structural framing in a wall or floor. It can also be the distance from the top to the bottom, such as the depth of a tank or pool of a component or material, such as the depth of the structural framing. (in.)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:RoofFramingFactor": {
          "additionalProperties": false,
          "description": "Fraction of the surface that is composed of structural framing material. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:RoofFramingMaterial": {
          "additionalProperties": false,
          "description": "The material used to create the structural integrity in an opaque surface. In many cases the framing material is not continuous across the construction.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Wood",
                "Steel",
                "Concrete",
                "Brick",
                "Masonry",
                "Other",
                "Unknown",
                "None"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:RoofFramingSpacing": {
          "additionalProperties": false,
          "description": "Dimension of the distance between two components.\n\nExamples include: Framing spacing: the dimension from centerline to centerline of a surface framing material.\n\nWindow spacing: the dimension between windows in a discrete window layout. (in.)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:RoofInsulation": {
          "anyOf": [
            {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:RoofInsulationApplication": {
                  "additionalProperties": false,
                  "description": "A description of the type of insulation and how it is applied.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Loose-fill",
                        "Batt",
                        "Spray-on",
                        "Rigid",
                        "Other",
                        "Unknown",
                        "None"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:RoofInsulationCondition": {
                  "additionalProperties": false,
                  "description": "Assessed condition of installed insulation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Excellent",
                        "Good",
                        "Average",
                        "Poor",
                        "Other",
                        "Unknown",
                        "None"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:RoofInsulationContinuity": {
                  "additionalProperties": false,
                  "description": "Insulation installation type.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Cavity",
                        "Continuous",
                        "Other",
                        "Unknown",
                        "None"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:RoofInsulationMaterial": {
                  "additionalProperties": false,
                  "description": "Material used for the structural component of the surface.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/auc:InsulationMaterialType"
                    }
                  },
                  "type": "object"
                },
                "auc:RoofInsulationThickness": {
                  "additionalProperties": false,
                  "description": "Thickness of roof insulation. (in.)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:RoofInsulationApplication": {
                    "additionalProperties": false,
                    "description": "A description of the type of insulation and how it is applied.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Loose-fill",
                          "Batt",
                          "Spray-on",
                          "Rigid",
                          "Other",
                          "Unknown",
                          "None"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:RoofInsulationCondition": {
                    "additionalProperties": false,
                    "description": "Assessed condition of installed insulation.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Excellent",
                          "Good",
                          "Average",
                          "Poor",
                          "Other",
                          "Unknown",
                          "None"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:RoofInsulationContinuity": {
                    "additionalProperties": false,
                    "description": "Insulation installation type.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Cavity",
                          "Continuous",
                          "Other",
                          "Unknown",
                          "None"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:RoofInsulationMaterial": {
                    "additionalProperties": false,
                    "description": "Material used for the structural component of the surface.",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/auc:InsulationMaterialType"
                      }
                    },
                    "type": "object"
                  },
                  "auc:RoofInsulationThickness": {
                    "additionalProperties": false,
                    "description": "Thickness of roof insulation. (in.)",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:decimal"
                      },
                      "@auc:Source": {
                        "enum": [
                          "Default",
                          "Estimate",
                          "Agent",
                          "Assessor",
                          "Auditor",
                          "Product specification",
                          "Building Component Library",
                          "Utility transfer",
                          "Energy Management System",
                          "Direct measurement",
                          "Design files",
                          "Simulation",
                          "ENERGY STAR Portfolio Manager",
                          "US EPA",
                          "US EIA",
                          "Target Finder",
                          "Arch2030",
                          "ASHRAE",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:RoofRValue": {
          "additionalProperties": false,
          "description": "(Also known as thermal resistance), quantity determined by the temperature difference, at steady state, between two defined surfaces of a material or construction that induces a unit heat flow rate through unit area (R = ΔT/q). R-value is the reciprocal of thermal conductance. A unit of thermal resistance used for comparing insulating values of different materials, for the specific thickness of the material. The higher the R-value number, a material, the greater its insulating properties and the slower the heat flow through it. This R-value does not include the interior and exterior air film coefficients. (hr-ft2-F/Btu)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:RoofSlope": {
          "additionalProperties": false,
          "description": "A descriptive value for tilt, when an exact numeric angle is not known.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Flat",
                "Sloped",
                "> 2:12",
                "< 2:12",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:RoofUFactor": {
          "additionalProperties": false,
          "description": "The thermal transmission in unit time through a unit area of a particular body or assembly, including its boundary films, divided by the difference between the environmental temperatures on either side of the body or assembly. Note that the U-factor for a construction assembly, including fenestration, includes the interior and exterior film coefficients (the boundary films referenced above). (Btu/hr·ft2·°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:RoofVisibleAbsorptance": {
          "additionalProperties": false,
          "description": "The fraction of incident visible wavelength radiation that is absorbed by the material or surface. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:SpecialRoofClassification": {
          "additionalProperties": false,
          "description": "Additional classifications for the roof construction.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Cool roof",
                "Green roof",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        }
      },
      "type": "object"
    },
    "auc:ScenarioType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "auc:AllResourceTotals": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:AllResourceTotal": {
              "anyOf": [
                {
                  "$ref": "#/definitions/auc:AllResourceTotalType"
                },
                {
                  "items": {
                    "$ref": "#/definitions/auc:AllResourceTotalType"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:AllResourceTotal"
          ],
          "type": "object"
        },
        "auc:AnnualCoolingDegreeDays": {
          "additionalProperties": false,
          "description": "Cooling degree days are calculated as the sum of the differences between daily average temperatures and the base temperature, calculated at the ASHRAE base temperature of 65F, unless otherwise specified. Use the Interval Frequency term to characterize whether the HDD calculation is for annual or monthly intervals. (°F-days)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:AnnualHeatingDegreeDays": {
          "additionalProperties": false,
          "description": "Heating degree days are calculated as the sum of the differences between daily average temperatures and the base temperature, calculated at the ASHRAE base temperature of 50F, unless otherwise specified. Use the Interval Frequency term to characterize whether the HDD calculation is for annual or monthly intervals. (°F-days)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CDDBaseTemperature": {
          "additionalProperties": false,
          "description": "Reference temperature for calculating Cooling Degree Days (CDD). (°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:HDDBaseTemperature": {
          "additionalProperties": false,
          "description": "Reference temperature for calculating Heating Degree Days (HDD). (°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Normalization": {
          "additionalProperties": false,
          "description": "Normalization criteria to shift or scaled the measurement, where the intention is that these normalized values allow the comparison of corresponding normalized values for different datasets.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "National Median",
                "Regional Median",
                "Adjusted to specific year",
                "Weather normalized",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ResourceUses": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:ResourceUse": {
              "anyOf": [
                {
                  "$ref": "#/definitions/auc:ResourceUseType"
                },
                {
                  "items": {
                    "$ref": "#/definitions/auc:ResourceUseType"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:ResourceUse"
          ],
          "type": "object"
        },
        "auc:ScenarioName": {
          "additionalProperties": false,
          "description": "Name of the scenario for which energy use data is included. This may include benchmarks, baselines, and improved cases. For retrofits, each package represents a different scenario.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:ScenarioType": {
          "additionalProperties": false,
          "description": "Type of scenario for which energy use is presented.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:Benchmark": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:BenchmarkTool": {
                  "additionalProperties": false,
                  "description": "Benchmarking tools provide a performance ranking based on a peer group of similar buildings.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Portfolio Manager",
                        "Buildings Performance Database Tool",
                        "EnergyIQ",
                        "Labs21",
                        "Fabs21",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:BenchmarkType": {
                  "additionalProperties": false,
                  "description": "Source of energy data or building characteristics for benchmarking energy performance.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:CBECS": {
                      "$ref": "#/definitions/xs:anyType"
                    },
                    "auc:CodeMinimum": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:CalculationMethod": {
                          "$ref": "#/definitions/.auc:CalculationMethod"
                        },
                        "auc:CodeName": {
                          "additionalProperties": false,
                          "description": "The name of an energy efficiency code or standard that is applied to building construction requirements.",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:CodeYear": {
                          "additionalProperties": false,
                          "description": "Date for the Energy Code or Standard used with the Energy Code term. As the energy codes and standards are updated, dates are assigned for version control. There can be significant changes between different year versions, so it is important to capture the year of the standard as it applies to the building in question. (CCYY)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:gYear"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    },
                    "auc:Other": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:CalculationMethod": {
                          "$ref": "#/definitions/.auc:CalculationMethod"
                        },
                        "auc:OtherBenchmarkDescription": {
                          "additionalProperties": false,
                          "description": "General description of the benchmark scenario (e.g. original design, building next door).",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:string"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    },
                    "auc:PortfolioManager": {
                      "$ref": "#/definitions/xs:anyType"
                    },
                    "auc:StandardPractice": {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:CalculationMethod": {
                          "$ref": "#/definitions/.auc:CalculationMethod"
                        },
                        "auc:StandardPracticeDescription": {
                          "additionalProperties": false,
                          "description": "General description of the standard practice represented by this scenario (e.g. builder standard practice, portfolio average, local practice).",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:string"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:CurrentBuilding": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AssetScore": {
                  "$ref": "#/definitions/.auc:AssetScore"
                },
                "auc:CalculationMethod": {
                  "$ref": "#/definitions/.auc:CalculationMethod"
                },
                "auc:ENERGYSTARScore": {
                  "$ref": "#/definitions/.auc:ENERGYSTARScore"
                }
              },
              "type": "object"
            },
            "auc:Other": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AnnualSavingsCost": {
                  "additionalProperties": false,
                  "description": "Cost savings per year, including energy, demand, change in rate schedule, and other cost impacts on utility bills. ($/year)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:integer"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualSavingsSiteEnergy": {
                  "additionalProperties": false,
                  "description": "Site energy savings per year. [MMBtu/year]",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualSavingsSourceEnergy": {
                  "additionalProperties": false,
                  "description": "Source energy savings per year. [MMBtu/year]",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualWaterCostSavings": {
                  "additionalProperties": false,
                  "description": "Total annual reduction in water costs, not including water heating costs (hot and cold). ($/year)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualWaterSavings": {
                  "additionalProperties": false,
                  "description": "Total annual water savings (hot and cold). (gal/year)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AssetScore": {
                  "$ref": "#/definitions/.auc:AssetScore"
                },
                "auc:ENERGYSTARScore": {
                  "$ref": "#/definitions/.auc:ENERGYSTARScore"
                },
                "auc:InternalRateOfReturn": {
                  "additionalProperties": false,
                  "description": "Internal rate of return (IRR) of measure or package (%)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:NetPresentValue": {
                  "additionalProperties": false,
                  "description": "Net Present Value (NPV) of measure or package ($)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ReferenceCase": {
                  "$ref": "#/definitions/.auc:ReferenceCase"
                },
                "auc:SimplePayback": {
                  "additionalProperties": false,
                  "description": "The length of time required for the investment to pay for itself. (yrs)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:SummerPeakElectricityReduction": {
                  "additionalProperties": false,
                  "description": "Reduction in largest 15 minute peak demand for the summer months as defined in the utility rate schedule (for electrical energy use only). (kW)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WinterPeakElectricityReduction": {
                  "additionalProperties": false,
                  "description": "Reduction in largest 15 minute peak demand for the winter months as defined in the utility rate schedule (for electrical energy use only) (kW)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:PackageOfMeasures": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AnnualSavingsByFuel": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "auc:AnnualSavingsNativeUnits": {
                          "additionalProperties": false,
                          "description": "Site energy savings per year for this resource type, in the original units. (units/yr)",
                          "patternProperties": {
                            "^@xmlns(:\\w+)?$": {
                              "type": "string"
                            },
                            "^@xsi:\\w+$": {
                              "type": "string"
                            },
                            "^\\?\\w+$": {
                              "type": "string"
                            }
                          },
                          "properties": {
                            "#": {
                              "type": "string"
                            },
                            "$": {
                              "$ref": "#/definitions/xs:decimal"
                            },
                            "@auc:Source": {
                              "enum": [
                                "Default",
                                "Estimate",
                                "Agent",
                                "Assessor",
                                "Auditor",
                                "Product specification",
                                "Building Component Library",
                                "Utility transfer",
                                "Energy Management System",
                                "Direct measurement",
                                "Design files",
                                "Simulation",
                                "ENERGY STAR Portfolio Manager",
                                "US EPA",
                                "US EIA",
                                "Target Finder",
                                "Arch2030",
                                "ASHRAE",
                                "Other"
                              ],
                              "type": "string"
                            }
                          },
                          "type": "object"
                        },
                        "auc:EnergyResource": {
                          "$ref": "#/definitions/.auc:EnergyResource"
                        },
                        "auc:ResourceUnits": {
                          "$ref": "#/definitions/.auc:ResourceUnits"
                        }
                      },
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "auc:AnnualSavingsNativeUnits": {
                            "additionalProperties": false,
                            "description": "Site energy savings per year for this resource type, in the original units. (units/yr)",
                            "patternProperties": {
                              "^@xmlns(:\\w+)?$": {
                                "type": "string"
                              },
                              "^@xsi:\\w+$": {
                                "type": "string"
                              },
                              "^\\?\\w+$": {
                                "type": "string"
                              }
                            },
                            "properties": {
                              "#": {
                                "type": "string"
                              },
                              "$": {
                                "$ref": "#/definitions/xs:decimal"
                              },
                              "@auc:Source": {
                                "enum": [
                                  "Default",
                                  "Estimate",
                                  "Agent",
                                  "Assessor",
                                  "Auditor",
                                  "Product specification",
                                  "Building Component Library",
                                  "Utility transfer",
                                  "Energy Management System",
                                  "Direct measurement",
                                  "Design files",
                                  "Simulation",
                                  "ENERGY STAR Portfolio Manager",
                                  "US EPA",
                                  "US EIA",
                                  "Target Finder",
                                  "Arch2030",
                                  "ASHRAE",
                                  "Other"
                                ],
                                "type": "string"
                              }
                            },
                            "type": "object"
                          },
                          "auc:EnergyResource": {
                            "$ref": "#/definitions/.auc:EnergyResource"
                          },
                          "auc:ResourceUnits": {
                            "$ref": "#/definitions/.auc:ResourceUnits"
                          }
                        },
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:AnnualSavingsCost": {
                  "additionalProperties": false,
                  "description": "Cost savings per year, including energy, demand, change in rate schedule, and other cost impacts on utility bills. ($/year)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:integer"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualSavingsSiteEnergy": {
                  "additionalProperties": false,
                  "description": "Site energy savings per year. [MMBtu/year]",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualSavingsSourceEnergy": {
                  "additionalProperties": false,
                  "description": "Source energy savings per year. [MMBtu/year]",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualWaterCostSavings": {
                  "additionalProperties": false,
                  "description": "Total annual reduction in water costs, not including water heating costs (hot and cold). ($/year)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualWaterSavings": {
                  "additionalProperties": false,
                  "description": "Total annual water savings (hot and cold). (gal/year)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AssetScore": {
                  "$ref": "#/definitions/.auc:AssetScore"
                },
                "auc:CalculationMethod": {
                  "$ref": "#/definitions/.auc:CalculationMethod"
                },
                "auc:CostEffectivenessScreeningMethod": {
                  "$ref": "#/definitions/.auc:CostEffectivenessScreeningMethod"
                },
                "auc:ENERGYSTARScore": {
                  "$ref": "#/definitions/.auc:ENERGYSTARScore"
                },
                "auc:EquipmentDisposalAndSalvageCosts": {
                  "additionalProperties": false,
                  "description": "The net cost of disposing of equipment being replaced or removed. In some cases the salvage value may exceed disposal costs, resulting in a negative value. ($)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:FundingFromIncentives": {
                  "additionalProperties": false,
                  "description": "Funding obtained through incentives to implement the measure or project ($)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:FundingFromTaxCredits": {
                  "additionalProperties": false,
                  "description": "Funding obtained through utility or state tax credits to implement the measure or project. ($)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ImplementationPeriod": {
                  "additionalProperties": false,
                  "description": "Total period of time necessary to implement all measures in the package. (months)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:integer"
                    }
                  },
                  "type": "object"
                },
                "auc:ImplementationPeriodCostSavings": {
                  "additionalProperties": false,
                  "description": "Total cost savings during the project implementation period. ($)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:InternalRateOfReturn": {
                  "additionalProperties": false,
                  "description": "Internal rate of return (IRR) of measure or package (%)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:MVCost": {
                  "additionalProperties": false,
                  "description": "Annual cost to verify energy savings. ($/year)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:MeasuresID": {
                  "anyOf": [
                    {
                      "additionalProperties": false,
                      "description": "ID numbers for measures included in the package. Multiple items may be selected.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "@IDref": {
                          "$ref": "#/definitions/xs:IDREF"
                        }
                      },
                      "required": [
                        "@IDref"
                      ],
                      "type": "object"
                    },
                    {
                      "items": {
                        "additionalProperties": false,
                        "description": "ID numbers for measures included in the package. Multiple items may be selected.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "@IDref": {
                            "$ref": "#/definitions/xs:IDREF"
                          }
                        },
                        "required": [
                          "@IDref"
                        ],
                        "type": "object"
                      },
                      "type": "array"
                    }
                  ]
                },
                "auc:NPVofTaxImplications": {
                  "additionalProperties": false,
                  "description": "Net present value of impacts on depreciation and other tax deductions. ($)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:NetPresentValue": {
                  "additionalProperties": false,
                  "description": "Net Present Value (NPV) of measure or package ($)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:OMCostAnnualSavings": {
                  "additionalProperties": false,
                  "description": "Annual cost savings for operation, maintenance, and repair. ($)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:OtherFinancialIncentives": {
                  "additionalProperties": false,
                  "description": "Present value of funding gained from other financial incentives over the life of the project. ($)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:integer"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:PackageFirstCost": {
                  "additionalProperties": false,
                  "description": "The sum of the initial expenditures to implement the package of measures; includes items such as equipment, transportation, installation, preparation for service, as well as other related costs for planning, designing, training, and managing the project during the first year. ($)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:PercentGuaranteedSavings": {
                  "additionalProperties": false,
                  "description": "Percentage of cost savings guaranteed by the contractor. (%)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ProjectMarkup": {
                  "additionalProperties": false,
                  "description": "Percent markup applied to implementation costs, if any. (%)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:RecurringIncentives": {
                  "additionalProperties": false,
                  "description": "Funding gained from recurring financial incentives. ($/year)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:integer"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ReferenceCase": {
                  "$ref": "#/definitions/.auc:ReferenceCase"
                },
                "auc:SimplePayback": {
                  "additionalProperties": false,
                  "description": "The length of time required for the investment to pay for itself. (yrs)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:SummerPeakElectricityReduction": {
                  "additionalProperties": false,
                  "description": "Reduction in largest 15 minute peak demand for the summer months as defined in the utility rate schedule (for electrical energy use only). (kW)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WinterPeakElectricityReduction": {
                  "additionalProperties": false,
                  "description": "Reduction in largest 15 minute peak demand for the winter months as defined in the utility rate schedule (for electrical energy use only) (kW)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Target": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:AnnualSavingsCost": {
                  "additionalProperties": false,
                  "description": "Cost savings per year, including energy, demand, change in rate schedule, and other cost impacts on utility bills. ($/year)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:integer"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualSavingsSiteEnergy": {
                  "additionalProperties": false,
                  "description": "Site energy savings per year. [MMBtu/year]",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualSavingsSourceEnergy": {
                  "additionalProperties": false,
                  "description": "Source energy savings per year. [MMBtu/year]",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualWaterCostSavings": {
                  "additionalProperties": false,
                  "description": "Total annual reduction in water costs, not including water heating costs (hot and cold). ($/year)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AnnualWaterSavings": {
                  "additionalProperties": false,
                  "description": "Total annual water savings (hot and cold). (gal/year)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:AssetScore": {
                  "$ref": "#/definitions/.auc:AssetScore"
                },
                "auc:ENERGYSTARScore": {
                  "$ref": "#/definitions/.auc:ENERGYSTARScore"
                },
                "auc:InternalRateOfReturn": {
                  "additionalProperties": false,
                  "description": "Internal rate of return (IRR) of measure or package (%)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:NetPresentValue": {
                  "additionalProperties": false,
                  "description": "Net Present Value (NPV) of measure or package ($)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ReferenceCase": {
                  "$ref": "#/definitions/.auc:ReferenceCase"
                },
                "auc:SimplePayback": {
                  "additionalProperties": false,
                  "description": "The length of time required for the investment to pay for itself. (yrs)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:SummerPeakElectricityReduction": {
                  "additionalProperties": false,
                  "description": "Reduction in largest 15 minute peak demand for the summer months as defined in the utility rate schedule (for electrical energy use only). (kW)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WinterPeakElectricityReduction": {
                  "additionalProperties": false,
                  "description": "Reduction in largest 15 minute peak demand for the winter months as defined in the utility rate schedule (for electrical energy use only) (kW)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:TemporalStatus": {
          "$ref": "#/definitions/.auc:TemporalStatus"
        },
        "auc:TimeSeriesData": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:TimeSeries": {
              "anyOf": [
                {
                  "$ref": "#/definitions/auc:TimeSeriesType"
                },
                {
                  "items": {
                    "$ref": "#/definitions/auc:TimeSeriesType"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:TimeSeries"
          ],
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:WeatherType": {
          "additionalProperties": false,
          "description": "Weather conditions associated with the scenario.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:Actual": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:WeatherDataSource": {
                  "$ref": "#/definitions/.auc:WeatherDataSource"
                }
              },
              "type": "object"
            },
            "auc:AdjustedToYear": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:WeatherYear": {
                  "additionalProperties": false,
                  "description": "Year to which the weather conditions are adjusted. (CCYY)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:gYear"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Normalized": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:NormalizationStartYear": {
                  "additionalProperties": false,
                  "description": "First year included in normalized weather data.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:gYear"
                    }
                  },
                  "type": "object"
                },
                "auc:NormalizationYears": {
                  "additionalProperties": false,
                  "description": "Number of years included in normalized weather data.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:integer"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:WeatherDataSource": {
                  "$ref": "#/definitions/.auc:WeatherDataSource"
                }
              },
              "type": "object"
            },
            "auc:Other": {
              "$ref": "#/definitions/xs:anyType"
            }
          },
          "type": "object"
        }
      },
      "type": "object"
    },
    "auc:ScheduleType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ScheduleDetails": {
          "anyOf": [
            {
              "additionalProperties": false,
              "description": "Type of day for which the schedule will be specified",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:DayEndTime": {
                  "additionalProperties": false,
                  "description": "In military time (00 start of day). If the end hour is the next day, then this day ends at 24 and the next starts at 00 and ends at closing time. For example, a night club may be open from 8PM Friday to 2AM Saturday, then on Friday: Start Hour is 20 and End Hour is 24, and on Saturday: Start hour is 00 and End Hour is 02. (hh:mm:ss.zzz)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:time"
                    }
                  },
                  "type": "object"
                },
                "auc:DayStartTime": {
                  "additionalProperties": false,
                  "description": "In military time (00 start of day). If the night before the schedule runs into this day, then start time is 00, while yesterday's end time is 24. For example, a night club may be open from 8PM Friday to 2AM Saturday, then on Friday: Start Hour is 20 and End Hour is 24, and on Saturday: Start hour is 00 and End Hour is 02. (hh:mm:ss.zzz)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:time"
                    }
                  },
                  "type": "object"
                },
                "auc:DayType": {
                  "additionalProperties": false,
                  "description": "Type of day for which the schedule will be specified",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "All week",
                        "Weekday",
                        "Weekend",
                        "Saturday",
                        "Sunday",
                        "Holiday",
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:PartialOperationPercentage": {
                  "additionalProperties": false,
                  "description": "Percent of category that is in operation. If Schedule Category is Occupancy, then the percent of occupants from typical max. If Schedule Category is equipment, then power as a percent of installed capacity. This field is not used for temperature or relative humidity settings. (0-1) (%)",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:decimal"
                    },
                    "@auc:Source": {
                      "enum": [
                        "Default",
                        "Estimate",
                        "Agent",
                        "Assessor",
                        "Auditor",
                        "Product specification",
                        "Building Component Library",
                        "Utility transfer",
                        "Energy Management System",
                        "Direct measurement",
                        "Design files",
                        "Simulation",
                        "ENERGY STAR Portfolio Manager",
                        "US EPA",
                        "US EIA",
                        "Target Finder",
                        "Arch2030",
                        "ASHRAE",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                },
                "auc:ScheduleCategory": {
                  "additionalProperties": false,
                  "description": "Type of schedule (such as occupancy, lighting, heating, etc.) that will be specified",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Business",
                        "Occupied",
                        "Unoccupied",
                        "Sleeping",
                        "Public access",
                        "Setback",
                        "Operating",
                        "HVAC equipment",
                        "Cooling equipment",
                        "Heating equipment",
                        "Lighting",
                        "Cooking equipment",
                        "Miscellaneous equipment",
                        "On-peak",
                        "Off-peak",
                        "Super off-peak",
                        "Other"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "description": "Type of day for which the schedule will be specified",
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "auc:DayEndTime": {
                    "additionalProperties": false,
                    "description": "In military time (00 start of day). If the end hour is the next day, then this day ends at 24 and the next starts at 00 and ends at closing time. For example, a night club may be open from 8PM Friday to 2AM Saturday, then on Friday: Start Hour is 20 and End Hour is 24, and on Saturday: Start hour is 00 and End Hour is 02. (hh:mm:ss.zzz)",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:time"
                      }
                    },
                    "type": "object"
                  },
                  "auc:DayStartTime": {
                    "additionalProperties": false,
                    "description": "In military time (00 start of day). If the night before the schedule runs into this day, then start time is 00, while yesterday's end time is 24. For example, a night club may be open from 8PM Friday to 2AM Saturday, then on Friday: Start Hour is 20 and End Hour is 24, and on Saturday: Start hour is 00 and End Hour is 02. (hh:mm:ss.zzz)",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:time"
                      }
                    },
                    "type": "object"
                  },
                  "auc:DayType": {
                    "additionalProperties": false,
                    "description": "Type of day for which the schedule will be specified",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "All week",
                          "Weekday",
                          "Weekend",
                          "Saturday",
                          "Sunday",
                          "Holiday",
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:PartialOperationPercentage": {
                    "additionalProperties": false,
                    "description": "Percent of category that is in operation. If Schedule Category is Occupancy, then the percent of occupants from typical max. If Schedule Category is equipment, then power as a percent of installed capacity. This field is not used for temperature or relative humidity settings. (0-1) (%)",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "$ref": "#/definitions/xs:decimal"
                      },
                      "@auc:Source": {
                        "enum": [
                          "Default",
                          "Estimate",
                          "Agent",
                          "Assessor",
                          "Auditor",
                          "Product specification",
                          "Building Component Library",
                          "Utility transfer",
                          "Energy Management System",
                          "Direct measurement",
                          "Design files",
                          "Simulation",
                          "ENERGY STAR Portfolio Manager",
                          "US EPA",
                          "US EIA",
                          "Target Finder",
                          "Arch2030",
                          "ASHRAE",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  },
                  "auc:ScheduleCategory": {
                    "additionalProperties": false,
                    "description": "Type of schedule (such as occupancy, lighting, heating, etc.) that will be specified",
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "$": {
                        "enum": [
                          "Business",
                          "Occupied",
                          "Unoccupied",
                          "Sleeping",
                          "Public access",
                          "Setback",
                          "Operating",
                          "HVAC equipment",
                          "Cooling equipment",
                          "Heating equipment",
                          "Lighting",
                          "Cooking equipment",
                          "Miscellaneous equipment",
                          "On-peak",
                          "Off-peak",
                          "Super off-peak",
                          "Other"
                        ],
                        "type": "string"
                      }
                    },
                    "type": "object"
                  }
                },
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:SchedulePeriodBeginDate": {
          "additionalProperties": false,
          "description": "Date when the schedule begins. (CCYY-MM-DD)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:date"
            }
          },
          "type": "object"
        },
        "auc:SchedulePeriodEndDate": {
          "additionalProperties": false,
          "description": "Date when the schedule ends. (CCYY-MM-DD)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:date"
            }
          },
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        }
      },
      "type": "object"
    },
    "auc:SiteType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "auc:Address": {
          "$ref": "#/definitions/.auc:Address"
        },
        "auc:ClimateZoneType": {
          "additionalProperties": false,
          "description": "The climate zone type, based on the organization defining it. Many different organizations have implemented different climate zone definitions based on their needs. The list below represents the current list. This list can be added to over time based on the collaborative BEDES development process.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:ASHRAE": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ClimateZone": {
                  "additionalProperties": false,
                  "description": "Based on the Climate Zone Type term, this is the climate zone designation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "1A",
                        "1B",
                        "2A",
                        "2B",
                        "3A",
                        "3B",
                        "3C",
                        "4A",
                        "4B",
                        "4C",
                        "5A",
                        "5B",
                        "5C",
                        "6A",
                        "6B",
                        "7",
                        "8"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:BuildingAmerica": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ClimateZone": {
                  "additionalProperties": false,
                  "description": "Based on the Climate Zone Type term, this is the climate zone designation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Subarctic",
                        "Marine",
                        "Hot-dry",
                        "Mixed-dry",
                        "Hot-humid",
                        "Mixed-humid",
                        "Cold",
                        "Very cold"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:CBECS": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ClimateZone": {
                  "additionalProperties": false,
                  "description": "Based on the Climate Zone Type term, this is the climate zone designation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "1",
                        "2",
                        "3",
                        "4",
                        "5"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:CaliforniaTitle24": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ClimateZone": {
                  "additionalProperties": false,
                  "description": "Based on the Climate Zone Type term, this is the climate zone designation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Climate Zone 1",
                        "Climate Zone 2",
                        "Climate Zone 3",
                        "Climate Zone 4",
                        "Climate Zone 5",
                        "Climate Zone 6",
                        "Climate Zone 7",
                        "Climate Zone 8",
                        "Climate Zone 9",
                        "Climate Zone 10",
                        "Climate Zone 11",
                        "Climate Zone 12",
                        "Climate Zone 13",
                        "Climate Zone 14",
                        "Climate Zone 15",
                        "Climate Zone 16"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:DOE": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ClimateZone": {
                  "additionalProperties": false,
                  "description": "Based on the Climate Zone Type term, this is the climate zone designation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Subarctic",
                        "Marine",
                        "Hot-dry",
                        "Mixed-dry",
                        "Hot-humid",
                        "Mixed-humid",
                        "Cold",
                        "Very cold"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:EnergyStar": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ClimateZone": {
                  "additionalProperties": false,
                  "description": "Based on the Climate Zone Type term, this is the climate zone designation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "Northern",
                        "North-Central",
                        "South-Central",
                        "Southern"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:IECC": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ClimateZone": {
                  "additionalProperties": false,
                  "description": "Based on the Climate Zone Type term, this is the climate zone designation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "enum": [
                        "1",
                        "2",
                        "3",
                        "4 (Except Marine)",
                        "4 (Marine)",
                        "5",
                        "6",
                        "7",
                        "8"
                      ],
                      "type": "string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "auc:Other": {
              "additionalProperties": false,
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "auc:ClimateZone": {
                  "additionalProperties": false,
                  "description": "Based on the Climate Zone Type term, this is the climate zone designation.",
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "$": {
                      "$ref": "#/definitions/xs:string"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            }
          },
          "type": "object"
        },
        "auc:Facilities": {
          "additionalProperties": false,
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:Facility": {
              "anyOf": [
                {
                  "$ref": "#/definitions/auc:CommercialFacilityType"
                },
                {
                  "items": {
                    "$ref": "#/definitions/auc:CommercialFacilityType"
                  },
                  "minItems": 1,
                  "type": "array"
                }
              ]
            }
          },
          "required": [
            "auc:Facility"
          ],
          "type": "object"
        },
        "auc:FloorAreas": {
          "$ref": "#/definitions/.auc:FloorAreas"
        },
        "auc:Latitude": {
          "additionalProperties": false,
          "description": "Distance north or south of the equator measured in degrees up to 90 degrees. (degrees)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Longitude": {
          "additionalProperties": false,
          "description": "Distance measured in degrees east or west from an imaginary line (called the prime meridian) that goes from the North Pole to the South Pole and that passes through Greenwich, England. (degrees)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:OccupancyClassification": {
          "$ref": "#/definitions/.auc:OccupancyClassification"
        },
        "auc:Ownership": {
          "$ref": "#/definitions/.auc:Ownership"
        },
        "auc:OwnershipStatus": {
          "$ref": "#/definitions/.auc:OwnershipStatus"
        },
        "auc:PremisesIdentifiers": {
          "$ref": "#/definitions/.auc:PremisesIdentifiers"
        },
        "auc:PremisesName": {
          "$ref": "#/definitions/.auc:PremisesName"
        },
        "auc:PremisesNotes": {
          "$ref": "#/definitions/.auc:PremisesNotes"
        },
        "auc:PrimaryContactID": {
          "$ref": "#/definitions/.auc:PrimaryContactID"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:WeatherDataStationID": {
          "additionalProperties": false,
          "description": "For an actual weather station, this is the ID assigned by NOAA. For hourly energy simulations, this is the six digit code associated with the hourly weather data, generally found in the name of the weather data file, as well as in the header of the data file. (NNNNNN)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:WeatherStationCategory": {
          "additionalProperties": false,
          "description": "Describes the type of weather station used to specify the site's weather.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "FAA",
                "ICAO",
                "NWS",
                "WBAN",
                "WMO",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WeatherStationName": {
          "additionalProperties": false,
          "description": "The name of the weather station associated with this premises, which could be used for simulations, weather normalization, anomaly resolution, etc. For simulations, this is usually the name of the weather file, but the name is also in the header of the data file (TMY, IWEC), such as USA_CO_Denver.Intl.AP",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:string"
            }
          },
          "type": "object"
        },
        "auc:eGRIDRegionCode": {
          "additionalProperties": false,
          "description": "The eGRID (Emissions and Generation Resource Database) region code associated with the data being described.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "AKGD",
                "AKMS",
                "AZNM",
                "CAMX",
                "ERCT",
                "FRCC",
                "HIMS",
                "HIOA",
                "MORE",
                "MROW",
                "NEWE",
                "NWPP",
                "NYCW",
                "NYLI",
                "NYUP",
                "RFCE",
                "RFCM",
                "RFCW",
                "RMPA",
                "SPNO",
                "SPSO",
                "SRMV",
                "SRMW",
                "SRSO",
                "SRTV",
                "SRVC",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        }
      },
      "type": "object"
    },
    "auc:SpaceType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "auc:ConditionedVolume": {
          "additionalProperties": false,
          "description": "Heated or cooled air volume of a premises. (ft3)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:integer"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DaylightingIlluminanceSetpoint": {
          "additionalProperties": false,
          "description": "Lighting level used for controlling electric lights when daylighting is available. (lux)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DaylitFloorArea": {
          "additionalProperties": false,
          "description": "Area (ft^2) that the space is daylit. (ft^2)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            }
          },
          "type": "object"
        },
        "auc:FloorAreas": {
          "$ref": "#/definitions/.auc:FloorAreas"
        },
        "auc:OccupancyClassification": {
          "$ref": "#/definitions/.auc:OccupancyClassification"
        },
        "auc:OccupancyLevels": {
          "$ref": "#/definitions/.auc:OccupancyLevels"
        },
        "auc:OccupancyScheduleID": {
          "anyOf": [
            {
              "additionalProperties": false,
              "description": "ID numbers of the occupancy schedules associated with the space.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "@IDref": {
                  "$ref": "#/definitions/xs:IDREF"
                }
              },
              "required": [
                "@IDref"
              ],
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "description": "ID numbers of the occupancy schedules associated with the space.",
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "@IDref": {
                    "$ref": "#/definitions/xs:IDREF"
                  }
                },
                "required": [
                  "@IDref"
                ],
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:OccupantsActivityLevel": {
          "additionalProperties": false,
          "description": "The activity level that drives the amount of internal gains due to occupants. \"Low\" corresponds to typical office/retail work (Sensible load 250 Btu/hr, Latent load 200 Btu/hr), \"High\" corresponds to heavier factory work or gymnasiums (Sensible load 580 Btu/hr, Latent load 870 Btu/hr)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Low",
                "High",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PercentageOfCommonSpace": {
          "additionalProperties": false,
          "description": "Percentage of gross floor area that is common space only. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PremisesIdentifiers": {
          "anyOf": [
            {
              "$ref": "#/definitions/.auc:PremisesIdentifiers"
            },
            {
              "items": {
                "$ref": "#/definitions/.auc:PremisesIdentifiers"
              },
              "type": "array"
            }
          ]
        },
        "auc:PremisesName": {
          "$ref": "#/definitions/.auc:PremisesName"
        },
        "auc:PremisesNotes": {
          "$ref": "#/definitions/.auc:PremisesNotes"
        },
        "auc:PrimaryContactID": {
          "$ref": "#/definitions/.auc:PrimaryContactID"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        }
      },
      "type": "object"
    },
    "auc:State": {
      "enum": [
        "AA",
        "AE",
        "AL",
        "AK",
        "AP",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY"
      ],
      "type": "string"
    },
    "auc:TelephoneNumber": {
      "type": "string"
    },
    "auc:ThermalZoneType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "auc:ACAdjusted": {
          "additionalProperties": false,
          "description": "Times when the HVAC equipment is setback. For example, when the heat is lowered during the heating season, or the cooling setpoint increased during the cooling season.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "During the day",
                "At night",
                "During sleeping and unoccupied hours",
                "Seasonal",
                "Never-rarely",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:DeliveryID": {
          "anyOf": [
            {
              "additionalProperties": false,
              "description": "ID number of HVAC delivery systems supporting the zone",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "@IDref": {
                  "$ref": "#/definitions/xs:IDREF"
                }
              },
              "required": [
                "@IDref"
              ],
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "description": "ID number of HVAC delivery systems supporting the zone",
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "@IDref": {
                    "$ref": "#/definitions/xs:IDREF"
                  }
                },
                "required": [
                  "@IDref"
                ],
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:HVACScheduleID": {
          "anyOf": [
            {
              "additionalProperties": false,
              "description": "ID numbers of the heating, cooling, or other HVAC schedules associated with the zone.",
              "patternProperties": {
                "^@xmlns(:\\w+)?$": {
                  "type": "string"
                },
                "^@xsi:\\w+$": {
                  "type": "string"
                },
                "^\\?\\w+$": {
                  "type": "string"
                }
              },
              "properties": {
                "#": {
                  "type": "string"
                },
                "@IDref": {
                  "$ref": "#/definitions/xs:IDREF"
                }
              },
              "required": [
                "@IDref"
              ],
              "type": "object"
            },
            {
              "items": {
                "additionalProperties": false,
                "description": "ID numbers of the heating, cooling, or other HVAC schedules associated with the zone.",
                "patternProperties": {
                  "^@xmlns(:\\w+)?$": {
                    "type": "string"
                  },
                  "^@xsi:\\w+$": {
                    "type": "string"
                  },
                  "^\\?\\w+$": {
                    "type": "string"
                  }
                },
                "properties": {
                  "#": {
                    "type": "string"
                  },
                  "@IDref": {
                    "$ref": "#/definitions/xs:IDREF"
                  }
                },
                "required": [
                  "@IDref"
                ],
                "type": "object"
              },
              "type": "array"
            }
          ]
        },
        "auc:HeatLowered": {
          "additionalProperties": false,
          "description": "Times when the HVAC equipment is setback. For example, when the heat is lowered during the heating season, or the cooling setpoint increased during the cooling season.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "During the day",
                "At night",
                "During sleeping and unoccupied hours",
                "Never / rarely",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:PremisesName": {
          "$ref": "#/definitions/.auc:PremisesName"
        },
        "auc:SetbackTemperatureHeating": {
          "additionalProperties": false,
          "description": "Room temperature setting during reset periods. (°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:SetpointTemperatureCooling": {
          "additionalProperties": false,
          "description": "The lowest allowed range in setpoint. If there is no range, then the low and high setpoints are the same. (°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:SetpointTemperatureHeating": {
          "additionalProperties": false,
          "description": "The lowest allowed range in setpoint. If there is no range, then the low and high setpoints are the same. (°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:SetupTemperatureCooling": {
          "additionalProperties": false,
          "description": "Room temperature setting during reset periods. (°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Spaces": {
          "additionalProperties": false,
          "description": "Areas of a building that share systems characteristics such as occupancy, plug loads, or lighting.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:Space": {
              "$ref": "#/definitions/auc:SpaceType"
            }
          },
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        }
      },
      "type": "object"
    },
    "auc:TimeSeriesType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "auc:CDDBaseTemperature": {
          "additionalProperties": false,
          "description": "Reference temperature for calculating Cooling Degree Days (CDD). (°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:CoolingDegreeDays": {
          "additionalProperties": false,
          "description": "Cooling degree days are calculated as the sum of the differences between daily average temperatures and the base temperature, calculated at the ASHRAE base temperature of 65F, unless otherwise specified. Use the Interval Frequency term to characterize whether the HDD calculation is for annual or monthly intervals. (°F-days)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:EndTimeStamp": {
          "additionalProperties": false,
          "description": "The timestamp that marks the end of the time series. (CCYY-MM-DDThh:mm:ss.zzz)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:dateTime"
            }
          },
          "type": "object"
        },
        "auc:EnergyFlowDirection": {
          "additionalProperties": false,
          "description": "Direction associated with current related time series data.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Forward",
                "Reverse",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:HDDBaseTemperature": {
          "additionalProperties": false,
          "description": "Reference temperature for calculating Heating Degree Days (HDD). (°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:HeatingDegreeDays": {
          "additionalProperties": false,
          "description": "Heating degree days are calculated as the sum of the differences between daily average temperatures and the base temperature, calculated at the ASHRAE base temperature of 50F, unless otherwise specified. Use the Interval Frequency term to characterize whether the HDD calculation is for annual or monthly intervals. (°F-days)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:IntervalFrequency": {
          "additionalProperties": false,
          "description": "Indicates frequency of data that's available for a given variable. Data that's available can range from 10 minute interval to annual. This interval frequency can be applied to resource or other time series data like weather.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "10-minute",
                "15-minute",
                "30-minute",
                "Hourly",
                "Daily",
                "Weekly",
                "Monthly",
                "Annually",
                "Quarterly",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:IntervalReading": {
          "additionalProperties": false,
          "description": "The numerical value of the reading. This has to be paired with Reading Type to specify whether this reading is mean, point, median, peak or minimum",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Phase": {
          "additionalProperties": false,
          "description": "Phase information associated with electricity readings",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Phase AN",
                "Phase A",
                "Phase AB",
                "Phase BN",
                "Phase B",
                "Phase CN",
                "Phase C",
                "Phase ABC",
                "Phase BC",
                "Phase CA",
                "Phase S1",
                "Phase S2",
                "Phase S1S2",
                "Phase S1N",
                "Phase S2N",
                "Phase S1S2N",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ReadingType": {
          "additionalProperties": false,
          "description": "Type of data recorded by the meter or other source.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Point",
                "Median",
                "Average",
                "Total",
                "Peak",
                "Minimum",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ResourceUseID": {
          "additionalProperties": false,
          "description": "ID number of resource use that this time series contributes to. This field is not used for non-energy data such as weather.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "@IDref": {
              "$ref": "#/definitions/xs:IDREF"
            }
          },
          "required": [
            "@IDref"
          ],
          "type": "object"
        },
        "auc:StartTimeStamp": {
          "additionalProperties": false,
          "description": "The timestamp that marks the beginning of the time series. (CCYY-MM-DDThh:mm:ss.zzz)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:dateTime"
            }
          },
          "type": "object"
        },
        "auc:TimeSeriesReadingQuantity": {
          "additionalProperties": false,
          "description": "Type of energy, water, power, weather metric included in the time series",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Currency",
                "Current",
                "Current Angle",
                "Demand",
                "Frequency",
                "Power",
                "Power Factor",
                "Energy",
                "Voltage",
                "Voltage Angle",
                "Distortion Power Factor",
                "Volumetric Flow",
                "Humidity ratio",
                "Relative humidity",
                "Diffuse Horizontal Radiation",
                "Direct Normal Radiation",
                "Global Horizontal Radiation",
                "Dry-Bulb Temperature",
                "Wet-Bulb Temperature",
                "Wind Speed",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        }
      },
      "type": "object"
    },
    "auc:WallSystemType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:CMUFill": {
          "additionalProperties": false,
          "description": "The fill condition of hollow unit masonry walls. The definitions correspond to the following conditions:\n\nSolid: Where every cell is grouted\n\nEmpty: Where the cells are partially grouted and the remaining cells are left empty\n\nInsulated: Where the cells are partially grouted and the remaining cells are filled with insulating material.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Empty",
                "Insulated",
                "Solid",
                "Unknown",
                "Not Applicable"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ExteriorRoughness": {
          "additionalProperties": false,
          "description": "A description of the roughness of the exposed surface of a material. This property is used to approximate the effect of the surface condition on the convection of air across the surface. In energy simulation models, it is used to help determine the convection coefficients for a surface.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Very rough",
                "Rough",
                "Medium rough",
                "Medium smooth",
                "Smooth",
                "Very smooth",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ExteriorWallColor": {
          "additionalProperties": false,
          "description": "Color of a material or component. Can be applied to opaque surfaces, materials, and so forth.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "White",
                "Light",
                "Medium",
                "Med-dark",
                "Dark",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:ExteriorWallConstruction": {
          "additionalProperties": false,
          "description": "The general description of the main structural construction method used for an opaque surface.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/auc:EnvelopeConstructionType"
            }
          },
          "type": "object"
        },
        "auc:ExteriorWallFinish": {
          "additionalProperties": false,
          "description": "The final material applied to a surface, either interior or exterior. Some structural components don't have an exterior finish, such as unfinished poured concrete.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Wood",
                "Stone",
                "Tile",
                "Brick",
                "Masonry",
                "Concrete",
                "Metal",
                "Metal panel",
                "Metal panel-standing seam",
                "Sheet metal",
                "EIFS",
                "Shingles-asphalt",
                "Shingles-composition",
                "Shingles-wood",
                "Shingles-asbestos",
                "Shingles-slate or tile",
                "Shakes-wood",
                "Carpet",
                "Linoleum",
                "Asphalt or fiberglass",
                "Plastic-rubber-synthetic sheeting",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:InteriorVisibleAbsorptance": {
          "additionalProperties": false,
          "description": "The fraction of incident visible wavelength radiation that is absorbed by the material or surface. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:WallExteriorSolarAbsorptance": {
          "additionalProperties": false,
          "description": "The fraction of incident radiation in the solar spectrum that is absorbed by the material or surface. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WallExteriorThermalAbsorptance": {
          "additionalProperties": false,
          "description": "The fraction of incident long wavelength infrared radiation that is absorbed by the material or surface. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WallFramingDepth": {
          "additionalProperties": false,
          "description": "Dimension of the distance from the front to the back, such as the depth of structural framing in a wall or floor. It can also be the distance from the top to the bottom, such as the depth of a tank or pool of a component or material, such as the depth of the structural framing. (in.)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WallFramingFactor": {
          "additionalProperties": false,
          "description": "Fraction of the surface that is composed of structural framing material. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WallFramingMaterial": {
          "additionalProperties": false,
          "description": "The material used to create the structural integrity in an opaque surface. In many cases the framing material is not continuous across the construction.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Wood",
                "Steel",
                "Concrete",
                "Brick",
                "Masonry",
                "Other",
                "Unknown",
                "None"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WallFramingSpacing": {
          "additionalProperties": false,
          "description": "Dimension of the distance between two components.\t\n\nExamples include: \nFraming spacing: the dimension from centerline to centerline of a surface framing material\n\nWindow spacing: the dimension between windows in a discrete window layout. (in.)\t\t",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WallInsulations": {
          "additionalProperties": false,
          "description": "A description of the type of insulation and how it is applied.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "auc:WallInsulation": {
              "anyOf": [
                {
                  "additionalProperties": false,
                  "patternProperties": {
                    "^@xmlns(:\\w+)?$": {
                      "type": "string"
                    },
                    "^@xsi:\\w+$": {
                      "type": "string"
                    },
                    "^\\?\\w+$": {
                      "type": "string"
                    }
                  },
                  "properties": {
                    "#": {
                      "type": "string"
                    },
                    "auc:WallInsulationApplication": {
                      "additionalProperties": false,
                      "description": "A description of the type of insulation and how it is applied.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "Loose-fill",
                            "Batt",
                            "Spray-on",
                            "Rigid",
                            "Other",
                            "Unknown",
                            "None"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:WallInsulationCondition": {
                      "additionalProperties": false,
                      "description": "Assessed condition of installed insulation.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "Excellent",
                            "Good",
                            "Average",
                            "Poor",
                            "Other",
                            "Unknown",
                            "None"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:WallInsulationContinuity": {
                      "additionalProperties": false,
                      "description": "Insulation installation type.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "Cavity",
                            "Continuous",
                            "Other",
                            "Unknown",
                            "None"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:WallInsulationLocation": {
                      "additionalProperties": false,
                      "description": "Whether wall insulation is on the inside or outside of the wall.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "enum": [
                            "Interior",
                            "Exterior",
                            "Unknown",
                            "None"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:WallInsulationMaterial": {
                      "additionalProperties": false,
                      "description": "Material used for the structural component of the surface.",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/auc:InsulationMaterialType"
                        }
                      },
                      "type": "object"
                    },
                    "auc:WallInsulationRValue": {
                      "additionalProperties": false,
                      "description": "Insulation R Value of the layer (hr-ft2-F/Btu)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "auc:WallInsulationThickness": {
                      "additionalProperties": false,
                      "description": "Thickness of wall insulation. (in.)",
                      "patternProperties": {
                        "^@xmlns(:\\w+)?$": {
                          "type": "string"
                        },
                        "^@xsi:\\w+$": {
                          "type": "string"
                        },
                        "^\\?\\w+$": {
                          "type": "string"
                        }
                      },
                      "properties": {
                        "#": {
                          "type": "string"
                        },
                        "$": {
                          "$ref": "#/definitions/xs:decimal"
                        },
                        "@auc:Source": {
                          "enum": [
                            "Default",
                            "Estimate",
                            "Agent",
                            "Assessor",
                            "Auditor",
                            "Product specification",
                            "Building Component Library",
                            "Utility transfer",
                            "Energy Management System",
                            "Direct measurement",
                            "Design files",
                            "Simulation",
                            "ENERGY STAR Portfolio Manager",
                            "US EPA",
                            "US EIA",
                            "Target Finder",
                            "Arch2030",
                            "ASHRAE",
                            "Other"
                          ],
                          "type": "string"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                },
                {
                  "items": {
                    "additionalProperties": false,
                    "patternProperties": {
                      "^@xmlns(:\\w+)?$": {
                        "type": "string"
                      },
                      "^@xsi:\\w+$": {
                        "type": "string"
                      },
                      "^\\?\\w+$": {
                        "type": "string"
                      }
                    },
                    "properties": {
                      "#": {
                        "type": "string"
                      },
                      "auc:WallInsulationApplication": {
                        "additionalProperties": false,
                        "description": "A description of the type of insulation and how it is applied.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "enum": [
                              "Loose-fill",
                              "Batt",
                              "Spray-on",
                              "Rigid",
                              "Other",
                              "Unknown",
                              "None"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:WallInsulationCondition": {
                        "additionalProperties": false,
                        "description": "Assessed condition of installed insulation.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "enum": [
                              "Excellent",
                              "Good",
                              "Average",
                              "Poor",
                              "Other",
                              "Unknown",
                              "None"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:WallInsulationContinuity": {
                        "additionalProperties": false,
                        "description": "Insulation installation type.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "enum": [
                              "Cavity",
                              "Continuous",
                              "Other",
                              "Unknown",
                              "None"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:WallInsulationLocation": {
                        "additionalProperties": false,
                        "description": "Whether wall insulation is on the inside or outside of the wall.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "enum": [
                              "Interior",
                              "Exterior",
                              "Unknown",
                              "None"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:WallInsulationMaterial": {
                        "additionalProperties": false,
                        "description": "Material used for the structural component of the surface.",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/auc:InsulationMaterialType"
                          }
                        },
                        "type": "object"
                      },
                      "auc:WallInsulationRValue": {
                        "additionalProperties": false,
                        "description": "Insulation R Value of the layer (hr-ft2-F/Btu)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      },
                      "auc:WallInsulationThickness": {
                        "additionalProperties": false,
                        "description": "Thickness of wall insulation. (in.)",
                        "patternProperties": {
                          "^@xmlns(:\\w+)?$": {
                            "type": "string"
                          },
                          "^@xsi:\\w+$": {
                            "type": "string"
                          },
                          "^\\?\\w+$": {
                            "type": "string"
                          }
                        },
                        "properties": {
                          "#": {
                            "type": "string"
                          },
                          "$": {
                            "$ref": "#/definitions/xs:decimal"
                          },
                          "@auc:Source": {
                            "enum": [
                              "Default",
                              "Estimate",
                              "Agent",
                              "Assessor",
                              "Auditor",
                              "Product specification",
                              "Building Component Library",
                              "Utility transfer",
                              "Energy Management System",
                              "Direct measurement",
                              "Design files",
                              "Simulation",
                              "ENERGY STAR Portfolio Manager",
                              "US EPA",
                              "US EIA",
                              "Target Finder",
                              "Arch2030",
                              "ASHRAE",
                              "Other"
                            ],
                            "type": "string"
                          }
                        },
                        "type": "object"
                      }
                    },
                    "type": "object"
                  },
                  "type": "array"
                }
              ]
            }
          },
          "type": "object"
        },
        "auc:WallRValue": {
          "additionalProperties": false,
          "description": "(Also known as thermal resistance), quantity determined by the temperature difference, at steady state, between two defined surfaces of a material or construction that induces a unit heat flow rate through unit area (R = ΔT/q). R-value is the reciprocal of thermal conductance. A unit of thermal resistance used for comparing insulating values of different materials, for the specific thickness of the material. The higher the R-value number, a material, the greater its insulating properties and the slower the heat flow through it. This R-value does not include the interior and exterior air film coefficients. (hr-ft2-F/Btu)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WallUFactor": {
          "additionalProperties": false,
          "description": "The thermal transmission in unit time through a unit area of a particular body or assembly, including its boundary films, divided by the difference between the environmental temperatures on either side of the body or assembly. Note that the U-factor for a construction assembly, including fenestration, includes the interior and exterior film coefficients (the boundary films referenced above). (Btu/hr·ft2·°F)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        }
      },
      "type": "object"
    },
    "auc:WaterUseType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        }
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "@ID": {
          "$ref": "#/definitions/xs:ID"
        },
        "@auc:Status": {
          "enum": [
            "Existing",
            "Proposed",
            "Past",
            "Reference",
            "Other"
          ],
          "type": "string"
        },
        "auc:ControlTechnology": {
          "$ref": "#/definitions/.auc:ControlTechnology"
        },
        "auc:LinkedPremises": {
          "$ref": "#/definitions/.auc:LinkedPremises"
        },
        "auc:Location": {
          "$ref": "#/definitions/.auc:Location"
        },
        "auc:LowFlowFixtures": {
          "additionalProperties": false,
          "description": "True if the fixtures used for this application include aerators, low flow toilets, or showerheads with flow restrictors",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:boolean"
            }
          },
          "type": "object"
        },
        "auc:Manufacturer": {
          "$ref": "#/definitions/.auc:Manufacturer"
        },
        "auc:ModelNumber": {
          "$ref": "#/definitions/.auc:ModelNumber"
        },
        "auc:Quantity": {
          "$ref": "#/definitions/.auc:Quantity"
        },
        "auc:ThirdPartyCertification": {
          "$ref": "#/definitions/.auc:ThirdPartyCertification"
        },
        "auc:UserDefinedFields": {
          "$ref": "#/definitions/.auc:UserDefinedFields"
        },
        "auc:WaterFixtureCyclesPerDay": {
          "additionalProperties": false,
          "description": "Average number of draws per day for this fixture. (cycles/day)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WaterFixtureFractionHotWater": {
          "additionalProperties": false,
          "description": "Average fraction of water use for this application that is drawn from the hot water system. (0-1) (%)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WaterFixtureRatedFlowRate": {
          "additionalProperties": false,
          "description": "Rated volumetric flow rate of the water fixture. (gpm)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WaterFixtureVolumePerCycle": {
          "additionalProperties": false,
          "description": "Average amount of water used per cycle of the fixture. (gal/cycle)",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "$ref": "#/definitions/xs:decimal"
            },
            "@auc:Source": {
              "enum": [
                "Default",
                "Estimate",
                "Agent",
                "Assessor",
                "Auditor",
                "Product specification",
                "Building Component Library",
                "Utility transfer",
                "Energy Management System",
                "Direct measurement",
                "Design files",
                "Simulation",
                "ENERGY STAR Portfolio Manager",
                "US EPA",
                "US EIA",
                "Target Finder",
                "Arch2030",
                "ASHRAE",
                "Other"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:WaterResource": {
          "$ref": "#/definitions/.auc:WaterResource"
        },
        "auc:WaterUseType": {
          "additionalProperties": false,
          "description": "Short description of the water fixture or application.",
          "patternProperties": {
            "^@xmlns(:\\w+)?$": {
              "type": "string"
            },
            "^@xsi:\\w+$": {
              "type": "string"
            },
            "^\\?\\w+$": {
              "type": "string"
            }
          },
          "properties": {
            "#": {
              "type": "string"
            },
            "$": {
              "enum": [
                "Restroom Sink Use",
                "Restroom Toilet/Urinal Water Use",
                "Kitchen Water Use",
                "Shower Facility Water Use",
                "Drinking Fountain Water Use",
                "Janitorial Water Use",
                "Laundry Water Use",
                "Indoor Washdown Water Use (if indoor)",
                "Outdoor Landscape Water Use",
                "Outdoor Non-Landscape Water Use",
                "Outdoor Washdown Water Use (if outdoor)",
                "Cooling Tower Make-up Water Use",
                "Hydronic Loop Make-up Water Use",
                "Evaporative Cooling System Water Use",
                "Pre-Treatment Process Water Use",
                "Captured Rain Water",
                "Recycled Greywater",
                "Condensate Recovery",
                "Stormwater Sewer Production",
                "Stormwater Discharge",
                "Other",
                "Unknown"
              ],
              "type": "string"
            }
          },
          "type": "object"
        },
        "auc:YearInstalled": {
          "$ref": "#/definitions/.auc:YearInstalled"
        },
        "auc:YearofManufacture": {
          "$ref": "#/definitions/.auc:YearofManufacture"
        }
      },
      "type": "object"
    },
    "auc:ZipCode": {
      "type": "string"
    },
    "xs:ID": {
      "pattern": "^([A-Z_a-zÀ-ÖØ-öø-˿Ͱ-ͽͿ-῿‌-‍⁰-↏Ⰰ-⿯、-퟿豈-﷏ﷰ-�𐀀-󯿿][\\d\\--\\.A-Z_a-z·À-ÖØ-öø-ͽͿ-ٟ٪-ۯۺ-޿ߊ-॥॰-৥ৰ-੥ੰ-૥૰-୥୰-௥௰-౥౰-೥೰-൥൰-๏๚-໏໚-༟༪-ဿ၊-ႏႚ-៟៪-᠏᠚-᥅ᥐ-᧏᧚-᩿᪊-᪏᪚-᭏᭚-ᮯᮺ-᰿᱊-ᱏᱚ-῿‌-‍‿-⁀⁰-↏Ⰰ-⿯、-ꘟꘪ-꣏꣚-ꣿꤊ-ꧏ꧚-꩏꩚-꯯꯺-퟿豈-﷏ﷰ-／：-�𐀀-𐒟𐒪-𑁥𑁰-𝟍𝠀-󯿿]*)$",
      "type": "string"
    },
    "xs:IDREF": {
      "pattern": "^([A-Z_a-zÀ-ÖØ-öø-˿Ͱ-ͽͿ-῿‌-‍⁰-↏Ⰰ-⿯、-퟿豈-﷏ﷰ-�𐀀-󯿿][\\d\\--\\.A-Z_a-z·À-ÖØ-öø-ͽͿ-ٟ٪-ۯۺ-޿ߊ-॥॰-৥ৰ-੥ੰ-૥૰-୥୰-௥௰-౥౰-೥೰-൥൰-๏๚-໏໚-༟༪-ဿ၊-ႏႚ-៟៪-᠏᠚-᥅ᥐ-᧏᧚-᩿᪊-᪏᪚-᭏᭚-ᮯᮺ-᰿᱊-ᱏᱚ-῿‌-‍‿-⁀⁰-↏Ⰰ-⿯、-ꘟꘪ-꣏꣚-ꣿꤊ-ꧏ꧚-꩏꩚-꯯꯺-퟿豈-﷏ﷰ-／：-�𐀀-𐒟𐒪-𑁥𑁰-𝟍𝠀-󯿿]*)$",
      "type": "string"
    },
    "xs:anyType": {
      "additionalProperties": false,
      "patternProperties": {
        "^@\\w+$": {
          "type": [
            "string",
            "number",
            "boolean"
          ]
        },
        "^@xmlns(:\\w+)?$": {
          "type": "string"
        },
        "^@xsi:\\w+$": {
          "type": "string"
        },
        "^\\?\\w+$": {
          "type": "string"
        },
        "^\\w+$": {}
      },
      "properties": {
        "#": {
          "type": "string"
        },
        "$": {
          "type": "string"
        }
      },
      "type": "object"
    },
    "xs:boolean": {
      "type": "boolean"
    },
    "xs:date": {
      "type": "string"
    },
    "xs:dateTime": {
      "type": "string"
    },
    "xs:decimal": {
      "type": "number"
    },
    "xs:gMonthDay": {
      "type": "string"
    },
    "xs:gYear": {
      "type": "string"
    },
    "xs:int": {
      "maximum": 2147483647,
      "minimum": -2147483648,
      "type": "integer"
    },
    "xs:integer": {
      "type": "integer"
    },
    "xs:string": {
      "type": "string"
    },
    "xs:time": {
      "type": "string"
    }
  },
  "description": "JSON Schema generated by XMLSpy v2016 rel. 2 (x64) (http://www.altova.com)",
  "patternProperties": {
    "^\\?\\w+$": {
      "type": "string"
    }
  },
  "properties": {
    "#": {
      "type": "string"
    },
    "@xmlns:auc": {
      "default": "http://nrel.gov/schemas/bedes-auc/2014"
    },
    "@xmlns:xs": {
      "default": "http://www.w3.org/2001/XMLSchema"
    },
    "auc:Address": {
      "$ref": "#/definitions/.auc:Address"
    },
    "auc:AnnualCoolingEfficiencyUnits": {
      "$ref": "#/definitions/.auc:AnnualCoolingEfficiencyUnits"
    },
    "auc:AnnualHeatingEfficiencyUnit": {
      "$ref": "#/definitions/.auc:AnnualHeatingEfficiencyUnit"
    },
    "auc:AssetScore": {
      "$ref": "#/definitions/.auc:AssetScore"
    },
    "auc:AssetScoreData": {
      "$ref": "#/definitions/.auc:AssetScoreData"
    },
    "auc:Audits": {
      "$ref": "#/definitions/.auc:Audits"
    },
    "auc:BurnerType": {
      "$ref": "#/definitions/.auc:BurnerType"
    },
    "auc:CalculationMethod": {
      "$ref": "#/definitions/.auc:CalculationMethod"
    },
    "auc:Capacity": {
      "$ref": "#/definitions/.auc:Capacity"
    },
    "auc:CapacityUnits": {
      "$ref": "#/definitions/.auc:CapacityUnits"
    },
    "auc:ClothesWasherClassification": {
      "$ref": "#/definitions/.auc:ClothesWasherClassification"
    },
    "auc:ClothesWasherLoaderType": {
      "$ref": "#/definitions/.auc:ClothesWasherLoaderType"
    },
    "auc:CompressorStaging": {
      "$ref": "#/definitions/.auc:CompressorStaging"
    },
    "auc:ContactEmailAddress": {
      "$ref": "#/definitions/.auc:ContactEmailAddress"
    },
    "auc:ControlTechnology": {
      "$ref": "#/definitions/.auc:ControlTechnology"
    },
    "auc:ControlledByEMCS": {
      "$ref": "#/definitions/.auc:ControlledByEMCS"
    },
    "auc:CostEffectivenessScreeningMethod": {
      "$ref": "#/definitions/.auc:CostEffectivenessScreeningMethod"
    },
    "auc:DraftType": {
      "$ref": "#/definitions/.auc:DraftType"
    },
    "auc:DryerType": {
      "$ref": "#/definitions/.auc:DryerType"
    },
    "auc:DutyCycle": {
      "$ref": "#/definitions/.auc:DutyCycle"
    },
    "auc:ENERGYSTARScore": {
      "$ref": "#/definitions/.auc:ENERGYSTARScore"
    },
    "auc:EnergyResource": {
      "$ref": "#/definitions/.auc:EnergyResource"
    },
    "auc:EnergyUseByFuelType": {
      "$ref": "#/definitions/.auc:EnergyUseByFuelType"
    },
    "auc:FloorAreas": {
      "$ref": "#/definitions/.auc:FloorAreas"
    },
    "auc:FluorescentStartType": {
      "$ref": "#/definitions/.auc:FluorescentStartType"
    },
    "auc:FoundationWallInsulationCondition": {
      "$ref": "#/definitions/.auc:FoundationWallInsulationCondition"
    },
    "auc:FoundationWallInsulationContinuity": {
      "$ref": "#/definitions/.auc:FoundationWallInsulationContinuity"
    },
    "auc:HeatingStaging": {
      "$ref": "#/definitions/.auc:HeatingStaging"
    },
    "auc:IdentifierCustomName": {
      "$ref": "#/definitions/.auc:IdentifierCustomName"
    },
    "auc:IdentifierLabel": {
      "$ref": "#/definitions/.auc:IdentifierLabel"
    },
    "auc:IdentifierValue": {
      "$ref": "#/definitions/.auc:IdentifierValue"
    },
    "auc:IgnitionType": {
      "$ref": "#/definitions/.auc:IgnitionType"
    },
    "auc:LinkedPremises": {
      "$ref": "#/definitions/.auc:LinkedPremises"
    },
    "auc:Location": {
      "$ref": "#/definitions/.auc:Location"
    },
    "auc:Manufacturer": {
      "$ref": "#/definitions/.auc:Manufacturer"
    },
    "auc:ModelNumber": {
      "$ref": "#/definitions/.auc:ModelNumber"
    },
    "auc:OccupancyClassification": {
      "$ref": "#/definitions/.auc:OccupancyClassification"
    },
    "auc:OccupancyLevels": {
      "$ref": "#/definitions/.auc:OccupancyLevels"
    },
    "auc:Ownership": {
      "$ref": "#/definitions/.auc:Ownership"
    },
    "auc:OwnershipStatus": {
      "$ref": "#/definitions/.auc:OwnershipStatus"
    },
    "auc:PremisesIdentifiers": {
      "$ref": "#/definitions/.auc:PremisesIdentifiers"
    },
    "auc:PremisesName": {
      "$ref": "#/definitions/.auc:PremisesName"
    },
    "auc:PremisesNotes": {
      "$ref": "#/definitions/.auc:PremisesNotes"
    },
    "auc:PrimaryContactID": {
      "$ref": "#/definitions/.auc:PrimaryContactID"
    },
    "auc:PrimaryFuel": {
      "$ref": "#/definitions/.auc:PrimaryFuel"
    },
    "auc:Priority": {
      "$ref": "#/definitions/.auc:Priority"
    },
    "auc:Quantity": {
      "$ref": "#/definitions/.auc:Quantity"
    },
    "auc:ReferenceCase": {
      "$ref": "#/definitions/.auc:ReferenceCase"
    },
    "auc:Refrigerant": {
      "$ref": "#/definitions/.auc:Refrigerant"
    },
    "auc:ResourceBoundary": {
      "$ref": "#/definitions/.auc:ResourceBoundary"
    },
    "auc:ResourceUnits": {
      "$ref": "#/definitions/.auc:ResourceUnits"
    },
    "auc:SlabHeating": {
      "$ref": "#/definitions/.auc:SlabHeating"
    },
    "auc:SlabInsulationCondition": {
      "$ref": "#/definitions/.auc:SlabInsulationCondition"
    },
    "auc:SlabInsulationOrientation": {
      "$ref": "#/definitions/.auc:SlabInsulationOrientation"
    },
    "auc:SystemPerformanceRatio": {
      "$ref": "#/definitions/.auc:SystemPerformanceRatio"
    },
    "auc:TemporalStatus": {
      "$ref": "#/definitions/.auc:TemporalStatus"
    },
    "auc:ThirdPartyCertification": {
      "$ref": "#/definitions/.auc:ThirdPartyCertification"
    },
    "auc:UserDefinedFields": {
      "$ref": "#/definitions/.auc:UserDefinedFields"
    },
    "auc:VentilationControlMethod": {
      "$ref": "#/definitions/.auc:VentilationControlMethod"
    },
    "auc:WaterCooledCondenserFlowControl": {
      "$ref": "#/definitions/.auc:WaterCooledCondenserFlowControl"
    },
    "auc:WaterResource": {
      "$ref": "#/definitions/.auc:WaterResource"
    },
    "auc:WaterSideEconomizerType": {
      "$ref": "#/definitions/.auc:WaterSideEconomizerType"
    },
    "auc:WeatherDataSource": {
      "$ref": "#/definitions/.auc:WeatherDataSource"
    },
    "auc:YearInstalled": {
      "$ref": "#/definitions/.auc:YearInstalled"
    },
    "auc:YearofManufacture": {
      "$ref": "#/definitions/.auc:YearofManufacture"
    }
  },
  "type": "object"
};});

